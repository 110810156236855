import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookProvider, LoginButton } from 'react-facebook';
import ButtonLink from 'components/ButtonLink';

import { authFB, FB_SIGNUP_SUCCESSFUL } from 'reducers/usersActions';
import { useDispatch } from 'react-redux';
import { RaazLogo } from 'components/RaazIcons';

const WelcomeScreen = () => {
    const element = document.querySelector('meta[name="fb:app_id"]');
    const fbAppID = element && element.getAttribute('content');

    const dispatch = useDispatch();
    const handleComplete = data => {
        authFB(data).then(response =>
            dispatch({ type: FB_SIGNUP_SUCCESSFUL, payload: response }),
        );
    };

    return (
        <div className="registration h-100 p-5">
            <div className="registration-contents d-flex flex-column align-items-center justify-content-center">
                <div className="logo-container">
                    <div className="logo">
                        <RaazLogo fill="white" />
                        <span>By Ignite House</span>
                    </div>
                </div>

                {fbAppID && (
                    <FacebookProvider appId={fbAppID}>
                        <LoginButton
                            className="btn btn-primary rounded-0 btn-block mt-5 btn-lg"
                            scope="email"
                            onCompleted={handleComplete}
                            onError={() => {}}>
                            <span>Continue with Facebook</span>
                        </LoginButton>
                    </FacebookProvider>
                )}
                <ButtonLink to="/sign-up" block large className="mt-4 mb-5">
                    Register with email
                </ButtonLink>
                <p className="mt-4 mb-3 text-center">
                    I already have an account. <Link to="/login">Log in</Link>
                </p>
                <p
                    className="mt-4 mb-0 registration-terms text-center"
                    style={{ fontSize: '0.9em' }}>
                    By logging in, you agree to our <br />
                    <a href="https://www.ignitegifts.com/terms" target="_blank">
                        Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://www.ignitegifts.com/privacy"
                        target="_blank">
                        Data Privacy Policy
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default WelcomeScreen;
