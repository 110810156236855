import React, { Fragment } from 'react';
import Button from './Button';
import createSchedule from '../utils/createSchedule';
import * as moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import {
    checkIfGrowthLab,
    checkIfExpired,
    checkIfWorkout,
    checkIfBuddyWorkout,
    checkIfPostCard,
    checkIfGiftbox,
} from '../utils/userCardUtils';

const ResumeBadge = () => (
    <div className="StackItem--circular-badge isResume text-uppercase">
        Resume
    </div>
);

export const NextBadge = () => (
    <div className="StackItem--circular-badge isNext text-uppercase">Next</div>
);

export const GLBadge = ({ className, ...props }) => (
    <span
        className={`StackItem--badge is-gl text-uppercase ${
            className ? className : ''
        }`}
        {...props}>
        Growthlab
    </span>
);

export const WorkoutBadge = () => (
    <span className="StackItem--badge is-workout text-uppercase">Workout</span>
);

export const PostcardBadge = ({ className, ...props }) => (
    <span
        className={`StackItem--badge is-pc text-uppercase ${
            className ? className : ''
        }`}
        {...props}>
        Postcard
    </span>
);

export const GiftboxBadge = ({ className, ...props }) => (
    <span
        className={`StackItem--badge is-gc text-uppercase ${
            className ? className : ''
        }`}
        {...props}>
        Giftbox
    </span>
);

export const CardBadge = ({ type, ...props }) => {
    switch (type) {
        case 'Postcard':
            return <PostcardBadge {...props} />;
        case 'Workout':
        case 'Buddy Workout':
            return <WorkoutBadge {...props} />;
        case 'Growthlab':
            return <GLBadge {...props} />;
        case 'Mastery':
            return (
                <span className="StackItem--badge is-workout text-uppercase">
                    Workout
                </span>
            );
        default:
            return (
                <span className="StackItem--badge is-time text-uppercase">
                    {type}
                </span>
            );
    }
};

const StackItemTitle = ({ children }) => (
    <h4
        className="text-uppercase"
        style={{
            fontWeight: 600,
            fontSize: '14px',
        }}>
        {children}
    </h4>
);

const StackItemDesc = ({ children }) => (
    <p className="mb-5" style={{ color: '#717171', fontSize: '14px' }}>
        {children}
    </p>
);

const createUrl = (type, friendlyId, userCardId) => {
    switch (type) {
        case 'growthlab':
        case 'Growthlab':
        case 'GrowthLab':
            return `/growthlab-card/${friendlyId}`;

        case 'workout':
        case 'Workout':
        case 'Solo Workout':
            return `/mastery/${friendlyId}`;

        case 'Buddy Workout':
            return `/buddy-workout/${friendlyId}`;

        case 'Postcard':
            return `/post-card/${friendlyId}`;

        case 'GiftBox':
        case 'giftbox':
            return `/giftbox/${userCardId}`;

        default:
            return '';
    }
};

const StackItem = ({
    type,
    title,
    time,
    description,
    claimed,
    event,
    imageUrl,
    cardId,
    isFavorited,
    userId,
    friendlyId,
    className = '',
    isNext,
    isStarted,
}) => {
    const isGrowthlab = checkIfGrowthLab(type);
    const isExpired = checkIfExpired(event, type);
    const isWorkout = checkIfWorkout(type);
    const isBuddyWorkout = checkIfBuddyWorkout(type);
    const isGiftbox = checkIfGiftbox(type);
    const isPostCard = checkIfPostCard(type);
    const schedule = event
        ? createSchedule(event.date, event.startTime, event.endTime)
        : null;
    const isAttendee = event ? event.isAttendee : null;

    const to = createUrl(type, friendlyId, cardId);

    return (
        <div className={`StackItem--container ${className} mb-3`}>
            {isNext && <NextBadge />}
            {!isNext && isStarted && !claimed ? <ResumeBadge /> : null}
            <div className="StackItem">
                <div
                    className="StackItem--cover"
                    style={
                        imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}
                    }
                />
                <div className="StackItem--details px-4 pt-5 pb-4 d-flex flex-column">
                    <div className="d-flex align-items-stretch mb-4">
                        {isGrowthlab && (
                            <Fragment>
                                <GLBadge />
                                {schedule && (
                                    <span
                                        className="ml-2 StackItem--badge is-time"
                                        style={
                                            isExpired
                                                ? { color: '#F65050' }
                                                : {}
                                        }>
                                        {schedule}
                                    </span>
                                )}
                            </Fragment>
                        )}
                        {isWorkout || isBuddyWorkout ? <WorkoutBadge /> : null}
                        {isGiftbox && (
                            <Fragment>
                                <GiftboxBadge />
                                <span className="ml-2 StackItem--badge is-time">
                                    Collect Anytime
                                </span>
                            </Fragment>
                        )}
                        {isPostCard && <PostcardBadge />}

                        {time && (
                            <span className="ml-2 StackItem--badge is-time">
                                {time}
                            </span>
                        )}
                    </div>
                    <StackItemTitle>
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </StackItemTitle>
                    <StackItemDesc>
                        {description.length > 120
                            ? `${description.slice(0, 120)}...`
                            : description}
                    </StackItemDesc>
                    {!isGiftbox && (
                        /*
                        Claim - walang attendance, future/ongoing event
                        Review - may attendance, hindi pa claimed yung card
                        Claimed - may attendance, claimed yung card
                        Lapsed - hindi claimed yung card, past event
                        */
                        <div className="d-flex align-items-stretch mt-auto">
                            {isGrowthlab && (
                                <Fragment>
                                    {!claimed && !isAttendee ? (
                                        <Button
                                            disabled={isExpired}
                                            className="ml-auto d-flex align-items-center text-uppercase"
                                            rounded
                                            primary={!isExpired}
                                            light={isExpired}
                                            style={{ fontSize: '14px' }}
                                            to={!isExpired ? to : null}>
                                            {isExpired ? (
                                                <span>Lapsed</span>
                                            ) : (
                                                <Fragment>
                                                    <span className="mr-2">
                                                        {!isNext && isStarted
                                                            ? 'Resume'
                                                            : 'Claim'}
                                                    </span>
                                                    <i className="fe fe-arrow-right" />
                                                </Fragment>
                                            )}
                                        </Button>
                                    ) : null}
                                    {!claimed && isAttendee ? (
                                        <Button
                                            className="ml-auto d-flex align-items-center text-uppercase"
                                            rounded
                                            transparent
                                            to={to}
                                            style={{
                                                color: '#717171',
                                                fontSize: '14px',
                                            }}>
                                            <span className="mr-2">Review</span>
                                            <i className="fe fe-arrow-right" />
                                        </Button>
                                    ) : null}
                                    {claimed && isAttendee ? (
                                        <Button
                                            className="ml-auto d-flex align-items-center text-uppercase"
                                            rounded
                                            disabled
                                            light
                                            style={{
                                                color: '#276ef1',
                                                fontSize: '14px',
                                            }}>
                                            <span>Claimed</span>
                                        </Button>
                                    ) : null}
                                </Fragment>
                            )}

                            {!isGrowthlab && (
                                <Fragment>
                                    {!claimed && (
                                        <Button
                                            disabled={isExpired}
                                            className="ml-auto d-flex align-items-center text-uppercase"
                                            rounded
                                            primary={!isExpired}
                                            light={isExpired}
                                            style={{ fontSize: '14px' }}
                                            to={!isExpired ? to : null}>
                                            {isExpired ? (
                                                <span>Lapsed</span>
                                            ) : (
                                                <Fragment>
                                                    <span className="mr-2">
                                                        {!isNext && isStarted
                                                            ? 'Resume'
                                                            : 'Claim'}
                                                    </span>
                                                    <i className="fe fe-arrow-right" />
                                                </Fragment>
                                            )}
                                        </Button>
                                    )}

                                    {claimed && (
                                        <Button
                                            className="ml-auto d-flex align-items-center text-uppercase"
                                            rounded
                                            transparent
                                            to={to}
                                            style={{
                                                color: '#717171',
                                                fontSize: '14px',
                                            }}>
                                            <span className="mr-2">Review</span>
                                            <i className="fe fe-arrow-right" />
                                        </Button>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}
                    {isGiftbox && (
                        <div className="d-flex align-items-stretch mt-auto">
                            <Button
                                to={to}
                                className="ml-auto d-flex align-items-center text-uppercase"
                                rounded
                                primary
                                style={{ fontSize: '14px' }}>
                                <span className="mr-2">Collect</span>
                                <i className="fe fe-arrow-right" />
                            </Button>
                        </div>
                    )}

                    <FavoriteUserCardButton
                        userCardId={cardId}
                        isFavorited={isFavorited}
                        userId={userId}
                    />
                </div>
            </div>
        </div>
    );
};

export const GiftboxStack = ({
    title,
    time,
    description,
    claimed,
    locked = false,
    id,
    imageUrl,
}) => (
    <div className="StackItem--container mb-3">
        <div className="StackItem">
            <div
                className="StackItem--cover"
                style={!locked ? { backgroundImage: `url(${imageUrl})` } : {}}>
                {locked && (
                    <div
                        className="LockCover d-flex flex-column align-items-center justify-content-center"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${imageUrl})`,
                        }}>
                        <span
                            className="d-flex align-items-center justify-content-center mb-2"
                            style={{
                                width: '3.75rem',
                                height: '3.75rem',
                                position: 'relative',
                                borderRadius: '50%',
                                backgroundColor: 'white',
                            }}>
                            <i
                                className="fe fe-lock"
                                style={{
                                    color: '#A0A0A0',
                                    fontSize: '2.5rem',
                                }}
                            />
                        </span>
                        <span
                            className="text-uppercase text-white"
                            style={{ fontSize: '20px' }}>
                            Card Locked
                        </span>
                    </div>
                )}
            </div>
            <div className="StackItem--details px-4 pt-5 pb-4 d-flex flex-column">
                <div className="d-flex align-items-stretch mb-4">
                    <GiftboxBadge />

                    <span className="ml-2 StackItem--badge is-time">
                        Collect Anytime
                    </span>
                </div>
                <StackItemTitle>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                </StackItemTitle>
                {description && <StackItemDesc>{description}</StackItemDesc>}
                {locked && (
                    <div className="d-flex align-items-stretch mt-auto w-100">
                        <div
                            className="p-3 w-100 text-center"
                            style={{
                                backgroundColor: '#F7F7F7',
                                color: '#121212',
                                borderRadius: '2px',
                                fontSize: '14px',
                            }}>
                            Complete growth cards to unlock
                        </div>
                    </div>
                )}
                {!locked && (
                    <div className="d-flex align-items-stretch mt-auto">
                        {!claimed ? (
                            <Button
                                className="ml-auto d-flex align-items-center text-uppercase"
                                rounded
                                to={`/giftbox-collection/${id}`}
                                primary
                                style={{ fontSize: '14px' }}>
                                <span className="mr-2">Claim</span>
                                <i className="fe fe-arrow-right" />
                            </Button>
                        ) : (
                            <Button
                                className="ml-auto d-flex align-items-center text-uppercase"
                                rounded
                                transparent
                                to={`/giftbox-collection/${id}`}
                                style={{
                                    color: '#717171',
                                    fontSize: '14px',
                                }}>
                                <span className="mr-2">Review</span>
                                <i className="fe fe-arrow-right" />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>
);

const FAVORITE_USER_CARD = gql`
    mutation FavoriteCard($input: FavoriteUserCardInput!) {
        favoriteUserCard(input: $input) {
            errors
            success
            userCard {
                id
                isFavorited
            }
        }
    }
`;

const FavoriteUserCardButton = ({ isFavorited, userId, userCardId }) => {
    const [favoriteCard, { data, loading }] = useMutation(FAVORITE_USER_CARD);

    const onClick = async () => {
        const a = await favoriteCard({
            variables: {
                input: {
                    favorite: !isFavorited,
                    userId,
                    userCardId,
                },
            },
        });
        if (a.data.favoriteUserCard.success) {
        }
        try {
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Button
            onClick={onClick}
            rounded
            isLoading={loading}
            smallLoader
            style={{
                position: 'absolute',
                top: '-1rem',
                right: '1.5rem',
                backgroundColor: isFavorited ? '#FF862D' : '#F7F7F7',
                color: '#717171',
                borderRadius: '50%',
                zIndex: 3,
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            }}>
            <i
                className="fe fe-heart"
                style={isFavorited ? { color: 'white' } : {}}
            />
        </Button>
    );
};

export default StackItem;
