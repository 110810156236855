import { FETCH_STOPS_SUCCESS } from './stopsActions';

const initialState = {
    stops: [],
    stop_groups: [],
    iggy_next: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STOPS_SUCCESS:
            return {
                ...state,
                stops: action.payload.stops,
                stop_groups: action.payload.stop_groups,
                iggy_next: action.payload.iggy_next,
            };
        default:
            return state;
    }
};

export default reducer;
