import React, { Fragment, useEffect, useState } from 'react';
import { GiftboxTitle } from '../components/ProjectTitle';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { DefaultMediaObject } from '../components/GiftboxMediaObject';
import {
    useParams,
    Route,
    useRouteMatch,
    Switch,
    useHistory,
} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { Formik, Form, Field } from 'formik';
import { OpenTextV2 } from '../components/QuestionInputV2';
import { PostcardBadge } from '../components/StackItem';
import START_USER_CARD from './mutations/startUserCard';

const GET_POSTCARD = gql`
    query GetPostcard($friendlyId: ID!) {
        currentUser {
            id
        }
        card(friendlyId: $friendlyId) {
            id
            affinityStream {
                id
                name
            }
            event {
                id
                archiveUrl
            }
            title
            imageUrl
            ordinality
            description
            userCard {
                id
                postcardMeta
            }
        }
    }
`;

const ADD_POSTCARD_TAKEAWAY = gql`
    mutation AddAnswerToBuddyWorkout(
        $answerPostcardInput: AnswerPostcardInput!
    ) {
        answerPostcard(input: $answerPostcardInput) {
            errors
            success
            userCard {
                postcardMeta
                id
                isClaimed
            }
        }
    }
`;

const PostcardTakeaway = ({ name, ans, userId, userCardId }) => {
    const [submitPostcardTakeaway] = useMutation(ADD_POSTCARD_TAKEAWAY);
    const history = useHistory();
    return (
        <Formik
            onSubmit={async (answers, { setSubmitting, setErrors }) => {
                const a = await submitPostcardTakeaway({
                    variables: {
                        answerPostcardInput: {
                            userId,
                            userCardId,
                            answers,
                        },
                    },
                });
                setSubmitting(true);
                history.push('/');
                try {
                    setSubmitting(false);
                } catch (error) {
                    setErrors(error);
                    setSubmitting(false);
                }
            }}
            initialValues={{
                keyTakeaways: '',
                ...ans,
            }}>
            {({ isSubmitting }) => (
                <Fragment>
                    <Form className="p-5">
                        <PostcardBadge className="d-inline-block mb-2" />
                        <Field
                            required
                            name="keyTakeaways"
                            component={OpenTextV2}
                            label={`In your own words. Please share your key takeway from this ${name} Postcard. Key takeaway refer specially to the behaviour and practices you believe are important for you to adopt.`}
                        />
                        <div className="d-flex w-100 mt-5">
                            <Button
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                rounded
                                style={{ borderRadius: '2px' }}
                                className="ml-auto"
                                type="submit"
                                primary>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};

const PostcardScreen = ({ userId }) => {
    const { id } = useParams();
    const showBack = useRouteMatch('/post-card/:id/takeaways');
    const r = useQuery(GET_POSTCARD, {
        variables: {
            friendlyId: id,
        },
    });

    const [startUserCard, { loading }] = useMutation(START_USER_CARD);
    useEffect(() => {
        if (r.data && r.data.card) {
            startUserCard({
                variables: {
                    input: {
                        userCardId: r.data.card.userCard.id,
                        start: true,
                        userId,
                    },
                },
            });
        }
    }, [r.data]);

    if (!r.data && r.loading) {
        return <Loader fullscreen />;
    }

    const { card, currentUser } = r.data;

    return (
        <Fragment>
            <GiftboxTitle
                title={showBack ? card.title : 'Personal Growth'}
                subtitle={showBack ? 'Postcard' : ''}
                showBack={showBack}
            />
            <div
                className="py-5 px-3 mx-auto mt-5"
                style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <Switch>
                    <Route path="/post-card/:id/takeaways">
                        <PostcardTakeaway
                            name={card.affinityStream.name}
                            userId={currentUser.id}
                            userCardId={card.userCard.id}
                            ans={card.userCard.postcardMeta}
                        />
                    </Route>
                    <Route path="/post-card/:id">
                        <div
                            className="py-5 d-flex align-items-center w-100"
                            style={{ minHeight: 'calc(100vh - 200px)' }}>
                            <DefaultMediaObject
                                to={`/post-card/${id}/takeaways`}
                                buttonText="Continue"
                                type="Postcard"
                                heading={card.affinityStream.name}
                                body={card.description}
                                coverImage={card.imageUrl}>
                                <Button
                                    href={card.event.archiveUrl}
                                    rounded
                                    primary
                                    target="_self"
                                    style={{
                                        borderRadius: '2px',
                                    }}
                                    className="d-flex align-items-center mr-4">
                                    Continue
                                </Button>
                            </DefaultMediaObject>
                        </div>
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default PostcardScreen;
