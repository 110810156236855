import React from 'react';
import Button from './Button';
import { GLBadge, GiftboxBadge } from './StackItem';
import createSchedule from '../utils/createSchedule';
import ReactAddToCalendar from 'react-add-to-calendar';
import { getStartTime, getEndTime } from '../utils/getStartEndTime';

const GrowthLabEvent = ({ currentEvent, giftbox }) => {
    const startTime = getStartTime(
        currentEvent.date,
        currentEvent.startTime || currentEvent.start_time,
    );
    const endTime = getEndTime(
        currentEvent.date,
        currentEvent.endTime || currentEvent.end_time,
    );
    const schedule = currentEvent
        ? createSchedule(
              currentEvent.date,
              currentEvent.startTime || currentEvent.start_time,
              currentEvent.endTime || currentEvent.end_time,
          )
        : null;

    let event = {
        title: currentEvent.name,
        description: currentEvent.description,
        location: currentEvent.location || '',
        startTime: startTime.format(),
        endTime: endTime.format(),
    };
    return (
        <div className="media mb-5 d-flex">
            <div className="mr-3 col">
                <div
                    style={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${currentEvent.coverImageUrl})`,
                        width: '350px',
                        height: '252px',
                    }}
                />
            </div>
            <div className="media-body col">
                <div className="d-flex align-items-center">
                    {giftbox && <GiftboxBadge className="mr-2" />} <GLBadge />
                </div>
                <h3
                    className="lora mb-4 mt-3 large-text"
                    dangerouslySetInnerHTML={{ __html: currentEvent.name }}
                />
                <p className="mb-4" style={{ fontSize: '20px' }}>
                    {currentEvent.description}
                </p>
                {schedule && (
                    <p
                        className="mb-3"
                        style={{ fontSize: '14px', color: '#717171' }}>
                        {schedule}
                    </p>
                )}
                <div className="d-flex align-items-center">
                    {!currentEvent.isCurrent ? (
                        <ReactAddToCalendar
                            event={event}
                            buttonLabel="Add to calendar"
                            useFontAwesomeIcons={false}
                            className="btn btn-primary"
                            buttonTemplate={{ 'fe fe-calendar': 'right' }}
                        />
                    ) : (
                        <Button
                            transparent
                            style={{ color: '#276ef1' }}
                            className="d-flex align-items-center"
                            to={`/growthlab-app/${currentEvent.id}`}>
                            <span className="mr-2">Next</span>
                            <i className="fe fe-external-link" />
                        </Button>
                    )}
                    <Button
                        primary
                        rounded
                        style={{ borderRadius: '2px' }}
                        className="d-flex align-items-center"
                        href={currentEvent.webcastUrl}>
                        <span className="mr-2">Join Webcast </span>
                        <i className="fe fe-video" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default GrowthLabEvent;
