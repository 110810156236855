import React from 'react';
import ReactDOM from 'react-dom';
import 'animate.css';
import * as serviceWorker from 'mobile-ui/serviceWorker';
import App from 'mobile-ui/app';
import Provider from '../components/Provider';
import { ApolloProvider } from '@apollo/client';
import { client } from '../client';

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('mobile-ui')) {
        ReactDOM.render(
            <ApolloProvider client={client}>
                <Provider>
                    <App />
                </Provider>
            </ApolloProvider>,
            document.getElementById('mobile-ui'),
        );
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
