import { gql } from '@apollo/client';
import cardParts from './cardParts';

const GET_USER_CARD = gql`
    query getUserCard($id: ID!, $userId: ID) {
        userCard(id: $id) {
            id
            giftboxMeta
            ordinality
            mustReads {
                id
                imageUrl
                name
                url
                description
            }
            card {
                ...cardParts
            }
        }
    }

    ${cardParts}
`;

export default GET_USER_CARD;
