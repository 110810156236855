import React, { useEffect, Fragment } from 'react';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import DashboardScreen from './DashboardScreen';
import ItineraryGroupScreen from './ItineraryGroupScreen';
import ContactUsScreen from './ContactUsScreen';
import {
    POPULATE_ITINERARY_GROUPS,
    TOGGLE_APP_AREA_LOCK,
} from '../reducers/itineraryActions';
import { useDispatch } from 'react-redux';
import { getItineraryGroups } from '../reducers/usersActions';
import GrowthLabScreen, { GrowthLabScreenWithEventId } from './GrowthLabScreen';
import CurrentAreaScreen from './CurrentAreaScreen';
import CurrentAreaScreenWithEventId from './CurrentAreaScreenWithEventId';
import consumer from '../channels/consumer';
import RaazScreen from './RaazScreen';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import IggyJrScreen from './IggyJrScreen';
const CHANNEL = { channel: 'AppAreasChannel' };
const GRAPHQL_CHANNEL = { channel: 'GraphqlChannel' };

const GrowthLab = () => {
    const history = useHistory();
    const goHome = () => history.push('/');

    return (
        <div className="mx-auto" style={{ maxWidth: MAX_WIDTH }}>
            <BrowserRouter basename="/growthlab-app">
                <Switch>
                    <Route path="/groups/:groupSlug/itinerary/:itineraryId/:slug">
                        <CurrentAreaScreen />
                    </Route>
                    <Route path="/groups/:groupSlug/itinerary/:itineraryId">
                        <GrowthLabScreen />
                    </Route>
                    <Route path="/groups/:groupSlug">
                        <ItineraryGroupScreen />
                    </Route>
                    <Route path="/:eventId/:slug">
                        <CurrentAreaScreenWithEventId />
                    </Route>
                    <Route path="/:eventId">
                        <GrowthLabScreenWithEventId goHome={goHome} />
                    </Route>
                    <Route path="/" exact>
                        <DashboardScreen />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
};

const PrivateScreen = () => {
    const dispatch = useDispatch();

    return (
        <Switch>
            <Route path="/contact-us">
                <div className="mx-auto" style={{ maxWidth: MAX_WIDTH }}>
                    <ContactUsScreen />
                </div>
            </Route>
            <Route path="/">
                <RaazScreen />
            </Route>
        </Switch>
    );
};

export default PrivateScreen;
