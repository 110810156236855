export const MAX_WIDTH = 600;
const OPTION_TAGS = [
    {
        value: `Education - K-12, Higher Ed, Tech/Voc, Special Ed, etc.`,
        body: `Education - K-12, Higher Ed, Tech/Voc, Special Ed, etc.`,
    },
    { value: `Youth, Children & Families`, body: `Youth, Children & Families` },
    {
        value: `Economic Opportunity - Careers, SME, Entrepreneurship, Rural & Urban Development, etc.`,
        body: `Economic Opportunity - Careers, SME, Entrepreneurship, Rural & Urban Development, etc.`,
    },
    {
        value: `Leadership, Mentoring, Human Capital & Organizational Development`,
        body: `Leadership, Mentoring, Human Capital & Organizational Development`,
    },
    {
        value: `Health & Wellbeing - Medicine, Psychology, Fitness, Sports, Addiction, etc.`,
        body: `Health & Wellbeing - Medicine, Psychology, Fitness, Sports, Addiction, etc.`,
    },
    {
        value: `Relationships, Spirituality, Faith & Religion`,
        body: `Relationships, Spirituality, Faith & Religion`,
    },
    {
        value: `Environment- Sustainability, Agriculture, Forestry & Fishery`,
        body: `Environment- Sustainability, Agriculture, Forestry & Fishery`,
    },
    {
        value: `Discrimination - Race, Gender, Religion, Seniors, Disabilities, Indigenous, Peoples, etc`,
        body: `Discrimination - Race, Gender, Religion, Seniors, Disabilities, Indigenous, Peoples, etc`,
    },
    {
        value: `Social Justice - Poverty, Hunger, Healthcare, Housing, Human Rights, etc.`,
        body: `Social Justice - Poverty, Hunger, Healthcare, Housing, Human Rights, etc.`,
    },
    {
        value: `Cruelty & Violence - War, Refugees, Human Trafficking, Animal Rights, etc.`,
        body: `Cruelty & Violence - War, Refugees, Human Trafficking, Animal Rights, etc.`,
    },
    {
        value: `Science & Technology, Engineering & Math`,
        body: `Science & Technology, Engineering & Math`,
    },
    { value: `Beauty, Fashion & Design`, body: `Beauty, Fashion & Design` },
    {
        value: `Media, Communications, Arts & Entertainment`,
        body: `Media, Communications, Arts & Entertainment`,
    },
    {
        value: `Hospitality, Leisure, Food & Travel`,
        body: `Hospitality, Leisure, Food & Travel`,
    },
    {
        value: `Professional & Business Services`,
        body: `Professional & Business Services`,
    },
    {
        value: `Banking, Insurance & Financial Services`,
        body: `Banking, Insurance & Financial Services`,
    },
    {
        value: `Architecture, Construction & Real Estate`,
        body: `Architecture, Construction & Real Estate`,
    },
    {
        value: `Manufacturing, Trading, Retail & Distribution`,
        body: `Manufacturing, Trading, Retail & Distribution`,
    },
    {
        value: `Transport, Telecommunications & Logistics`,
        body: `Transport, Telecommunications & Logistics`,
    },
    { value: `Utilities, Energy & Mining`, body: `Utilities, Energy & Mining` },
    {
        value: `Government & Politics, Defense & Military`,
        body: `Government & Politics, Defense & Military`,
    },
];
export const HURDLES = [
    {
        id: 'sample-hundred-h',
        name: 'Setup',
        ordinality: 1,
        stop_type: 'setup',
        is_locked: false,
        is_done: false,
        points: 5,
        sub_stops: {
            preamble: true,
            solo_workout: true,
            buddy: false,
            growthlab: false,
            postcard: false,
            dataviz: false,
        },
        preamble: {
            body:
                'Invite members into the project. Use an online collaboration tool to bring everyone together.',
        },
        solo_workout: {
            id: 'setup-solo-workout',
            slug: 'setup-solo-workout',
            sections: [
                {
                    id: 363,
                    discernment_id: 27,
                    ordinality: 1,
                    title: null,
                    subtitle: null,
                    content: [
                        {
                            id: 499,
                            ordinality: 1,
                            question: 'Title of Project',
                            question_key: 'stop1-q1',
                            question_type: 'text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                    ],
                },
            ],
            points: 5,
        },
    },
    {
        id: 'target-stakeholder',
        name: 'Target Stakeholder',
        ordinality: 2,
        stop_type: 'hurdles',
        is_locked: false,
        is_done: false,
        points: 5,
        sub_stops: {
            preamble: true,
            solo_workout: true,
            buddy: false,
            growthlab: false,
            postcard: false,
            dataviz: true,
        },
        preamble: {
            body:
                'Describe your foremost stakeholder who will benefit from your 100H Launch. To keep it real, look at them through the lens of a real person you have encountered and describe this person to represent your target stakeholder.',
        },
        solo_workout: {
            id: 't-stakeholder',
            slug: 't-stakeholder',
            sections: [
                {
                    id: 364,
                    discernment_id: 28,
                    ordinality: 1,
                    title: null,
                    subtitle: null,
                    content: [
                        {
                            id: 506,
                            ordinality: 1,
                            question:
                                'Describe the target stakeholder who will benefit from our project.',
                            question_key: 'hurdle1-q1',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 507,
                            ordinality: 2,
                            question:
                                'How have we encountered them in meaningful ways and why do we care about solving their problems?',
                            question_key: 'hurdle1-q2',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 510,
                            ordinality: 3,
                            question:
                                'Select the tag that best describes our target stakeholder',
                            question_key: 'hurdle1-q3',
                            question_type: 'radio',
                            optional: false,
                            help_text: '',
                            sample_answer: '',
                            tags: null,
                            max: 2,
                            options: [
                                {
                                    body: 'Customers B2C',
                                    value: 'Customers B2C',
                                },
                                {
                                    body: 'Customers B2B',
                                    value: 'Customers B2B',
                                },
                                {
                                    body: 'Workforce',
                                    value: 'Workforce',
                                },
                                { body: 'Others', value: 'Others' },
                            ],
                        },
                        {
                            id: 507,
                            ordinality: 4,
                            question: 'Reference material (if any)',
                            question_key: 'hurdle1-q4',
                            question_type: 'file_upload',
                            optional: true,
                            help_text: null,
                            sample_answer: null,
                        },
                    ],
                },
            ],
            points: 5,
        },
    },
    {
        id: 'target-impact',
        name: 'Target Impact',
        ordinality: 3,
        stop_type: 'hurdles',
        is_locked: false,
        is_done: false,
        points: 20,
        sub_stops: {
            preamby: true,
            solo_workout: true,
            buddy: false,
            growthlab: false,
            postcard: false,
            dataviz: false,
        },
        preamble: {
            body:
                'Focusing on your Target Stakeholder, describe their key pains, needs, wants, dreams, etc you seek to address via your 100H Launch. Keep your energies focused on your most meaningful impact to them. ',
        },
        solo_workout: {
            id: 't-impact',
            slug: 't-impact',
            sections: [
                {
                    id: 365,
                    discernment_id: 29,
                    ordinality: 1,
                    title: null,
                    subtitle: null,
                    content: [
                        {
                            id: 517,
                            ordinality: 1,
                            question:
                                'Describe Impact here (pains, needs, wants, dreams, opportunities) of your stakeholder ',
                            question_key: 'hurdle2-q1',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 518,
                            ordinality: 2,
                            question:
                                'What is missing or not working well that is causing this problem for so many members of your target stakeholder group',
                            question_key: 'hurdle2-q2',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 520,
                            ordinality: 3,
                            question:
                                'Given the particular time of crisis. How has the pain worsened for our target Stakeholder?',
                            question_key: 'hurdle2-q3',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 521,
                            ordinality: 4,
                            question:
                                'Select up to 3 tags that best describe the target Problem.',
                            question_key: 'hurdle2-q4',
                            question_type: 'select',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                            max: 3,
                            options: OPTION_TAGS,
                        },
                    ],
                },
            ],
            points: 10,
        },
    },
    {
        id: 'target-delta',
        name: 'Target Delta',
        ordinality: 4,
        stop_type: 'hurdles',
        is_locked: false,
        is_done: false,
        points: 15,
        sub_stops: {
            preambe: true,
            solo_workout: true,
            buddy: false,
            growthlab: false,
            postcard: false,
            dataviz: false,
        },
        preambe: {
            body:
                'Complete your From-To Delta Statement to describe your vision of change. What changes for the better for your Target Stakeholder before and after your intervention? ',
        },
        solo_workout: {
            id: 't-delta',
            slug: 't-delta',
            sections: [
                {
                    id: 366,
                    discernment_id: 30,
                    ordinality: 1,
                    title: null,
                    subtitle: null,
                    content: [
                        {
                            id: 528,
                            ordinality: 2,
                            question: `Complete the Delta Statement below referencing what changes for our Target Stakeholder.

From:

To:

Examples:

From: Feeling alone about their situation.
To: Feeling connected with kindred spirits that they share the same problem and seek the same solutions

From: Having irregular income and jobs
To: Enjoying regular income from stable jobs`,
                            question_key: 'hurdle3-q1',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                        {
                            id: 529,
                            ordinality: 3,
                            question: 'Impact on Target Stakeholder',
                            question_key: 'hurdle3-q2',
                            question_type: 'horizontal_radio',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                            options: [
                                { body: '1', value: '1' },
                                { body: '2', value: '2' },
                                { body: '3', value: '3' },
                                { body: '4', value: '4' },
                            ],
                            slider_left_label: 'Not Much',
                            slider_right_label: 'Very Much',
                        },
                        {
                            id: 530,
                            ordinality: 4,
                            question: 'Date Started',
                            question_key: 'hurdle3-q3',
                            question_type: 'open_text',
                            optional: false,
                            help_text: null,
                            sample_answer: null,
                        },
                    ],
                },
            ],
            points: 5,
        },
    },
];

export const PEER_EXCHANGE_DATA = [
    {
        name: 'Carlota Monteiro',
        content:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
    {
        name: 'Gibby Radki',
        content:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
    {
        name: 'Harrison Phillips',
        content:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
    {
        name: 'Mattie Blooman',
        content:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
    {
        name: 'Su Xinyi',
        content:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
];

export const ITERATED_RESPONSE_DATA = [
    {
        name: 'Su Xinyi',
        question:
            'Describe the target stakeholder who will benefit from our project.',
        answer:
            'Whatever gochujang tacos enamel pin, meggings hashtag flexitarian tousled ugh hell of listicle sartorial seitan selvage. Green juice raw denim forage street art, ',
    },
    {
        name: 'Mattie Blooman',
        question: 'Select the tag that best describes our target stakeholder',
        answer: 'Customers B2C',
    },
];

export const PERSONAL_GROWTH = 'PERSONAL_GROWTH';
export const BUSINESS_IMPACT = 'BUSINESS_IMPACT';
export const DAILY_CALENDAR = 'DAILY_CALENDAR';
