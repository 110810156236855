import React, { Fragment, useState, useEffect } from 'react';
import { useScroll } from '../../hooks/useScroll';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import Loader from '../../components/Loader';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { Link, useHistory } from 'react-router-dom';
import {
    TargetIcon,
    BrainstormIcon,
    RefineIcon,
    TestIcon,
    LaunchIcon,
} from '../../components/IteratedResponseIcons';
import { addFinalResponse } from '../../reducers/itineraryActions';
import Button from '../../components/Button';

const LINKS = [
    {
        question: 'Figure out a better Target Delta?',
        icon: TargetIcon,
        hurdle: 3,
    },
    { question: 'Brainstorm for new Ideas?', icon: BrainstormIcon, hurdle: 4 },
    {
        question: 'Refine the prototype for further testing?',
        icon: RefineIcon,
        hurdle: 6,
    },
    {
        question: 'Test the same prototype to gather more data and evidence?',
        icon: TestIcon,
        hurdle: 3,
    },
    {
        question:
            'Prep it for launch since it’s testing well and delighting our users or customers?',
        icon: LaunchIcon,
        hurdle: 3,
    },
];

const IteratedResponseLink = ({ icon: Icon, hurdle = 0, question, to }) => (
    <Link
        to={to}
        className="IteratedResponseLink d-flex align-items-center p-3 w-100 mb-4">
        <Icon height={24} />
        <span className="d-block ml-3">
            <strong className="d-block mb-1">{question}</strong>
            <span className="d-block">Please redo Hurdle {hurdle} onwards</span>
        </span>
    </Link>
);

const IteratedResponseStop8 = ({ stop, projectId, stops, isOwner }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { sections, slug } = stop.hundred_h.find(
        h => h.type === 'iterated_response',
    );

    const submit = () => {
        const data = {
            discernment_slug: slug,
            project_id: projectId,
            stop_id: stop && stop.id,
            answers: [
                {
                    question_key: 'hurdle_8',
                    values: ['done'],
                },
            ],
        };
        dispatch(addFinalResponse(data)).then(
            res => {
                history.push(`/100h/projects/${projectId}`);
            },
            () => {},
        );
    };

    const createLink = (stopId, id) =>
        `/100h/projects/${projectId}/hurdles/${stopId}/solo_workout/${id}`;

    const WithSoloworkoutLink = LINKS.map(l => {
        const s = stops.find(
            stop => stop.solo_workout.slug === `hurdle-${l.hurdle}-discernment`,
        );
        return {
            ...l,
            to: createLink(s.id, s.solo_workout.id),
        };
    });

    return (
        <div
            className="d-flex flex-column mx-auto"
            style={{ position: 'relative', maxWidth: MAX_WIDTH }}>
            <div className="d-flex flex-column w-100">
                <div className="py-5">
                    <h2 className="header-pretitle isRaaz">ITERATED WORKOUT</h2>
                    <h1 className="lora">Should we?</h1>
                    <p className="mb-4">
                        Now we need to look at all data and feedback we’ve
                        gathered from sharing our prototypes with the users or
                        customers. Let’s make an informed decision on what steps
                        to take next.
                    </p>
                    {WithSoloworkoutLink.map((l, i) => (
                        <IteratedResponseLink
                            question={l.question}
                            hurdle={l.hurdle}
                            key={i}
                            icon={l.icon}
                            to={l.to}
                        />
                    ))}
                    <div className="mt-5 py-5 w-100 d-flex justify-content-end">
                        {isOwner ? (
                            <Button primary type="button" onClick={submit}>
                                Next
                            </Button>
                        ) : (
                            <Button
                                primary
                                type="button"
                                to={`/100h/projects/${projectId}`}>
                                Next
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IteratedResponseStop8;
