import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
    MAX_WIDTH,
    PERSONAL_GROWTH,
    BUSINESS_IMPACT,
    DAILY_CALENDAR,
} from '../utils/CONSTANTS';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../components/Button';
import {
    ChartIcon,
    RocketIcon,
    StackIcon,
    UserIcon,
    VideoIcon,
} from '../components/RaazIcons';
import Stack from '../components/Stack';
import Loader from '../components/Loader';
import { gql, useQuery } from '@apollo/client';
import * as moment from 'moment';
import UpcomingEvent from '../components/UpcomingEvent';
import GrowthLabEvent from '../components/GrowthLabEvent';
import cardParts from './queries/cardParts';
import { Link } from 'react-router-dom';
import { getStartTime } from '../utils/getStartEndTime';
import groupBy from 'lodash.groupby';
import HorizontalUserCards, {
    HorizontalNextCards,
} from '../components/HorizontalUserCards';
import AllUserCards from '../components/AllUserCards';
import Scorecards from '../components/Scorecards';

const projectParts = gql`
    fragment projectParts on Project {
        id
        archived
        description
        members
        ordinality
        title
        currentStop(userId: $userId) {
            id
            duration
            name
            ordinality
            stopType
        }
        currentSubstop(userId: $userId)
        owner {
            id
            displayName
            avatar
            email
        }
        users {
            id
            displayName
            avatar
            email
        }
        stops {
            discernment {
                id
                name
                slug
                ordinality
            }
            id
            duration
            name
            ordinality
            stopType
        }
        costs
        partners
        sales
        workforce
        customers
    }
`;

const GET_USER_CARDS = gql`
    query GetUserCards($userId: ID!) {
        userCards(userId: $userId) {
            ...userCardParts
            isStarted
        }

        nextCards(userId: $userId) {
            ...userCardParts
        }

        nextProject(userId: $userId) {
            ...projectParts
        }
    }

    fragment userCardParts on UserCard {
        id
        isClaimed
        isFavorited
        ordinality
        giftboxMeta
        postcardMeta
        buddyAnswers
        buddies {
            avatar
            displayName
            selfTitle
            title
        }
        card {
            ...cardParts
        }
    }

    ${projectParts}
    ${cardParts}
`;

const GET_DASHBOARD_DATA = gql`
    query GetDashboardData($userId: ID!) {
        projects(userId: $userId) {
            ...projectParts
        }

        getDashboardStats(userId: $userId)

        events {
            archiveUrl
            date
            endTime
            groupSize
            id
            isCurrent
            learnMoreUrl
            name
            startTime
            webcastUrl
            coverImageUrl
            speakers
        }

        giftboxCards(userId: $userId) {
            ...cardParts
        }
    }

    ${projectParts}
    ${cardParts}
`;

const H_CARDS = [
    {
        k: 'GrowthLab',
        desc:
            'Click on these cards to join our daily webcasts - where we gather as a community and apply best practices in peak performance to live case studies presented by peers.',
    },
    {
        k: 'Postcard',
        desc:
            'Re-live the characters, stories, and case studies from each community gathering. Includes full video, highlights, quotable quotes, and key takeaways. Just add yours!',
    },
    {
        k: 'Workout',
        desc:
            'Peak performance demands regular and rigorous practice. Complete these cards to keep powering your next level breakthroughs.',
    },
    {
        k: 'GiftBox',
        desc:
            'Each Giftbox holds personalized recommendations based on your latest breakthroughs - to accelerate your next breakthroughs!',
    },
];

const NewDashboard = ({ user }) => {
    const [activeTab, setActiveTab] = useState(PERSONAL_GROWTH);
    const [showHorizontal, setShowHorizontal] = useState(true);
    const [currentAllCards, setAllCards] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef();

    const r = useQuery(GET_DASHBOARD_DATA, {
        variables: {
            userId: user.id,
        },
    });

    const r2 = useQuery(GET_USER_CARDS, {
        variables: {
            userId: user.id,
        },
        pollInterval: 10000,
    });

    const initialCards = r2.data ? r2.data.userCards : [];
    const groupedCards = groupBy(initialCards, 'card.cardType');
    const nCards = r2.data ? r2.data.nextCards.filter(Boolean) : [];
    const horizontalCards = H_CARDS.map(k => {
        if (Object.keys(groupedCards).length) {
            if (k.k === 'Workout') {
                return {
                    title: k.k,
                    cards: groupedCards[k.k].concat(
                        groupedCards['Buddy Workout'] || [],
                    ),
                    desc: k.desc,
                };
            } else {
                return {
                    title: k.k,
                    cards: groupedCards[k.k] || [],
                    desc: k.desc,
                };
            }
        } else {
            return {};
        }
    });
    const currentCards = horizontalCards.find(h => h.title === currentAllCards);

    useEffect(() => {
        window.showPersonalGrowth = () => {
            ref.current &&
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'start',
                });
            setActiveTab(PERSONAL_GROWTH);
        };
        window.showBusinessImpact = () => {
            ref.current &&
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'start',
                });
            setActiveTab(BUSINESS_IMPACT);
        };
    }, []);

    if (r.loading || r2.loading) return <Loader fullscreen />;

    const events = r.data.events;
    const projects = r.data.projects;
    const dashboardStats = r.data.getDashboardStats;
    const upcomingEvents = events.filter(
        e => e.date && moment().diff(e.date) < 0 && !e.isCurrent,
    );

    const currentEvent = events.find(e => e.isCurrent);
    let done = initialCards.filter(p => p.isClaimed).length;

    const hasProjects = projects && projects.length > 0;

    return (
        <div className="w-100">
            <Scorecards
                user={user}
                dashboardStats={dashboardStats}
                activeTab={activeTab}
                done={done}
                projectCount={projects.length}
            />
            <div
                className="mx-auto py-5"
                style={{
                    maxWidth: MAX_WIDTH * 1.3,
                    fontSize: '18px',
                    position: 'relative',
                }}>
                <div className="DashboardAnchors pb-5 mt-5">
                    <Button
                        onClick={() => setActiveTab(PERSONAL_GROWTH)}
                        transparent
                        className={`DashboardAnchor py-3 px-5 ${
                            activeTab === PERSONAL_GROWTH ? 'isActive' : ''
                        }`}>
                        <span className="text-uppercase">Personal Growth</span>
                    </Button>
                    <Button
                        onClick={() => false}
                        onMouseEnter={() => {
                            setShowTooltip(true);
                        }}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                        }}
                        transparent
                        style={{ position: 'relative' }}
                        className="DashboardAnchor py-3 px-5 isDisabled">
                        <span className="text-uppercase">Empathy Projects</span>
                        {showTooltip && (
                            <span
                                className="d-flex text-uppercase flex-column p-3 Btn--tooltip"
                                style={{ top: '5rem', left: '6rem' }}>
                                <span>Coming Soon!</span>
                            </span>
                        )}
                    </Button>
                    <Button
                        onClick={() => setActiveTab(BUSINESS_IMPACT)}
                        transparent
                        className={`DashboardAnchor py-3 px-5 ${
                            activeTab === BUSINESS_IMPACT ? 'isActive' : ''
                        }`}>
                        <span className="text-uppercase">Business Impact</span>
                    </Button>
                    <Button
                        onClick={() => setActiveTab(DAILY_CALENDAR)}
                        transparent
                        className={`DashboardAnchor py-3 px-5 ${
                            activeTab === DAILY_CALENDAR ? 'isActive' : ''
                        }`}>
                        <span className="text-uppercase">Daily Calendar</span>
                    </Button>
                </div>
            </div>

            <HorizontalNextCards
                title="What's Next"
                cards={nCards}
                userId={user.id}
                activeTab={activeTab}
                nextProject={r2.data.nextProject || null}
                desc="We update this stack in real time based on your latest breakthroughs. Just complete these cards to keep powering your next level breakthroughs."
            />
            <div ref={ref} style={{ height: 0 }} />

            {activeTab === PERSONAL_GROWTH && (
                <Fragment>
                    {showHorizontal &&
                        horizontalCards.map((hc, i) => (
                            <HorizontalUserCards
                                title={hc.title}
                                cards={hc.cards}
                                userId={user.id}
                                alt={i % 2 === 0}
                                desc={hc.desc}
                                nextCardIds={nCards.map(c => c.id)}
                                key={i}
                                setAll={title => {
                                    setShowHorizontal(false);
                                    setAllCards(title);
                                }}
                            />
                        ))}
                    {!showHorizontal && (
                        <AllUserCards
                            type={PERSONAL_GROWTH}
                            cards={currentCards.cards}
                            title={currentAllCards}
                            desc={currentCards.desc}
                            user={user}
                            goBack={() => {
                                setShowHorizontal(true);
                                setAllCards('');
                            }}
                        />
                    )}
                </Fragment>
            )}
            {activeTab === BUSINESS_IMPACT && (
                <div
                    className="pb-5 mx-auto"
                    style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                    <h2
                        className="lora pb-5 w-100 large-text"
                        style={{
                            borderBottom: '1px solid #DADADA',
                        }}>
                        Business Impact
                    </h2>
                    {hasProjects ? (
                        <Fragment>
                            {projects.map((p, i) => (
                                <Stack
                                    idx={i + 1}
                                    key={i}
                                    type={BUSINESS_IMPACT}
                                    project={p}
                                />
                            ))}
                            <div className="NewProjectButton--container">
                                <Button
                                    to="/100h/projects/new"
                                    className="text-uppercase px-5"
                                    style={{ borderRadius: '2px' }}
                                    rounded
                                    primary>
                                    <i className="fe fe-plus" />
                                    <span className="ml-2">
                                        create new project
                                    </span>
                                </Button>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="text-center">
                            <h2 className="text-center w-100 mx-auto my-5 font-weight-bold">
                                Welcome to 100H Launchpad
                            </h2>
                            <div className="d-flex align-items-start justify-content-space-between text-left mb-4">
                                <div className="px-5 w-100 d-flex flex-column">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ height: '24px' }}>
                                        <RocketIcon
                                            fill="#276ef1"
                                            height="24"
                                        />
                                    </div>
                                    <p className="mt-3">
                                        Welcome to 100-HOUR LAUNCHPAD, your
                                        Business Impact space within our
                                        community of practice where you will
                                        launch new products, services, solutions
                                        and models - fast, lean, and agile.
                                    </p>
                                </div>
                                <div className="px-5 w-100 d-flex flex-column">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ height: '24px' }}>
                                        <UserIcon fill="#276ef1" height="16" />
                                    </div>
                                    <p className="mt-3">
                                        You can start as many launchpad projects
                                        as you want, while adding hand-picked
                                        community members as collaborators. Each
                                        project guides your team through 9
                                        critical hurdles all the way to launch.
                                    </p>
                                </div>
                                <div className="px-5 w-100 d-flex flex-column">
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ height: '24px' }}>
                                        <VideoIcon fill="#276ef1" height="24" />
                                    </div>
                                    <p className="mt-3">
                                        Highly recommended - for max impact,
                                        join us for{' '}
                                        <Link
                                            className="text-body font-weight-bold"
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                            to="/growthlabs/7842664e-9ccc-4054-af29-9ceacd2fc228">
                                            100H Opportunity Tuesdays
                                        </Link>
                                        and{' '}
                                        <Link
                                            className="text-body font-weight-bold"
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                            to="/growthlabs/27526f63-195b-48e3-a1ad-15092eebf243">
                                            100H Innovation Thursdays
                                        </Link>{' '}
                                        to experience 100H Launchpad in
                                        practice, applied to live business cases
                                        within our community.
                                    </p>
                                </div>
                            </div>
                            <Button
                                to="/100h/projects/new"
                                className="text-uppercase px-5"
                                style={{ borderRadius: '2px' }}
                                rounded
                                primary>
                                <i className="fe fe-plus" />
                                <span className="ml-2">create new project</span>
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {activeTab === DAILY_CALENDAR && (
                <div
                    className="pb-5 mx-auto"
                    style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                    <h2
                        className="lora pb-5 w-100 large-text"
                        style={{
                            borderBottom: '1px solid #DADADA',
                        }}>
                        Daily Calendar
                    </h2>
                    {currentEvent && (
                        <GrowthLabEvent currentEvent={currentEvent} />
                    )}

                    <div style={{ maxWidth: MAX_WIDTH }} className="mx-auto">
                        <h3
                            style={{ fontSize: '20px', fontWeight: 'bold' }}
                            className="mb-4">
                            Upcoming Events
                        </h3>
                        <ul className="list-unstyled growthlab-schedule">
                            {upcomingEvents.length > 0 ? (
                                upcomingEvents
                                    .sort((a, b) =>
                                        getStartTime(a.date, a.startTime).diff(
                                            getStartTime(b.date, b.startTime),
                                        ),
                                    )
                                    .slice(0, 4)
                                    .map((e, i) => {
                                        return (
                                            <UpcomingEvent
                                                key={i}
                                                event_id={e.id}
                                                isCurrent={e.isCurrent}
                                                cover_image={e.coverImageUrl}
                                                name={e.name}
                                                description={e.description}
                                                date={e.date}
                                                start_time={e.startTime}
                                                end_time={e.endTime}
                                                location={e.location}
                                                features={e.features}
                                                learn_more_url={e.learnMoreUrl}
                                            />
                                        );
                                    })
                            ) : (
                                <li className="w-100 p-4">
                                    No upcoming events.
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewDashboard;
