import * as moment from 'moment';

export const getStartTime = (date, start_time) => {
    const check = moment(date);
    let startTime;
    if (start_time && start_time.length > 5) {
        startTime = moment(start_time);
        startTime
            .date(check.date())
            .month(check.month())
            .year(check.year());
    } else {
        const [startHour, startMinute] = start_time
            ? start_time.split(':')
            : [0, 0];

        startTime = check.hour(startHour || 0).minute(startMinute || 0);
    }

    return startTime;
};

export const getEndTime = (date, end_time) => {
    const check = moment(date);
    let endTime;
    if (end_time && end_time.length > 5) {
        endTime = moment(end_time);
        endTime
            .date(check.date())
            .month(check.month())
            .year(check.year());
    } else {
        const [endhour, endMinute] = end_time ? end_time.split(':') : [0, 0];
        endTime = check.hour(endhour || 0).minute(endMinute || 0);
    }
    return endTime;
};
