import React, { Fragment, useState, useEffect } from 'react';
import ProjectTitle from '../../components/ProjectTitle';
import { Switch, Route, useParams } from 'react-router-dom';
import { useScroll } from '../../hooks/useScroll';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import Loader from '../../components/Loader';
import StepTracker from '../../components/StepTracker';
import IteratedResponse from '../../components/IteratedResponse';
import CurrentStep from '../../components/100HCurrentStep';
import Preamble from '../../components/Preamble';
import getQuestionsFromSections from '../../utils/getQuestionsFromSections';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import {
    getPeerReview,
    getFinalResponses,
} from '../../reducers/itineraryActions';
import IteratedResponseStop8 from './IteratedResponseStop8';
import IteratedResponseStop4 from './IteratedResponseStop4';

const IteratedResponses = ({
    stop,
    answers,
    members,
    projectId,
    soloWorkoutId,
    isOwner,
    ownerId,
}) => {
    const { scrollY } = useScroll();
    const [currentView, setCurrentView] = useState(0);
    const [finalResponses, setFinalResponses] = useState([]);
    const dispatch = useDispatch();
    const { scrollIntoView, offsets, setRef } = useScrollIntoView({
        offset: 72,
    });

    const { sections, slug } = stop.hundred_h.find(
        h => h.type === 'iterated_response',
    );

    const filteredContent = getQuestionsFromSections(sections);
    const userOwner = members ? members.find(m => m.id === ownerId) : {};

    useEffect(() => {
        dispatch(
            getFinalResponses(soloWorkoutId, { project_id: projectId }),
        ).then(res => {
            setFinalResponses(res.answers);
        });
    }, [soloWorkoutId]);

    return (
        <div
            className="d-flex flex-column mx-auto w-100"
            style={{ position: 'relative', maxWidth: MAX_WIDTH }}>
            <StepTracker
                length={offsets.length}
                scrollIntoView={scrollIntoView}
                currentView={currentView}
            />

            <div ref={setRef} className="d-flex flex-column w-100">
                {filteredContent.map((d, i) => {
                    /* Remove html tags from c.question */
                    const div = document.createElement('div');
                    div.innerHTML = d.question;
                    const ans = answers[d.question_key];
                    let user = {};
                    let answer = '';
                    if (ans && ans[0]) {
                        answer = d.options
                            ? ans[0].values
                                  .map(v => d.options.find(o => o.value === v))
                                  .filter(Boolean)
                                  .map(o => o.body)
                                  .join(', ')
                            : ans[0].values;

                        user = ans && ans[0] ? ans[0].user : {};
                    }
                    return (
                        <IteratedResponse
                            key={i}
                            id={`n${i + 1}`}
                            projectId={projectId}
                            scrollY={scrollY}
                            setCurrentView={setCurrentView}
                            section={i}
                            answer={answer}
                            user={user}
                            userOwner={userOwner}
                            question={div.innerText}
                            question_key={d.question_key}
                            nextSection={offsets[i + 1]}
                            isLast={i === filteredContent.length - 1}
                            slug={slug}
                            finalResponses={finalResponses}
                            referenceMaterials={
                                ans && ans[0]
                                    ? ans[0].reference_materials || []
                                    : []
                            }
                            stop={stop}
                            isOwner={isOwner}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const IteratedResponseScreen = ({ projectDetails, stops }) => {
    const [answers, setAnswers] = useState({});
    const { projectId, stopId, soloWorkoutId } = useParams();
    const stop = stops.find(s => s.id === stopId);
    const { user: currentUser } = useSelector(s => s.users);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getPeerReview(soloWorkoutId, {
                project_id: projectId,
                filter_favorite: true,
            }),
        )
            .then(({ answers }) => setAnswers(answers))
            .catch(console.warn);
    }, [projectId, soloWorkoutId]);

    if (!projectDetails || !stop || !stop.hundred_h)
        return <Loader fullscreen />;

    const isStop4 = stop.ordinality === 4;
    const isStop8 = stop.ordinality === 8;

    return (
        <Fragment>
            <ProjectTitle
                title={projectDetails.title}
                subtitle={`Hurdle ${stop.ordinality}: ${stop.name}`}
            />
            <div className="d-flex flex-column align-items-center justify-content-center">
                <Switch>
                    <Route path="/100h/projects/:projectId/hurdles/:stopId/iterated-response/:soloWorkoutId/responses">
                        {isStop8 && (
                            <IteratedResponseStop8
                                projectId={projectId}
                                stop={stop}
                                soloWorkoutId={soloWorkoutId}
                                stops={stops}
                                isOwner={
                                    projectDetails.owner_id === currentUser.id
                                }
                            />
                        )}
                        {isStop4 && (
                            <IteratedResponseStop4
                                projectId={projectId}
                                stop={stop}
                                answers={answers}
                                members={projectDetails.members}
                                soloWorkoutId={soloWorkoutId}
                                isOwner={
                                    projectDetails.owner_id === currentUser.id
                                }
                            />
                        )}

                        {!isStop4 && !isStop8 ? (
                            <IteratedResponses
                                projectId={projectId}
                                stop={stop}
                                answers={answers}
                                members={projectDetails.members}
                                soloWorkoutId={soloWorkoutId}
                                ownerId={projectDetails.owner_id}
                                isOwner={
                                    projectDetails.owner_id === currentUser.id
                                }
                            />
                        ) : null}
                    </Route>
                    <Route
                        path="/100h/projects/:projectId/hurdles/:stopId/iterated-response/:soloWorkoutId"
                        exact>
                        <CurrentStep />
                        <Preamble
                            title="Iterated Workout"
                            content="Iterated Workout refers to notes that the project sponsor/creator moved from Peer Exchange to be used as reference in synthesizing ideas from the project members."
                            to={`/100h/projects/${projectId}/hurdles/${stopId}/iterated-response/${soloWorkoutId}/responses`}
                        />
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default IteratedResponseScreen;
