import React from 'react';
import Button from './Button';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { hideModal } from '../reducers/modalActions';
import { removeProject, removeMember } from '../reducers/itineraryActions';
import { useHistory } from 'react-router-dom';

export const DeleteProjectModal = ({ projectId, member }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <Formik
            initialValues={{ message: '' }}
            onSubmit={(_, { setSubmitting }) => {
                setSubmitting(true);

                if (!member) {
                    dispatch(removeProject(projectId)).then(
                        () => {
                            setSubmitting(false);
                            dispatch(hideModal());
                            history.push('/');
                        },
                        () => {
                            setSubmitting(false);
                        },
                    );
                }
                if (member) {
                    dispatch(removeMember(projectId, member.email)).then(
                        res => {
                            setSubmitting(false);
                            dispatch(hideModal());
                            console.log(res);
                        },
                    ),
                        () => {
                            setSubmitting(false);
                        };
                }
            }}>
            {({ isSubmitting }) => (
                <Form>
                    {!member && (
                        <div className="px-5 pt-5 pb-3">
                            <h2
                                className="mb-3"
                                style={{
                                    fontSize: '20px',
                                    color: '#121212',
                                    fontWeight: 'bold',
                                }}>
                                Delete Project
                            </h2>
                            <p style={{ fontSize: '14px', color: '#717171' }}>
                                Are you sure to delete this project?
                            </p>
                        </div>
                    )}
                    {member && (
                        <div className="px-5 pt-5 pb-3">
                            <h2
                                className="mb-3"
                                style={{
                                    fontSize: '20px',
                                    color: '#121212',
                                    fontWeight: 'bold',
                                }}>
                                Remove member
                            </h2>
                            <p style={{ fontSize: '14px', color: '#717171' }}>
                                Are you sure you want do remove {member.name}?
                            </p>
                        </div>
                    )}
                    <div className="d-flex align-items-center py-3 px-5">
                        <Button
                            disabled={isSubmitting}
                            secondary
                            className="ml-auto mr-2"
                            onClick={() => dispatch(hideModal())}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                            danger
                            type="submit">
                            {member ? 'Remove' : 'Delete'}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
