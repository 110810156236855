import React, { Fragment, useEffect } from 'react';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import {
    useParams,
    NavLink,
    Switch,
    Route,
    Link,
    useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    getEventGroup,
    ADD_EVENT_GROUP,
} from '../../reducers/growthlabsActions';
import Loader from '../../components/Loader';
import moment from 'moment';
import Button from '../../components/Button';
import AddToCalendar from 'react-add-to-calendar';
import UpcomingEvent from '../../components/UpcomingEvent';
import GrowthLabEvent from '../../components/GrowthLabEvent';
import { getStartTime } from '../../utils/getStartEndTime';

const PreviousEpisode = ({
    cover_image,
    name,
    description,
    date,
    webcast_url,
    start_time,
    end_time,
    id,
    archive_url,
    slug,
    features,
    addToEvent,
}) => {
    const check = moment(date);
    return (
        <div className="d-flex align-items-center py-3 mb-3 w-100">
            <div
                className="EventImage mr-3"
                style={{
                    backgroundImage: `url("${cover_image}")`,
                }}
            />
            <div className="mr-auto pr-2">
                <h3 className="mb-2">{name}</h3>
                <p
                    className="mb-2"
                    style={{
                        fontSize: '0.6875rem',
                        color: 'rgba(60, 60, 67, 0.6)',
                    }}>
                    {check.format('DD MMMM YYYY')} • {start_time} {end_time}
                </p>
                {features && (
                    <p
                        className="mb-0"
                        style={{
                            fontSize: '0.6875rem',
                            color: 'rgba(60, 60, 67, 0.6)',
                        }}>
                        <i className="fe fe-user mr-2" />
                        <span>{features}</span>
                    </p>
                )}
            </div>

            <Button primary small href={archive_url} target="_blank">
                View Postcard
            </Button>
        </div>
    );
};

export const CurrentEvent = ({
    webcast_url,
    cover_image,
    name,
    description,
    date,
    start_time,
    end_time,
    features,
    id,
}) => {
    const check = moment(date);
    return (
        <div
            className="d-flex align-items-center py-0 pr-3 mb-3 w-100"
            style={{
                boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.1)`,
                borderRadius: '12px',
            }}>
            <div
                className="EventImage mr-3"
                style={{
                    borderTopLeftRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    overflow: 'hidden',
                    backgroundImage: `url("${cover_image}")`,
                }}
            />

            <div className="mr-auto pr-2">
                <h3
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: name }}
                />
                <p
                    className="mb-2"
                    style={{
                        fontSize: '0.6875rem',
                        color: 'rgba(60, 60, 67, 0.6)',
                    }}>
                    {check.format('DD MMMM YYYY')} • {start_time} {end_time}
                </p>
                {features && (
                    <p
                        className="mb-0"
                        style={{
                            fontSize: '0.6875rem',
                            color: 'rgba(60, 60, 67, 0.6)',
                        }}>
                        <i className="fe fe-user mr-2" />
                        <span>{features}</span>
                    </p>
                )}
            </div>

            <div className="d-flex flex-column align-items-stretch">
                <Button
                    primary
                    rounded
                    small
                    className="mb-3"
                    target="_blank"
                    style={{ fontSize: '11px' }}
                    href={webcast_url}>
                    <span>Join Webcast </span>
                    <i className="fe fe-video" />
                </Button>
                <Button
                    light
                    rounded
                    small
                    to={`/growthlab-app/${id}`}
                    style={{ fontSize: '11px' }}>
                    <span>Next</span>
                </Button>
            </div>
        </div>
    );
};

const GrowthlabDetailScreen = ({ eventGroups }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const eventGroup = eventGroups.find(e => e.id === id);

    useEffect(() => {
        if (!eventGroup) {
            dispatch(getEventGroup(id)).then(d =>
                dispatch({ type: ADD_EVENT_GROUP, payload: d }),
            );
        }
    }, [eventGroup, id]);

    if (!eventGroup) return <Loader fullscreen />;

    const { future_events, past_events, current_event } = eventGroup;

    return (
        <Fragment>
            <Fragment>
                <div
                    className="Growthlabs--hero mb-5 p-5 text-white d-flex justify-content-center flex-column"
                    style={{
                        backgroundImage: `url("${eventGroup.cover_image}")`,
                    }}>
                    <Button
                        to="/growthlabs"
                        transparent
                        className="pl-0 d-flex align-items-center mb-3">
                        <i
                            className="fe fe-arrow-left"
                            style={{ color: 'white' }}
                        />
                        <span
                            className="ml-3"
                            style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                            Back to 24/7 Growthlab
                        </span>
                    </Button>
                    <h1>{eventGroup.name}</h1>
                    <p className="m-0" style={{ maxWidth: MAX_WIDTH }}>
                        {eventGroup.description}
                    </p>
                </div>
                {Object.keys(current_event).length ? (
                    <GrowthLabEvent currentEvent={current_event} />
                ) : null}
                <div className="mb-6 mx-auto" style={{ maxWidth: MAX_WIDTH }}>
                    <ul
                        className="nav nav-tabs justify-content-center mb-5"
                        style={{ flexWrap: 'nowrap' }}>
                        <li className="nav-item w-100 text-center">
                            <NavLink
                                to={`/growthlabs/${id}`}
                                activeClassName="active"
                                exact
                                activeStyle={{ borderColor: '#121212' }}
                                style={{ fontSize: '0.875rem' }}
                                className="nav-link px-5">
                                Next 100H Challenge
                            </NavLink>
                        </li>
                        <li className="nav-item w-100 text-center">
                            <NavLink
                                to={`/growthlabs/${id}/previous-episodes`}
                                activeClassName="active"
                                activeStyle={{ borderColor: '#121212' }}
                                style={{ fontSize: '0.875rem' }}
                                className="nav-link px-5">
                                Previous Episodes
                            </NavLink>
                        </li>
                    </ul>

                    <Switch>
                        <Route path={`/growthlabs/${id}`} exact>
                            {future_events.length > 0 ? (
                                future_events
                                    .sort((a, b) =>
                                        getStartTime(a.date, a.startTime).diff(
                                            getStartTime(b.date, b.startTime),
                                        ),
                                    )
                                    .map((p, i) => (
                                        <UpcomingEvent key={i} {...p} />
                                    ))
                            ) : (
                                <div className="p-5 text-center">
                                    No upcoming events.
                                </div>
                            )}
                        </Route>
                        <Route
                            path={`/growthlabs/${id}/previous-episodes`}
                            exact>
                            {past_events.length > 0 ? (
                                past_events
                                    .sort((a, b) =>
                                        getStartTime(b.date, b.startTime).diff(
                                            getStartTime(a.date, a.startTime),
                                        ),
                                    )
                                    .map((p, i) => (
                                        <PreviousEpisode key={i} {...p} />
                                    ))
                            ) : (
                                <div className="p-5 text-center">
                                    No previous events.
                                </div>
                            )}
                        </Route>
                    </Switch>
                </div>
            </Fragment>
        </Fragment>
    );
};

export default GrowthlabDetailScreen;
