import React from 'react';
import Button from './Button';

const StepTracker = ({ length, scrollIntoView, currentView }) => {
    if (length === 1) return null;

    return (
        <div className="step-tracker">
            {Array.from(new Array(length)).map((_, i) => (
                <Button
                    key={i}
                    transparent
                    rounded
                    className={currentView === i ? 'isActive' : ''}
                    onClick={scrollIntoView.bind(null, i)}
                />
            ))}
        </div>
    );
};

export default StepTracker;
