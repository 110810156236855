import { useState } from 'react';
import {
    ALL,
    filterUserCardsByType,
    ALL_STATUS,
    WORKOUT,
    POSTCARD,
    GROWTHLAB,
    GIFTBOX,
    CLAIM,
    CLAIMED,
    REVIEW,
    COLLECT,
    LAPSED,
    filterUserCardByStatus,
} from '../utils/userCardUtils';

const TYPE_OPTIONS = [
    { label: 'Workout', value: WORKOUT },
    { label: 'Postcard', value: POSTCARD },
    { label: 'GrowthLab', value: GROWTHLAB },
    { label: 'Giftbox', value: GIFTBOX },
];
const getTypeOptionsBasedOnStatus = status => {
    switch (status) {
        case CLAIM:
            return [WORKOUT, POSTCARD, GROWTHLAB];
        case REVIEW:
            return [WORKOUT, POSTCARD];
        case CLAIMED:
        case LAPSED:
            return [GROWTHLAB];
        case COLLECT:
            return [GIFTBOX];

        default:
            return [WORKOUT, POSTCARD, GROWTHLAB, GIFTBOX];
    }
};

const STATUS_OPTIONS = [
    { label: 'Claim', value: CLAIM },
    { label: 'Claimed', value: CLAIMED },
    { label: 'Review', value: REVIEW },
    { label: 'Lapsed', value: LAPSED },
    { label: 'Collect', value: COLLECT },
];
const getStatusOptionsBasedOnType = type => {
    switch (type) {
        case WORKOUT:
        case POSTCARD:
            return [CLAIM, REVIEW];
        case GROWTHLAB:
            return [CLAIM, CLAIMED, LAPSED];
        case GIFTBOX:
            return [COLLECT];
        default:
            return [CLAIM, CLAIMED, REVIEW, LAPSED, COLLECT];
    }
};

export default cards => {
    const [filter, setCardFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [filterFavorite, setFavoriteFilter] = useState(false);

    const setFilter = f => {
        const v = filter.includes(f)
            ? filter.filter(fi => fi !== f)
            : filter.concat(f);
        setCardFilter(v);
    };

    const setStatus = f => {
        const v = statusFilter.includes(f)
            ? statusFilter.filter(fi => fi !== f)
            : statusFilter.concat(f);
        setStatusFilter(v);
    };

    const filteredCards = cards
        .filter(c =>
            filter.length > 0
                ? filter.some(f => filterUserCardsByType(f, c.card.cardType))
                : true,
        )
        .filter(c =>
            statusFilter.length > 0
                ? statusFilter.some(f => filterUserCardByStatus(f, c))
                : true,
        )
        .filter(c => (filterFavorite ? c.isFavorited : true));

    const typeOptions =
        statusFilter.length > 0
            ? [
                  ...new Set(
                      statusFilter.flatMap(f => getTypeOptionsBasedOnStatus(f)),
                  ),
              ]
            : getTypeOptionsBasedOnStatus(ALL_STATUS);

    const statusOptions =
        filter.length > 0
            ? [...new Set(filter.flatMap(f => getStatusOptionsBasedOnType(f)))]
            : getStatusOptionsBasedOnType(ALL);

    return {
        personalGrowth: cards,
        filteredCards,
        setFilter,
        setStatus,
        setFavoriteFilter,
        filterFavorite,
        filter,
        statusFilter,
        typeOptions: typeOptions.map(v =>
            TYPE_OPTIONS.find(t => t.value === v),
        ),
        statusOptions: statusOptions.map(v =>
            STATUS_OPTIONS.find(t => t.value === v),
        ),
    };
};
