import moment from 'moment';

// Claim
// Review
// Collect
// Claimed
// Lapsed

// Favorites

export const checkIfGrowthLab = type =>
    ['growthlab', 'Growthlab', 'GrowthLab'].includes(type);
export const checkIfExpired = (event, type) =>
    event &&
    moment(event.date).diff(moment().subtract(1, 'days')) < 0 &&
    checkIfGrowthLab(type);
export const checkIfWorkout = type =>
    ['workout', 'Workout', 'Solo Workout'].includes(type);
export const checkIfBuddyWorkout = type => ['Buddy Workout'].includes(type);
export const checkIfPostCard = type => ['Postcard'].includes(type);
export const checkIfGiftbox = type => ['GiftBox', 'giftbox'].includes(type);

export const GROWTHLAB = 'GROWTHLAB';
export const WORKOUT = 'WORKOUT';
export const POSTCARD = 'POSTCARD';
export const GIFTBOX = 'GIFTBOX';
export const ALL = 'ALL';

export const CLAIM = 'CLAIM';
export const CLAIMED = 'CLAIMED';
export const REVIEW = 'REVIEW';
export const LAPSED = 'LAPSED';
export const COLLECT = 'COLLECT';
export const ALL_STATUS = 'ALL_STATUS';

export const filterUserCardsByType = (filter, type) => {
    switch (filter) {
        case GROWTHLAB:
            return checkIfGrowthLab(type);
        case WORKOUT:
            return checkIfWorkout(type) || checkIfBuddyWorkout(type);
        case POSTCARD:
            return checkIfPostCard(type);
        case GIFTBOX:
            return checkIfGiftbox(type);
        default:
            return true;
    }
};

export const filterUserCardByStatus = (filter, userCard) => {
    const { cardType, event } = userCard.card ? userCard.card : {};
    switch (filter) {
        case CLAIM:
            return (
                !userCard.isClaimed &&
                !checkIfGiftbox(cardType) &&
                !checkIfExpired(event, cardType)
            );
        case LAPSED:
            return !userCard.isClaimed && checkIfExpired(event, cardType);
        case CLAIMED:
            return userCard.isClaimed && checkIfGrowthLab(cardType);
        case COLLECT:
            return checkIfGiftbox(cardType);
        case REVIEW:
            return (
                userCard.isClaimed &&
                (checkIfWorkout(cardType) || checkIfPostCard(cardType))
            );
        default:
            return true;
    }
};
