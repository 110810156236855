import React from 'react';

export const EmptyEmpathyProjectsIcon = () => (
    <svg width="230" height="116" viewBox="0 0 230 116" fill="none">
        <path
            d="M229.948 113.785C224.919 102.992 213.943 95.0077 219.115 75.2046C236.385 9.12064 148.494 -23.5913 121.324 19.4548C107.343 41.6091 74.17 53.3344 66.0095 47.9852C46.8417 35.4248 12.8711 47.9852 3.8249 70.9126C-2.26388 86.3291 -0.223742 101.54 3.98305 113.785H229.948Z"
            fill="#EBF3FA"
        />
        <path
            d="M17.7686 114.434H67.0748L61.5994 95.5705C59.8857 89.6488 54.5079 85.5202 48.3359 85.3812L30.4191 84.9781C29.8339 84.9642 29.2767 84.992 28.7194 85.0615L24.8044 84.9781C18.1169 84.8252 12.976 90.4967 14.1741 96.6826L17.7686 114.434Z"
            fill="#8CB4F4"
        />
        <path
            d="M59.2857 40.997C56.1509 39.3567 51.3861 39.1343 50.6198 39.037C41.3549 37.7859 35.782 51.2141 35.4477 55.2871C34.8486 62.7935 30.864 63.9334 25.2632 68.6319C14.0478 78.0427 17.3358 88.9966 23.4799 93.0556C27.158 95.5021 33.6504 93.0556 33.6504 93.0556C33.7479 93.1251 51.4 83.2138 60.1077 78.4319C73.1482 76.5692 67.2688 45.1812 59.2857 40.997Z"
            fill="#233862"
        />
        <path
            d="M57.3492 73.8022C57.391 73.8161 53.2392 75.7066 50.2298 74.7197C47.4016 73.7883 44.9216 69.5486 44.9634 69.5486C46.1894 69.3678 47.4294 68.1724 47.7777 61.0273L48.3211 61.1664L57.4467 63.46C57.4467 63.46 57.0845 66.3375 56.9312 69.062C56.8198 71.3557 56.8337 73.5381 57.3492 73.8022Z"
            fill="#FFB27D"
        />
        <path
            d="M57.4467 63.4613C57.4467 63.4613 57.4467 64.059 57.1123 66.7419C51.9574 65.4491 49.6725 63.7393 48.0703 61.7793L57.4467 63.4613Z"
            fill="#ED975D"
        />
        <path
            d="M59.3971 63.8214C59.3971 63.8214 47.3318 65.7814 46.2312 58.2611C45.1305 50.7547 42.8178 45.945 50.4805 44.3742C58.1293 42.8034 60.0519 45.4585 61.0411 47.8772C62.0024 50.2959 62.504 63.1125 59.3971 63.8214Z"
            fill="#FFB27D"
        />
        <path
            d="M59.4119 44.0684C59.4119 44.0684 53.9365 52.7008 49.0463 54.7443C44.1561 56.7877 41.7598 55.7451 41.7598 55.7451C41.7598 55.7451 43.9889 52.3811 44.5601 47.8634C44.7273 46.5289 45.6051 45.389 46.845 44.8886C50.3559 43.4846 57.1409 41.2744 59.4119 44.0684Z"
            fill="#233862"
        />
        <path
            d="M54.5908 44.9031C54.5908 44.9031 57.7952 46.5434 59.1188 48.0864C60.4006 49.5738 61.8634 53.6051 61.7659 55.4122C61.7659 55.4122 63.3542 46.738 60.0522 43.7633C56.5553 40.5939 54.5908 44.9031 54.5908 44.9031Z"
            fill="#233862"
        />
        <path
            d="M67.0044 92.1651C66.6422 116.631 66.9069 111.515 64.218 113.837C63.5771 114.393 61.752 114.726 59.3696 114.879C51.8462 115.38 38.6384 114.142 38.1369 112.752C36.4093 107.956 37.6214 106.469 36.1863 99.1155C36.0331 98.3371 35.852 97.4891 35.6291 96.5717C34.4169 91.5257 33.2466 89.8159 37.5238 81.7117C41.4109 74.3721 45.9668 68.4086 46.329 68.631C56.5692 74.6918 56.9175 69.618 56.9175 69.618C56.9175 69.618 67.1855 80.2243 67.0044 92.1651Z"
            fill="white"
        />
        <path
            d="M56.917 69.6191C56.917 69.6191 67.394 71.3984 68.634 75.9162C69.874 80.4479 77.1466 107.054 77.1466 107.054C77.1466 107.054 71.7966 108.013 69.3167 105.539C66.8367 103.065 60.7066 82.6303 60.7066 82.6303L56.917 69.6191Z"
            fill="white"
        />
        <path
            d="M59.3697 114.879C51.8463 115.379 38.6386 114.142 38.137 112.752C36.4094 107.956 37.6215 106.469 36.1865 99.1151L39.8089 88.9258C39.8089 88.9258 39.2098 100.269 42.5675 103.258C45.9112 106.246 56.2907 109.012 57.9208 111.515C58.701 112.71 59.2165 113.919 59.3697 114.879Z"
            fill="#EBF3FF"
        />
        <path
            d="M50.7451 110.112C50.7451 110.112 58.1571 108.458 59.3552 109.125C60.5534 109.779 63.4792 112.17 63.8414 113.004C64.2037 113.838 61.1804 112.378 60.1912 112.114C59.5503 111.947 57.8784 112.531 57.0425 112.809C53.5455 113.977 51.3999 113.379 51.3999 113.379L50.7451 110.112Z"
            fill="#FFB27D"
        />
        <path
            d="M46.329 68.6328C46.329 68.6328 35.838 69.2444 32.3828 74.0402C27.5344 80.796 17.9072 109.571 23.4244 113.407C29.6382 117.73 51.7068 114.533 51.7068 114.533C51.7068 114.533 52.4452 111.114 51.4142 109.446C50.3972 107.791 33.79 105.109 33.0237 104.344C32.2574 103.579 42.5951 89.4423 42.5951 87.1487C42.5951 84.869 46.329 68.6328 46.329 68.6328Z"
            fill="white"
        />
        <path
            d="M59.7188 111.182C59.7188 111.182 61.2792 113.183 61.7807 113.42C62.2962 113.656 62.6167 113.6 62.6167 113.6L61.5717 111.724L59.7188 111.182Z"
            fill="#FFB27D"
        />
        <path
            d="M59.3701 109.125L63.8842 111.307C63.8842 111.307 63.6891 111.627 63.1318 111.599C62.5745 111.572 61.1674 111.057 61.1674 111.057L59.3701 109.125Z"
            fill="#FFB27D"
        />
        <path
            d="M76.0034 114.309H55.0215C55.0215 113.391 55.7738 112.641 56.6934 112.641H76.0034V114.309Z"
            fill="#ED7D2B"
        />
        <path
            d="M75.9473 91.5117H108.395L100.607 114.309H67.9502L75.9473 91.5117Z"
            fill="#ED7D2B"
        />
        <path
            d="M108.87 91.5117L101.082 114.309H68.4385L76.4216 91.5117H108.87Z"
            fill="#ED6E33"
        />
        <path
            d="M91.3695 102.702C91.1744 103.578 90.2967 104.273 89.4189 104.273C88.5412 104.273 87.9979 103.564 88.2068 102.702C88.4019 101.826 89.2796 101.131 90.1573 101.131C91.0351 101.117 91.5784 101.826 91.3695 102.702Z"
            fill="white"
        />
        <rect x="133" y="31" width="30" height="30" fill="url(#pattern0)" />
        <rect x="163" y="20" width="14" height="14" fill="url(#pattern1)" />
        <defs>
            <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1">
                <use xlinkHref="#image0" transform="scale(0.0208333)" />
            </pattern>
            <pattern
                id="pattern1"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1">
                <use xlinkHref="#image0" transform="scale(0.0208333)" />
            </pattern>
            <image
                id="image0"
                width="48"
                height="48"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEQElEQVRoQ+1ZbWgbZRz//e+a1qobNpdW7LqBOge+tUsiwiYMGaiTCaPayUQpTEEUc9mKIgh+CIIiIlpzseDwgzh8W6dWhh+mjIk6Bta7FMF3RbZOqTZJZ4dz6ZL7y9VOs6y5596SrtD7FPL8/r+X+z958jx3hEV+0SL3j6UAC93BpQ4smg5w6prmqVxrHxNvYSAOoIsAYmCcgB/AtN+EOdyeyf5mF2oyEe2UIG0F8W0MrCFgJQMM4BgBXxL4gzal+C6lvplxcnOEU4gByifj/cT8DIBOAekMGK81Ez+5TMtOVmInHuruCIXkpwHqB9Bsy8P4lSV+IpLO7haFsA0wPrCu9cLSzOsA94mIqsZzJtHd7Wn9oPV9LhHbSIQ9ABSXPHuKRdreuUs/WauuZgB+MB7KX8DvEeMOl6Jn4KeZ+AEwiECvAgh54mF8ejLUsmnli4f/nq++ZoBcMrabGPd5Ev2/qDT3sckXD+NtJWPc4zhAPhG9C0R7fYkGXMyEOyNp4/1q2nM6wOrqlgIv/xmEFQF78Es3HlZOra5enc4JkFejfQAN+1WrRz0z9UYy+kgl9zwB4sMeVp16+J2P8x1FM7aJAnwP8JpGOXKnQ18rmn6dbYCcGjtFQIs74oah/1I042JBB2InAJwFapg9sdBxRTPaRAGOAFgl5loIBP2iaPoVtgEKanyEwVsWwp5Yk/Yqmr5V9CN+FODnxWSNRzBoR0TT07YBZre7REcByI23aKtYLpflro6h0QnbANZgLhnb52MTV5fcBIyENaNXuJWwAJPJWFxijALnz6GfJfPGyEtjlqezrpq70bx6Hv0ju92Nzk6jgZ4VVJK/BbCsLnPCOem0yXx1raOq7YmskIxuY6a3nGsFj2TifrujpfBMnFOjQwR6OHhrYkYCMmHNUO2QwgCz5wMs/xzADWLJIBH8RRgnNpD2U9FXAKu4kIyvYsYhgLuCtGjDdRQo3aRoXx0T6Qk7cIbgj+Taq2SmzwC6VETqczzHMjZEBg1rARFejgNYTFNqtMcEfQLgEiGzN8C0SdjYnjZ0p+WuAlik+eTa9WDpwzqEmILJm5WXs4edmrdwrgPMhnik51pI8kcOntQ59MK/SzJvahscG3NY8B/MUwCr+nii+/ISNX1MwJVuRavwR8pk3tKRHvvRC4/nAJbY5M74ZVKZ9wO43os4gO9Qlm9VhkbHPdZ7m0KVYn8OrAuXSsV9ANa7NDHaDN5c/RDYJYf/AJbgxGPdF4WKTdazpNsdGjhgtrb2tj93yDp/+7p8TaFKZU7d3FTIT78C4H47Rwy8oRRpO+3ST/tyPlccWACL7993CbFnifH4/OY4HVayA5SCGYR5z8uoSDyfiO8A8QsApDksg/CUkjZSolq344F2oFK8oMbuZWDQen0kMe0MZ/Q33Zpzgq9bACfiQWCWAgRxF/1wLHXAz90LonbRd+Af3LROQEg0ZXoAAAAASUVORK5CYII="
            />
        </defs>
    </svg>
);

export const EmpathyProjectsIcon = () => (
    <svg width="230" height="116" viewBox="0 0 230 116" fill="none">
        <path
            d="M229.948 113.83C224.919 103.037 213.943 95.0526 219.115 75.2495C236.385 9.16557 148.494 -23.5464 121.324 19.4997C107.343 41.654 74.17 53.3793 66.0095 48.0301C46.8417 35.4697 12.8711 48.0301 3.8249 70.9576C-2.26388 86.374 -0.223742 101.585 3.98305 113.83H229.948Z"
            fill="#EBF3FA"
        />
        <path
            d="M25.7371 114.706H66.0453L61.5691 99.2853C60.1682 94.4442 55.7718 91.0691 50.7261 90.9554L36.079 90.6259C35.6006 90.6145 35.145 90.6373 34.6894 90.6941L31.4889 90.6259C26.0219 90.5009 21.8191 95.1374 22.7986 100.194L25.7371 114.706Z"
            fill="#8CB4F4"
        />
        <path
            d="M59.6775 54.6693C57.1148 53.3284 53.2196 53.1465 52.5931 53.067C45.019 52.0442 40.4631 63.0219 40.1898 66.3515C39.7 72.4881 36.4426 73.4199 31.8639 77.261C22.6952 84.9544 25.3832 93.9093 30.406 97.2276C33.4129 99.2276 38.7205 97.2276 38.7205 97.2276C38.8002 97.2844 53.231 89.1818 60.3495 85.2726C71.0103 83.7498 66.2038 58.0899 59.6775 54.6693Z"
            fill="#233862"
        />
        <path
            d="M58.0946 81.4885C58.1288 81.4998 54.7347 83.0453 52.2745 82.2385C49.9624 81.4771 47.935 78.0111 47.9692 78.0111C48.9715 77.8633 49.9852 76.886 50.2699 71.0449L50.7141 71.1586L58.1744 73.0336C58.1744 73.0336 57.8782 75.386 57.7529 77.6133C57.6618 79.4884 57.6732 81.2726 58.0946 81.4885Z"
            fill="#FFB27D"
        />
        <path
            d="M58.1746 73.0333C58.1746 73.0333 58.1746 73.5219 57.9012 75.7152C53.687 74.6583 51.8191 73.2605 50.5093 71.6582L58.1746 73.0333Z"
            fill="#ED975D"
        />
        <path
            d="M59.7684 73.3289C59.7684 73.3289 49.9049 74.9312 49.0051 68.7833C48.1054 62.6467 46.2147 58.7148 52.479 57.4307C58.7319 56.1465 60.3037 58.3171 61.1124 60.2944C61.8982 62.2717 62.3083 72.7494 59.7684 73.3289Z"
            fill="#FFB27D"
        />
        <path
            d="M59.7803 57.1806C59.7803 57.1806 55.3042 64.2377 51.3064 65.9082C47.3086 67.5787 45.3496 66.7264 45.3496 66.7264C45.3496 66.7264 47.172 63.9763 47.6389 60.283C47.7756 59.192 48.4932 58.2602 49.5068 57.8511C52.377 56.7033 57.9238 54.8964 59.7803 57.1806Z"
            fill="#233862"
        />
        <path
            d="M55.8389 57.8617C55.8389 57.8617 58.4585 59.2026 59.5405 60.464C60.5884 61.68 61.7843 64.9755 61.7046 66.4529C61.7046 66.4529 63.003 59.3617 60.3036 56.9298C57.4448 54.3388 55.8389 57.8617 55.8389 57.8617Z"
            fill="#233862"
        />
        <path
            d="M65.9873 96.5001C65.6912 116.501 65.9076 112.319 63.7094 114.217C63.1854 114.671 61.6934 114.944 59.7457 115.069C53.5953 115.478 42.7979 114.467 42.3878 113.33C40.9755 109.41 41.9664 108.194 40.7933 102.182C40.668 101.546 40.5199 100.853 40.3377 100.102C39.3468 95.9773 38.3901 94.5796 41.8867 87.9543C45.0644 81.9541 48.7889 77.079 49.085 77.2608C57.4564 82.2155 57.7412 78.0676 57.7412 78.0676C57.7412 78.0676 66.1354 86.7384 65.9873 96.5001Z"
            fill="white"
        />
        <path
            d="M57.7412 78.0684C57.7412 78.0684 66.3063 79.523 67.32 83.2163C68.3336 86.9209 74.2791 108.672 74.2791 108.672C74.2791 108.672 69.9054 109.456 67.878 107.433C65.8507 105.41 60.8392 88.7051 60.8392 88.7051L57.7412 78.0684Z"
            fill="white"
        />
        <path
            d="M59.7464 115.068C53.596 115.477 42.7985 114.466 42.3885 113.33C40.9762 109.409 41.9671 108.193 40.7939 102.181L43.7553 93.8516C43.7553 93.8516 43.2655 103.125 46.0104 105.568C48.7439 108.011 57.2293 110.273 58.5619 112.318C59.1997 113.295 59.6211 114.284 59.7464 115.068Z"
            fill="#EBF3FF"
        />
        <path
            d="M52.6953 111.171C52.6953 111.171 58.7546 109.819 59.7342 110.364C60.7137 110.898 63.1055 112.853 63.4016 113.535C63.6978 114.216 61.2262 113.023 60.4175 112.807C59.8936 112.671 58.5268 113.148 57.8435 113.375C54.9846 114.33 53.2306 113.841 53.2306 113.841L52.6953 111.171Z"
            fill="#FFB27D"
        />
        <path
            d="M49.0855 77.2617C49.0855 77.2617 40.509 77.7617 37.6844 81.6823C33.7208 87.2052 25.8505 110.729 30.3608 113.865C35.4406 117.399 53.4819 114.786 53.4819 114.786C53.4819 114.786 54.0855 111.99 53.2427 110.626C52.4113 109.274 38.8347 107.081 38.2083 106.456C37.5819 105.831 46.033 94.2736 46.033 92.3986C46.033 90.5349 49.0855 77.2617 49.0855 77.2617Z"
            fill="white"
        />
        <path
            d="M60.0312 112.045C60.0312 112.045 61.3069 113.681 61.7169 113.875C62.1383 114.068 62.4003 114.022 62.4003 114.022L61.5461 112.488L60.0312 112.045Z"
            fill="#FFB27D"
        />
        <path
            d="M59.7466 110.365L63.4368 112.149C63.4368 112.149 63.2774 112.411 62.8218 112.388C62.3662 112.365 61.2159 111.945 61.2159 111.945L59.7466 110.365Z"
            fill="#FFB27D"
        />
        <path
            d="M73.3448 114.604H56.1919C56.1919 113.854 56.8069 113.24 57.5587 113.24H73.3448V114.604Z"
            fill="#ED7D2B"
        />
        <path
            d="M73.2989 95.9668H99.8255L93.4586 114.604H66.7612L73.2989 95.9668Z"
            fill="#ED7D2B"
        />
        <path
            d="M100.213 95.9668L93.8462 114.604H67.1602L73.6865 95.9668H100.213Z"
            fill="#ED6E33"
        />
        <path
            d="M85.9067 105.114C85.7472 105.83 85.0297 106.399 84.3121 106.399C83.5946 106.399 83.1504 105.819 83.3212 105.114C83.4807 104.398 84.1982 103.83 84.9158 103.83C85.6333 103.819 86.0775 104.398 85.9067 105.114Z"
            fill="white"
        />

        <rect x="60" y="25" width="22" height="22" fill="url(#pattern0)" />
        <rect x="82" y="13" width="12" height="12" fill="url(#pattern1)" />
        <defs>
            <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1">
                <use xlinkHref="#image0" transform="scale(0.0208333)" />
            </pattern>
            <pattern
                id="pattern1"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1">
                <use xlinkHref="#image0" transform="scale(0.0208333)" />
            </pattern>
            <image
                id="image0"
                width="48"
                height="48"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEQElEQVRoQ+1ZbWgbZRz//e+a1qobNpdW7LqBOge+tUsiwiYMGaiTCaPayUQpTEEUc9mKIgh+CIIiIlpzseDwgzh8W6dWhh+mjIk6Bta7FMF3RbZOqTZJZ4dz6ZL7y9VOs6y5596SrtD7FPL8/r+X+z958jx3hEV+0SL3j6UAC93BpQ4smg5w6prmqVxrHxNvYSAOoIsAYmCcgB/AtN+EOdyeyf5mF2oyEe2UIG0F8W0MrCFgJQMM4BgBXxL4gzal+C6lvplxcnOEU4gByifj/cT8DIBOAekMGK81Ez+5TMtOVmInHuruCIXkpwHqB9Bsy8P4lSV+IpLO7haFsA0wPrCu9cLSzOsA94mIqsZzJtHd7Wn9oPV9LhHbSIQ9ABSXPHuKRdreuUs/WauuZgB+MB7KX8DvEeMOl6Jn4KeZ+AEwiECvAgh54mF8ejLUsmnli4f/nq++ZoBcMrabGPd5Ev2/qDT3sckXD+NtJWPc4zhAPhG9C0R7fYkGXMyEOyNp4/1q2nM6wOrqlgIv/xmEFQF78Es3HlZOra5enc4JkFejfQAN+1WrRz0z9UYy+kgl9zwB4sMeVp16+J2P8x1FM7aJAnwP8JpGOXKnQ18rmn6dbYCcGjtFQIs74oah/1I042JBB2InAJwFapg9sdBxRTPaRAGOAFgl5loIBP2iaPoVtgEKanyEwVsWwp5Yk/Yqmr5V9CN+FODnxWSNRzBoR0TT07YBZre7REcByI23aKtYLpflro6h0QnbANZgLhnb52MTV5fcBIyENaNXuJWwAJPJWFxijALnz6GfJfPGyEtjlqezrpq70bx6Hv0ju92Nzk6jgZ4VVJK/BbCsLnPCOem0yXx1raOq7YmskIxuY6a3nGsFj2TifrujpfBMnFOjQwR6OHhrYkYCMmHNUO2QwgCz5wMs/xzADWLJIBH8RRgnNpD2U9FXAKu4kIyvYsYhgLuCtGjDdRQo3aRoXx0T6Qk7cIbgj+Taq2SmzwC6VETqczzHMjZEBg1rARFejgNYTFNqtMcEfQLgEiGzN8C0SdjYnjZ0p+WuAlik+eTa9WDpwzqEmILJm5WXs4edmrdwrgPMhnik51pI8kcOntQ59MK/SzJvahscG3NY8B/MUwCr+nii+/ISNX1MwJVuRavwR8pk3tKRHvvRC4/nAJbY5M74ZVKZ9wO43os4gO9Qlm9VhkbHPdZ7m0KVYn8OrAuXSsV9ANa7NDHaDN5c/RDYJYf/AJbgxGPdF4WKTdazpNsdGjhgtrb2tj93yDp/+7p8TaFKZU7d3FTIT78C4H47Rwy8oRRpO+3ST/tyPlccWACL7993CbFnifH4/OY4HVayA5SCGYR5z8uoSDyfiO8A8QsApDksg/CUkjZSolq344F2oFK8oMbuZWDQen0kMe0MZ/Q33Zpzgq9bACfiQWCWAgRxF/1wLHXAz90LonbRd+Af3LROQEg0ZXoAAAAASUVORK5CYII="
            />
        </defs>
    </svg>
);

export const EmptyAcceleratePeakPerformanceIcon = () => (
    <svg width="177" height="119" viewBox="0 0 177 119" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M166.67 119C168.192 112.918 169 106.554 169 100C169 56.9218 134.078 22 91 22C47.9218 22 13 56.9218 13 100C13 106.554 13.8082 112.918 15.3304 119H166.67Z"
            fill="#EBF3FA"
        />
        <path d="M88 71V85.5L101.735 80.1589L121.5 36L88 71Z" fill="#B8B8B8" />
        <path
            d="M109 48.5L112 50.5L114.5 52.5L121.5 36L109 48.5Z"
            fill="#ED6D32"
        />
        <path
            d="M1.70432 104.429C1.13952 101.879 0.698272 99.2754 0.407048 96.6456L0.212899 94.6688L0.124649 92.6832C0.0805241 91.3594 -0.0342005 90.0181 0.00992437 88.6855C-0.0695004 83.3287 0.610022 77.9543 1.67784 72.624C3.84879 61.9458 8.2701 51.6118 14.377 42.2838C15.1624 41.1365 15.9302 39.9628 16.7509 38.8332L19.2748 35.4797C21.0575 33.3176 22.8842 31.182 24.8434 29.1699C28.7087 25.0751 33.0153 21.4039 37.5602 18.0239C42.1403 14.6969 47.0735 11.8376 52.1743 9.31368C57.3105 6.86916 62.6672 4.83942 68.1652 3.33035C73.6808 1.88305 79.3023 0.832879 84.9768 0.303381C90.66 -0.120218 96.3521 -0.111393 102 0.400456C103.412 0.524005 104.815 0.762279 106.218 0.938779C107.622 1.1594 109.025 1.27413 110.41 1.59183C113.181 2.15662 115.952 2.7126 118.644 3.5598C124.098 4.98062 129.296 7.19568 134.335 9.5696C144.316 14.5734 153.273 21.3509 160.589 29.3728C164.128 33.5206 167.49 37.7301 170.094 42.425C170.747 43.5987 171.47 44.693 172.044 45.9108L173.791 49.4761C174.365 50.6587 174.824 51.9118 175.292 53.1385C175.768 54.3652 176.209 55.6007 176.624 56.8273L175.512 57.4804C170.261 48.8849 163.66 41.163 156.044 34.6325C148.375 28.1726 139.727 22.8688 130.443 19.0917C121.133 15.4117 111.249 13.1349 101.259 12.5524C96.2639 12.323 91.2513 12.3848 86.274 12.9054C81.3056 13.4614 76.3812 14.4145 71.5716 15.7383C61.9083 18.3151 52.7391 22.6129 44.3995 28.1373C36.1305 33.7589 28.6646 40.6247 22.5489 48.576C16.4244 56.5096 11.5265 65.4052 8.2701 74.892C4.86366 84.3348 3.17809 94.3688 2.99276 104.429H1.70432Z"
            fill="#E3EAF7"
        />
        <path
            d="M0.407048 96.6456C0.698272 99.2754 1.13952 101.879 1.70432 104.429H2.99276C3.17809 94.3688 4.86366 84.3348 8.2701 74.892C11.5265 65.4052 16.4244 56.5096 22.5489 48.576C28.6646 40.6247 36.1305 33.7589 44.3995 28.1373C52.7391 22.6129 61.9083 18.3151 71.5716 15.7383C76.3812 14.4145 81.3056 13.4614 86.274 12.9054C91.2513 12.3848 96.2639 12.323 101.259 12.5524C111.249 13.1349 121.133 15.4117 130.443 19.0917L134.335 9.5696C129.296 7.19568 124.098 4.98062 118.644 3.5598C116.028 2.73624 113.336 2.18788 110.642 1.6391L110.41 1.59183C109.025 1.27413 107.622 1.1594 106.218 0.938779C104.815 0.762279 103.412 0.524005 102 0.400456C96.3521 -0.111393 90.66 -0.120218 84.9768 0.303381C79.3023 0.832879 73.6808 1.88305 68.1652 3.33035C62.6672 4.83942 57.3105 6.86916 52.1743 9.31368C47.0735 11.8376 42.1403 14.6969 37.5602 18.0239C33.0153 21.4039 28.7087 25.0751 24.8434 29.1699C22.8842 31.182 21.0575 33.3176 19.2748 35.4797L16.7509 38.8332C15.9302 39.9628 15.1624 41.1365 14.377 42.2838C8.2701 51.6118 3.84879 61.9458 1.67784 72.624C0.610022 77.9543 -0.0695004 83.3287 0.00992437 88.6855C-0.0342005 90.0181 0.0805241 91.3594 0.124649 92.6832L0.212899 94.6688L0.407048 96.6456Z"
            fill="url(#paint0_angular)"
        />
        <line
            x1="129.508"
            y1="21.6678"
            x2="134.707"
            y2="7.11016"
            stroke="#174291"
        />
        <defs>
            <radialGradient
                id="paint0_angular"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(88.312 52.2146) rotate(90) scale(52.2146 103.291)">
                <stop offset="0.101304" stopColor="#EA5815" />
                <stop offset="0.508334" stopColor="#ED6E33" />
            </radialGradient>
        </defs>
    </svg>
);

export const AcceleratePeakPerformanceIcon = () => (
    <svg width="177" height="119" viewBox="0 0 177 119" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M168.67 119C170.192 112.918 171 106.554 171 100C171 56.9218 136.078 22 93 22C49.9218 22 15 56.9218 15 100C15 106.554 15.8082 112.918 17.3304 119H168.67Z"
            fill="#EBF3FA"
        />
        <path
            d="M1.70432 104.429C1.13952 101.879 0.698272 99.2754 0.407048 96.6456L0.212899 94.6688L0.124649 92.6832C0.0805241 91.3594 -0.0342005 90.0181 0.00992437 88.6855C-0.0695004 83.3287 0.610022 77.9543 1.67784 72.624C3.84879 61.9458 8.2701 51.6118 14.377 42.2838C15.1624 41.1365 15.9302 39.9628 16.7509 38.8332L19.2748 35.4797C21.0575 33.3176 22.8842 31.182 24.8434 29.1699C28.7087 25.0751 33.0153 21.4039 37.5602 18.0239C42.1403 14.6969 47.0735 11.8376 52.1743 9.31368C57.3105 6.86916 62.6672 4.83942 68.1652 3.33035C73.6808 1.88305 79.3023 0.832879 84.9768 0.303381C90.66 -0.120218 96.3521 -0.111393 102 0.400456C103.412 0.524005 104.815 0.762279 106.218 0.938779C107.622 1.1594 109.025 1.27413 110.41 1.59183C113.181 2.15662 115.952 2.7126 118.644 3.5598C124.098 4.98062 129.296 7.19568 134.335 9.5696C144.316 14.5734 153.273 21.3509 160.589 29.3728C164.128 33.5206 167.49 37.7301 170.094 42.425C170.747 43.5987 171.47 44.693 172.044 45.9108L173.791 49.4761C174.365 50.6587 174.824 51.9118 175.292 53.1385C175.768 54.3652 176.209 55.6007 176.624 56.8273L175.512 57.4804C170.261 48.8849 163.66 41.163 156.044 34.6325C148.375 28.1726 139.727 22.8688 130.443 19.0917C121.133 15.4117 111.249 13.1349 101.259 12.5524C96.2639 12.323 91.2513 12.3848 86.274 12.9054C81.3056 13.4614 76.3812 14.4145 71.5716 15.7383C61.9083 18.3151 52.7391 22.6129 44.3995 28.1373C36.1305 33.7589 28.6646 40.6247 22.5489 48.576C16.4244 56.5096 11.5265 65.4052 8.2701 74.892C4.86366 84.3348 3.17809 94.3688 2.99276 104.429H1.70432Z"
            fill="#E3EAF7"
        />
        <path
            d="M0.407048 96.6456C0.698272 99.2754 1.13952 101.879 1.70432 104.429H2.99276C3.17809 94.3688 4.86366 84.3348 8.2701 74.892C11.5265 65.4052 16.4244 56.5096 22.5489 48.576C28.6646 40.6247 36.1305 33.7589 44.3995 28.1373C52.7391 22.6129 61.9083 18.3151 71.5716 15.7383C76.3812 14.4145 81.3056 13.4614 86.274 12.9054C91.2513 12.3848 96.2639 12.323 101.259 12.5524C111.249 13.1349 121.133 15.4117 130.443 19.0917L134.335 9.5696C129.296 7.19568 124.098 4.98062 118.644 3.5598C116.028 2.73624 113.336 2.18788 110.642 1.6391L110.41 1.59183C109.025 1.27413 107.622 1.1594 106.218 0.938779C104.815 0.762279 103.412 0.524005 102 0.400456C96.3521 -0.111393 90.66 -0.120218 84.9768 0.303381C79.3023 0.832879 73.6808 1.88305 68.1652 3.33035C62.6672 4.83942 57.3105 6.86916 52.1743 9.31368C47.0735 11.8376 42.1403 14.6969 37.5602 18.0239C33.0153 21.4039 28.7087 25.0751 24.8434 29.1699C22.8842 31.182 21.0575 33.3176 19.2748 35.4797L16.7509 38.8332C15.9302 39.9628 15.1624 41.1365 14.377 42.2838C8.2701 51.6118 3.84879 61.9458 1.67784 72.624C0.610022 77.9543 -0.0695004 83.3287 0.00992437 88.6855C-0.0342005 90.0181 0.0805241 91.3594 0.124649 92.6832L0.212899 94.6688L0.407048 96.6456Z"
            fill="url(#paint0_angular)"
        />
        <line
            x1="129.529"
            y1="21.3885"
            x2="134.728"
            y2="6.83087"
            stroke="#174291"
        />
        <defs>
            <radialGradient
                id="paint0_angular"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(88.312 52.2146) rotate(90) scale(52.2146 103.291)">
                <stop offset="0.101304" stopColor="#EA5815" />
                <stop offset="0.508334" stopColor="#ED6E33" />
            </radialGradient>
        </defs>
    </svg>
);

export const ScorecardCalendarIcon = () => (
    <svg
        width="197"
        height="112"
        viewBox="0 0 197 112"
        fill="none"
        className="mt-auto">
        <path
            d="M166.164 111.577C166.164 111.577 187.924 113.882 194.439 99.7067C203.905 79.1095 185.096 62.9171 171.15 65.8811C140.744 72.3169 148.066 1.27628 99.2612 15.7671C85.5336 19.8426 48.4623 -4.37733 38.1222 34.4569C35.6636 43.6921 26.9489 44.7075 14.3004 50.567C-10.4502 62.0389 0.873365 111.769 18.5485 111.961C36.2373 112.153 166.164 111.577 166.164 111.577Z"
            fill="#EBF2F9"
        />
        <path
            opacity="0.24"
            d="M136.065 103.242H52.0713C50.4504 103.242 49.1362 101.907 49.1362 100.261V13.3477H139V100.261C139 101.907 137.686 103.242 136.065 103.242Z"
            fill="#EBF3FA"
        />
        <path
            d="M162.454 95.9299H39.5455C38.3031 95.9299 37.181 95.5823 36.3715 95.0242C35.5616 94.4659 35.0701 93.7018 35.0701 92.8664V5.07013H166.93V92.8652C166.93 93.7012 166.439 94.4657 165.629 95.0241C164.82 95.5823 163.698 95.9299 162.454 95.9299Z"
            fill="#F7F7F7"
            stroke="#717171"
            strokeWidth="0.140252"
        />
        <path d="M167 5H35V21H167V5Z" fill="#ED6E33" />
        <path
            opacity="0.38"
            d="M73 13H134"
            stroke="#FADDA3"
            strokeWidth="2.65475"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M59.3185 12.9233H58.895C57.7414 12.9233 56.8057 11.9728 56.8057 10.8011V2.12216C56.8057 0.950461 57.7414 0 58.895 0H59.3185C60.4721 0 61.4078 0.950461 61.4078 2.12216V10.8011C61.4091 11.9728 60.4733 12.9233 59.3185 12.9233Z"
            fill="#47404F"
        />
        <path
            d="M144.513 12.9233H144.089C142.936 12.9233 142 11.9728 142 10.8011V2.12216C142 0.950461 142.936 0 144.089 0H144.513C145.666 0 146.602 0.950461 146.602 2.12216V10.8011C146.602 11.9728 145.666 12.9233 144.513 12.9233Z"
            fill="#47404F"
        />
    </svg>
);

export const BreakthroughIcon = () => (
    <svg width="260" height="145" viewBox="0 0 260 145" fill="none">
        <path
            d="M259.323 145C253.728 124.815 237.899 110.264 219.242 110.264C213.123 110.264 207.315 111.846 202.065 114.654C199.356 101.571 189.556 91.8613 177.844 91.8613H177.82C177.804 91.315 177.772 90.7782 177.717 90.251C177.796 89.4938 177.844 88.727 177.844 87.9506C177.844 77.9344 170.834 72.5 162.5 72.5C160.596 72.5 157.181 72.2236 155.5 73C154.64 66.0701 153 61.5 146.963 56.3969L149.5 34H148.5C148.5 34 146.5 42.5 139.5 48.5C134.5 52.7857 125 54.5 125 54.5C124.068 54.2412 117.496 57 116.5 57C110.5 59.5 105.5 64 104.577 71.1482C104.577 71.9246 104.64 72.6722 104.736 73.4198L104.727 73.4849C104.706 73.6338 104.686 73.7771 104.672 73.9278C102.585 72.9981 100.346 72.4613 97.9877 72.4613C87.3593 72.4613 78.7387 82.8131 78.7148 95.5994C78.0535 95.4653 77.3763 95.379 76.6752 95.379C71.6956 95.379 67.4171 98.9254 65.4572 104.025C58.7885 98.7337 50.8133 95.6569 42.2325 95.6569C19.3094 95.6569 0.652936 117.613 -0.000488281 145H259.323Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M166.387 10.1527C167.645 15.8389 168.182 23.0658 165.51 29.7131C165.411 29.954 165.312 30.1949 165.213 30.4358C164.907 31.161 164.589 31.8792 164.263 32.5688C163.898 33.3405 162.983 33.698 162.183 33.3815L140.023 24.4753C139.77 24.3696 139.555 24.2129 139.398 24.0099C139.29 23.8753 139.209 23.7236 139.154 23.5547C139.055 23.2646 139.05 22.9393 139.162 22.6336C139.415 21.8615 139.696 21.0817 139.999 20.3063C140.098 20.0655 140.185 19.8176 140.284 19.5767C142.955 12.9294 148.347 8.08511 153.201 4.85499C155.757 3.14924 158.17 1.90041 159.948 1.06657C160.238 0.927712 160.54 0.836479 160.834 0.788454C161.282 0.709099 161.718 0.735209 162.127 0.850286C163.012 1.10322 163.76 1.76034 164.147 2.74888C164.853 4.58123 165.726 7.15466 166.387 10.1527Z"
            fill="#ED6E33"
        />
        <path
            d="M139.994 20.3286C139.65 21.2171 139.344 22.0954 139.057 22.9686C138.873 23.5386 139.161 24.1557 139.722 24.383L162.443 33.5165C163.096 33.7805 163.836 33.4905 164.136 32.8587C164.511 32.0845 164.868 31.2843 165.211 30.4676L139.994 20.3286Z"
            fill="#588FE0"
        />
        <path
            d="M157.885 21.5368C156.684 21.8585 155.408 21.782 154.239 21.3091C152.808 20.7329 151.674 19.6278 151.069 18.2076C150.464 16.7874 150.45 15.2088 151.026 13.7782C151.603 12.3475 152.709 11.2141 154.13 10.6088C155.551 10.0035 157.13 9.98871 158.561 10.5649C159.992 11.1411 161.125 12.2462 161.731 13.6664C162.336 15.0866 162.35 16.6651 161.774 18.0958C161.197 19.5264 160.091 20.6598 158.67 21.2652C158.414 21.3748 158.152 21.4653 157.885 21.5368ZM155.255 11.6447C155.055 11.6983 154.85 11.7735 154.658 11.8557C153.567 12.3215 152.715 13.1827 152.277 14.2905C151.836 15.3887 151.844 16.6013 152.31 17.6914C152.775 18.7815 153.637 19.6329 154.745 20.071C155.843 20.5117 157.057 20.5031 158.147 20.0373C159.238 19.5715 160.09 18.7103 160.528 17.6026C160.969 16.5043 160.961 15.2917 160.495 14.2016C160.03 13.1115 159.168 12.2602 158.06 11.822C157.154 11.4522 156.179 11.397 155.255 11.6447Z"
            fill="#B74B42"
        />
        <path
            d="M162.893 2.03203C162.716 1.55902 162.446 1.16171 162.127 0.848963C161.718 0.733886 161.273 0.71033 160.834 0.787131C160.841 0.815706 160.856 0.832203 160.864 0.860779C161.567 2.6836 162.44 5.25703 163.1 8.25508C164.358 13.9413 164.896 21.1682 162.224 27.8155C162.125 28.0564 162.026 28.2973 161.927 28.5381C161.858 28.6996 161.789 28.861 161.72 29.0225L163.886 29.902C163.911 29.8441 163.936 29.7863 163.962 29.7284C164.061 29.4876 164.16 29.2467 164.258 29.0058C166.93 22.3585 166.393 15.1316 165.135 9.44538C164.47 6.42828 163.608 3.86182 162.893 2.03203Z"
            fill="#FFC08D"
        />
        <path
            d="M164.15 2.75826C163.477 1.00909 161.646 0.27462 159.948 1.06643C158.17 1.90026 155.766 3.14654 153.201 4.85484L166.386 10.1526C165.726 7.15452 164.853 4.58108 164.15 2.75826Z"
            fill="#588FE0"
        />
        <path
            d="M143.557 27.4885C141.437 26.7804 138.9 28.6341 137.888 31.6414C136.876 34.6486 139.896 38.3722 139.896 38.3722C139.896 38.3722 144.552 37.2265 145.565 34.2192C146.567 31.2146 145.667 28.1991 143.557 27.4885Z"
            fill="#B74B42"
        />
        <path
            d="M155.655 32.1994C153.535 31.4914 150.998 33.3451 149.986 36.3523C148.974 39.3595 151.994 43.0831 151.994 43.0831C151.994 43.0831 156.651 41.9374 157.663 38.9302C158.675 35.923 157.778 32.917 155.655 32.1994Z"
            fill="#B74B42"
        />
        <path
            d="M142.408 30.859C140.947 30.3622 139.197 31.6477 138.497 33.7239C137.797 35.8001 139.884 38.3649 139.884 38.3649C139.884 38.3649 143.094 37.5762 143.794 35.5C144.484 33.4264 143.866 31.3462 142.408 30.859Z"
            fill="#ED6E33"
        />
        <path
            d="M154.532 35.5543C153.071 35.0575 151.322 36.343 150.622 38.4192C149.922 40.4954 152.008 43.0602 152.008 43.0602C152.008 43.0602 155.219 42.2715 155.919 40.1953C156.612 38.1312 155.993 36.051 154.532 35.5543Z"
            fill="#ED6E33"
        />
        <path
            d="M154.636 19.3959L160.163 16.8122C159.827 18.1479 158.8 19.2704 157.37 19.6534C156.417 19.9087 155.456 19.7887 154.636 19.3959Z"
            fill="#FFE6D2"
        />
        <path
            d="M160.107 14.7357L153.132 18.095C152.882 17.7333 152.694 17.3346 152.574 16.8869C152.015 14.8009 153.257 12.651 155.344 12.0918C157.392 11.5428 159.497 12.7248 160.107 14.7357Z"
            fill="#FFE6D2"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="148.5"
                y1="34.5"
                x2="89.5"
                y2="147.5"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#EBF3FA" stopOpacity="0" />
                <stop offset="0.186934" stopColor="#EBF3FA" />
            </linearGradient>
        </defs>
    </svg>
);
