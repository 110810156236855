import React from 'react';
import { Link, useParams } from 'react-router-dom';

const SubstopItem = ({
    type,
    id,
    stopId,
    is_locked,
    showLockModal,
    points,
    totalPoints,
    dataviz,
}) => {
    let to = '',
        title = '';
    switch (type) {
        case 'solo_workout':
            to = `solo_workout/${id}`;
            title = 'Solo Workout';
            break;
        case 'dataviz':
            to = `dataviz/${id}`;
            title = 'Data Viz';
            break;
        case 'growthlab':
            to = `growthlab/${id}`;
            title = 'GrowthLab';
            break;
        case 'postcard':
            to = `postcard/${id}`;
            title = 'Postcard';
            break;
        default:
            return null;
    }

    const percentage = parseFloat(points / totalPoints).toFixed(2);
    const dataVizPercentage = dataviz
        ? parseFloat(dataviz.points / totalPoints).toFixed(2)
        : '';

    const link = `/stop/${stopId}/${to}`;

    return (
        <li className="substop-item pr-3">
            {is_locked ? (
                <button
                    className="d-flex align-items-center py-3 text-body pl-5 w-100 bg-transparent border-0"
                    onClick={showLockModal}>
                    <span
                        className="d-flex align-items-center justify-content-center mr-3 small ml-2"
                        style={{
                            border: '2px solid #dadada',
                            borderRadius: '50%',
                            width: '1.25rem',
                            height: '1.25rem',
                        }}
                    />
                    <span className="mr-auto">{title}</span>
                    <i className="fe fe-lock" />
                </button>
            ) : (
                <Link
                    to={link}
                    className="d-flex align-items-center py-3 text-body pl-5">
                    <span
                        className="d-flex align-items-center justify-content-center mr-3 small ml-2"
                        style={{
                            border: '2px solid #dadada',
                            borderRadius: '50%',
                            width: '1.25rem',
                            height: '1.25rem',
                        }}
                    />
                    <span className="mr-auto">{title}</span>
                    <span
                        className="badge badge-pill p-2 text-white"
                        style={{ backgroundColor: '#414141' }}>
                        {(type === 'dataviz' ? dataVizPercentage : percentage) *
                            100}
                        %
                    </span>
                </Link>
            )}
        </li>
    );
};

export const HundredHoursSubstopItem = ({
    type,
    id,
    stopId,
    is_locked,
    showLockModal,
    points,
    totalPoints,
    is_done,
}) => {
    const { projectId } = useParams();

    let to = '',
        title = '';
    switch (type) {
        case 'solo_workout':
            to = `solo_workout/${id}`;
            title = 'Solo Workout';
            break;
        case 'iterated_response':
            to = `iterated-response/${id}`;
            title = 'Iterated Workout';
            break;
        case 'peer_exchange':
            to = `peer-exchange/${id}`;
            title = 'Peer Exchange';
            break;
        default:
            return null;
    }

    const percentage = parseFloat(points / totalPoints).toFixed(2);

    const link = `/100h/projects/${projectId}/hurdles/${stopId}/${to}`;

    return (
        <li className="substop-item pr-3">
            {is_locked ? (
                <button
                    className="d-flex align-items-center py-3 text-body pl-5 w-100 bg-transparent border-0"
                    onClick={showLockModal}>
                    <span
                        className="d-flex align-items-center justify-content-center mr-3 small ml-2"
                        style={{
                            border: '2px solid #dadada',
                            borderRadius: '50%',
                            width: '1.25rem',
                            height: '1.25rem',
                        }}
                    />
                    <span className="mr-auto">{title}</span>
                    <i className="fe fe-lock" />
                </button>
            ) : (
                <Link
                    to={link}
                    className="d-flex align-items-center py-3 text-body pl-5">
                    <span
                        className="d-flex align-items-center justify-content-center mr-3 small ml-2"
                        style={{
                            border: '2px solid #dadada',
                            borderRadius: '50%',
                            width: '1.25rem',
                            height: '1.25rem',
                        }}>
                        {is_done ? <span className="fe fe-check" /> : null}
                    </span>
                    <span className="mr-auto">{title}</span>
                    {!isNaN(percentage) && (
                        <span
                            className="badge badge-pill p-2 text-white"
                            style={{ backgroundColor: '#414141' }}>
                            {percentage * 100}%
                        </span>
                    )}
                </Link>
            )}
        </li>
    );
};

export default SubstopItem;
