import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StopLetter from 'components/StopLetter';
import {
    show100hSoloWorkoutModal,
    show100hPeerExchangeModal,
    show100hIteratedResponseModal,
} from 'reducers/modalActions';
import { Link, useParams } from 'react-router-dom';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { HundredHIcon } from '../../components/RaazIcons';
import { getProject } from '../../reducers/hundredhoursActions';
import Loader from '../../components/Loader';
import { StopGroups } from '../RAAZ/RaazDashboard';
import Button from '../../components/Button';
import { showDeleteModal } from '../../reducers/modalActions';
import { gql, useQuery, useMutation } from '@apollo/client';
import groupBy from 'lodash.groupby';

const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
        }
    }
`;

const GET_PROJECT = gql`
    query GetProject($userId: ID!, $id: ID!) {
        project(userId: $userId, id: $id) {
            id
            title
            owner {
                id
                firstName
                lastName
            }
            currentStop(userId: $userId) {
                id
            }
            stops {
                id
                ordinality
                stopType
                meta
                name
                substops
                stopGroup {
                    id
                    name
                    ordinality
                    stopGroupType
                }
            }
        }
    }
`;

const ProjectScreen = () => {
    const match = useRouteMatch();
    const params = useParams();
    const dispatch = useDispatch();

    const [project, setProject] = useState(null);
    const { stop_groups } = project || {};

    useEffect(() => {
        dispatch(getProject(params.projectId)).then(res => {
            setProject(res);
        }, console.warn);
    }, [params.projectId]);

    if (!project) return <Loader fullscreen />;

    const filteredStopGroups = stop_groups.filter(
        s => s.type === 'hundred_hours',
    );

    const owner = project.members.find(m => m.id === project.owner_id);
    const ownerName = owner.name;

    // const { data, loading } = useQuery(GET_CURRENT_USER);
    // const r = useQuery(GET_PROJECT, {
    //     skip: !data,
    //     variables: {
    //         userId: data ? data.currentUser.id : '',
    //         id: params.projectId,
    //     },
    // });

    // if (!(r.data && r.data.project)) return <Loader fullscreen />;

    // const { project } = r.data;
    // const ownerName = `${project.owner.firstName} ${project.owner.lastName}`;
    // const stop_groups = r.data.project.stops.map(s => s.stopGroup);
    // const g = groupBy(r.data.project.stops, 'stopGroup.id');
    // const uniqGroups = [...new Set(stop_groups.map(s => s.id))]
    //     .map(i => ({ ...stop_groups.find(s => s.id === i), stops: g[i] }))
    //     .sort((a, b) => a.ordinality - b.ordinality);

    return (
        <div className="ProjectContainer" style={{ position: 'relative' }}>
            <div
                style={{
                    position: 'absolute',
                    backgroundImage:
                        'radial-gradient(circle at 49% 78%, #f9f9f9, #b5b5b5 70%)',
                    height: '55vh',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                }}
            />
            <div
                className="container w-100 mb-7"
                style={{
                    position: 'relative',
                    zIndex: 2,
                    maxWidth: MAX_WIDTH,
                }}>
                <div className="pt-6 mb-5">
                    <div className="w-100" style={{ position: 'relative' }}>
                        <h1 className="font-weight-bold text-uppercase mb-3">
                            100H Launchpad
                        </h1>
                        <h3>
                            {project.title} by {ownerName}
                        </h3>

                        <Button
                            transparent
                            small
                            className="d-flex align-items-center"
                            onClick={() => {
                                dispatch(
                                    showDeleteModal({
                                        projectId: params.projectId,
                                    }),
                                );
                            }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}>
                            <i className="fe fe-trash" />
                            <span className="ml-2">Delete</span>
                        </Button>
                    </div>
                    <Button
                        small
                        rounded
                        style={{ borderRadius: '2px' }}
                        to={`/100h/projects/${params.projectId}/hurdles/setup`}>
                        Edit Project
                    </Button>
                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <StopGroups
                            stop_groups={filteredStopGroups}
                            hundred_hours
                            current_stop={project.current_stop}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectScreen;
