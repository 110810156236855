import { gql } from '@apollo/client';

const START_USER_CARD = gql`
    mutation StartUserCard($input: StartUserCardInput!) {
        startUserCard(input: $input) {
            success
            errors
            userCard {
                id
                isStarted
            }
        }
    }
`;

export default START_USER_CARD;
