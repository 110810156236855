import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import ProjectScreen from './100H/ProjectScreen';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import ProjectIndexScreen from './100H/ProjectIndexScreen';
import HurdleScreen from './100H/HurdleScreen';
import NewProjectScreen from './100H/NewProjectScreen';
import SetupScreen from './100H/SetupScreen';
import { useDispatch } from 'react-redux';
import PeerExchangeScreen from './PeerExchangeScreen';
import IteratedResponseScreen from './100H/IteratedResponseScreen';
import { getProject } from '../reducers/hundredhoursActions';
import Loader from '../components/Loader';
import { useMutation, gql } from '@apollo/client';

const WithProjectDetails = ({ userId }) => {
    const [stops, setStops] = useState([]);
    const [projectDetails, setProjectDetails] = useState([]);
    const { projectId } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProject(projectId)).then(res => {
            setStops(res.stops);
            setProjectDetails(res);
        });
    }, [projectId]);

    const UPDATE_LAST_PROJECT_VISITED = gql`
        mutation LastProjectVisited(
            $lastProjectVisited: UpdateLastProjectVisitedInput!
        ) {
            updateLastProjectVisited(input: $lastProjectVisited) {
                success
                errors
            }
        }
    `;

    const [triggerUpdateLastProjectVisited, { loading }] = useMutation(
        UPDATE_LAST_PROJECT_VISITED,
        {
            variables: {
                lastProjectVisited: {
                    projectId,
                    userId,
                },
            },
        },
    );

    useEffect(() => {
        triggerUpdateLastProjectVisited();
    }, []);

    if (!stops) return <Loader fullscreen />;

    return (
        <Fragment>
            <Route path="/100h/projects/:projectId/hurdles/setup">
                <div className="mx-auto py-5" style={{ maxWidth: MAX_WIDTH }}>
                    <SetupScreen />
                </div>
            </Route>
            <Route path="/100h/projects/:projectId/hurdles/:stopId/solo_workout/:soloWorkoutId">
                <HurdleScreen stops={stops} hurdle />
            </Route>

            <Route path="/100h/projects/:projectId/hurdles/:stopId/peer-exchange/:soloWorkoutId">
                <PeerExchangeScreen
                    stops={stops}
                    projectDetails={projectDetails}
                />
            </Route>

            <Route path="/100h/projects/:projectId/hurdles/:stopId/iterated-response/:soloWorkoutId">
                <IteratedResponseScreen
                    stops={stops}
                    projectDetails={projectDetails}
                />
            </Route>

            <Route path="/100h/projects/:projectId" exact>
                <ProjectScreen userId={userId} />
            </Route>
        </Fragment>
    );
};

const HundredHScreen = ({ userId }) => {
    return (
        <div>
            <Switch>
                <Route path="/100h/projects/new" exact>
                    <div
                        className="mx-auto py-5"
                        style={{ maxWidth: MAX_WIDTH, minWidth: '100%' }}>
                        <NewProjectScreen userId={userId} />
                    </div>
                </Route>
                <Route path="/100h/projects/:projectId">
                    <WithProjectDetails userId={userId} />
                </Route>
            </Switch>
        </div>
    );
};

export default HundredHScreen;
