import React, { Fragment, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Lottie from 'react-lottie-player';
import pulseData from '../lotties/pulse.json';
import loaderData from '../lotties/avatarloader.json';
import Loader from './Loader';
import Button from './Button';
import { GoogleMeetsLogo } from './RaazIcons';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';

const Avatar = ({ src, center = false, firstName = '', lastName = '' }) => {
    const isDefaultAvatar = src === '/images/user-icon.png';

    return (
        <div
            className="d-flex align-items-center text-center justify-content-center"
            style={{
                width: '40px',
                height: '40px',
                backgroundColor: getDefaultAvatarColors(
                    `${firstName} ${lastName}`,
                ),
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                color: 'white',
                borderRadius: '50%',
                overflow: 'hidden',
                ...(center
                    ? {
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          margin: 'auto',
                      }
                    : {}),
                ...(!isDefaultAvatar && { backgroundImage: `url(${src})` }),
            }}>
            {isDefaultAvatar ? `${firstName[0]}${lastName[0]}` : null}
        </div>
    );
};

const Pulse = ({ src, ...others }) => {
    return (
        <div className="pulse--container" style={{ position: 'relative' }}>
            <Lottie
                loop
                play
                animationData={pulseData}
                style={{ height: 90, width: 90 }}
            />
            <Avatar src={src} center {...others} />
        </div>
    );
};

const AvatarLoader = () => {
    return (
        <div className="py-5 px-3">
            <Lottie
                loop
                play
                style={{
                    height: 24,
                    width: 24,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                animationData={loaderData}
            />
            <p
                className="mt-3 mb-0 text-center"
                style={{ fontSize: '11px', color: '#717171' }}>
                Finding member...
            </p>
        </div>
    );
};

const GET_BREAKOUT_GROUP = gql`
    query GetBreakoutGroup($eventId: ID!, $userId: ID) {
        userGroup(eventId: $eventId, userId: $userId) {
            id
            group
            linkUrl
            users {
                id
                firstName
                lastName
                avatar
            }
        }
        currentUser {
            id
            firstName
            lastName
            avatar
        }
    }
`;

const NoGroup = ({ currentUser }) => (
    <Fragment>
        <h1 className="lora large-text">Breakout Groups</h1>
        <p className="text-center mb-5 pb-3" style={{ fontSize: '14px' }}>
            Our algorithm is finding the best match for you. Give us a moment.
        </p>
        <Pulse
            src={currentUser.avatar}
            firstName={currentUser.firstName}
            lastName={currentUser.lastName}
        />
        <div className="breakout-members d-flex align-items-center">
            <AvatarLoader />
            <AvatarLoader />
            <AvatarLoader />
        </div>
    </Fragment>
);

const WithGroup = ({ linkUrl, group, users, next }) => (
    <Fragment>
        <h1 className="lora large-text mb-2">You Are in Group {group}</h1>
        <p
            className="text-center"
            style={{ color: '#717171', fontSize: '14px' }}>
            Breakout Challenge
        </p>
        <p className="text-center mb-5 pb-3" style={{ fontSize: '14px' }}>
            Launch the Google Meets room and join your group.
        </p>
        <div
            className="breakout-members d-flex align-items-center justify-content-center flex-wrap mb-5"
            style={{ width: '400px' }}>
            {users.map((u, i) => (
                <div
                    key={i}
                    style={{ width: 80 }}
                    className="py-5 px-3 d-flex flex-column text-center align-items-center justify-content-center mb-2">
                    <Avatar
                        src={u.avatar}
                        firstName={u.firstName}
                        lastName={u.lastName}
                    />
                    <p
                        className="mt-3 mb-0"
                        style={{ fontSize: '11px', color: '#000' }}>
                        {u.firstName}
                    </p>
                </div>
            ))}
        </div>

        {linkUrl && (
            <Button
                href={linkUrl}
                primary
                rounded
                className="d-flex align-items-center mb-3"
                style={{ borderRadius: '2px' }}>
                <span className="mr-2">Go to Google Meets</span>
                <GoogleMeetsLogo />
            </Button>
        )}
    </Fragment>
);

const BreakoutGroups = ({ next, eventId, userId }) => {
    const r = useQuery(GET_BREAKOUT_GROUP, {
        variables: {
            eventId,
            userId,
        },
        pollInterval: 3000,
    });

    useEffect(() => {
        if (r.data && r.data.userGroup && r.data.userGroup.id !== '-') {
            r.stopPolling();
        }
    }, [r.data]);

    if (r.loading) return <Loader fullscreen />;

    const { userGroup, currentUser } = r.data;

    const noGroup = userGroup.id === '-';

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {noGroup && <NoGroup currentUser={currentUser} />}
            {!noGroup && <WithGroup {...userGroup} next={next} />}
        </div>
    );
};

export default BreakoutGroups;
