import React, { useState } from 'react';
import Button from './Button';
import TextareaAutosize from 'react-autosize-textarea/lib';

export const Input = ({
    type = 'text',
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                type={type}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const Textarea = ({ field, className = '', id = '', label = '' }) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <TextareaAutosize
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const NumericalInput = ({
    inputmode = 'numeric',
    pattern = '[0-9]*',
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                type="text"
                inputMode={inputmode}
                pattern={pattern}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

export const PasswordInput = ({
    field,
    className = '',
    id = '',
    label = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, togglePasswordView] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const toggleShowPassword = () => {
        togglePasswordView(!showPassword);
    };

    return (
        <div
            className={`ignite-input-group py-3 ${className} ${
                isFocused ? 'is-focused' : ''
            } ${hasValue ? 'is-dirty' : ''}`}>
            <label htmlFor={id || name}>{label}</label>
            <input
                className="pr-5"
                type={!showPassword ? 'password' : 'text'}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            <Button
                transparent
                large
                className="append-button"
                onClick={toggleShowPassword}>
                {showPassword ? (
                    <i className="fe fe-eye-off" />
                ) : (
                    <i className="fe fe-eye" />
                )}
            </Button>
        </div>
    );
};

export const Radio = ({ label, options, field, slider, disabled, form }) => {
    return (
        <div className="mb-3">
            <p
                className="font-weight-bold"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            <div
                className={`OptionsContainer ${
                    slider ? 'is-slider' : ''
                } mb-4`}>
                {options &&
                    options.map((o, i) => {
                        const checked = field.value === o.value;
                        return (
                            <label key={i} className="d-flex mb-3">
                                <input
                                    type="radio"
                                    disabled={disabled}
                                    onChange={() =>
                                        form.setFieldValue(field.name, o.value)
                                    }
                                    checked={checked}
                                    value={o.value}
                                    style={{ display: 'none' }}
                                />
                                <i
                                    className={`RadioInput ${
                                        checked ? 'isSelected' : ''
                                    }`}
                                />

                                <span className="ml-3">
                                    {o.body || o.label}
                                </span>
                            </label>
                        );
                    })}
            </div>
        </div>
    );
};

export const RadioHorizontal = ({
    label,
    field,
    disabled,
    form,
    options,
    slider_left_label,
    slider_right_label,
}) => {
    return (
        <div className="mb-3">
            <p>{label}</p>
            <div
                className="d-flex align-items-center mb-3"
                style={{
                    color: '#rgba(60,60,67,0.6)',
                    fontSize: '0.6875rem',
                }}>
                <span className="mr-auto">{slider_left_label}</span>
                <span>{slider_right_label}</span>
            </div>
            <div className={`OptionsContainer is-slider is-horizontal mb-4`}>
                {options.map((o, i) => {
                    const checked = field.value === o.value;
                    return (
                        <label
                            key={i}
                            className="d-flex flex-column align-items-center">
                            <input
                                type="radio"
                                disabled={disabled}
                                onChange={() =>
                                    form.setFieldValue(field.name, o.value)
                                }
                                checked={checked}
                                value={o.value}
                                style={{ display: 'none' }}
                            />
                            <i
                                className={`RadioInput ${
                                    checked ? 'isSelected' : ''
                                }`}
                            />

                            <span
                                className="mt-3"
                                style={{
                                    color: '#rgba(60,60,67,0.6)',
                                    fontSize: '0.6875rem',
                                }}>
                                {o.body}
                            </span>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};
