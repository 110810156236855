import React from 'react';

export const IteratedResponseIcon = ({ width = 72, height = 72 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 72 72">
        <g fill="none">
            <circle cx="27.857" cy="45.839" r="25.965" fill="#095FFD" />
            <g>
                <path
                    fill="#FFC20E"
                    d="M5.73 19.46c3.52 4.892 5.955 10.48 7.145 16.388l1.093 5.436 1.153-5.64C16.326 29.75 18.773 24.178 22.3 19.3l4.35-6.017-4.227-12.001H5.515L1.288 13.282 5.73 19.46zm8.238-12.14c2.001 0 3.623 1.621 3.623 3.622 0 2-1.622 3.623-3.623 3.623-2 0-3.623-1.622-3.623-3.623 0-2 1.623-3.623 3.623-3.623z"
                    transform="translate(21.738 21.01)"
                />
                <path
                    fill="#121212"
                    d="M13.968 42.491h-.001c-.575-.001-1.07-.406-1.183-.97l-1.093-5.435c-1.163-5.779-3.498-11.136-6.94-15.921L.306 13.987c-.23-.32-.29-.733-.158-1.106L4.376.88c.17-.484.626-.807 1.139-.807h16.907c.512 0 .969.323 1.14.807l4.226 12.001c.132.374.071.788-.16 1.109l-4.35 6.017c-3.448 4.77-5.794 10.114-6.974 15.88l-1.153 5.639c-.114.562-.609.965-1.183.965zM6.711 18.754c3.566 4.958 6.009 10.493 7.266 16.46 1.26-6.032 3.73-11.623 7.343-16.623l3.98-5.504L21.567 2.49H6.37l-3.733 10.6 4.074 5.665zm7.257-2.981c-2.663 0-4.83-2.167-4.83-4.83 0-2.665 2.167-4.832 4.83-4.832 2.664 0 4.831 2.167 4.831 4.831 0 2.664-2.167 4.83-4.83 4.83zm0-7.246c-1.332 0-2.415 1.084-2.415 2.415 0 1.332 1.083 2.415 2.415 2.415 1.333 0 2.416-1.083 2.416-2.415 0-1.331-1.083-2.415-2.416-2.415z"
                    transform="translate(21.738 21.01)"
                />
            </g>
            <g>
                <path
                    fill="#B5A19C"
                    d="M1.288 1.281L18.195 1.281 18.195 6.111 1.288 6.111z"
                    transform="translate(25.965 16.178) rotate(-180 9.742 3.696)"
                />
                <path
                    fill="#121212"
                    d="M18.195 7.32H1.288C.621 7.32.08 6.778.08 6.11v-4.83C.08.613.621.073 1.288.073h16.907c.667 0 1.208.54 1.208 1.208v4.83c0 .668-.54 1.208-1.208 1.208zm-15.7-2.416h14.493V2.488H2.496v2.416z"
                    transform="translate(25.965 16.178)"
                />
            </g>
            <g>
                <path
                    fill="#9DC1E4"
                    d="M23.026 1.28v6.101c0 1.3-1.053 2.353-2.353 2.353H3.64c-1.3 0-2.353-1.053-2.353-2.353v-6.1"
                    transform="translate(23.55 7.725)"
                />
                <path
                    fill="#121212"
                    d="M20.673 10.942H3.641c-1.963 0-3.56-1.597-3.56-3.56V1.28C.08.613.62.073 1.287.073s1.208.54 1.208 1.208v6.1c0 .632.513 1.146 1.145 1.146h17.032c.632 0 1.145-.514 1.145-1.145V1.28c0-.668.541-1.208 1.208-1.208.667 0 1.208.54 1.208 1.208v6.1c0 1.964-1.598 3.561-3.56 3.561z"
                    transform="translate(23.55 7.725)"
                />
            </g>
            <g>
                <path
                    fill="#FFF"
                    d="M1.288 20.603L1.288 1.281"
                    transform="translate(34.418 40.332)"
                />
                <path
                    fill="#121212"
                    d="M1.288 21.811c-.667 0-1.208-.54-1.208-1.208V1.281C.08.613.621.073 1.288.073c.667 0 1.208.54 1.208 1.208v19.322c0 .668-.541 1.208-1.208 1.208z"
                    transform="translate(34.418 40.332)"
                />
            </g>
            <g fill="#121212" transform="translate(12.982)">
                <path d="M48.585 5.883c-.309 0-.618-.119-.854-.354l-3.415-3.416c-.472-.471-.472-1.236 0-1.707.471-.472 1.236-.472 1.708 0l3.415 3.415c.471.472.471 1.236 0 1.708-.236.236-.545.354-.854.354z" />
                <path d="M45.17 5.883c-.31 0-.618-.119-.854-.354-.472-.472-.472-1.236 0-1.708L47.73.406c.472-.472 1.236-.472 1.708 0 .471.471.471 1.236 0 1.707L46.024 5.53c-.236.236-.545.354-.854.354z" />
                <circle cx="2.194" cy="9.006" r="1.208" />
                <path d="M34.644 71.562c-.047 0-.094-.002-.142-.008C2.154 67.785.071 33.001.053 32.65c-.032-.666.48-1.232 1.147-1.265.66-.039 1.232.479 1.265 1.145.005.084.47 8.441 4.716 17.099 5.629 11.479 14.914 18.047 27.6 19.526.663.077 1.138.677 1.06 1.34-.07.614-.593 1.067-1.197 1.067z" />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M1.344 10.743c.787 4.768 5.83 7.905 5.83 7.905s3.765-4.591 2.978-9.359c-.787-4.767-5.829-7.904-5.829-7.904S.558 5.976 1.344 10.743z"
                    transform="translate(6.038 15.398)"
                />
                <path
                    fill="#121212"
                    d="M7.172 19.856c-.219 0-.44-.059-.637-.183-.225-.139-5.516-3.486-6.382-8.733C-.713 5.693 3.223.823 3.39.62 3.774.15 4.446.036 4.962.36c.224.14 5.516 3.486 6.383 8.733.865 5.247-3.07 10.117-3.239 10.32-.236.29-.582.443-.934.443zm-2.57-16.78c-.953 1.472-2.56 4.482-2.066 7.47.493 2.99 2.981 5.324 4.358 6.41.954-1.471 2.56-4.48 2.067-7.47-.494-2.989-2.982-5.323-4.358-6.41z"
                    transform="translate(6.038 15.398)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M5.143 10.438c3.724 3.078 9.587 2.133 9.587 2.133s-.175-5.935-3.899-9.014C7.107.48 1.244 1.423 1.244 1.423s.175 5.936 3.899 9.015z"
                    transform="translate(.177 28.078)"
                />
                <path
                    fill="#121212"
                    d="M12.623 13.918c-2.236 0-5.66-.41-8.25-2.55C.275 7.981.044 1.725.037 1.46.019.853.453.33 1.052.232 1.312.19 7.502-.76 11.6 2.627s4.33 9.643 4.337 9.909c.017.606-.417 1.13-1.015 1.227-.096.016-.996.155-2.299.155zm-6.71-4.411c2.347 1.94 5.752 2.07 7.496 1.974-.247-1.735-1.012-5.063-3.348-6.994-2.334-1.928-5.745-2.05-7.496-1.971.247 1.736 1.013 5.061 3.347 6.99z"
                    transform="translate(.177 28.078)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M7.502 10.122c4.425 1.942 9.805-.57 9.805-.57s-1.792-5.662-6.216-7.604c-4.424-1.942-9.805.57-9.805.57s1.792 5.662 6.216 7.604z"
                    transform="translate(0 37.313)"
                />
                <path
                    fill="#121212"
                    d="M11.292 12.067c-1.393 0-2.868-.221-4.275-.838C2.147 9.09.214 3.135.135 2.882c-.184-.577.09-1.201.64-1.458.24-.111 5.931-2.718 10.801-.582 4.87 2.138 6.803 8.093 6.882 8.345.184.578-.09 1.202-.64 1.46-.17.078-3.098 1.42-6.526 1.42zm-3.304-3.05c2.788 1.226 6.095.41 7.75-.152-.71-1.601-2.358-4.592-5.132-5.81-2.774-1.219-6.089-.407-7.75.152.712 1.603 2.359 4.592 5.132 5.81z"
                    transform="translate(0 37.313)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M8.268 10.178c4.602 1.47 9.692-1.588 9.692-1.588s-2.372-5.445-6.974-6.916C6.383.204 1.293 3.263 1.293 3.263s2.373 5.444 6.975 6.915z"
                    transform="translate(2.894 45.287)"
                />
                <path
                    fill="#121212"
                    d="M10.96 11.785c-1.003 0-2.038-.13-3.06-.457C2.836 9.71.293 3.988.187 3.746-.056 3.19.152 2.54.671 2.228.897 2.09 6.289-1.097 11.353.525c5.065 1.618 7.608 7.339 7.714 7.582.242.555.034 1.205-.485 1.517-.18.11-3.652 2.161-7.622 2.161zM2.925 3.782c.874 1.519 2.824 4.322 5.71 5.246 2.902.926 6.105-.225 7.694-.958-.875-1.519-2.824-4.322-5.71-5.246-2.898-.924-6.104.227-7.694.958z"
                    transform="translate(2.894 45.287)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M10.142 10.21c4.827-.206 8.551-4.832 8.551-4.832S14.59 1.085 9.763 1.29c-4.828.206-8.551 4.832-8.551 4.832s4.103 4.293 8.93 4.087z"
                    transform="translate(4.102 52.408)"
                />
                <path
                    fill="#121212"
                    d="M9.805 11.425C4.67 11.425.517 7.143.338 6.957c-.418-.439-.447-1.12-.067-1.592C.437 5.16 4.398.31 9.71.084c5.349-.237 9.672 4.27 9.855 4.46.42.438.448 1.118.068 1.591-.166.207-4.128 5.056-9.44 5.281l-.052-1.206.051 1.206c-.13.006-.26.009-.388.009zM2.922 6.048c1.344 1.124 4.123 3.061 7.169 2.955 3.042-.13 5.652-2.316 6.892-3.551-1.345-1.124-4.14-3.076-7.169-2.955-3.042.13-5.652 2.316-6.892 3.55z"
                    transform="translate(4.102 52.408)"
                />
            </g>
            <path
                fill="#121212"
                d="M24.23 71.562c-.605 0-1.127-.453-1.198-1.067-.078-.663.396-1.263 1.06-1.34 12.686-1.479 21.972-8.048 27.6-19.527 4.246-8.657 4.711-17.014 4.716-17.098.033-.666.611-1.182 1.266-1.145.665.033 1.178.599 1.146 1.264-.018.352-2.1 35.136-34.45 38.905-.046.006-.093.008-.14.008z"
            />
            <g>
                <path
                    fill="#00B581"
                    d="M10.128 10.566C9.341 15.334 4.3 18.471 4.3 18.471S.533 13.88 1.32 9.112c.787-4.767 5.83-7.904 5.83-7.904s3.765 4.591 2.978 9.358z"
                    transform="translate(54.345 15.575)"
                />
                <path
                    fill="#121212"
                    d="M4.3 19.679c-.351 0-.698-.153-.934-.442C3.198 19.033-.738 14.163.13 8.916.994 3.669 6.286.322 6.51.183c.515-.322 1.188-.21 1.571.259.168.204 4.104 5.074 3.238 10.32-.866 5.248-6.158 8.595-6.382 8.734-.198.124-.42.183-.638.183zM6.872 2.897C5.496 3.983 3.005 6.319 2.512 9.31c-.496 3.004 1.11 6.005 2.065 7.472 1.376-1.086 3.866-3.422 4.36-6.413.496-3.004-1.11-6.005-2.065-7.472z"
                    transform="translate(54.345 15.575)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M10.807 10.438c-3.725 3.078-9.588 2.133-9.588 2.133s.176-5.935 3.9-9.014C8.841.48 14.704 1.423 14.704 1.423s-.174 5.936-3.898 9.015z"
                    transform="translate(55.73 28.078)"
                />
                <path
                    fill="#121212"
                    d="M3.326 13.918c-1.303 0-2.203-.14-2.299-.155-.598-.096-1.032-.621-1.014-1.227.007-.266.238-6.522 4.336-9.91C8.45-.761 14.64.192 14.898.232c.598.097 1.032.622 1.015 1.228-.008.265-.239 6.522-4.337 9.909-2.59 2.14-6.014 2.55-8.25 2.55zm-.785-2.44c1.749.083 5.162-.042 7.496-1.971 2.334-1.93 3.1-5.255 3.347-6.991-1.747-.077-5.162.044-7.496 1.972-2.334 1.929-3.1 5.255-3.347 6.99z"
                    transform="translate(55.73 28.078)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M11.04 10.122c-4.425 1.942-9.806-.57-9.806-.57S3.027 3.89 7.451 1.948c4.424-1.942 9.805.57 9.805.57s-1.792 5.662-6.217 7.604z"
                    transform="translate(53.314 37.313)"
                />
                <path
                    fill="#121212"
                    d="M7.25 12.067c-3.429 0-6.356-1.342-6.527-1.42-.549-.258-.823-.882-.64-1.46.08-.252 2.014-6.207 6.883-8.345 4.868-2.133 10.561.472 10.8.582.55.257.825.88.642 1.458-.08.253-2.014 6.208-6.883 8.347-1.407.616-2.883.838-4.275.838zM2.8 8.862c1.66.56 4.975 1.375 7.753.154 2.774-1.217 4.42-4.206 5.132-5.809-1.662-.56-4.976-1.37-7.75-.152C5.15 4.278 3.51 7.26 2.801 8.862z"
                    transform="translate(53.314 37.313)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M11.002 10.178C6.4 11.648 1.31 8.59 1.31 8.59s2.372-5.445 6.975-6.916c4.602-1.47 9.692 1.589 9.692 1.589s-2.372 5.444-6.975 6.915z"
                    transform="translate(49.691 45.287)"
                />
                <path
                    fill="#121212"
                    d="M8.31 11.785c-3.969 0-7.44-2.052-7.622-2.16-.52-.313-.727-.963-.484-1.518.105-.243 2.649-5.964 7.714-7.582 5.065-1.62 10.455 1.567 10.682 1.703.52.313.727.963.484 1.518-.106.243-2.649 5.964-7.714 7.582-1.021.327-2.056.457-3.06.457zM2.942 8.067c1.593.73 4.806 1.88 7.694.961 2.9-.927 4.842-3.724 5.712-5.242-1.593-.729-4.806-1.88-7.694-.961-2.9.927-4.842 3.724-5.712 5.242z"
                    transform="translate(49.691 45.287)"
                />
            </g>
            <g>
                <path
                    fill="#00B581"
                    d="M9.91 10.21c-4.828-.206-8.552-4.832-8.552-4.832s4.103-4.293 8.93-4.088c4.828.206 8.552 4.832 8.552 4.832s-4.103 4.293-8.93 4.087z"
                    transform="translate(47.703 52.408)"
                />
                <path
                    fill="#121212"
                    d="M10.246 11.425c-.129 0-.258-.003-.388-.009-5.313-.225-9.275-5.075-9.44-5.28-.38-.472-.352-1.154.067-1.593.183-.19 4.542-4.709 9.855-4.459 5.313.225 9.275 5.075 9.44 5.281.381.472.352 1.153-.067 1.592-.178.186-4.331 4.468-9.467 4.468zm-.285-2.422c3.024.098 5.82-1.83 7.166-2.954-1.245-1.235-3.864-3.424-6.89-3.552C7.19 2.388 4.41 4.325 3.07 5.448c1.244 1.236 3.864 3.427 6.892 3.555z"
                    transform="translate(47.703 52.408)"
                />
            </g>
        </g>
    </svg>
);

export const PeerExchangeIcon = ({ width = 72, height = 72 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <circle
                cx="26.158"
                cy="41.974"
                r="26.158"
                fill="#1765F9"
                fillRule="nonzero"
            />
            <g
                fill="#121212"
                fillRule="nonzero"
                transform="translate(.608 4.867)">
                <path d="M4.762 45.52c-.311 0-.623-.12-.86-.357l-3.44-3.441c-.476-.475-.476-1.245 0-1.72.474-.475 1.244-.475 1.72 0l3.44 3.44c.475.475.475 1.246 0 1.72-.237.239-.549.357-.86.357z" />
                <path d="M1.321 45.52c-.31 0-.622-.12-.86-.357-.475-.475-.475-1.246 0-1.72l3.44-3.441c.476-.475 1.246-.475 1.721 0 .475.475.475 1.245 0 1.72l-3.44 3.44c-.238.239-.55.357-.86.357z" />
                <circle cx="56.573" cy="1.217" r="1.217" />
                <path d="M65.09 59.615c-2.013 0-3.65-1.637-3.65-3.65 0-2.012 1.637-3.65 3.65-3.65s3.65 1.638 3.65 3.65c0 2.013-1.637 3.65-3.65 3.65zm0-4.866c-.67 0-1.217.545-1.217 1.216 0 .671.546 1.217 1.217 1.217.67 0 1.217-.546 1.217-1.217s-.546-1.216-1.217-1.216z" />
            </g>
            <g>
                <path
                    fill="#02B482"
                    d="M33.704 1.217H6.444c-2.887 0-5.227 2.34-5.227 5.228v17.527c0 2.887 2.34 5.227 5.228 5.227h3.288v6.083l4.345-6.083h19.627c2.887 0 5.228-2.34 5.228-5.228V6.444c0-2.887-2.341-5.227-5.229-5.227z"
                    transform="translate(14.6 35.282)"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M9.733 36.499c-.123 0-.249-.019-.371-.058-.504-.162-.846-.63-.846-1.159v-4.866H6.445C2.89 30.416 0 27.526 0 23.97V6.444C0 2.891 2.89 0 6.445 0h27.26c3.553 0 6.444 2.89 6.444 6.445v17.527c0 3.553-2.89 6.444-6.444 6.444h-19l-3.982 5.574c-.233.325-.604.509-.99.509zM6.445 2.433c-2.212 0-4.012 1.8-4.012 4.012v17.527c0 2.212 1.8 4.01 4.012 4.01h3.288c.672 0 1.217.545 1.217 1.217v2.287l2.138-2.994c.228-.32.597-.51.99-.51h19.626c2.212 0 4.012-1.798 4.012-4.01V6.443c0-2.212-1.8-4.011-4.012-4.011H6.444z"
                    transform="translate(14.6 35.282)"
                />
            </g>
            <g fillRule="nonzero">
                <path
                    fill="#FF8617"
                    d="M7.908 1.217L1.217 13.383 14.6 13.383z"
                    transform="translate(26.766 42.582)"
                />
                <path
                    fill="#121212"
                    d="M14.6 14.6H1.217c-.43 0-.829-.227-1.048-.598-.218-.37-.225-.828-.018-1.205L6.842.631c.427-.78 1.705-.78 2.132 0l6.691 12.166c.208.377.2.835-.018 1.205-.22.37-.617.598-1.047.598zM3.275 12.166h9.267L7.908 3.741l-4.633 8.425z"
                    transform="translate(26.766 42.582)"
                />
            </g>
            <g fillRule="nonzero">
                <path
                    fill="#FFC131"
                    d="M38.933 9.246V6.445c0-2.888-2.341-5.228-5.229-5.228H6.444c-2.887 0-5.227 2.34-5.227 5.228v17.527c0 2.887 2.34 5.227 5.228 5.227h3.288v6.083l4.345-6.083h19.627c2.887 0 5.228-2.34 5.228-5.228v-7.425"
                />
                <path
                    fill="#121212"
                    d="M9.733 36.499c-.123 0-.249-.019-.371-.058-.504-.162-.846-.63-.846-1.159v-4.866H6.445C2.89 30.416 0 27.526 0 23.97V6.445C0 2.89 2.89 0 6.445 0h27.26c3.553 0 6.444 2.89 6.444 6.445v2.801c0 .673-.545 1.217-1.217 1.217s-1.216-.544-1.216-1.217V6.445c0-2.213-1.8-4.012-4.012-4.012H6.444c-2.211 0-4.01 1.8-4.01 4.012v17.527c0 2.212 1.799 4.01 4.01 4.01h3.29c.671 0 1.216.545 1.216 1.217v2.287l2.138-2.994c.228-.32.597-.51.99-.51h19.626c2.212 0 4.012-1.798 4.012-4.01v-7.426c0-.673.544-1.217 1.216-1.217.672 0 1.217.544 1.217 1.217v7.425c0 3.554-2.89 6.445-6.445 6.445h-19l-3.98 5.573c-.234.326-.605.51-.99.51z"
                />
            </g>
            <g>
                <path
                    fill="#A9C5F9"
                    d="M33.704 1.217H6.444c-2.887 0-5.227 2.34-5.227 5.228v17.527c0 2.887 2.34 5.228 5.228 5.228h3.288v6.083l4.345-6.083h19.627c2.887 0 5.228-2.341 5.228-5.228V6.445c0-2.888-2.341-5.228-5.229-5.228z"
                    transform="translate(31.632 12.166)"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M9.733 36.499c-.123 0-.249-.019-.371-.058-.504-.162-.846-.63-.846-1.159v-4.866H6.445C2.89 30.416 0 27.526 0 23.97V6.445C0 2.89 2.89 0 6.445 0h27.26c3.553 0 6.444 2.89 6.444 6.445v17.527c0 3.553-2.89 6.444-6.445 6.444h-19l-3.98 5.574c-.234.325-.605.509-.99.509zM6.445 2.433c-2.212 0-4.012 1.8-4.012 4.012v17.527c0 2.212 1.8 4.01 4.012 4.01h3.288c.672 0 1.217.545 1.217 1.218v2.287l2.138-2.994c.228-.32.597-.51.99-.51h19.626c2.212 0 4.012-1.799 4.012-4.011V6.445c0-2.213-1.8-4.012-4.012-4.012H6.444z"
                    transform="translate(31.632 12.166)"
                />
            </g>
            <g fillRule="nonzero" transform="translate(43.799 19.466)">
                <circle cx="7.908" cy="7.908" r="6.691" fill="#7352C2" />
                <path
                    fill="#121212"
                    d="M7.908 15.816C3.548 15.816 0 12.27 0 7.908 0 3.548 3.548 0 7.908 0s7.908 3.548 7.908 7.908-3.547 7.908-7.908 7.908zm0-13.383c-3.019 0-5.475 2.456-5.475 5.475 0 3.02 2.456 5.475 5.475 5.475 3.02 0 5.475-2.456 5.475-5.475 0-3.019-2.456-5.475-5.475-5.475z"
                />
            </g>
            <g fillRule="nonzero">
                <path
                    fill="#E8624E"
                    d="M7.178 1.755L1.755 7.178c-.64.641-.64 1.68 0 2.32l5.423 5.423c.641.64 1.68.64 2.32 0l5.423-5.423c.64-.64.64-1.679 0-2.32L9.498 1.755c-.64-.64-1.679-.64-2.32 0z"
                    transform="translate(11.736 6.87)"
                />
                <path
                    fill="#121212"
                    d="M8.338 16.618c-.763 0-1.48-.297-2.02-.836L.895 10.358c-1.114-1.113-1.114-2.926 0-4.04L6.318.896c1.08-1.079 2.961-1.079 4.04 0l5.423 5.424c1.114 1.113 1.114 2.926 0 4.04l-5.423 5.423c-.54.54-1.257.836-2.02.836zm-.3-14.003L2.617 8.04c-.165.165-.165.434 0 .599l5.423 5.424c.165.164.434.164.599 0l5.423-5.424c.165-.165.165-.434 0-.6L8.638 2.616c-.166-.164-.434-.164-.6 0z"
                    transform="translate(11.736 6.87)"
                />
            </g>
        </g>
    </svg>
);

export const LockedGrowthlabIcon = ({ width = 72, height = 72 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H72V72H0z" />
            <g transform="translate(0 4)">
                <circle cx="30.504" cy="39.616" r="24.335" fill="#276EF1" />
                <circle
                    cx="7.3"
                    cy="48.671"
                    r="1.132"
                    fill="#121212"
                    fillRule="nonzero"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M20.94 60.556c-1.873 0-3.396-1.524-3.396-3.396 0-1.872 1.523-3.396 3.396-3.396 1.872 0 3.395 1.524 3.395 3.396 0 1.872-1.523 3.396-3.395 3.396zm0-4.528c-.624 0-1.132.508-1.132 1.132 0 .625.508 1.132 1.132 1.132.624 0 1.132-.507 1.132-1.132 0-.624-.508-1.132-1.132-1.132z"
                />
                <g>
                    <path
                        fill="#F7F7F7"
                        d="M43.577 22.072c.834-1.631.833-3.844-.6-5.277-.42-.42-.899-.736-1.404-.963 1.402-2.046 1.132-4.94-.783-6.856-1.702-1.702-4.176-2.091-6.143-1.176-.226-.347-.485-.68-.79-.985-1.915-1.915-4.758-2.26-6.885-1.029-.386-.828-.918-1.612-1.608-2.302C22.35.47 17.596.337 14.746 3.188c-1.247 1.246-1.923 2.857-2.037 4.527-1.796-.524-3.76-.139-5.119 1.22-1.523 1.524-1.85 3.81-1.013 5.763-1.5-.48-3.162-.18-4.304.963-1.663 1.662-1.201 4.653.557 6.41h40.747z"
                        transform="translate(11.885 17.544)"
                    />
                    <path
                        fill="#121212"
                        d="M43.577 23.204H2.83c-.3 0-.588-.12-.8-.332-2.07-2.07-2.787-5.782-.558-8.012.954-.953 2.232-1.477 3.573-1.512-.245-1.916.37-3.837 1.745-5.213 1.293-1.293 3.113-1.913 4.97-1.747.337-1.517 1.091-2.906 2.186-4 1.6-1.602 3.752-2.441 6.079-2.383 2.296.065 4.476 1.015 6.14 2.679.493.493.927 1.04 1.296 1.632 2.429-.873 5.253-.245 7.196 1.698.136.136.266.279.393.43 2.261-.664 4.79-.018 6.54 1.732 1.966 1.966 2.519 4.847 1.5 7.233.244.177.473.372.686.586 1.91 1.91 1.782 4.686.81 6.592-.194.378-.583.617-1.009.617zM3.333 20.94H42.82c.337-1.082.271-2.43-.644-3.345-.304-.304-.662-.55-1.066-.73-.307-.136-.536-.403-.628-.725-.09-.324-.033-.671.156-.948 1.125-1.64.851-3.916-.649-5.415-1.312-1.313-3.268-1.692-4.864-.95-.51.24-1.118.065-1.425-.407-.203-.311-.414-.575-.644-.806-1.506-1.507-3.775-1.857-5.516-.848-.274.159-.605.195-.906.1-.303-.095-.552-.314-.687-.601-.347-.744-.812-1.41-1.383-1.98-1.252-1.253-2.887-1.97-4.602-2.017-1.698-.076-3.26.563-4.416 1.72C14.53 5.005 13.94 6.32 13.84 7.792c-.024.342-.2.655-.482.852-.28.195-.637.254-.964.158-1.489-.435-2.983-.085-4.002.934-1.152 1.152-1.455 2.925-.773 4.515.175.408.096.88-.202 1.209-.298.328-.76.452-1.183.316-1.157-.369-2.367-.107-3.16.685-1.15 1.15-.789 3.172.26 4.479z"
                        transform="translate(11.885 17.544)"
                    />
                </g>
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M38.893 31.693c-.292 0-.584-.113-.806-.337-2.41-2.442-2.387-6.391.054-8.803.445-.441 1.162-.435 1.6.009.44.444.436 1.161-.008 1.6-1.554 1.535-1.57 4.049-.034 5.604.438.445.434 1.161-.012 1.6-.22.218-.507.327-.794.327zM22.48 35.476c-1.461 0-2.921-.56-4.027-1.679-2.192-2.222-2.17-5.812.05-8.004.444-.44 1.16-.434 1.6.01.44.445.435 1.16-.01 1.6-1.331 1.316-1.345 3.47-.03 4.804 1.317 1.33 3.47 1.344 4.803.028.444-.44 1.16-.434 1.6.01.44.445.435 1.16-.01 1.6-1.1 1.089-2.538 1.631-3.977 1.631zM48.051 37.63h-.007c-.625-.003-1.128-.513-1.125-1.138.008-1.19.478-2.305 1.323-3.141.84-.83 1.95-1.285 3.13-1.285h.027l1.481.009c.626.003 1.129.514 1.126 1.138-.004.624-.51 1.126-1.132 1.126h-.007l-1.48-.009c-.571.024-1.137.221-1.554.631-.415.411-.646.96-.65 1.544-.004.624-.51 1.126-1.132 1.126z"
                />
                <circle cx="10.187" cy="10.187" r="9.055" fill="#FFC043" />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M10.187 20.374C4.57 20.374 0 15.804 0 10.187 0 4.569 4.57 0 10.187 0c5.617 0 10.187 4.57 10.187 10.187 0 5.617-4.57 10.187-10.187 10.187zm0-18.11c-4.369 0-7.923 3.554-7.923 7.923 0 4.368 3.554 7.923 7.923 7.923s7.923-3.555 7.923-7.923c0-4.369-3.554-7.923-7.923-7.923z"
                />
                <g>
                    <path
                        fill="#F65050"
                        d="M14.64 6.057c-.363-2.775-2.71-4.925-5.585-4.925H6.791c-2.874 0-5.222 2.15-5.585 4.925 1.657 1.837 4.048 2.998 6.717 2.998 2.669 0 5.06-1.161 6.718-2.998z"
                        transform="translate(2.264 10.187)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M7.923 10.187c-2.87 0-5.624-1.23-7.557-3.371C.144 6.57.04 6.239.083 5.91.525 2.541 3.41 0 6.791 0h2.264c3.382 0 6.266 2.541 6.708 5.91.043.329-.06.66-.283.906-1.933 2.142-4.687 3.37-7.557 3.37zM2.426 5.69C3.9 7.116 5.874 7.923 7.923 7.923s4.023-.807 5.498-2.232c-.497-1.986-2.29-3.427-4.366-3.427H6.791c-2.075 0-3.868 1.441-4.365 3.427z"
                        transform="translate(2.264 10.187)"
                    />
                </g>
                <g fillRule="nonzero" transform="translate(4.528 2.264)">
                    <ellipse
                        cx="5.659"
                        cy="6.225"
                        fill="#FFDED5"
                        rx="4.528"
                        ry="5.093"
                    />
                    <path
                        fill="#121212"
                        d="M5.66 12.45C2.54 12.45 0 9.659 0 6.226 0 2.793 2.54 0 5.66 0s5.659 2.793 5.659 6.225c0 3.433-2.54 6.226-5.66 6.226zm0-10.186c-1.873 0-3.396 1.777-3.396 3.961 0 2.185 1.523 3.962 3.395 3.962 1.873 0 3.396-1.777 3.396-3.962 0-2.184-1.523-3.961-3.396-3.961z"
                    />
                </g>
                <g transform="translate(50.935 3.396)">
                    <circle cx="10.187" cy="10.187" r="9.055" fill="#F65050" />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M10.187 20.374C4.569 20.374 0 15.804 0 10.187 0 4.569 4.57 0 10.187 0c5.617 0 10.187 4.57 10.187 10.187 0 5.617-4.57 10.187-10.187 10.187zm0-18.11c-4.369 0-7.923 3.554-7.923 7.923 0 4.368 3.554 7.923 7.923 7.923 4.368 0 7.923-3.555 7.923-7.923 0-4.369-3.555-7.923-7.923-7.923z"
                    />
                </g>
                <g>
                    <path
                        fill="#7DA8F7"
                        d="M14.64 6.057c-.363-2.775-2.71-4.925-5.585-4.925H6.791c-2.874 0-5.222 2.15-5.585 4.925 1.657 1.837 4.048 2.998 6.717 2.998 2.669 0 5.06-1.161 6.718-2.998z"
                        transform="translate(53.198 13.583)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M7.923 10.187c-2.87 0-5.624-1.23-7.557-3.371C.144 6.57.04 6.239.084 5.91.524 2.541 3.409 0 6.791 0h2.264c3.382 0 6.266 2.541 6.707 5.91.043.329-.06.66-.282.906-1.933 2.142-4.687 3.37-7.557 3.37zM2.425 5.69C3.9 7.116 5.875 7.923 7.923 7.923s4.024-.807 5.498-2.232c-.497-1.986-2.29-3.427-4.366-3.427H6.791c-2.076 0-3.868 1.441-4.366 3.427z"
                        transform="translate(53.198 13.583)"
                    />
                </g>
                <g fillRule="nonzero" transform="translate(55.462 5.66)">
                    <ellipse
                        cx="5.659"
                        cy="6.225"
                        fill="#FFDED5"
                        rx="4.528"
                        ry="5.093"
                    />
                    <path
                        fill="#121212"
                        d="M5.66 12.45C2.54 12.45 0 9.659 0 6.226 0 2.793 2.54 0 5.66 0s5.659 2.793 5.659 6.225c0 3.433-2.54 6.226-5.66 6.226zm0-10.186c-1.873 0-3.396 1.777-3.396 3.961 0 2.185 1.523 3.962 3.395 3.962 1.873 0 3.396-1.777 3.396-3.962 0-2.184-1.523-3.961-3.396-3.961z"
                    />
                </g>
                <g transform="translate(43.012 41.88)">
                    <circle cx="10.187" cy="10.187" r="9.055" fill="#02B482" />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M10.187 20.374C4.569 20.374 0 15.804 0 10.187 0 4.569 4.57 0 10.187 0c5.617 0 10.187 4.57 10.187 10.187 0 5.617-4.57 10.187-10.187 10.187zm0-18.11c-4.369 0-7.923 3.554-7.923 7.923 0 4.368 3.554 7.923 7.923 7.923 4.368 0 7.923-3.555 7.923-7.923 0-4.369-3.555-7.923-7.923-7.923z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFC043"
                        d="M14.64 6.057c-.363-2.775-2.71-4.925-5.585-4.925H6.791c-2.874 0-5.222 2.15-5.585 4.925 1.657 1.837 4.048 2.998 6.717 2.998 2.669 0 5.06-1.161 6.718-2.998z"
                        transform="translate(45.275 52.067)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M7.923 10.187c-2.87 0-5.624-1.23-7.557-3.371C.144 6.57.04 6.239.084 5.91.524 2.541 3.409 0 6.791 0h2.264c3.382 0 6.266 2.541 6.707 5.91.043.329-.06.66-.282.906-1.933 2.142-4.687 3.37-7.557 3.37zM2.425 5.69C3.9 7.116 5.875 7.923 7.923 7.923s4.024-.807 5.498-2.232c-.497-1.986-2.29-3.427-4.366-3.427H6.791c-2.076 0-3.868 1.441-4.366 3.427z"
                        transform="translate(45.275 52.067)"
                    />
                </g>
                <g fillRule="nonzero" transform="translate(47.54 44.143)">
                    <ellipse
                        cx="5.659"
                        cy="6.225"
                        fill="#FFDED5"
                        rx="4.528"
                        ry="5.093"
                    />
                    <path
                        fill="#121212"
                        d="M5.66 12.45C2.54 12.45 0 9.659 0 6.226 0 2.793 2.54 0 5.66 0s5.659 2.793 5.659 6.225c0 3.433-2.54 6.226-5.66 6.226zm0-10.186c-1.873 0-3.396 1.777-3.396 3.961 0 2.185 1.523 3.962 3.395 3.962 1.873 0 3.396-1.777 3.396-3.962 0-2.184-1.523-3.961-3.396-3.961z"
                    />
                </g>
                <g fill="#121212" fillRule="nonzero">
                    <path
                        d="M52.067 24.335H37.352c-.626 0-1.132-.506-1.132-1.131 0-.626.506-1.132 1.132-1.132h13.583v-2.264c0-.626.506-1.132 1.132-1.132.625 0 1.131.506 1.131 1.132v3.396c0 .625-.506 1.131-1.131 1.131zM31.693 49.237H23.77c-.626 0-1.132-.506-1.132-1.132v-18.11c0-.626.506-1.132 1.132-1.132.625 0 1.131.506 1.131 1.132v16.978h6.792c.625 0 1.132.506 1.132 1.132 0 .626-.507 1.132-1.132 1.132zM20.374 24.335H1.132C.507 24.335 0 23.83 0 23.204v-6.792c0-.625.507-1.132 1.132-1.132.625 0 1.132.507 1.132 1.132v5.66h18.11c.625 0 1.132.506 1.132 1.132 0 .625-.507 1.131-1.132 1.131zM27.634 5.562c-.29 0-.58-.11-.8-.332l-3.201-3.2c-.442-.443-.442-1.159 0-1.601.442-.442 1.158-.442 1.6 0l3.201 3.2c.442.443.442 1.16 0 1.601-.221.222-.51.332-.8.332z"
                        transform="translate(9.055 6.225)"
                    />
                    <path
                        d="M24.433 5.562c-.29 0-.58-.11-.8-.332-.442-.442-.442-1.158 0-1.6l3.2-3.201c.443-.442 1.159-.442 1.601 0 .442.442.442 1.158 0 1.6l-3.2 3.201c-.222.222-.512.332-.801.332z"
                        transform="translate(9.055 6.225)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const SoloWorkoutIcon = ({ width = 32, height = 32 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <circle cx="43.509" cy="34.509" r="25.526" fill="#276EF1" />
            <circle
                cx="64.286"
                cy="48.162"
                r="1.187"
                fill="#4C241D"
                fillRule="nonzero"
            />
            <path
                fill="#121212"
                fillRule="nonzero"
                d="M8.485 8.983c-1.964 0-3.562-1.598-3.562-3.562 0-1.964 1.598-3.561 3.562-3.561 1.964 0 3.562 1.597 3.562 3.561s-1.598 3.562-3.562 3.562zm0-4.749c-.654 0-1.187.532-1.187 1.187S7.83 6.61 8.485 6.61s1.187-.533 1.187-1.188c0-.655-.533-1.187-1.187-1.187z"
            />
            <g>
                <path
                    fill="#F7F7F7"
                    d="M49.828 68.779V53.38c0-2.502.896-4.91 2.504-6.78 4.407-5.124 6.976-11.979 6.622-19.459-.642-13.601-11.185-24.805-24.311-25.8C19.593.2 8.169 12.156 7.543 27.325l-.029-.003-6.136 12.078c-.412 1.192.28 2.486 1.473 2.76l5.375 1.236 1.34 15.211c.111 1.069.983 1.88 2.022 1.88L22.353 59.3v9.479"
                    transform="translate(0 1.78)"
                />
                <path
                    fill="#121212"
                    d="M22.354 69.966c-.656 0-1.187-.53-1.187-1.187v-8.153l-9.45 1.042c-.043.005-.086.007-.13.007-1.651 0-3.028-1.266-3.201-2.944l-1.267-14.37-4.535-1.042C1.7 43.115.936 42.536.49 41.73c-.459-.827-.544-1.817-.234-2.716.018-.05.04-.1.064-.15l6.053-11.911c.414-8.055 3.848-15.509 9.453-20.493C20.993 1.863 27.712-.377 34.732.158c13.81 1.048 24.732 12.624 25.408 26.928.354 7.493-2.1 14.699-6.908 20.29-1.43 1.662-2.217 3.794-2.217 6.005v15.397c0 .656-.531 1.188-1.187 1.188s-1.187-.53-1.187-1.187V53.381c0-2.78.991-5.462 2.791-7.554 4.411-5.13 6.661-11.745 6.336-18.63-.62-13.108-10.6-23.714-23.215-24.672-6.381-.476-12.467 1.544-17.15 5.708-5.2 4.626-8.363 11.603-8.674 19.142-.01.257-.105.502-.264.699L2.48 39.852c-.067.245-.036.506.087.727.07.128.236.354.55.426l5.375 1.235c.501.114.871.54.916 1.052l1.341 15.212c.045.429.38.769.788.795l10.687-1.179c.331-.036.67.07.922.296.251.226.395.547.395.884v9.479c0 .656-.532 1.187-1.187 1.187z"
                    transform="translate(0 1.78)"
                />
            </g>
            <g>
                <path
                    fill="#FF862D"
                    d="M45.76 23.23c.875-1.71.874-4.031-.63-5.535-.44-.44-.942-.772-1.473-1.01 1.472-2.146 1.188-5.182-.82-7.19-1.786-1.787-4.38-2.195-6.443-1.235-.238-.363-.51-.712-.83-1.033-2.008-2.008-4.99-2.371-7.22-1.079-.407-.87-.964-1.691-1.688-2.415-3.161-3.162-8.148-3.3-11.138-.31-1.308 1.307-2.017 2.997-2.136 4.749-1.884-.55-3.943-.147-5.37 1.28-1.597 1.597-1.94 3.995-1.062 6.044-1.574-.504-3.317-.188-4.515 1.01-1.744 1.743-1.26 4.88.584 6.724H45.76z"
                    transform="translate(19.712)"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M45.76 24.417H3.02c-.315 0-.617-.125-.84-.348-2.17-2.17-2.923-6.065-.584-8.403 1-1 2.34-1.55 3.747-1.586-.257-2.01.387-4.025 1.83-5.468 1.356-1.356 3.27-2.007 5.214-1.833.353-1.59 1.144-3.048 2.292-4.196C16.358.904 18.621.019 21.056.084c2.408.068 4.695 1.065 6.44 2.81.517.517.972 1.09 1.36 1.712 2.548-.916 5.509-.257 7.547 1.781.143.143.28.292.412.45 2.371-.694 5.025-.018 6.861 1.818 2.063 2.063 2.642 5.084 1.574 7.586.255.186.495.39.719.613 2.003 2.005 1.87 4.917.849 6.916-.203.397-.612.647-1.058.647zM3.547 22.043h41.418c.355-1.135.285-2.549-.674-3.509-.319-.318-.696-.576-1.119-.766-.321-.144-.562-.422-.657-.762-.096-.338-.036-.702.164-.992 1.178-1.718.891-4.108-.682-5.68-1.376-1.375-3.425-1.776-5.102-.997-.536.25-1.172.068-1.496-.427-.213-.327-.434-.603-.674-.844-1.582-1.582-3.961-1.949-5.786-.89-.289.166-.634.205-.952.105-.318-.1-.58-.33-.72-.63-.363-.781-.851-1.48-1.45-2.079-1.314-1.313-3.028-2.065-4.827-2.114-1.786-.072-3.42.59-4.632 1.804-1.067 1.066-1.686 2.446-1.791 3.99-.024.359-.21.686-.505.893-.295.205-.668.268-1.011.166-1.562-.454-3.13-.088-4.198.98-1.208 1.208-1.527 3.068-.81 4.736.183.428.1.923-.213 1.267-.314.345-.798.473-1.241.332-1.212-.386-2.482-.111-3.314.719-1.207 1.207-.828 3.327.272 4.698z"
                    transform="translate(19.712)"
                />
            </g>
            <g fill="#121212" fillRule="nonzero">
                <path
                    d="M23.101 9.987c-.306 0-.612-.118-.845-.352-1.225-1.241-1.895-2.885-1.884-4.63.01-1.744.7-3.38 1.941-4.605.466-.46 1.218-.455 1.679.01.46.467.456 1.218-.01 1.68-.79.779-1.229 1.82-1.235 2.93-.007 1.11.418 2.155 1.199 2.945.46.467.456 1.218-.01 1.678-.232.23-.533.344-.835.344zM5.945 13.965c-1.533 0-3.064-.588-4.224-1.761-2.3-2.33-2.276-6.096.052-8.396.466-.462 1.217-.455 1.679.01.46.467.456 1.218-.01 1.678-1.397 1.381-1.411 3.641-.032 5.04 1.381 1.396 3.64 1.408 5.038.03.466-.463 1.217-.456 1.678.009.461.467.457 1.218-.01 1.679-1.155 1.142-2.663 1.711-4.171 1.711zM32.767 16.225h-.007c-.656-.004-1.185-.54-1.18-1.194.007-1.248.5-2.419 1.388-3.295.88-.87 2.045-1.348 3.281-1.348h.03l1.553.01c.655.003 1.184.539 1.18 1.194-.004.654-.535 1.18-1.187 1.18h-.007l-1.554-.01h-.014c-.608 0-1.18.236-1.614.663-.436.431-.678 1.006-.682 1.62-.004.653-.534 1.18-1.187 1.18z"
                    transform="translate(24.932 4.923)"
                />
            </g>
            <g>
                <path
                    fill="#F65050"
                    d="M9.672 25.01c-1.311 0-2.374-1.062-2.374-2.374v-3.41c0-2.65 1.526-5.042 3.982-6.24 1.272-.622 2.081-1.939 2.061-3.355-.027-1.933-1.682-3.588-3.616-3.615-1.02-.043-1.925.36-2.63 1.055-.704.696-1.093 1.624-1.093 2.614 0 1.311-1.063 2.374-2.374 2.374-1.311 0-2.375-1.063-2.375-2.374 0-2.272.89-4.4 2.508-5.994 1.617-1.596 3.73-2.453 6.03-2.424 4.513.063 8.236 3.785 8.299 8.3.044 3.244-1.81 6.262-4.727 7.685-.812.397-1.317 1.154-1.317 1.975v3.41c0 1.31-1.062 2.374-2.374 2.374z"
                    transform="translate(34.43 17.215)"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M9.672 26.198c-1.964 0-3.562-1.598-3.562-3.562v-3.41c0-3.108 1.782-5.908 4.65-7.307.86-.421 1.407-1.313 1.394-2.271-.018-1.285-1.16-2.427-2.445-2.444-.852-.03-1.43.368-1.779.713-.477.47-.74 1.099-.74 1.768 0 1.964-1.598 3.561-3.562 3.561-1.964 0-3.561-1.597-3.561-3.561 0-2.591 1.016-5.02 2.861-6.84C4.768 1.03 7.216.058 9.807.08c5.15.072 9.398 4.32 9.47 9.47.052 3.702-2.065 7.144-5.392 8.769-.402.196-.65.544-.65.908v3.41c-.001 1.964-1.599 3.561-3.563 3.561zm-.09-21.372c.064 0 .13.002.193.004 2.526.035 4.718 2.226 4.753 4.785.026 1.874-1.044 3.615-2.727 4.437-2.045.998-3.316 2.981-3.316 5.175v3.41c0 .655.533 1.187 1.187 1.187.655 0 1.187-.532 1.187-1.187v-3.41c0-1.28.76-2.444 1.984-3.041 2.505-1.223 4.098-3.815 4.06-6.603-.054-3.877-3.251-7.075-7.127-7.129-2.01-.013-3.776.695-5.18 2.082-1.39 1.37-2.155 3.197-2.155 5.149 0 .655.533 1.187 1.187 1.187.655 0 1.187-.532 1.187-1.187 0-1.31.514-2.538 1.447-3.458.92-.909 2.092-1.401 3.32-1.401z"
                    transform="translate(34.43 17.215)"
                />
            </g>
            <g fillRule="nonzero" transform="translate(40.49 44.522)">
                <circle cx="3.613" cy="3.64" r="2.374" fill="#F65050" />
                <path
                    fill="#121212"
                    d="M3.613 7.202C1.65 7.202.051 5.604.051 3.64.051 1.676 1.65.08 3.613.08c1.964 0 3.562 1.597 3.562 3.561S5.577 7.202 3.613 7.202zm0-4.749c-.654 0-1.187.532-1.187 1.187s.533 1.188 1.187 1.188c.655 0 1.187-.533 1.187-1.188 0-.655-.532-1.187-1.187-1.187z"
                />
            </g>
        </g>
    </svg>
);

export const RestartIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L32 0 32 32 0 32z" />
            <path
                fill={fill}
                d="M5.333 16h-4l5.187 5.187.093.186L12 16H8c0-5.16 4.173-9.333 9.333-9.333S26.667 10.84 26.667 16s-4.174 9.333-9.334 9.333c-2.573 0-4.906-1.053-6.586-2.746L8.853 24.48c2.174 2.173 5.16 3.52 8.48 3.52 6.627 0 12-5.373 12-12s-5.373-12-12-12c-6.626 0-12 5.373-12 12zM16 17.333l5.707 3.387.96-1.613L18 16.333v-5.666h-2v6.666z"
            />
        </g>
    </svg>
);

export const HundredHIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L32 0 32 32 0 32z" />
            <path
                fill={fill}
                d="M8 2.667h16v8L18.667 16 24 21.333v8H8v-8L13.333 16 8 10.667v-8M21.333 22L16 16.667 10.667 22v4.667h10.666V22M16 15.333L21.333 10V5.333H10.667V10L16 15.333M13.333 8h5.334v1L16 11.667 13.333 9V8z"
            />
        </g>
    </svg>
);

export const GrowthlabsIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L32 0 32 32 0 32z" />
            <path
                fill={fill}
                d="M17.4 13.053L13.333 10v12l4.067-3.053L21.333 16 17.4 13.053zm0 0L13.333 10v12l4.067-3.053L21.333 16 17.4 13.053zm0 0L13.333 10v12l4.067-3.053L21.333 16 17.4 13.053zm-2.733-7.626V2.733C11.987 3 9.547 4.067 7.573 5.68l1.894 1.907c1.48-1.147 3.253-1.92 5.2-2.16zm-7.08 4.04L5.68 7.573C4.067 9.547 3 11.987 2.733 14.667h2.694c.24-1.947 1.013-3.72 2.16-5.2zm-2.16 7.866H2.733c.267 2.68 1.334 5.12 2.947 7.094l1.907-1.907c-1.147-1.467-1.92-3.24-2.16-5.187zm2.146 8.987C9.547 27.933 12 29 14.667 29.267v-2.694c-1.947-.24-3.72-1.013-5.2-2.16L7.573 26.32zM29.333 16c0 6.88-5.226 12.56-11.933 13.267v-2.694C22.627 25.88 26.667 21.4 26.667 16S22.627 6.12 17.4 5.427V2.733C24.107 3.44 29.333 9.12 29.333 16z"
            />
        </g>
    </svg>
);

export const PeakPocketIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L24 0 24 24 0 24z" />
            <path d="M0 0L24 0 24 24 0 24z" />
            <path
                fill={fill}
                d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67C3 7.19 2.25 9.04 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43c-.88-1.13-1.45-2.48-1.63-3.9h-2m5.04 5.37l-1.43 1.37C7.18 21 9.04 21.79 11 22v-2c-1.42-.18-2.77-.75-3.9-1.63M12.5 7v5.25l4.5 2.67-.75 1.23L11 13V7h1.5z"
            />
        </g>
    </svg>
);

export const RocketIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                fill={fill}
                d="M12 2S7 4 7 12c0 3.1.76 5.75 1.67 7.83C9 20.55 9.71 21 10.5 21h3c.79 0 1.5-.45 1.83-1.17C16.25 17.75 17 15.1 17 12c0-8-5-10-5-10m1.5 17h-3c-1-2.24-1.5-4.59-1.5-7 0-4.64 1.9-6.8 3-7.67 1.1.87 3 3.03 3 7.67 0 2.41-.5 4.76-1.5 7m6.5 3l-3.86-1.55c.7-1.53 1.2-3.11 1.51-4.72m-9.79 4.72L4 22l2.35-6.27c.31 1.61.81 3.19 1.51 4.72M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
            />
        </g>
    </svg>
);

export const PeakPadIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L24 0 24 24 0 24z" />
            <path
                fill={fill}
                d="M12 5.9c1.16 0 2.1.94 2.1 2.1 0 1.16-.94 2.1-2.1 2.1-1.16 0-2.1-.94-2.1-2.1 0-1.16.94-2.1 2.1-2.1zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1zM12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
            />
        </g>
    </svg>
);

export const ChixIcon = ({ width = 32, height = 32, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                fill={fill}
                d="M15 4v7H5.17L4 12.17V4h11m1-2H3c-.552 0-1 .448-1 1v14l4-4h10c.552 0 1-.448 1-1V3c0-.552-.448-1-1-1m5 4h-2v9H6v2c0 .552.448 1 1 1h11l4 4V7c0-.552-.448-1-1-1z"
            />
        </g>
    </svg>
);

export const RaazLogo = ({ fill }) => (
    <svg width="162" height="41" viewBox="0 0 162 41">
        <g fill={fill} fillRule="evenodd">
            <text
                fontFamily="OpenSans-Bold, Open Sans"
                fontSize="16"
                fontWeight="bold"
                letterSpacing="1.143"
                transform="translate(8 8.877)">
                <tspan x="24.132" y="17">
                    RESTART.APP
                </tspan>
            </text>
            <path
                d="M6.577 7.63c.952 0 1.73.785 1.73 1.745 0 .959-.778 1.744-1.73 1.744H3.46v1.396h3.116c2.302 0 4.154 1.866 4.154 4.187v.523h1.384v-.523c0-1.675-.727-3.158-1.886-4.187h7.529C16.892 17.208 13.69 21.203 9.173 23L9 22.93C3.998 21.028.57 16.423.07 11.136c-.036-.209-.036-.418-.053-.628v-.052c-.011-.14-.015-.287-.016-.432L0 9.374c0-.96.779-1.744 1.73-1.744h4.847zm7.961-5.586l2.366.827c.015.005.03.009.044.015l.018.006v.004c.607.27 1.034.883 1.034 1.593v4.886c0 .959-.779 1.744-1.73 1.744-.953 0-1.732-.785-1.732-1.744v-7.33zM11.423 1c.952 0 1.73.785 1.73 1.745v6.63c0 .959-.778 1.744-1.73 1.744s-1.73-.785-1.73-1.744v-6.63c0-.96.778-1.745 1.73-1.745zM3.461 2.044v4.19H0V4.489c0-.732.454-1.359 1.092-1.617l-.003-.004 2.372-.824zM6.577 1c.952 0 1.73.785 1.73 1.745v3.489h-3.46v-3.49c0-.96.778-1.744 1.73-1.744z"
                transform="translate(8 8.877)"
            />
        </g>
    </svg>
);

export const CertificateIllus = () => (
    <svg width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H72V72H0z" />
            <g transform="translate(4 1)">
                <circle cx="31.983" cy="25.949" r="25.949" fill="#276EF1" />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M36.707 12.569c-.308 0-.617-.118-.853-.354l-3.413-3.413c-.471-.471-.471-1.235 0-1.706.471-.472 1.235-.472 1.707 0l3.413 3.413c.471.471.471 1.235 0 1.706-.236.236-.545.354-.854.354z"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M33.294 12.569c-.308 0-.617-.118-.853-.354-.471-.471-.471-1.235 0-1.706l3.413-3.413c.471-.472 1.235-.472 1.707 0 .471.47.471 1.235 0 1.706l-3.413 3.413c-.236.236-.545.354-.854.354z"
                />
                <g>
                    <path
                        fill="#F7F7F7"
                        d="M10.862 25.949V1.207H6.907c-3.148 0-5.7 2.552-5.7 5.7v19.042h9.655z"
                        transform="translate(0 6.035)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M10.862 27.156H1.207C.54 27.156 0 26.616 0 25.949V6.907C0 3.099 3.099 0 6.907 0h3.955c.667 0 1.207.54 1.207 1.207v24.742c0 .666-.54 1.207-1.207 1.207zm-8.448-2.414h7.241V2.414H6.907c-2.478 0-4.493 2.015-4.493 4.493v17.835z"
                        transform="translate(0 6.035)"
                    />
                </g>
                <g>
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        d="M1.213 1.3C1.349 4.516 3.929 6.46 7.242 6.46H62.76v41.036H8.04c-3.774 0-6.833-3.06-6.833-6.833V1.634l.006-.334z"
                        transform="translate(0 11.643)"
                    />
                    <path
                        fill="#121212"
                        d="M62.76 48.704H8.04c-4.433 0-8.04-3.607-8.04-8.04L.006 1.279C.018.627.546.102 1.198.093c.638-.006 1.194.505 1.22 1.156.104 2.433 1.997 4.005 4.824 4.005H62.76c.667 0 1.207.54 1.207 1.207v41.036c0 .666-.54 1.207-1.207 1.207zM2.414 6.127v34.537c0 3.102 2.524 5.626 5.626 5.626h53.513V7.668H7.242c-1.912 0-3.577-.563-4.828-1.541z"
                        transform="translate(0 11.643)"
                    />
                </g>
                <circle
                    cx="23.535"
                    cy="1.81"
                    r="1.367"
                    fill="#121212"
                    fillRule="nonzero"
                />
                <path
                    fill="#121212"
                    fillRule="nonzero"
                    d="M59.14 8.448c-1.997 0-3.621-1.624-3.621-3.62 0-1.997 1.624-3.621 3.62-3.621 1.997 0 3.621 1.624 3.621 3.62 0 1.997-1.624 3.621-3.62 3.621zm0-4.827c-.666 0-1.208.541-1.208 1.207 0 .665.542 1.207 1.207 1.207.666 0 1.207-.542 1.207-1.207 0-.666-.541-1.207-1.207-1.207z"
                />
                <g>
                    <path
                        fill="#FFC043"
                        d="M23.056 15.69H1.206v-3.475c0-6.034 4.892-10.925 10.926-10.925 6.033 0 10.924 4.891 10.924 10.925v3.475z"
                        transform="translate(18.104 38.622)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M23.056 16.897H1.206C.54 16.897 0 16.357 0 15.69v-3.475C0 5.525 5.442.083 12.132.083c6.69 0 12.131 5.442 12.131 12.132v3.475c0 .667-.54 1.207-1.207 1.207zM2.414 14.483H21.85v-2.268c0-5.36-4.36-9.718-9.718-9.718-5.359 0-9.718 4.36-9.718 9.718v2.268z"
                        transform="translate(18.104 38.622)"
                    />
                </g>
                <g>
                    <path
                        fill="#F7F7F7"
                        d="M9.518 15.987l-.819.327c-.936.374-1.98.374-2.915 0l-.819-.327c-2.27-.909-3.758-3.107-3.758-5.552V5.707c0-2.485 2.014-4.5 4.5-4.5h3.07c2.485 0 4.5 2.015 4.5 4.5v4.728c0 2.445-1.49 4.643-3.76 5.552z"
                        transform="translate(22.932 22.932)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M7.242 17.803c-.646 0-1.292-.123-1.907-.37l-.817-.326C1.773 16.01 0 13.391 0 10.435V5.706C0 2.56 2.56 0 5.706 0h3.07c3.147 0 5.707 2.56 5.707 5.706v4.73c0 2.955-1.773 5.574-4.517 6.67v.001l-.819.327c-.614.246-1.26.37-1.905.37zM5.706 2.413c-1.815 0-3.292 1.478-3.292 3.293v4.73c0 1.962 1.177 3.7 3 4.43l.817.327c.653.262 1.37.261 2.02 0l.818-.327c1.823-.73 3-2.468 3-4.43v-4.73c0-1.815-1.477-3.292-3.293-3.292h-3.07z"
                        transform="translate(22.932 22.932)"
                    />
                </g>
                <g>
                    <path
                        fill="#F65050"
                        d="M10.862 19.311L5.749 15.69 1.207 19.311 1.207 1.207 10.862 1.207z"
                        transform="translate(45.863 49.484)"
                    />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M1.207 20.518c-.178 0-.357-.04-.524-.12-.417-.2-.683-.623-.683-1.087V1.207C0 .54.54 0 1.207 0h9.655c.667 0 1.207.54 1.207 1.207V19.31c0 .451-.251.864-.652 1.072-.4.206-.884.174-1.252-.087l-4.372-3.097-3.833 3.055c-.219.174-.485.264-.753.264zm4.542-6.035c.245 0 .488.074.698.222l3.208 2.272V2.414H2.414v14.392l2.583-2.06c.22-.174.486-.263.752-.263z"
                        transform="translate(45.863 49.484)"
                    />
                </g>
                <g transform="translate(45.26 44.053)">
                    <circle cx="6.638" cy="6.638" r="5.431" fill="#FFC043" />
                    <path
                        fill="#121212"
                        fillRule="nonzero"
                        d="M6.638 13.276C2.978 13.276 0 10.298 0 6.638 0 2.978 2.978 0 6.638 0c3.66 0 6.638 2.978 6.638 6.638 0 3.66-2.978 6.638-6.638 6.638zm0-10.862c-2.329 0-4.224 1.895-4.224 4.224 0 2.33 1.895 4.224 4.224 4.224 2.33 0 4.224-1.895 4.224-4.224S8.967 2.414 6.638 2.414z"
                    />
                </g>
                <g fill="#DADADA">
                    <path
                        d="M51.898 2.414h-12.07c-.666 0-1.206-.54-1.206-1.207S39.162 0 39.829 0h12.069c.666 0 1.207.54 1.207 1.207 0 .666-.54 1.207-1.207 1.207zM47.07 6.035H39.83c-.667 0-1.207-.54-1.207-1.207s.54-1.207 1.207-1.207h7.241c.667 0 1.207.54 1.207 1.207 0 .666-.54 1.207-1.207 1.207zM8.448 7.242H1.207C.54 7.242 0 6.702 0 6.035s.54-1.207 1.207-1.207h7.241c.667 0 1.207.54 1.207 1.207 0 .666-.54 1.207-1.207 1.207zM8.448 10.862H1.207C.54 10.862 0 10.322 0 9.655c0-.666.54-1.207 1.207-1.207h7.241c.667 0 1.207.54 1.207 1.207s-.54 1.207-1.207 1.207zM8.448 14.483H1.207C.54 14.483 0 13.943 0 13.276c0-.666.54-1.207 1.207-1.207h7.241c.667 0 1.207.54 1.207 1.207s-.54 1.207-1.207 1.207z"
                        transform="translate(6.035 24.138)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const LeaderboardIcon = ({
    width = 16,
    height = 16,
    fill = '#87878B',
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16">
        <path
            fill={fill}
            fillRule="evenodd"
            d="M8 4.727l1.633.993L9.2 3.847l1.467-1.254-1.927-.166L8 .667l-.753 1.76-1.914.166 1.454 1.254-.454 1.873L8 4.727m2 1.94v8.666H6V6.667h4zM8.667 8H7.333v6h1.334V8zm-8 3.333v4h4v-4h-4M3.333 14H2v-1.333h1.333V14m8-5.333v6.666h4V8.667h-4M14 14h-1.333v-4H14v4z"
        />
    </svg>
);

export const SliderIcon = ({ width = 16, height = 16, fill = '#87878B' }) => (
    <svg width={width} height={height} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L16 0 16 16 0 16z" />
            <path
                fill={fill}
                d="M12.667 12.667H3.333V3.333h9.334m0-1.333H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.593 13.4 2 12.667 2m-6 3.333v5.334L10 8 6.667 5.333z"
            />
        </g>
    </svg>
);

export const StarIcon = ({
    width = 16,
    height = 16,
    fill = '#FF862D',
    style = {},
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" style={style}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H16V16H0z" />
            <path
                fill={fill}
                d="M6.387 7.333L5.753 4.613 7.86 2.793 5.08 2.553 4 0 2.92 2.56 0.14 2.8 2.247 4.62 1.613 7.333 4 5.893z"
                transform="translate(4 4)"
            />
        </g>
    </svg>
);

export const VideoIcon = ({ width = 24, height = 24, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <title>ic_video_label</title>
        <g
            id="Welcome"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <polygon id="Bounds" points="0 0 24 0 24 24 0 24"></polygon>
            <path
                d="M21,3 C22.1,3 23,3.9 23,5 L23,5 L23,19 C23,20.1 22.1,21 21,21 L21,21 L3,21 C1.9,21 1,20.1 1,19 L1,19 L1,5 C1,3.9 1.9,3 3,3 L3,3 Z M21,5 L3,5 L3,16 L21,16 L21,5 Z M10,7 L15,10.5 L10,14 L10,7 Z"
                id="Combined-Shape"
                fill={fill}></path>
        </g>
    </svg>
);

export const EventIcon = ({ width = 16, height = 16, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H16V16H0z" />
            <path d="M0 0L16 0 16 16 0 16z" />
            <path
                fill={fill}
                d="M11.333 8.667H8V12h3.333V8.667zm-.666-7.334v1.334H5.333V1.333H4v1.334h-.667c-.74 0-1.326.6-1.326 1.333L2 13.333c0 .734.593 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V4c0-.733-.6-1.333-1.333-1.333H12V1.333h-1.333zm2 12H3.333V6h9.334v7.333z"
            />
        </g>
    </svg>
);

export const WorkoutIcon = ({ width = 16, height = 16, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H16V16H0z" />
            <path d="M0 0L16 0 16 16 0 16z" />
            <path
                fill={fill}
                d="M12.38 8.573L13.333 7.62 12.38 6.667 10 9.047 4.287 3.333 6.667 0.953 5.713 0 4.76 0.953 3.807 0 2.38 1.427 1.427 0.473 0.473 1.427 1.427 2.38 0 3.807 0.953 4.76 0 5.713 0.953 6.667 3.333 4.287 9.047 10 6.667 12.38 7.62 13.333 8.573 12.38 9.527 13.333 10.953 11.907 11.907 12.86 12.86 11.907 11.907 10.953 13.333 9.527z"
                transform="translate(1.333 1.333)"
            />
        </g>
    </svg>
);

export const WorkforceIcon = ({ width = 24, height = 24, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <path
            fill={fill}
            fillRule="evenodd"
            d="M12 4c-1.933 0-3.5 1.567-3.5 3.5S10.067 11 12 11s3.5-1.567 3.5-3.5S13.933 4 12 4m0 2c.828 0 1.5.672 1.5 1.5S12.828 9 12 9s-1.5-.672-1.5-1.5S11.172 6 12 6M5.5 7C4.12 7 3 8.12 3 9.5c0 .94.53 1.75 1.29 2.18.36.2.77.32 1.21.32.44 0 .85-.12 1.21-.32.37-.21.68-.51.91-.87C6.89 9.86 6.5 8.7 6.5 7.5v-.28c-.3-.14-.64-.22-1-.22m13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31.12.19.25.34.4.49.16.15.32.28.51.38.36.2.77.32 1.21.32.44 0 .85-.12 1.21-.32.76-.43 1.29-1.24 1.29-2.18C21 8.12 19.88 7 18.5 7M12 13c-2.34 0-7 1.17-7 3.5V18h14v-1.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78 13.78 0 14.76 0 16.5V18h3v-1.93c0-1.01.69-1.85 1.71-2.52m14.58 0c1.02.67 1.71 1.51 1.71 2.52V18h3v-1.5c0-1.74-2.78-2.72-4.71-2.95M12 15c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1z"
        />
    </svg>
);

export const ProjectPlusIcon = ({ width = 24, height = 24, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <path
            fill={fill}
            fillRule="evenodd"
            d="M17 14h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3M5 3h14c1.11 0 2 .89 2 2v7.8c-.61-.35-1.28-.6-2-.72V5H5v14h7.08c.12.72.37 1.39.72 2H5c-1.11 0-2-.89-2-2V5c0-1.11.89-2 2-2m2 4h10v2H7V7m0 4h10v1.08c-.85.14-1.63.46-2.32.92H7v-2m0 4h5v2H7v-2z"
        />
    </svg>
);

export const ChartIcon = ({ width = 24, height = 24, fill = '#fff' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L24 0 24 24 0 24z" />
            <path
                fill={fill}
                d="M14 8.78L18.24 1.45 19.97 2.45 14.74 11.5 8.23 7.75 3.46 16 20 16 20 18 0 18 0 0 2 0 2 14.54 7.5 5z"
                transform="translate(3 3)"
            />
        </g>
    </svg>
);

export const UserIcon = ({ fill = '#121212', width = 16, height = 16 }) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
            fill={fill}
        />
    </svg>
);

export const PasswordIcon = ({ fill = '#121212', width = 22, height = 12 }) => (
    <svg width={width} height={height} viewBox="0 0 22 12" fill="none">
        <path
            d="M6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8ZM11.65 4C10.83 1.67 8.61 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12C8.61 12 10.83 10.33 11.65 8H16V12H20V8H22V4H11.65Z"
            fill={fill}
        />
    </svg>
);

export const SubscribeNowIcon = ({ width = 64, height = 71 }) => (
    <svg width={width} height={height} viewBox="0 0 64 71" fill="none">
        <path
            d="M31.9822 51.898C46.3134 51.898 57.9312 40.2802 57.9312 25.949C57.9312 11.6178 46.3134 0 31.9822 0C17.651 0 6.0332 11.6178 6.0332 25.949C6.0332 40.2802 17.651 51.898 31.9822 51.898Z"
            fill="#276EF1"
        />
        <path
            d="M36.7071 12.569C36.3991 12.569 36.0901 12.451 35.8541 12.215L32.4411 8.802C31.9701 8.331 31.9701 7.567 32.4411 7.096C32.9121 6.624 33.6761 6.624 34.1481 7.096L37.5611 10.509C38.0321 10.98 38.0321 11.744 37.5611 12.215C37.3251 12.451 37.0161 12.569 36.7071 12.569Z"
            fill="#121212"
        />
        <path
            d="M33.2941 12.569C32.9861 12.569 32.6771 12.451 32.4411 12.215C31.9701 11.744 31.9701 10.98 32.4411 10.509L35.8541 7.096C36.3251 6.624 37.0891 6.624 37.5611 7.096C38.0321 7.566 38.0321 8.331 37.5611 8.802L34.1481 12.215C33.9121 12.451 33.6031 12.569 33.2941 12.569Z"
            fill="#121212"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.862 31.9839V7.24194H6.90703C3.75903 7.24194 1.20703 9.79394 1.20703 12.9419V31.9839H10.862Z"
            fill="#F7F7F7"
        />
        <path
            d="M10.862 33.191H1.207C0.54 33.191 0 32.651 0 31.984V12.942C0 9.13397 3.099 6.03497 6.907 6.03497H10.862C11.529 6.03497 12.069 6.57497 12.069 7.24197V31.984C12.069 32.65 11.529 33.191 10.862 33.191ZM2.414 30.777H9.655V8.44897H6.907C4.429 8.44897 2.414 10.464 2.414 12.942V30.777Z"
            fill="#121212"
        />
        <path
            d="M1.21303 12.943C1.34903 16.159 3.92903 18.103 7.24203 18.103H62.76V59.139H8.04003C4.26603 59.139 1.20703 56.079 1.20703 52.306V13.277L1.21303 12.943Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.76 60.347H8.04C3.607 60.347 0 56.74 0 52.307L0.006 12.922C0.018 12.27 0.546 11.745 1.198 11.736C1.836 11.73 2.392 12.241 2.418 12.892C2.522 15.325 4.415 16.897 7.242 16.897H62.76C63.427 16.897 63.967 17.437 63.967 18.104V59.14C63.967 59.806 63.427 60.347 62.76 60.347ZM2.414 17.77V52.307C2.414 55.409 4.938 57.933 8.04 57.933H61.553V19.311H7.242C5.33 19.311 3.665 18.748 2.414 17.77Z"
            fill="#121212"
        />
        <path
            d="M23.535 3.17699C24.2899 3.17699 24.902 2.56497 24.902 1.80999C24.902 1.05502 24.2899 0.442993 23.535 0.442993C22.78 0.442993 22.168 1.05502 22.168 1.80999C22.168 2.56497 22.78 3.17699 23.535 3.17699Z"
            fill="#121212"
        />
        <path
            d="M59.1405 8.44797C57.1435 8.44797 55.5195 6.82397 55.5195 4.82797C55.5195 2.83097 57.1435 1.20697 59.1395 1.20697C61.1365 1.20697 62.7605 2.83097 62.7605 4.82697C62.7605 6.82397 61.1365 8.44797 59.1405 8.44797ZM59.1405 3.62097C58.4745 3.62097 57.9325 4.16197 57.9325 4.82797C57.9325 5.49297 58.4745 6.03497 59.1395 6.03497C59.8055 6.03497 60.3465 5.49297 60.3465 4.82797C60.3465 4.16197 59.8055 3.62097 59.1395 3.62097H59.1405Z"
            fill="#121212"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.1586 54.312H19.3086V50.837C19.3086 44.803 24.2006 39.912 30.2346 39.912C36.2676 39.912 41.1586 44.803 41.1586 50.837V54.312Z"
            fill="#FFC043"
        />
        <path
            d="M41.1595 55.519H19.3095C18.6435 55.519 18.1035 54.979 18.1035 54.312V50.837C18.1035 44.147 23.5455 38.705 30.2355 38.705C36.9255 38.705 42.3665 44.147 42.3665 50.837V54.312C42.3665 54.979 41.8265 55.519 41.1595 55.519ZM20.5175 53.105H39.9535V50.837C39.9535 45.477 35.5935 41.119 30.2355 41.119C24.8765 41.119 20.5175 45.479 20.5175 50.837V53.105Z"
            fill="#121212"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.4497 38.919L31.6307 39.246C30.6947 39.62 29.6507 39.62 28.7157 39.246L27.8967 38.919C25.6267 38.01 24.1387 35.812 24.1387 33.367V28.639C24.1387 26.154 26.1527 24.139 28.6387 24.139H31.7087C34.1937 24.139 36.2087 26.154 36.2087 28.639V33.367C36.2087 35.812 34.7187 38.01 32.4487 38.919H32.4497Z"
            fill="#F7F7F7"
        />
        <path
            d="M30.1736 40.735C29.5276 40.735 28.8816 40.612 28.2666 40.365L27.4496 40.039C24.7046 38.942 22.9316 36.323 22.9316 33.367V28.638C22.9316 25.492 25.4916 22.932 28.6376 22.932H31.7076C34.8546 22.932 37.4146 25.492 37.4146 28.638V33.368C37.4146 36.323 35.6416 38.942 32.8976 40.038V40.039L32.0786 40.366C31.4646 40.612 30.8186 40.736 30.1736 40.736V40.735ZM28.6376 25.345C26.8226 25.345 25.3456 26.823 25.3456 28.638V33.368C25.3456 35.33 26.5226 37.068 28.3456 37.798L29.1626 38.125C29.8156 38.387 30.5326 38.386 31.1826 38.125L32.0006 37.798C33.8236 37.068 35.0006 35.33 35.0006 33.368V28.638C35.0006 26.823 33.5236 25.346 31.7076 25.346H28.6376V25.345Z"
            fill="#121212"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.7253 68.795L51.6123 65.174L47.0703 68.795V50.691H56.7253V68.795Z"
            fill="#F65050"
        />
        <path
            d="M47.0703 70.002C46.8923 70.002 46.7133 69.962 46.5463 69.882C46.1293 69.682 45.8633 69.259 45.8633 68.795V50.691C45.8633 50.024 46.4033 49.484 47.0703 49.484H56.7253C57.3923 49.484 57.9323 50.024 57.9323 50.691V68.794C57.9323 69.245 57.6813 69.658 57.2803 69.866C56.8803 70.072 56.3963 70.04 56.0283 69.779L51.6563 66.682L47.8233 69.737C47.6043 69.911 47.3383 70.001 47.0703 70.001V70.002ZM51.6123 63.967C51.8573 63.967 52.1003 64.041 52.3103 64.189L55.5183 66.461V51.898H48.2773V66.29L50.8603 64.23C51.0803 64.056 51.3463 63.967 51.6123 63.967Z"
            fill="#121212"
        />
        <path
            d="M51.8978 56.122C54.8973 56.122 57.3288 53.6905 57.3288 50.691C57.3288 47.6916 54.8973 45.26 51.8978 45.26C48.8983 45.26 46.4668 47.6916 46.4668 50.691C46.4668 53.6905 48.8983 56.122 51.8978 56.122Z"
            fill="#FFC043"
        />
        <path
            d="M51.8978 57.329C48.2378 57.329 45.2598 54.351 45.2598 50.691C45.2598 47.031 48.2378 44.053 51.8978 44.053C55.5578 44.053 58.5358 47.031 58.5358 50.691C58.5358 54.351 55.5578 57.329 51.8978 57.329ZM51.8978 46.467C49.5688 46.467 47.6738 48.362 47.6738 50.691C47.6738 53.021 49.5688 54.915 51.8978 54.915C54.2278 54.915 56.1218 53.02 56.1218 50.691C56.1218 48.362 54.2268 46.467 51.8978 46.467Z"
            fill="#121212"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.9332 26.552H45.8632C45.1972 26.552 44.6572 26.012 44.6572 25.345C44.6572 24.678 45.1972 24.138 45.8642 24.138H57.9332C58.5992 24.138 59.1402 24.678 59.1402 25.345C59.1402 26.011 58.6002 26.552 57.9332 26.552ZM53.1052 30.173H45.8652C45.1982 30.173 44.6582 29.633 44.6582 28.966C44.6582 28.299 45.1982 27.759 45.8652 27.759H53.1062C53.7732 27.759 54.3132 28.299 54.3132 28.966C54.3132 29.632 53.7732 30.173 53.1062 30.173H53.1052ZM14.4832 31.38H7.24216C6.57516 31.38 6.03516 30.84 6.03516 30.173C6.03516 29.506 6.57516 28.966 7.24216 28.966H14.4832C15.1502 28.966 15.6902 29.506 15.6902 30.173C15.6902 30.839 15.1502 31.38 14.4832 31.38ZM14.4832 35H7.24216C6.57516 35 6.03516 34.46 6.03516 33.793C6.03516 33.127 6.57516 32.586 7.24216 32.586H14.4832C15.1502 32.586 15.6902 33.126 15.6902 33.793C15.6902 34.46 15.1502 35 14.4832 35ZM14.4832 38.621H7.24216C6.57516 38.621 6.03516 38.081 6.03516 37.414C6.03516 36.748 6.57516 36.207 7.24216 36.207H14.4832C15.1502 36.207 15.6902 36.747 15.6902 37.414C15.6902 38.081 15.1502 38.621 14.4832 38.621Z"
            fill="#DADADA"
        />
    </svg>
);

export const StackIcon = ({ width = 22, height = 20, fill = '#717171' }) => (
    <svg width={width} height={height} viewBox="0 0 22 20" fill="none">
        <path
            d="M20.4698 2.35L19.1298 1.79V10.82L21.5598 4.96C21.9698 3.94 21.4998 2.77 20.4698 2.35ZM0.969765 6.05L5.92976 18C6.23976 18.77 6.96976 19.24 7.73976 19.26C7.99976 19.26 8.26976 19.21 8.52976 19.1L15.8998 16.05C16.6498 15.74 17.1098 15 17.1298 14.26C17.1398 14 17.0898 13.71 16.9998 13.45L11.9998 1.5C11.7098 0.73 10.9698 0.26 10.1898 0.25C9.92976 0.25 9.66976 0.31 9.41976 0.4L2.05976 3.45C1.03976 3.87 0.549765 5.04 0.969765 6.05ZM17.1198 2.25C17.1198 1.71957 16.9091 1.21086 16.534 0.835786C16.1589 0.460714 15.6502 0.25 15.1198 0.25H13.6698L17.1198 8.59"
            fill={fill}
        />
    </svg>
);

export const GoogleMeetsLogo = ({ fill = 'white' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66663 7.03484C2.66663 4.25418 4.89966 2 7.65423 2C10.4088 2 12.6648 4.25418 12.6648 7.03484C12.6648 10.4493 10.2276 12.4593 7.66484 13.9652L7.65423 12.0697C4.89966 12.0697 2.66663 9.81551 2.66663 7.03484ZM10.7575 7.17597V5.30097L9.44264 6.65804V5.7926C9.44264 5.42737 9.15375 5.12858 8.80066 5.12858H5.47972C5.12664 5.12858 4.83775 5.42737 4.83775 5.7926V7.17597H4.83771V8.55935C4.83771 8.92456 5.1266 9.22337 5.47968 9.22337H8.80062C9.15371 9.22337 9.4426 8.92456 9.4426 8.55935V7.6857L10.7574 9.04567V7.17597H10.7575Z"
            fill={fill}
        />
    </svg>
);
