import React, { useState } from 'react';
import Button from './Button';
import { GiftboxBadge, CardBadge } from './StackItem';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';
import * as moment from 'moment';
import createSchedule from '../utils/createSchedule';

const ButtonWithTooltip = ({ buttonText, date }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <Button
            primary
            rounded
            onMouseEnter={() => {
                setShowTooltip(true);
            }}
            onMouseLeave={() => {
                setShowTooltip(false);
            }}
            onClick={() => {}}
            style={{
                borderRadius: '2px',
                position: 'relative',
            }}>
            <span>{buttonText}</span>
            {showTooltip && (
                <span className="d-flex flex-column p-3 Btn--tooltip">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `This event is not yet live. Come back again on ${moment(
                                date,
                            ).format('MMMM D, YYYY')}.`,
                        }}
                    />
                </span>
            )}
        </Button>
    );
};

export const DefaultMediaObject = ({
    to = '/',
    buttonText = 'Next',
    heading,
    body,
    type,
    coverImage = '',
    children,
    isCurrent,
    date = '',
    startTime = '',
    endTime = '',
}) => {
    const schedule =
        startTime && endTime && date
            ? createSchedule(date, startTime, endTime)
            : null;
    return (
        <div className="mb-5 pb-3">
            <div
                className="media d-flex align-items-stretch"
                style={{ position: 'relative' }}>
                <div
                    style={{
                        width: '350px',
                        height: '232px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: '#717171',
                        ...(coverImage && {
                            backgroundImage: `url(${coverImage})`,
                        }),
                    }}
                />
                <div className="media-body pl-5 d-flex flex-column">
                    <div className="d-flex mb-3">
                        {type && <CardBadge type={type} />}
                    </div>
                    <h5 className="mt-0 lora large-text">{heading}</h5>
                    <p
                        style={{ fontSize: '20px' }}
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                    {schedule && (
                        <p
                            className="mb-3"
                            style={{ fontSize: '14px', color: '#717171' }}>
                            {schedule}
                        </p>
                    )}
                    <div className="w-100 d-flex align-items-center mt-auto">
                        {children}
                        {type === 'Growthlab' ? (
                            !isCurrent ? (
                                <ButtonWithTooltip
                                    buttonText="Next"
                                    date={date}
                                />
                            ) : (
                                <Button
                                    to={to}
                                    primary
                                    rounded
                                    style={{
                                        borderRadius: '2px',
                                        position: 'relative',
                                    }}>
                                    <span>{buttonText}</span>
                                </Button>
                            )
                        ) : type !== 'Postcard' ? (
                            <Button
                                to={to}
                                primary
                                rounded
                                style={{
                                    borderRadius: '2px',
                                    position: 'relative',
                                }}>
                                <span>{buttonText}</span>
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const GiftboxMediaObject = ({
    to = '/',
    href = '',
    heading = 'Media Heading',
    body = `Our starting grounds for innovation, customer-centricity and
                    all growth. We take stock of where we are..`,
    type,
    coverImg = '',
}) => (
    <div className="mb-5 pb-3">
        <div
            className="media d-flex align-items-stretch"
            style={{ position: 'relative' }}>
            <div
                style={{
                    width: '350px',
                    height: '232px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#717171',
                    ...(coverImg && {
                        backgroundImage: `url(${coverImg})`,
                    }),
                }}
            />
            <div className="media-body pl-5 d-flex flex-column">
                <div className="d-flex mb-3">
                    <GiftboxBadge />
                    {type && (
                        <span className="ml-2 StackItem--badge is-time text-uppercase">
                            {type}
                        </span>
                    )}
                </div>
                <h5 className="mt-0 lora large-text">{heading}</h5>
                <p
                    style={{ fontSize: '20px' }}
                    dangerouslySetInnerHTML={{ __html: body }}
                />
                {href ? (
                    <Button
                        href={href}
                        primary
                        rounded
                        target="_blank"
                        className="mt-auto align-self-start"
                        style={{ borderRadius: '2px' }}>
                        Next
                    </Button>
                ) : (
                    <Button
                        to={to}
                        primary
                        rounded
                        className="mt-auto align-self-start"
                        style={{ borderRadius: '2px' }}>
                        Next
                    </Button>
                )}
            </div>
        </div>
    </div>
);

export const BuddyMediaObject = ({
    to = '/',
    name = 'Media Heading',
    description = `Our starting grounds for innovation, customer-centricity and
                    all growth. We take stock of where we are..`,
    position,
    profileImage = '',
    email,
    mobile,
    initials,
}) => {
    const isDefaultAvatar =
        profileImage && profileImage === '/images/user-icon.png';
    return (
        <div className="mb-5 pb-3">
            <div
                className="media d-flex align-items-stretch"
                style={{ position: 'relative' }}>
                <div
                    className="d-flex align-items-center justify-content-center text-center"
                    style={{
                        width: '350px',
                        height: '232px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: getDefaultAvatarColors(name),
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '3rem',
                        ...(!isDefaultAvatar && {
                            backgroundImage: `url(${profileImage})`,
                        }),
                    }}>
                    {isDefaultAvatar ? initials : null}
                </div>
                <div className="media-body pl-5 d-flex flex-column">
                    <h5 className="mt-0 lora large-text mb-3">{name}</h5>
                    <span
                        style={{ fontSize: '14px', color: '#717171' }}
                        className="text-uppercase mb-3">
                        {position}
                    </span>
                    <p
                        className="mb-4"
                        style={{ fontSize: '20px' }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <ul
                        className="list-unstyled"
                        style={{ fontSize: '20px', color: '#717171' }}>
                        {email && (
                            <li className="d-flex align-items-center mb-3">
                                <i className="fe fe-send" />
                                <span className="ml-2">{email}</span>
                            </li>
                        )}
                        {mobile && (
                            <li className="d-flex align-items-center mb-3">
                                <i className="fe fe-smartphone" />
                                <span className="ml-2">{mobile}</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default GiftboxMediaObject;
