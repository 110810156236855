import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    showBusinessImpactModal,
    showPersonalGrowthModal,
} from '../reducers/modalActions';
import {
    BUSINESS_IMPACT,
    DAILY_CALENDAR,
    PERSONAL_GROWTH,
} from '../utils/CONSTANTS';
import Button from './Button';
import {
    AcceleratePeakPerformanceIcon,
    EmptyAcceleratePeakPerformanceIcon,
    BreakthroughIcon,
    EmpathyProjectsIcon,
    EmptyEmpathyProjectsIcon,
    ScorecardCalendarIcon,
} from './ScorecardIcons';

const IllusContainer = ({ children }) => (
    <div
        style={{ height: '120px', position: 'relative' }}
        className="d-flex flex-column">
        {children}
    </div>
);

const SC = [
    {
        title: 'Accelerate\nPeak Performance',
        desc:
            'The more cards you complete, the closer and faster you get to peak performance and lifelong growth.',
        moreDetails: (dispatch, dashboardStats) =>
            dispatch(showPersonalGrowthModal(dashboardStats)),
        icon: ({ empty, children }) => (
            <IllusContainer>
                {empty ? (
                    <EmptyAcceleratePeakPerformanceIcon />
                ) : (
                    <Fragment>
                        <AcceleratePeakPerformanceIcon />
                        {children}
                    </Fragment>
                )}
            </IllusContainer>
        ),
        tab: PERSONAL_GROWTH,
    },
    {
        title: 'Power up Empathy\nin your Markets',
        desc:
            'Where empathy, compassion and love are daily realities that power insights, innovation, impact. ',
        moreDetails: false,
        icon: ({ empty, children }) => (
            <IllusContainer>
                {empty ? <EmptyEmpathyProjectsIcon /> : <EmpathyProjectsIcon />}
                {children}
            </IllusContainer>
        ),
        tab: '',
    },
    {
        title: 'Launch Next\nLevel Breakthoughs',
        desc:
            'Step by step hurdles to help you sprint new products, services, solutions and models to market.',
        moreDetails: (dispatch, dashboardStats) =>
            dispatch(showBusinessImpactModal(dashboardStats)),
        icon: ({ children }) => (
            <IllusContainer>
                <BreakthroughIcon />
                {children}
            </IllusContainer>
        ),
        tab: BUSINESS_IMPACT,
    },
    {
        title: 'Do it everyday\nin 100-day Cycles',
        desc:
            'Synch your calendar to keep up with daily community gatherings and live case studies from peers.',
        moreDetails: false,
        icon: ({ children }) => (
            <IllusContainer>
                <ScorecardCalendarIcon />
                {children}
            </IllusContainer>
        ),
        tab: DAILY_CALENDAR,
    },
];

const Scorecard = ({
    title,
    desc,
    moreDetails,
    Icon,
    isActive,
    children,
    empty,
    dashboardStats,
    isDailyCalendar,
}) => {
    const dispatch = useDispatch();
    return (
        <div
            className={`Scorecard px-4 pt-4 pb-3 mx-3 d-flex flex-column align-items-center ${
                isActive ? 'active' : ''
            }`}>
            <Icon empty={empty}>{children}</Icon>
            <h3 className="mb-2 mt-3">{title}</h3>
            <p className="mb-1">{desc}</p>
            <div className="w-100 mt-auto d-flex justify-content-end">
                <Button
                    transparent
                    onClick={
                        moreDetails
                            ? () => moreDetails(dispatch, dashboardStats)
                            : () => {}
                    }
                    disabled={!moreDetails}
                    style={{ fontSize: '9px' }}
                    className="text-white d-flex align-items-center px-0">
                    {isDailyCalendar ? null : moreDetails ? (
                        <Fragment>
                            <span className="mr-2">More Details</span>
                            <i className="fe fe-arrow-right" />
                        </Fragment>
                    ) : (
                        'Coming Soon'
                    )}
                </Button>
            </div>
        </div>
    );
};

const Stat = ({ numerator, denominator }) => (
    <p
        className="d-flex justify-content-center align-items-flex-start mb-1"
        style={{ fontWeight: 700 }}>
        <span style={{ fontSize: '40px', lineHeight: 1 }}>{numerator}</span>
        <span style={{ opacity: 0.6 }}>/{denominator}</span>
    </p>
);

const isEmpty = (type, dashboardStats, projectCount) => {
    switch (type) {
        case PERSONAL_GROWTH:
            return dashboardStats.card_count === 0;
        case BUSINESS_IMPACT:
            return projectCount === 0;
        default:
            return true;
    }
};

const CardDetails = ({ type, done, dashboardStats, projectCount }) => {
    switch (type) {
        case PERSONAL_GROWTH:
            return (
                <Fragment>
                    <Stat
                        numerator={done}
                        denominator={dashboardStats.card_count}
                    />
                    <p className="mb-1 text-center" style={{ fontSize: '9px' }}>
                        Growth Cards
                    </p>
                </Fragment>
            );
        case BUSINESS_IMPACT:
            return (
                <Fragment>
                    <Stat numerator={0} denominator={projectCount} />
                    <p className="mb-1 text-center" style={{ fontSize: '9px' }}>
                        Breakthroughs Launched
                    </p>
                </Fragment>
            );
        case DAILY_CALENDAR:
            return (
                <Fragment>
                    <Stat
                        numerator={dashboardStats.license_days}
                        denominator={100}
                    />
                    <p className="mb-1 text-center" style={{ fontSize: '9px' }}>
                        Breakthrough Days Elapsed
                    </p>
                </Fragment>
            );

        default:
            return null;
    }
};

const Scorecards = ({
    activeTab,
    user,
    dashboardStats,
    done,
    projectCount,
}) => {
    return (
        <Fragment>
            <h1
                style={{ color: '#414141', fontSize: '28px' }}
                className="text-center my-3 pb-3 pt-5 lora">
                Welcome to the future of work, {user.firstName}
            </h1>
            <div className="d-flex justify-content-center pt-5 px-5">
                {SC.map((scorecard, i) => (
                    <Scorecard
                        key={i}
                        isActive={activeTab === scorecard.tab}
                        title={scorecard.title}
                        desc={scorecard.desc}
                        dashboardStats={dashboardStats}
                        moreDetails={scorecard.moreDetails}
                        empty={isEmpty(
                            scorecard.tab,
                            dashboardStats,
                            projectCount,
                        )}
                        isDailyCalendar={scorecard.tab === DAILY_CALENDAR}
                        Icon={scorecard.icon}>
                        <div
                            className="d-flex flex-column"
                            style={{
                                position: 'absolute',
                                bottom: '1rem',
                                left: 0,
                                right: 0,
                                margin: 'auto',
                                color: '#276EF1',
                            }}>
                            <CardDetails
                                type={scorecard.tab}
                                done={done}
                                projectCount={projectCount}
                                dashboardStats={dashboardStats}
                            />
                        </div>
                    </Scorecard>
                ))}
            </div>
        </Fragment>
    );
};

export default Scorecards;
