import React from 'react';
import Button from './Button';

const EmptyGiftboxCards = () => (
    <div
        className="d-flex flex-column p-5 align-items-center justify-content-center"
        style={{
            color: '#838383',
            border: '1px dashed #DADADA',
            borderRadius: '16px',
        }}>
        <div
            className="mb-3"
            style={{
                backgroundColor: '#F7F7F7',
                width: '80px',
                height: '80px',
                borderRadius: '50%',
            }}
            className="d-flex align-items-center justify-content-center">
            <svg width="32" height="34" viewBox="0 0 32 34" fill="none">
                <path
                    d="M30.375 9.10938H24.6094C25.1406 8.27344 25.4531 7.28125 25.4531 6.21875C25.4531 3.24609 23.0352 0.828125 20.0625 0.828125C18.4453 0.828125 16.9883 1.54687 16 2.67969C15.0117 1.54687 13.5547 0.828125 11.9375 0.828125C8.96484 0.828125 6.54688 3.24609 6.54688 6.21875C6.54688 7.28125 6.85547 8.27344 7.39062 9.10938H1.625C0.933594 9.10938 0.375 9.66797 0.375 10.3594V18.1719C0.375 18.3438 0.515625 18.4844 0.6875 18.4844H2.25V31.9219C2.25 32.6133 2.80859 33.1719 3.5 33.1719H28.5C29.1914 33.1719 29.75 32.6133 29.75 31.9219V18.4844H31.3125C31.4844 18.4844 31.625 18.3438 31.625 18.1719V10.3594C31.625 9.66797 31.0664 9.10938 30.375 9.10938ZM17.3281 6.21875C17.3281 4.71094 18.5547 3.48438 20.0625 3.48438C21.5703 3.48438 22.7969 4.71094 22.7969 6.21875C22.7969 7.72656 21.5703 8.95312 20.0625 8.95312H17.3281V6.21875ZM11.9375 3.48438C13.4453 3.48438 14.6719 4.71094 14.6719 6.21875V8.95312H11.9375C10.4297 8.95312 9.20312 7.72656 9.20312 6.21875C9.20312 4.71094 10.4297 3.48438 11.9375 3.48438ZM3.03125 15.8281V11.7656H14.6719V15.8281H3.03125ZM4.90625 18.4844H14.6719V30.5156H4.90625V18.4844ZM27.0938 30.5156H17.3281V18.4844H27.0938V30.5156ZM28.9688 15.8281H17.3281V11.7656H28.9688V15.8281Z"
                    fill="#F2307F"
                />
            </svg>
        </div>
        <p className="mb-1">
            Start collecting cards from the different categories.
        </p>
        <p className="mb-3">
            The more you collect, the more GiftBox cards will be rewarded to
            you. Good luck.
        </p>
        <Button
            primary
            rounded
            style={{ borderRadius: '2px' }}
            onClick={() =>
                window.scrollTo({
                    top: 600,
                    behavior: 'smooth',
                })
            }>
            See What's Next
        </Button>
    </div>
);

export default EmptyGiftboxCards;
