import React, { Fragment } from 'react';
import { QuestionBody, QuestionFooter } from '../GrowthLabQuestionScreen';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useHistory } from 'react-router-dom';
import { OpenText, Select, Slider } from '../../components/QuestionInput';
import { useSelector, useDispatch } from 'react-redux';
import {
    saveAnswers,
    submitAnswers,
    updateDiscernmentAnswer,
} from '../../reducers/itineraryActions';
import setResponseErrors from '../../utils/setResponseErrors';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import { Radio, RadioHorizontal } from '../../components/Inputs';
import Button from '../../components/Button';
import {
    createSchema,
    createDataForSave,
    getInitialAnswer,
} from '../../utils/answerUtils';

export const Content = ({
    question_type,
    question,
    question_key,
    options,
    max = 3,
    disabled,
    optional,
    values,
    slider_length,
    slider_left_label,
    slider_right_label,
    is_slider,
}) => {
    return (
        <div className={'mb-4'}>
            {question_type === 'open_text' && (
                <Field
                    name={question_key}
                    label={question}
                    component={OpenText}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'multi_text' && (
                <FieldArray
                    name={question_key}
                    render={arrayHelpers => (
                        <Fragment>
                            <label
                                dangerouslySetInnerHTML={{ __html: question }}
                            />
                            {values[question_key] &&
                            values[question_key].length > 0
                                ? values[question_key].map((v, i) => (
                                      <div
                                          className="d-flex w-100 mb-3"
                                          key={i}>
                                          <Field
                                              name={`${question_key}.${i}`}
                                              component={OpenText}
                                              disabled={disabled}
                                              required={!optional}
                                              noMargin
                                          />
                                          {values[question_key].length > 1 && (
                                              <Button
                                                  transparent
                                                  onClick={() =>
                                                      arrayHelpers.remove(i)
                                                  }>
                                                  <i className="fe fe-x" />
                                              </Button>
                                          )}
                                      </div>
                                  ))
                                : null}
                            {values[question_key] &&
                                values[question_key].length < max && (
                                    <Button
                                        className="mb-3"
                                        onClick={() => arrayHelpers.push('')}>
                                        <i className="fe fe-plus" />{' '}
                                        <span>Add another</span>
                                    </Button>
                                )}
                        </Fragment>
                    )}
                />
            )}
            {question_type === 'select' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Select}
                    options={options}
                    max={max}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Radio}
                    options={options}
                    disabled={disabled}
                    slider={is_slider}
                    required={!optional}
                />
            )}
            {question_type === 'slider' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Slider}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'horizontal_radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={RadioHorizontal}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                    options={options}
                />
            )}
            <ErrorMessage
                name={question_key}
                component={ErrorMessageContainer}
            />
        </div>
    );
};

const DiscernmentContent = ({
    title,
    next,
    content,
    goHome,
    isLast,
    slug,
    currentEventId,
}) => {
    const hasQuestion = content.filter(c => c.question_type).length;
    const history = useHistory();
    const dispatch = useDispatch();
    const answers = useSelector(s => s.itinerary.discernmentAnswers);
    const contentAnswers = answers[slug];

    const filteredContent = content.filter(c => c.question_key);

    const initialValues = filteredContent.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.question_key]: getInitialAnswer(cur, contentAnswers),
        };
    }, {});

    const schema = createSchema(filteredContent);

    if (hasQuestion) {
        return (
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    setSubmitting(true);
                    const data = createDataForSave(values, slug);
                    if (isLast) {
                        dispatch(submitAnswers(currentEventId, data)).then(
                            res => {
                                setSubmitting(false);
                                dispatch(updateDiscernmentAnswer(data));
                                goHome();
                            },
                            err => {
                                setSubmitting(false);
                                setResponseErrors(err, setFieldError);
                            },
                        );
                    } else {
                        dispatch(saveAnswers(currentEventId, data)).then(
                            res => {
                                setSubmitting(false);
                                dispatch(updateDiscernmentAnswer(data));
                                history.push(next);
                            },
                            err => {
                                setSubmitting(false);
                                setResponseErrors(err, setFieldError);
                            },
                        );
                    }
                }}>
                {({ isSubmitting, values }) => (
                    <Form>
                        <QuestionBody>
                            <h5 className="header-pretitle">{title}</h5>
                            {content
                                .sort((a, b) => a.ordinality - b.ordinality)
                                .map((c, i) =>
                                    c.type === 'pro_tip' ? (
                                        <div
                                            className="bg-gray p-4 mb-6 mt-6"
                                            key={i}>
                                            <strong className="mb-2 d-block">
                                                <i className="fe fe-info" /> PRO
                                                TIP
                                            </strong>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: c.blurb,
                                                }}
                                            />
                                        </div>
                                    ) : c.type === 'question' ? (
                                        <Content
                                            key={i}
                                            {...c}
                                            values={values}
                                            disabled={isSubmitting}
                                        />
                                    ) : (
                                        <div
                                            className="mb-3"
                                            key={i}
                                            dangerouslySetInnerHTML={{
                                                __html: c.blurb,
                                            }}
                                        />
                                    ),
                                )}
                        </QuestionBody>
                        <QuestionFooter
                            goHome={goHome}
                            disabled={isSubmitting}
                            isLoading={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        );
    }

    return (
        <div>
            <QuestionBody>
                <h2>{title}</h2>
                {content
                    .sort((a, b) => a.ordinality - b.ordinality)
                    .map(c => (
                        <div
                            key={c.id}
                            dangerouslySetInnerHTML={{ __html: c.blurb }}
                        />
                    ))}
            </QuestionBody>
            {isLast ? (
                <QuestionFooter goHome={goHome} onClickNext={goHome} />
            ) : (
                <QuestionFooter goHome={goHome} to={next} />
            )}
        </div>
    );
};

export default DiscernmentContent;
