import { SHOW_MODAL, HIDE_MODAL, UPDATE_MODAL } from './modalActions';

const initialState = {
    show: false,
    type: '',
    lockDetails: {},
    postcard: {},
    auditDetails: {},
    peerExchangeDetails: {},
    checkboxDetails: {},
    deleteDetails: {},
    sampleAnswer: '',
    dashboardStats: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            document.body.style = 'overflow: hidden';
            return {
                ...state,
                show: true,
                type: action.payload.type,
                lockDetails: action.payload.lockDetails || {},
                postcard: action.payload.postcard || {},
                auditDetails: action.payload.auditDetails || {},
                peerExchangeDetails: action.payload.peerExchangeDetails || {},
                checkboxDetails: action.payload.checkboxDetails || {},
                deleteDetails: action.payload.deleteDetails || {},
                sampleAnswer: action.payload.sampleAnswer || {},
                dashboardStats: action.payload.dashboardStats || {},
            };
        case HIDE_MODAL:
            document.body.style = 'overflow: auto';
            return {
                ...state,
                ...initialState,
            };

        case UPDATE_MODAL:
            return {
                ...state,
                ...{
                    [action.payload.key]: {
                        ...state[action.payload.key],
                        ...action.payload.details,
                    },
                },
            };
        default:
            return state;
    }
};

export default reducer;
