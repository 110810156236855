import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ScreenHeader from '../components/ScreenHeader';
import * as yup from 'yup';
import { Input, PasswordInput, NumericalInput } from '../components/Inputs';
import Button from '../components/Button';
import { useDispatch } from 'react-redux';
import { SIGN_UP_SUCCESSFUL, signUp } from '../reducers/usersActions';
import ErrorMessageContainer from '../components/ErrorMessageContainer';
import setResponseErrors from '../utils/setResponseErrors';
import { useHistory } from 'react-router-dom';

const SignUpSchema = yup.object().shape({
    first_name: yup.string().required('First name is required.'),
    last_name: yup.string().required('Last name is required.'),
    email: yup
        .string()
        .email('Must input a valid email address.')
        .required('Email is required.'),
});

const SignUpScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div className="py-1 px-4 pb-3" style={{ minHeight: '100vh' }}>
            <ScreenHeader title="Create an Account" pretitle="Ignite HOUSE" />
            <Formik
                validationSchema={SignUpSchema}
                onSubmit={(
                    { generalError, ...values },
                    { setSubmitting, setFieldError },
                ) => {
                    setSubmitting(true);
                    signUp(values).then(
                        data => {
                            setSubmitting(false);
                            dispatch({
                                type: SIGN_UP_SUCCESSFUL,
                                payload: data,
                            });
                            history.replace('/');
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                }}>
                {({ isSubmitting }) => (
                    <Form
                        className="d-flex flex-column "
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="first_name"
                            label="First Name"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="first_name"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            name="last_name"
                            label="Last Name"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="last_name"
                            component={ErrorMessageContainer}
                        />
                        <Field
                            name="email"
                            label="Email Address"
                            type="email"
                            className="mb-4"
                            component={Input}
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="email"
                            component={ErrorMessageContainer}
                        />
                        <p
                            className="mt-auto pb-0 mb-0 registration-terms text-center"
                            style={{ fontSize: '0.9em' }}>
                            By registering, you agree to our{' '}
                            <a
                                href="https://www.ignitegifts.com/terms"
                                target="_blank">
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.ignitegifts.com/privacy"
                                target="_blank">
                                Data Privacy Policy
                            </a>
                            .
                        </p>
                        <Button
                            block
                            large
                            type="submit"
                            className="mt-4"
                            disabled={isSubmitting}>
                            {isSubmitting && (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            <span> Register</span>
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SignUpScreen;
