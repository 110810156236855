import React from 'react';

export const WorkforceIcon = ({
    fill = '#dadada',
    height = 16,
    width = 16,
}) => (
    <svg width={width} height={height} viewBox="0 0 16 19" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13C3.58 13 0 14.79 0 17V19H16V17C16 14.79 12.42 13 8 13ZM4 7C4 9.20914 5.79086 11 8 11C10.2091 11 12 9.20914 12 7M7.5 0C7.2 0 7 0.21 7 0.5V3.5H6V1C6 1 3.75 1.86 3.75 4.75C3.75 4.75 3 4.89 3 6H13C12.95 4.89 12.25 4.75 12.25 4.75C12.25 1.86 10 1 10 1V3.5H9V0.5C9 0.21 8.81 0 8.5 0H7.5Z"
            fill={fill}
        />
    </svg>
);

export const CustomersIcon = ({
    fill = '#dadada',
    height = 16,
    width = 16,
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
            fill={fill}
        />
    </svg>
);

export const CostIcon = ({ fill = '#dadada', height = 16, width = 16 }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path
            d="M3.5 5C3.10218 5 2.72064 4.84196 2.43934 4.56066C2.15804 4.27936 2 3.89782 2 3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2C3.89782 2 4.27936 2.15804 4.56066 2.43934C4.84196 2.72064 5 3.10218 5 3.5C5 3.89782 4.84196 4.27936 4.56066 4.56066C4.27936 4.84196 3.89782 5 3.5 5ZM19.41 9.58L10.41 0.58C10.05 0.22 9.55 0 9 0H2C0.89 0 0 0.89 0 2V9C0 9.55 0.22 10.05 0.59 10.41L9.58 19.41C9.95 19.77 10.45 20 11 20C11.55 20 12.05 19.77 12.41 19.41L19.41 12.41C19.78 12.05 20 11.55 20 11C20 10.44 19.77 9.94 19.41 9.58Z"
            fill={fill}
        />
    </svg>
);

export const PartnerIcon = ({ fill = '#dadada', height = 18, width = 16 }) => (
    <svg width={width} height={height} viewBox="0 0 16 18" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0ZM12 10.54C12 11.6 11.72 14.07 9.81 16.83L9 12L9.94 10.12C9.32 10.05 8.67 10 8 10C7.33 10 6.68 10.05 6.06 10.12L7 12L6.19 16.83C4.28 14.07 4 11.6 4 10.54C1.61 11.24 0 12.5 0 14V18H6H7.09H8.91H10H16V14C16 12.5 14.4 11.24 12 10.54Z"
            fill={fill}
        />
    </svg>
);

export const SalesIcon = ({ fill = '#dadada', height = 16, width = 22 }) => (
    <svg width={width} height={height} viewBox="0 0 22 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0H22V12H4V0ZM13 3C14.6569 3 16 4.34315 16 6C16 7.65685 14.6569 9 13 9C11.3431 9 10 7.65685 10 6C10 4.34315 11.3431 3 13 3ZM8 2C8 3.10457 7.10457 4 6 4V8C7.10457 8 8 8.89543 8 10H18C18 8.89543 18.8954 8 20 8V4C18.8954 4 18 3.10457 18 2H8ZM0 4H2V14H18V16H0V4Z"
            fill={fill}
        />
    </svg>
);

export const OperationsIcon = ({
    fill = '#dadada',
    height = 20,
    width = 20,
}) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99999 13.5C8.067 13.5 6.49999 11.933 6.49999 10C6.49999 8.067 8.067 6.5 9.99999 6.5C11.933 6.5 13.5 8.067 13.5 10C13.5 11.933 11.933 13.5 9.99999 13.5ZM17.43 10.97C17.47 10.65 17.5 10.33 17.5 10C17.5 9.67 17.47 9.34 17.43 9L19.54 7.37C19.73 7.22 19.78 6.95 19.66 6.73L17.66 3.27C17.54 3.05 17.27 2.96 17.05 3.05L14.56 4.05C14.04 3.66 13.5 3.32 12.87 3.07L12.5 0.42C12.46 0.18 12.25 0 12 0H7.99999C7.74999 0 7.53999 0.18 7.49999 0.42L7.12999 3.07C6.49999 3.32 5.95999 3.66 5.43999 4.05L2.94999 3.05C2.72999 2.96 2.45999 3.05 2.33999 3.27L0.339992 6.73C0.209992 6.95 0.269992 7.22 0.459992 7.37L2.56999 9C2.52999 9.34 2.49999 9.67 2.49999 10C2.49999 10.33 2.52999 10.65 2.56999 10.97L0.459992 12.63C0.269992 12.78 0.209992 13.05 0.339992 13.27L2.33999 16.73C2.45999 16.95 2.72999 17.03 2.94999 16.95L5.43999 15.94C5.95999 16.34 6.49999 16.68 7.12999 16.93L7.49999 19.58C7.53999 19.82 7.74999 20 7.99999 20H12C12.25 20 12.46 19.82 12.5 19.58L12.87 16.93C13.5 16.67 14.04 16.34 14.56 15.94L17.05 16.95C17.27 17.03 17.54 16.95 17.66 16.73L19.66 13.27C19.78 13.05 19.73 12.78 19.54 12.63L17.43 10.97Z"
            fill={fill}
        />
    </svg>
);
