import React, { useRef, useEffect, useState } from 'react';
import AnimatedContainer from 'components/AnimatedContainer';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { donePeerReview } from 'reducers/itineraryActions';
import Button from 'components/Button';
import PeerExchangeAnswer from 'components/PeerExchangeAnswer';
import Loader from 'components/Loader';

const PeerExchangeGroupAnswers = ({
    id,
    question,
    isLast,
    scrollY,
    setCurrentView,
    section,
    nextSection,
    groupAnswers,
    members,
    options,
    isFromTo,
}) => {
    const ref = useRef();
    const [isLoading, setLoading] = useState(false);
    const [b, setBottom] = useState(0);
    const history = useHistory();
    const { projectId, stopId, soloWorkoutId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const { bottom } = ref.current.getBoundingClientRect();
        setBottom(scrollY + bottom - 100);
    }, [ref.current, scrollY]);

    const onClickNext = () => {
        if (isLast) {
            setLoading(true);
            dispatch(donePeerReview(projectId, stopId, soloWorkoutId))
                .then(data =>
                    history.push(
                        `/100h/projects/${projectId}/hurdles/${stopId}/peer-exchange/${soloWorkoutId}/plenary-exchange`,
                    ),
                )
                .catch(console.warn)
                .finally(() => setLoading(false));
        } else {
            window.scrollTo({ top: nextSection, behavior: 'smooth' });
        }
    };

    const editAnswerLink = `/100h/projects/${projectId}/hurdles/${stopId}/solo_workout/${soloWorkoutId}`;

    return (
        <AnimatedContainer
            scrollY={scrollY}
            id={id}
            section={section}
            setCurrentView={setCurrentView}>
            <div ref={ref} className="py-5">
                <h2 className="header-pretitle isRaaz">Peer Exchange</h2>
                <h1 className="question">{question}</h1>

                <ul className="row py-3 list-unstyled pl-0">
                    {groupAnswers ? (
                        groupAnswers.map((d, i) => {
                            const answer = options
                                ? d.values
                                      .map(v =>
                                          options.find(o => o.value === v),
                                      )
                                      .filter(Boolean)
                                      .map(
                                          o => `•  ${o.body.split(' --- ')[0]}`,
                                      )
                                      .join('<br /> ')
                                : d.values;

                            const isFromToAnswer = `<strong>From: </strong>${d.values[0]}<br />
                            <strong>To: </strong>${d.values[1]}`;
                            return (
                                <PeerExchangeAnswer
                                    project={d.project}
                                    soloWorkoutId={soloWorkoutId}
                                    questionKey={id}
                                    question={question}
                                    comments={d.comments}
                                    answer={isFromTo ? isFromToAnswer : answer}
                                    answerId={d.id}
                                    referenceMaterials={d.reference_materials}
                                    user={d.user}
                                    key={i}
                                    isFavorite={d.isFavorite}
                                    editAnswerLink={editAnswerLink}
                                />
                            );
                        })
                    ) : (
                        <li className="px-3">
                            <strong>
                                Please finish your solo workout so answers will
                                be shown.
                            </strong>
                        </li>
                    )}
                </ul>
                <div className="mt-5 py-5 w-100 d-flex justify-content-end">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Button primary onClick={onClickNext}>
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </AnimatedContainer>
    );
};

export default PeerExchangeGroupAnswers;
