import instance from '../instance';
import { client } from '../client';
import { gql } from '@apollo/client';

export const SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL';
export const FB_SIGNUP_SUCCESSFUL = 'FB_SIGNUP_SUCCESSFUL';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_SUCCESSFUL = 'UPDATE_USER_SUCCESSFUL';

export const signUp = data =>
    instance.post('/api/v3/register', data).then(res => res.data);
export const login = data =>
    instance.post('/api/v3/login', data).then(res => res.data);
export const forgotPassword = data =>
    instance.post('/api/v3/forgot_password', data).then(res => res.data);
export const authFB = data =>
    instance.post('/api/v3/register_facebook', data).then(res => res.data);

export const updateProfile = data => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/profile`,
            method: 'PATCH',
            data,
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getItineraryGroups = () => (dispatch, getState) => {
    const { auth_token } = getState().app;
    return instance
        .get('/api/v3/itinerary_groups', {
            headers: { Authorization: `Bearer ${auth_token}` },
        })
        .then(res => res.data);
};

export const getDataViz = id => (dispatch, getState) => {
    const { auth_token } = getState().app;

    return instance
        .request({
            url: `/api/v3/data_visualizations/${id}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const currentUser = () =>
    client
        .query({
            query: gql`
                query currentUser {
                    currentUser {
                        id
                        email
                    }
                }
            `,
        })
        .then(result => console.log(result));
