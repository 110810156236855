import { useState, useCallback, useRef } from 'react';

export function useScrollIntoView({ dep, offset = 0 } = {}) {
    const [offsets, setOffsets] = useState([]);

    const ref = useRef(null);
    const setRef = useCallback(
        node => {
            if (ref.current) {
                // Make sure to cleanup any events/references added to the last instance
            }

            if (node) {
                const els = node.children;
                setOffsets(Array.from(els).map(el => el.offsetTop + offset));
            }

            // Save a reference to the node
            ref.current = node;
        },
        [dep],
    );

    function scrollIntoView(target) {
        window.scrollTo({
            top: offsets[target],
            behavior: 'smooth',
        });
    }

    return { offsets, scrollIntoView, setRef };
}
