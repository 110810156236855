import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';

const defaultOpts = {
    height: '0',
    width: '0',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
};

const Container = ({
    id,
    title,
    subtitle,
    body = `This 2-minute video explains the Growth Quotient (GQ) and plots
                a course for Maximum Growth (MG) across the 4 Growth Streams.`,
}) => {
    const [opts, setOpts] = useState(defaultOpts);
    const videoRef = useRef(null);
    const _onReady = event => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    useEffect(() => {
        const { width } = videoRef.current.getBoundingClientRect();
        setOpts({
            ...opts,
            width: width,
            height: width / 1.6,
        });
    }, [videoRef.current]);

    return (
        <div className="VideoContainer mt-5" ref={videoRef}>
            <h2 className="header-pretitle isRaaz mb-3 text-uppercase">
                {subtitle}
            </h2>
            {title && <h1 className="lora mb-3">{title}</h1>}
            {body && <p>{body}</p>}
            <YouTube videoId={id} opts={opts} onReady={_onReady} />
        </div>
    );
};

export const VideoContainerFullParent = ({
    id,
    onEnd = () => {},
    onReady = () => {},
    onPause = () => {},
    onPlay = () => {},
}) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        const { width, height } = ref.current.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
    }, [ref.current]);

    return (
        <div className="VideoContainerFullParent" ref={ref}>
            <YouTube
                videoId={id}
                opts={{ ...defaultOpts, width, height }}
                onReady={onReady}
                onEnd={onEnd}
                onPause={onPause}
                onPlay={onPlay}
            />
        </div>
    );
};

export default Container;
