import React, { Fragment } from 'react';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { GiftboxStack, GiftboxBadge } from '../components/StackItem';
import { Route, useParams } from 'react-router-dom';
import { GiftboxTitle } from '../components/ProjectTitle';
import Button from '../components/Button';
import { gql, useQuery } from '@apollo/client';
import Loader from '../components/Loader';
import cardParts from './queries/cardParts';
import GET_CURRENT_USER from './queries/getCurrentUser';
import GET_USER_CARD from './queries/getUserCard';

const GET_GIFTBOX_COLLECTION = gql`
    query getGiftbox($userId: ID) {
        giftboxCards(userId: $userId) {
            ...cardParts
            userCard(userId: $userId) {
                id
                giftboxMeta
                mustReads {
                    id
                    imageUrl
                    name
                    url
                    description
                }
            }
        }
    }

    ${cardParts}
`;

const GiftboxStackItems = () => {
    const { data, loading } = useQuery(GET_CURRENT_USER);

    const r = useQuery(GET_GIFTBOX_COLLECTION, {
        skip: !data,
        variables: {
            userId: data ? data.currentUser.id : '',
        },
        fetchPolicy: 'cache-and-network',
    });

    if (!r.data) return <Loader fullscreen />;

    const { giftboxCards } = r.data;

    return (
        <Fragment>
            <h1 className="lora large-text mb-3">Giftbox Collection</h1>
            <div className="StackItems">
                {giftboxCards
                    .slice()
                    .sort((a, b) => a.ordinality - b.ordinality)
                    .map((g, i) => (
                        <GiftboxStack
                            key={i}
                            title={g.title}
                            id={g.userCard ? g.userCard.id : ''}
                            locked={!(g.userCard && g.userCard.id)}
                            description={g.description}
                            imageUrl={g.imageUrl}
                            userId={data.currentUser.id}
                            isFavorited={g.isFavorited}
                        />
                    ))}
            </div>
        </Fragment>
    );
};

const GiftboxItem = ({
    title,
    to = '',
    type = 'Data Viz',
    description,
    coverImg = '',
    locked,
}) => (
    <div className="mb-4" style={{ width: '49%' }}>
        <div
            className="d-flex align-items-stretch"
            style={{
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                height: '200px',
                overflow: 'hidden',
            }}>
            <div
                style={{
                    width: '168px',
                    height: '100%',
                    backgroundColor: '#717171',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${coverImg})`,
                }}
            />
            <div className="d-flex flex-column p-3 h-100 w-100">
                <div className="d-flex align-items-center mb-3">
                    <GiftboxBadge />
                    <span className="ml-2 StackItem--badge is-time text-uppercase">
                        {type}
                    </span>
                </div>
                <h2
                    className="text-uppercase mb-2"
                    style={{ fontSize: '14px' }}>
                    {title}
                </h2>
                {description && (
                    <p
                        className="mb-0"
                        style={{ fontSize: '14px', color: '#717171' }}>
                        {description.slice(0, 90)}...
                    </p>
                )}
                {!locked && (
                    <Button
                        to={to}
                        className="ml-auto mt-auto d-flex align-items-center text-uppercase"
                        rounded
                        transparent
                        style={{ fontSize: '14px', color: '#276ef1' }}>
                        <span className="mr-2">Claim</span>
                        <i className="fe fe-arrow-right" />
                    </Button>
                )}
                {locked && (
                    <Button
                        disabled
                        transparent
                        className="ml-auto mt-auto d-flex align-items-center text-uppercase"
                        style={{ fontSize: '14px', color: '#A0A0A0' }}>
                        <span className="mr-2">Coming Soon</span>
                        <i
                            className="fe fe-lock"
                            style={{
                                color: '#A0A0A0',
                            }}
                        />
                    </Button>
                )}
            </div>
        </div>
    </div>
);

export const Giftbox = ({ fromGiftbox, userId }) => {
    const { userCardId } = useParams();

    const r = useQuery(GET_USER_CARD, {
        variables: {
            id: userCardId,
            userId,
        },
    });

    if (r.loading || !r.data) return <Loader fullscreen />;

    const { userCard } = r.data;

    return (
        <Fragment>
            <h1
                className="large-text mb-3 text-uppercase"
                style={{ lineHeight: '38px', fontWeight: 'bold' }}>
                {userCard.card.title}
            </h1>
            <p
                style={{
                    fontSize: '20px',
                    color: '#717171',
                    maxWidth: MAX_WIDTH,
                }}>
                {userCard.card.description}
            </p>
            <div className="d-flex align-items-center justify-content-between flex-wrap py-4">
                <GiftboxItem
                    title="Data is Beautiful"
                    type="Data Viz"
                    description="Powerful insights await you with these data visualizations, all updated in real time based on the RESTART community’s collective responses to our smart cards."
                    to={`/giftbox/${userCardId}/data-viz${
                        fromGiftbox ? '?fromGiftbox=true' : ''
                    }`}
                    coverImg="/giftbox-bg/data-card-bg.jpg"
                    locked
                />
                <GiftboxItem
                    title="People You Should Meet"
                    type="Buddy Workout"
                    to={`/giftbox/${userCardId}/buddy-workout${
                        fromGiftbox ? '?fromGiftbox=true' : ''
                    }`}
                    coverImg="/giftbox-bg/buddy-card-bg.jpg"
                    description="We learn better when we learn with others - especially when smart algorithms work round the clock to find these matches. Beyond learnings, who knows what kind of collaborations these conversations will bring!"
                />
                <GiftboxItem
                    title="Articles You Should Read"
                    type="Must Reads"
                    to={`/giftbox/${userCardId}/must-reads${
                        fromGiftbox ? '?fromGiftbox=true' : ''
                    }`}
                    coverImg="/giftbox-bg/must-reads-card-bg.jpg"
                    description="We search the web for what’s most relevant based on your smart card responses. As we say: feed the appetite but balance the diet too - you’ll find both in this special curation just for you. "
                />
                <GiftboxItem
                    title="Events You Should Attend"
                    type="Growthlabs"
                    to={`/giftbox/${userCardId}/growthlabs${
                        fromGiftbox ? '?fromGiftbox=true' : ''
                    }`}
                    coverImg="/giftbox-bg/events-card-bg.jpg"
                    description="Our community is at it’s best when we gather as diverse individuals and teams - bound by something powerful that is shared. Our algorithms have found the perfect mix for your next gathering."
                />
            </div>
        </Fragment>
    );
};

const GiftboxCollectionScreen = ({ userId }) => {
    return (
        <div
            className="py-5 px-3 mx-auto"
            style={{ maxWidth: MAX_WIDTH * 1.8 }}>
            <Route path="/giftbox-collection/:userCardId">
                <Giftbox fromGiftbox userId={userId} />
            </Route>
            <Route path="/giftbox-collection" exact>
                <GiftboxStackItems />
            </Route>
        </div>
    );
};

export default GiftboxCollectionScreen;
