import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../reducers/hundredhoursActions';
import Button from './Button';

export const ReturnButton = ({ body, to }) => (
    <Link
        to={to}
        style={{
            color: '#717171',
            backgroundColor: '#F7F7F7',
            borderRadius: '2px',
        }}
        className="d-flex align-items-center px-3 py-2">
        <small
            className="mr-2"
            style={{
                fontSize: '0.6875rem',
            }}>
            {body}
        </small>
        <span
            style={{
                color: '#717171',
            }}>
            &times;
        </span>
    </Link>
);

export const ProjectTitle = ({ title, subtitle, to, transparent }) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const [project, setProject] = useState();
    const displaySubtitle = subtitle;

    useEffect(() => {
        if (!title) {
            dispatch(getProject(projectId)).then(res => {
                setProject(res);
            }, console.warn);
        }
    }, [title, projectId]);

    return (
        <div
            className="px-5 py-2 d-flex align-items-center justify-content-space-between"
            style={{
                position: 'fixed',
                left: 0,
                right: 0,
                zIndex: 10,
                top: 0,
                ...(!transparent && { backgroundColor: 'white' }),
            }}>
            <div className="mr-auto mt-4 pr-1 flex-column d-flex justify-content-center">
                <h2
                    className="mb-0 lora"
                    dangerouslySetInnerHTML={{
                        __html: project ? project.title : title,
                    }}
                />
                {displaySubtitle && (
                    <p className="header-pretitle isRaaz mb-0">
                        {displaySubtitle}
                    </p>
                )}
            </div>
            <div className="d-flex align-items-center">
                <ReturnButton
                    to={to || `/100h/projects/${projectId}`}
                    body="Return to Itinerary"
                />
            </div>
        </div>
    );
};

export const GiftboxTitle = ({
    title,
    subtitle,
    showBack,
    fromGiftbox,
    backToItinerary,
}) => {
    const history = useHistory();
    return (
        <div
            className="px-5 py-2 d-flex align-items-center justify-content-space-between"
            style={{
                position: 'fixed',
                left: 0,
                right: 0,
                zIndex: 10,
                backgroundColor: 'white',
            }}>
            {showBack ? (
                <Button
                    transparent
                    onClick={() => history.goBack()}
                    className="align-items-center d-flex mr-auto">
                    <i className="fe fe-arrow-left" />
                    <span className="ml-2">Back</span>
                </Button>
            ) : (
                <div
                    className="mr-auto mt-4 pr-1 flex-column d-flex justify-content-center"
                    style={{ backgroundColor: 'white' }}>
                    <h2
                        className="mb-0 lora"
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />

                    {subtitle && (
                        <p className="header-pretitle isRaaz mb-0">
                            {subtitle}
                        </p>
                    )}
                </div>
            )}
            <div className="d-flex align-items-center">
                {fromGiftbox ? (
                    <ReturnButton
                        to="/giftbox-collection"
                        body="Back to Giftbox Collection"
                    />
                ) : backToItinerary ? (
                    <ReturnButton
                        to={backToItinerary}
                        body="Back to Itinerary"
                    />
                ) : (
                    <ReturnButton to="/" body="Back to Dashboard" />
                )}
            </div>
        </div>
    );
};

export default ProjectTitle;
