const colors = [
    '#276EF1',
    '#689AF5',
    '#F2307F',
    '#7356BF',
    '#02B4A9',
    '#FF862D',
    '#30ADBE',
    '#E336A8',
    '#A126B5',
    '#ED6E33',
    '#F65050',
    '#02B482',
];

const getDefaultAvatarColors = str => {
    const idx = str.length % colors.length;
    return colors[idx];
};

export default getDefaultAvatarColors;
