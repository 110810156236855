import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import GrowthQuotientScreen from './GrowthQuotientScreen';
import Loader from '../components/Loader';
import DiscernmentScreen from './DiscernmentScreen';
import BreakoutGroupsScreen from './BreakoutGroupsScreen';
import GrowthDataVizScreen from './GrowthDataVizScreen';
import GiveMeGrowthScreen from './GiveMeGrowthScreen';
import PayItForwardScreen from './PayItForwardScreen';
import ChartScreen from './ChartScreen';
import { useDispatch } from 'react-redux';
import { getEventDetails } from '../reducers/itineraryActions';

const CurrentAreaScreenWithEventId = () => {
    const params = useParams();
    const history = useHistory();
    const { eventId, slug } = params;
    const [currentEvent, setEvent] = useState(null);
    const dispatch = useDispatch();

    const baseUrl = `/growthlab-app/${eventId}`;
    const goToItineraryGroup = () => history.push('/');

    useEffect(() => {
        dispatch(getEventDetails(eventId)).then(d => setEvent(d));
    }, [eventId]);

    if (!currentEvent) {
        return <Loader fullscreen />;
    }

    const currentArea = currentEvent.app_areas.find(area => area.slug === slug);

    switch (currentArea.type) {
        case 'growth_calculator':
            return (
                <GrowthQuotientScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'discernment':
            return (
                <DiscernmentScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentEvent={currentEvent}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'breakout_groups':
            return (
                <BreakoutGroupsScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                />
            );
        case 'growth_dataviz':
            return (
                <GrowthDataVizScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                />
            );
        case 'give_me_growth':
            return (
                <GiveMeGrowthScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'growth_payforward':
            return (
                <PayItForwardScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'charts':
            return (
                <ChartScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        case 'delta_declarations':
            return (
                <DiscernmentScreen
                    goToItineraryGroup={goToItineraryGroup}
                    currentEvent={currentEvent}
                    currentArea={currentArea}
                    baseUrl={baseUrl}
                />
            );
        default:
            return null;
    }
};

export default CurrentAreaScreenWithEventId;
