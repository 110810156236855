import React, { useState } from 'react';
import SubstopItem, { HundredHoursSubstopItem } from './SubstopItem';
import StopLetter from './StopLetter';
import {
    showLockedRestartModal,
    showAuditModal,
} from '../reducers/modalActions';
import { useDispatch } from 'react-redux';
import Button from './Button';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const StopItem = ({
    letter,
    title,
    points,
    disabled,
    sub_stops,
    is_locked,
    is_done,
    id,
    ...others
}) => {
    const [showSubstops, setShowSubstops] = useState(false);
    const dispatch = useDispatch();
    const enabledSubstops = Object.keys(sub_stops)
        .filter(s => sub_stops[s])
        .map(s => ({ ...others[s], type: s }));

    const showModal = substop => {
        dispatch(showLockedRestartModal(substop.type));
    };

    return (
        <li className={`d-block StopItem ${is_locked ? 'isLocked' : ''}`}>
            <Button
                transparent
                block
                onClick={() => setShowSubstops(!showSubstops)}
                style={{ marginTop: '1rem' }}
                className="ItineraryItem w-100 d-flex align-items-center py-3 text-body">
                <StopLetter
                    isLocked={is_locked}
                    letter={letter}
                    isDone={is_done}
                />
                <span className="mr-auto d-block pr-3 font-weight-bold">
                    {title}
                </span>

                <span
                    className="badge badge-pill p-2 text-white"
                    style={{ backgroundColor: '#121212' }}>
                    {points} pts
                </span>
            </Button>
            {showSubstops ? (
                <ul className="substop">
                    {enabledSubstops && enabledSubstops.length > 0
                        ? enabledSubstops.map((s, i) => (
                              <SubstopItem
                                  key={i}
                                  stopId={id}
                                  {...s}
                                  is_locked={is_locked}
                                  showLockModal={() => showModal(s)}
                                  totalPoints={points}
                                  dataviz={others.dataviz}
                              />
                          ))
                        : null}
                </ul>
            ) : null}
        </li>
    );
};

export const HundredHoursStopItem = ({
    letter,
    title,
    points,
    disabled,
    is_done,
    is_current,
    is_locked,
    id,
    duration,
    hundred_h: subStops,
    ...others
}) => {
    const [showSubstops, setShowSubstops] = useState(false);
    const [showEditImpact, setShowEditImpact] = useState(false);
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const showModal = substop => {
        console.log(substop.type);
        dispatch(showLockedRestartModal(substop.type));
    };

    const path = `/100h/projects/${projectId}`;

    const durationDisplay = moment.duration(duration, 'minutes').humanize();

    const handleClick = () => {
        dispatch(
            showAuditModal({
                next: path,
                projectId,
                stopId: id,
            }),
        );
    };

    const opts = {
        onMouseOver: () => {
            setShowEditImpact(true);
        },
        onMouseLeave: () => {
            setShowEditImpact(false);
        },
    };

    return (
        <li
            className={`d-block StopItem ${is_current ? 'is_current' : ''} ${
                is_done ? 'is_done' : ''
            }`}
            {...(is_done ? opts : {})}>
            <div
                className="ItineraryItem w-100 d-flex align-items-center px-4 py-2"
                style={{ marginTop: '1rem' }}>
                <Button
                    transparent
                    onClick={() => setShowSubstops(!showSubstops)}
                    className="d-flex align-items-center py-3 w-100 text-body">
                    <StopLetter
                        letter={letter}
                        isDone={is_done}
                        isCurrent={is_current}
                    />
                    <span className="mr-auto d-block pr-3 font-weight-bold">
                        {title}
                    </span>
                </Button>

                {showEditImpact && (
                    <Button
                        onClick={handleClick}
                        light
                        small
                        rounded
                        style={{ borderRadius: '1rem', color: '#276EF1' }}>
                        Edit&nbsp;Impact
                    </Button>
                )}
            </div>
            {showSubstops && subStops ? (
                <ul className="substop">
                    {(subStops || []).map((s, i) => (
                        <HundredHoursSubstopItem
                            key={i}
                            stopId={id}
                            {...s}
                            is_locked={is_locked}
                            showLockModal={() => showModal(s)}
                            totalPoints={points}
                        />
                    ))}
                </ul>
            ) : null}
        </li>
    );
};

export default StopItem;
