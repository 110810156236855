import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    getProject,
    updateProject,
    inviteMember,
} from 'reducers/hundredhoursActions';
import Loader from 'components/Loader';
import { Input, Textarea } from 'components/Inputs';
import ErrorMessageContainer from 'components/ErrorMessageContainer';
import Button from 'components/Button';
import setResponseErrors from 'utils/setResponseErrors';
import {
    RadioHorizontalV3,
    OpenTextV2,
} from '../../components/QuestionInputV2';
import ProjectTitle from '../../components/ProjectTitle';
import { showDeleteModal } from '../../reducers/modalActions';

const ProjectSchema = yup.object().shape({
    title: yup.string().required('This field is required'),
    description: yup.string().required('This field is required'),
});

const CollabSchema = yup.object().shape({
    email: yup
        .string()
        .email('Must input a valid email address')
        .required('This field is required'),
});

const OPTIONS = [
    { body: 1, value: 1 },
    { body: 2, value: 2 },
    { body: 3, value: 3 },
    { body: 4, value: 4 },
];

const initialValues = {
    title: '',
    description: '',
    workforce: 0,
    partners: 0,
    customers: 0,
    sales: 0,
    costs: 0,
    operations: 0,
};

const SetupScreen = () => {
    const [project, setProject] = useState(null);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    useEffect(() => {
        dispatch(getProject(params.projectId)).then(res => {
            setProject(res);
        }, console.warn);
    }, [params]);
    if (!project) return <Loader fullscreen />;
    const { members } = project;
    return (
        <Fragment>
            <ProjectTitle title={project.title} subtitle="Edit Project" />
            <Formik
                onSubmit={(
                    values,
                    { setSubmitting, setFieldErrorm, setStatus },
                ) => {
                    setSubmitting(true);
                    dispatch(updateProject(project.id, values)).then(
                        res => {
                            console.log(res);
                            setSubmitting(false);
                            setProject(res);
                            setStatus('Project details updated.');
                            setTimeout(() => setStatus(false), 5000);
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}
                initialValues={{ ...initialValues, ...project }}
                validationSchema={ProjectSchema}>
                {({ isSubmitting, status }) => (
                    <Form className="pt-5 mt-4">
                        <div className="mb-3">
                            <Field
                                component={OpenTextV2}
                                disabled={isSubmitting}
                                className="mb-1"
                                name="title"
                                label="Edit Project"
                                placeholder="Project Name"
                                max={400}
                            />
                            <ErrorMessage
                                component={ErrorMessageContainer}
                                name="title"
                            />
                            <Field
                                component={OpenTextV2}
                                disabled={isSubmitting}
                                name="description"
                                className="mb-1"
                                placeholder="Description"
                                max={400}
                            />
                            <ErrorMessage
                                component={ErrorMessageContainer}
                                name="description"
                            />
                        </div>
                        <h2 style={{ fontSize: '20px' }}>Impact Audit</h2>
                        <p style={{ fontSize: '14px' }} className="mb-4">
                            Rate the impact of this 100H Project on
                        </p>
                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            top
                            slider_left_label="Not Much"
                            slider_right_label="Very Much"
                            label="1. Customer Loyalty & Growth"
                            name="customers"
                            description="Bushwick swag fam, sustainable readymade subway tile copper mug taxidermy tattooed small batch four loko 3 wolf moon jean shorts vice mumblecore."
                        />
                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            label="2. Workforce Performance & Morale"
                            name="workforce"
                        />

                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            label="3. Operations & Cost Efficiencies"
                            name="costs"
                        />

                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            bottom
                            slider_left_label="Not Much"
                            slider_right_label="Very Much"
                            label="4. Revenue Growth"
                            name="sales"
                        />
                        <div className="d-flex mt-5 justify-content-end">
                            <Button
                                type="submit"
                                rounded
                                primary
                                disabled={isSubmitting}
                                isLoading={isSubmitting}>
                                Update
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Formik
                onSubmit={(
                    values,
                    {
                        setSubmitting,
                        setFieldError,
                        setStatus,
                        setValues,
                        setTouched,
                    },
                ) => {
                    setSubmitting(true);
                    dispatch(inviteMember(project.id, values)).then(
                        res => {
                            console.log(res);
                            setSubmitting(false);
                            setProject(res);
                            setStatus(`${values.email} added to project.`);
                            setValues({ email: '' }, false);
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}
                initialValues={{ email: '' }}
                validationSchema={CollabSchema}>
                {({ isSubmitting, status }) => (
                    <Form className="mt-6">
                        <div className="mb-6">
                            <h1 className="mb-0">Invite Collaborators</h1>
                            <p>
                                Invite members into the project. Use an online
                                collaboration tool to bring everyone together.
                            </p>
                        </div>
                        <div className="pt-3 mb-5">
                            <Field
                                component={Input}
                                disabled={isSubmitting}
                                className="mb-3"
                                name="email"
                                label="Email"
                            />
                            <ErrorMessage
                                component={ErrorMessageContainer}
                                name="email"
                            />
                            {status ? (
                                <div className="alert alert-success">
                                    {status}
                                </div>
                            ) : null}
                        </div>
                        <div className="d-flex mt-5">
                            <Button
                                type="submit"
                                className="ml-auto"
                                primary
                                rounded
                                disabled={isSubmitting}
                                isLoading={isSubmitting}>
                                Invite
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="bg-gray p-4 mb-6 mt-6">
                <strong className="mb-2 d-block">
                    <i className="fe fe-info" /> PRO TIP
                </strong>
                <p>
                    Pro Tip: Ideal team size depends on complexity of our 100H
                    Project - i.e., how many moving parts we have. On average,
                    these methods work best with 4-7 members per team. For more
                    complex projects, we've used these methods for as many as
                    25-30 members, all using the same itinerary, and later
                    breaking up into smaller, nimbler teams as focus areas
                    emerge.
                </p>
            </div>
            <h3>Members ({members && members.length})</h3>
            {members.map(member => {
                return (
                    <div
                        className="d-flex p-4 mb-4 align-items-center bg-gray"
                        key={member.id}>
                        <div>
                            {member.is_confirmed ? (
                                <div>
                                    <strong>{member.name}</strong>
                                    <br />
                                    <span className="text-muted">
                                        {member.email}
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <strong>{member.email}</strong>
                                    <br />
                                    <span className="text-muted">
                                        Pending invitation
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className="ml-auto d-flex align-items-center"
                            style={{ fontSize: '1.5rem' }}>
                            {member.is_confirmed ? (
                                <i className="fe fe-check-circle text-success" />
                            ) : (
                                <i className="fe fe-clock text-warning" />
                            )}
                            <Button
                                transparent
                                onClick={() => {
                                    dispatch(
                                        showDeleteModal({
                                            projectId: params.projectId,
                                            member,
                                        }),
                                    );
                                }}
                                style={{ fontSize: '1.5rem' }}
                                className="ml-3 p-0 text-danger">
                                <i className="fe fe-trash" />
                            </Button>
                        </div>
                    </div>
                );
            })}
            <hr />
            <div className="d-flex">
                <Link
                    to={`/100h/projects/${project.id}`}
                    className="btn btn-primary ml-auto">
                    Next
                </Link>
            </div>
        </Fragment>
    );
};

export default SetupScreen;
