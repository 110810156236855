import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Button from './Button';
import setResponseErrors from '../utils/setResponseErrors';
import ErrorMessageContainer from '../components/ErrorMessageContainer';

const CommentBoxInput = ({ field, id = '', disabled, rows = 1 }) => {
    const { onBlur, onChange, name, value } = field;
    const handleBlur = e => {
        onBlur(e);
    };

    return (
        <TextareaAutosize
            id={id || name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            placeholder="Write here"
            disabled={disabled}
            rows={rows}
            style={{ outline: 0 }}
            className="p-3"
        />
    );
};

const CommentBox = ({ reply, addComment }) => {
    return (
        <Formik
            initialValues={{ body: '' }}
            onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
                setSubmitting(true);
                reply(values).then(
                    res => {
                        addComment(res.comment);
                        setSubmitting(false);
                        resetForm();
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <div className="CommentBox d-flex align-items-stretch border-top py-3 px-4">
                        <div className="CommentBox-input mr-2">
                            <Field
                                component={CommentBoxInput}
                                name="body"
                                disabled={isSubmitting}
                            />
                        </div>
                        <Button
                            primary
                            type="submit"
                            className="ml-auto py-3"
                            small
                            style={{ alignSelf: 'flex-end' }}
                            isLoading={isSubmitting}
                            disabled={isSubmitting}>
                            Comment
                        </Button>
                    </div>
                    <ErrorMessage
                        component={ErrorMessageContainer}
                        name="body"
                    />
                </Form>
            )}
        </Formik>
    );
};

export default CommentBox;
