import * as yup from 'yup';

export const createDataForSave = (values, discernment_slug) => {
    const answers = Object.keys(values).map(question_key => {
        const allowFileUpload =
            values[question_key] && values[question_key].reference_materials;
        let answer = {
            question_key,
            values:
                typeof values[question_key] === 'string'
                    ? [values[question_key]]
                    : values[question_key],
            tags: [],
        };

        return allowFileUpload
            ? {
                  question_key,
                  tags: [],
                  values:
                      typeof values[question_key].values === 'string'
                          ? [values[question_key].values]
                          : values[question_key].values,
                  reference_materials: values[question_key].reference_materials,
              }
            : answer;
    });

    return {
        discernment_slug,
        answers,
    };
};

export const getInitialAnswer = (q, answers) => {
    const answer = answers
        ? answers.find(a => a.question_key === q.question_key)
        : null;

    switch (q.question_type) {
        case 'select':
            return answer ? answer.values : [];
        case 'slider':
            return answer ? answer.values : [0];
        case 'multi_text':
            return answer ? answer.values : [''];
        case 'radio':
        case 'horizontal_radio':
            return answer ? answer.values[0] : '';
        case 'file_upload':
            return answer &&
                answer.reference_materials &&
                answer.reference_materials.length
                ? {
                      reference_materials: answer.reference_materials,
                  }
                : {};
        case 'from_to':
            return answer ? answer.values : ['', ''];
        case 'open_text':
            if (q.allow_file_upload) {
                return answer
                    ? {
                          values: answer.values,
                          reference_materials: answer.reference_materials,
                      }
                    : {
                          values: '',
                          reference_materials: [],
                      };
            }
        default:
            return answer ? answer.values || [''] : '';
    }
};

export const createSchema = content => {
    let questionKeys = {};
    content.forEach(c => {
        questionKeys[c.question_key] = c.optional
            ? yup.mixed()
            : c.question_type === 'select'
            ? yup.array().min(1, 'Select at least 1 option.')
            : yup.string().required('This field is required.');
    });

    return yup.object().shape(questionKeys);
};

export const checkURLIfImg = url => url.match(/\.(jpeg|jpg|gif|png)$/) != null;
