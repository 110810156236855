const { gql } = require('@apollo/client');

const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            validLicense
            freeTrial
            licenseValidityDate
            firstName
        }
    }
`;

export default GET_CURRENT_USER;
