import React, { Fragment, useEffect } from 'react';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { Link, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEventGroups,
    FETCH_EVENT_GROUPS_SUCCESS,
} from '../../reducers/growthlabsActions';
import GrowthlabDetailScreen from './247GrowthlabDetailScreen';

const GrowthlabItem = ({ name, description, id, schedule, cover_image }) => (
    <Link to={`/growthlabs/${id}`} className="GrowthlabItem col-md-4 mb-4">
        <div
            className="GrowthlabItem--image mb-4"
            style={{ backgroundImage: `url("${cover_image}")` }}
        />
        <h2
            className="text-uppercase mb-2 font-weight-bold"
            style={{ fontSize: '1.1rem', color: '#12263f' }}>
            {name}
        </h2>
        <p className="schedule mb-2">{schedule}</p>
        <p className="description mb-4" style={{ color: '#12263f' }}>
            {description}
        </p>
        <span className="d-flex align-items-center">
            <span className="mr-2">See Schedules</span>
            <i className="fe fe-chevron-right" />
        </span>
    </Link>
);

const GrowthlabsScreen = () => {
    const dispatch = useDispatch();
    const { eventGroups } = useSelector(s => s.growthlabs);

    useEffect(() => {
        dispatch(getEventGroups()).then(d => {
            dispatch({ type: FETCH_EVENT_GROUPS_SUCCESS, payload: d });
        });
    }, []);

    return (
        <Switch>
            <Route path="/growthlabs/:id">
                <GrowthlabDetailScreen eventGroups={eventGroups} />
            </Route>
            <Route path="/growthlabs" exact>
                <div
                    className="Growthlabs--hero p-5 mb-5 text-white d-flex justify-content-center flex-column"
                    style={{
                        backgroundImage:
                            'url("/austin-distel-Hg3BHX6U5jg-unsplash.jpg")',
                    }}>
                    <h1>24/7 GrowthLabs</h1>
                    <p className="m-0" style={{ maxWidth: MAX_WIDTH }}>
                        Combine immersive media experiences with simple software
                        to pratice us in the optimal use of self-driven tools
                        like O/S 100 and 100H LAUNCHPAD.
                    </p>
                </div>
                <div
                    className="mx-auto mb-6 row align-items-stretch"
                    style={{ maxWidth: 900 }}>
                    {eventGroups.map((g, i) => (
                        <GrowthlabItem key={i} {...g} />
                    ))}
                </div>
            </Route>
        </Switch>
    );
};

export default GrowthlabsScreen;
