import React, { Fragment, useEffect, useRef, useState } from 'react';
import useFilterUserCard from '../hooks/useFilterUserCard';
import { MAX_WIDTH, PERSONAL_GROWTH } from '../utils/CONSTANTS';
import Button from './Button';
import { StackIcon } from './RaazIcons';
import Stack from './Stack';

const BY_DESC = (a, b) => b.ordinality - a.ordinality;
const BY_ASC = (a, b) => a.ordinality - b.ordinality;

const AllUserCards = ({ cards, title, user, goBack, desc }) => {
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [ascOrder, setAscOrder] = useState(false);
    const {
        setFilter,
        setStatus,
        personalGrowth,
        filteredCards,
        filter,
        statusFilter,
        typeOptions,
        statusOptions,
        filterFavorite,
        setFavoriteFilter,
    } = useFilterUserCard(cards || []);
    const node = useRef();
    const container = useRef();

    const hasFilter = filter.length || status.length || filterFavorite;
    const sortedCards = filteredCards.sort(ascOrder ? BY_ASC : BY_DESC);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        if (container && container.current) {
            window.scrollTo({ top: 600 });
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = e => {
        if (node.current && node.current.contains(e.target)) {
            return;
        }
        setShowFilterMenu(false);
    };

    return (
        <div style={{ backgroundColor: '#F7F7F7' }}>
            <div className="p-5 mx-auto" style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <h2
                    ref={container}
                    className="pb-0 w-100 d-flex align-items-center large-text">
                    <span className="lora">{title}</span>
                    <span
                        className="mx-4"
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#c4c4c4',
                        }}
                    />
                    <div>
                        <StackIcon fill="#276EF1" />
                        <span
                            className="ml-1"
                            style={{
                                fontSize: '14px',
                                color: '#717171',
                                fontWeight: '600',
                            }}>
                            {personalGrowth.filter(c => c.isClaimed).length} of{' '}
                            {personalGrowth.length} Cards
                        </span>
                    </div>
                    <div className="ml-auto position-relative">
                        <Button
                            transparent
                            className="d-flex align-items-center"
                            style={{ color: '#828282' }}
                            onClick={goBack}>
                            <span className="mr-2">See Less</span>
                            <i className="fe fe-chevron-up" />
                        </Button>
                    </div>
                </h2>
                <div className="d-flex">
                    <p
                        style={{
                            color: '#838383',
                            fontSize: '14px',
                            fontWeight: '600',
                            maxWidth: '760px',
                        }}>
                        {desc}
                    </p>
                    <div className="ml-auto position-relative">
                        <Button
                            transparent
                            onClick={() => setAscOrder(!ascOrder)}>
                            Order: {ascOrder ? 'Ascending' : 'Descending'}
                        </Button>
                    </div>
                    <div className="ml-2 position-relative" ref={node}>
                        <Button
                            className="d-flex align-items-center"
                            onClick={e => {
                                e.stopPropagation();
                                setShowFilterMenu(!showFilterMenu);
                            }}
                            transparent>
                            <span className="mr-2">Filter</span>
                            <i className="fe fe-filter position-relative">
                                {hasFilter && <span className="hasFilter" />}
                            </i>
                        </Button>
                        {showFilterMenu && (
                            <div
                                className="position-absolute px-3 py-4"
                                style={{
                                    backgroundColor: 'white',
                                    right: 0,
                                    top: '3rem',
                                    fontSize: '0.875rem',
                                    zIndex: 10,
                                    borderRadius: '8px',
                                    boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.1)`,
                                }}>
                                <div className="filter-options">
                                    <h2>Card Type</h2>
                                    {typeOptions.map((t, i) => (
                                        <div className="filter-option" key={i}>
                                            <input
                                                id={t.value}
                                                type="checkbox"
                                                name="filter"
                                                onChange={e =>
                                                    setFilter(e.target.value)
                                                }
                                                checked={filter.includes(
                                                    t.value,
                                                )}
                                                value={t.value}
                                            />
                                            <label htmlFor={t.value}>
                                                {t.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="filter-options">
                                    <h2>Status</h2>
                                    {statusOptions.map((t, i) => (
                                        <div className="filter-option" key={i}>
                                            <input
                                                id={t.value}
                                                type="checkbox"
                                                name="statusFilter"
                                                onChange={e =>
                                                    setStatus(e.target.value)
                                                }
                                                checked={statusFilter.includes(
                                                    t.value,
                                                )}
                                                value={t.value}
                                            />
                                            <label htmlFor={t.value}>
                                                {t.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="filter-options">
                                    <h2>Favorites</h2>
                                    <div className="filter-option">
                                        <input
                                            type="checkbox"
                                            id="favorite"
                                            checked={filterFavorite}
                                            onChange={() =>
                                                setFavoriteFilter(
                                                    !filterFavorite,
                                                )
                                            }
                                        />
                                        <label htmlFor="favorite">
                                            Favorites Only
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Stack
                    type={PERSONAL_GROWTH}
                    cards={sortedCards}
                    userId={user.id}
                />
            </div>
        </div>
    );
};

export default AllUserCards;
