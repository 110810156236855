import React from 'react';
import Button from './Button';

const Preamble = ({
    title,
    content,
    to,
    buttonTitle = 'Next',
    children,
    onClick,
}) => {
    return (
        <div className="Preamble p-5 mx-auto">
            <h1 className="lora">{title}</h1>
            <div className="mb-3">
                <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {children}
            </div>
            {to && (
                <Button to={to} primary small style={{ width: '180px' }}>
                    {buttonTitle}
                </Button>
            )}
            {onClick && (
                <Button
                    onClick={onClick}
                    primary
                    small
                    style={{ width: '180px' }}>
                    {buttonTitle}
                </Button>
            )}
        </div>
    );
};

export default Preamble;
