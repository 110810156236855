import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../../components/Button';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import { useHistory } from 'react-router-dom';
import {
    PRACTICE_QUESTION,
    PRACTICE_OPTIONS,
    FREQUENCY_OPTIONS,
    FREQUENCY_QUESTION,
    WORKFORCE_QUESTION,
    WORKFORCE_OPTIONS,
} from './GQOptions';
import { useDispatch } from 'react-redux';
import setResponseErrors from '../../utils/setResponseErrors';
import * as yup from 'yup';
import { postGrowthQuotient } from '../../reducers/itineraryActions';
import useWindowEvent from '../../hooks/useWindowEvent';
import AnimatedContainer from '../../components/AnimatedContainer';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import StepTracker from '../../components/StepTracker';
import { RadioV2 } from '../../components/QuestionInputV2';
import { useScroll } from '../../hooks/useScroll';

const QuestionSchema = yup.object().shape({
    practice: yup.number().required('Select at least one'),
    frequency: yup.number().when('practice', {
        is: val => val > 0,
        then: yup
            .number()
            .min(1, 'This field is required')
            .required('This field is required'),
        otherwise: yup.number(),
    }),
    workforce: yup.number().when('practice', {
        is: val => val > 0,
        then: yup
            .number()
            .min(1, 'This field is required')
            .required('This field is required'),
        otherwise: yup.number(),
    }),
});

const GQQuestion = ({
    goHome,
    gqSlug,
    title,
    next,
    eventId,
    initialValues,
    updateInitialValues,
    isLast,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <Formik
            validationSchema={QuestionSchema}
            initialValues={{
                practice: Number(initialValues.practice),
                frequency: Number(initialValues.frequency),
                workforce: Number(initialValues.workforce),
            }}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                let updatedValues = values;

                if (Number(values.practice) === 0) {
                    updatedValues.frequency = 0;
                    updatedValues.workforce = 0;
                }

                setSubmitting(true);
                dispatch(
                    postGrowthQuotient(eventId, {
                        [gqSlug]: updatedValues,
                    }),
                ).then(
                    res => {
                        updateInitialValues({ [gqSlug]: updatedValues });
                        setSubmitting(false);
                        history.push(next);
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {props => <InnerForm {...props} title={title} />}
        </Formik>
    );
};

const InnerForm = ({ values, isSubmitting, title }) => {
    const { offsets, scrollIntoView, setRef } = useScrollIntoView({
        dep: values.practice,
    });
    const [currentView, setCurrentView] = useState(0);
    const { scrollY } = useScroll();

    return (
        <Fragment>
            <StepTracker
                length={offsets.length}
                currentView={currentView}
                scrollIntoView={scrollIntoView}
            />
            <Form className="d-flex flex-column justify-content-center py-5">
                <div ref={setRef} className="px-5">
                    <AnimatedQuestion
                        scrollY={scrollY}
                        section={0}
                        nextSection={offsets[1]}
                        isLast={Number(values.practice) === 0}
                        name="practice"
                        label={PRACTICE_QUESTION}
                        options={PRACTICE_OPTIONS}
                        setCurrentView={setCurrentView}
                        isSubmitting={isSubmitting}
                        title={title}
                    />

                    {Number(values.practice) > 0 ? (
                        <Fragment>
                            <AnimatedQuestion
                                scrollY={scrollY}
                                section={1}
                                nextSection={offsets[2]}
                                name="frequency"
                                label={FREQUENCY_QUESTION}
                                options={FREQUENCY_OPTIONS}
                                setCurrentView={setCurrentView}
                                isSubmitting={isSubmitting}
                                title={title}
                            />
                            <AnimatedQuestion
                                scrollY={scrollY}
                                section={2}
                                name="workforce"
                                isLast
                                label={WORKFORCE_QUESTION}
                                options={WORKFORCE_OPTIONS}
                                setCurrentView={setCurrentView}
                                isSubmitting={isSubmitting}
                                title={title}
                            />
                        </Fragment>
                    ) : null}
                </div>
            </Form>
        </Fragment>
    );
};

const AnimatedQuestion = ({
    scrollY,
    section,
    setCurrentView,
    nextSection,
    name,
    label,
    options,
    isSubmitting,
    isLast,
    title,
}) => {
    const [height, setHeight] = useState('100vh');
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    const scroll = () =>
        window.scrollTo({ top: nextSection, behavior: 'smooth' });

    return (
        <AnimatedContainer
            scrollY={scrollY}
            section={section}
            setCurrentView={setCurrentView}>
            <div
                className="d-flex flex-column justify-content-center"
                style={{ minHeight: height }}>
                <h2 className="header-pretitle isRaaz mb-3 text-uppercase">
                    {title}
                </h2>
                <Field
                    name={name}
                    label={label}
                    component={RadioV2}
                    options={options}
                />
                <ErrorMessage name={name} component={ErrorMessageContainer} />
                {isLast ? (
                    <Button
                        type="submit"
                        primary
                        rounded
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        style={{ alignSelf: 'flex-start' }}>
                        Submit
                    </Button>
                ) : (
                    <Button
                        primary
                        rounded
                        onClick={scroll}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        style={{ alignSelf: 'flex-start' }}>
                        Next
                    </Button>
                )}
            </div>
        </AnimatedContainer>
    );
};

export default GQQuestion;
