import React from 'react';
import Button from './Button';
import moment from 'moment';

const Reply = ({
    user,
    time,
    children,
    currentUser,
    deleteComment,
    isDeleting,
}) => {
    const timestamp = moment(time).fromNow();
    return (
        <li className="comment mb-4" style={{ opacity: isDeleting ? 0.3 : 1 }}>
            <div className="row">
                <div className="col-auto">
                    <a className="avatar" href="#">
                        <img
                            src={user.avatar || '/icon.png'}
                            alt={`${user.name}'s avatar'`}
                            className="avatar-img rounded-circle"
                        />
                    </a>
                </div>
                <div className="col ml-n2">
                    <div className="comment-body px-5 py-4 w-100">
                        <div className="row">
                            <div className="col">
                                <strong className="comment-title">
                                    {user.name}
                                </strong>
                            </div>
                            <div className="col-auto">
                                <time className="comment-time">
                                    {timestamp}
                                </time>
                            </div>
                        </div>

                        <p
                            className="comment-text"
                            dangerouslySetInnerHTML={{ __html: children }}
                        />
                    </div>

                    {user.id === currentUser.id ? (
                        <div className="comment-actions py-3">
                            {/*<Button small transparent>
                                Edit
                            </Button>*/}
                            <Button small transparent onClick={deleteComment}>
                                Delete
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </li>
    );
};

export default Reply;
