import React from 'react';
import { CurrentStaticStep } from './100HCurrentStep';
import Button from './Button';
import { getTypeSlug } from './ProjectStack';
import { NextBadge } from './StackItem';

const ProjectUserCard = ({
    title,
    description,
    id,
    members,
    currentStop = {},
    currentSubstop,
    stops,
}) => {
    const stopData = (stops || []).find(s => s.id === currentStop.id);
    const to = `/100h/projects/${id}/hurdles/${currentStop.id}/${getTypeSlug(
        currentSubstop,
    )}/${stopData.discernment.id}`;
    return (
        <div className="StackItem--container alt mb-3">
            <NextBadge />
            <div className="StackItem">
                <div className="StackItem--details px-4 pt-5 pb-4 d-flex flex-column">
                    <h3 className="mb-3 mt-3" style={{ fontWeight: 'bold' }}>
                        {title}
                    </h3>
                    <p className="mb-0" style={{ fontSize: '14px' }}>
                        {description}
                    </p>
                    <h4
                        className="text-uppercase mt-auto"
                        style={{ fontSize: '14px', fontWeight: '600' }}>
                        Members ({members.length})
                    </h4>
                    {members.length <= 6 && (
                        <div className="ProjectStack--members">
                            {members.map((_, i) => (
                                <img
                                    src="./icon.png"
                                    className="avatar avatar-sm"
                                    key={i}
                                />
                            ))}
                        </div>
                    )}
                    {members.length > 6 && (
                        <div className="ProjectStack--members">
                            <div className="avatar-container">
                                <img
                                    src="./icon.png"
                                    className="avatar avatar-sm"
                                />
                            </div>
                            {members.slice(0, 5).map((m, i) => (
                                <img
                                    src={m.avatar || './icon.png'}
                                    className="avatar avatar-sm"
                                    key={i}
                                />
                            ))}
                        </div>
                    )}

                    <div className="ProjectStack--substop w-100 p-3 mt-3 d-flex align-items-start flex-column justify-content-center">
                        <div
                            className="d-flex align-items-center"
                            style={{ color: '#121212' }}>
                            <span
                                style={{
                                    color: 'white',
                                    width: '2rem',
                                    height: '2rem',
                                    overflow: 'hidden',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#121212',
                                    flexShrink: 0,
                                }}>
                                {currentStop.ordinality}
                            </span>
                            <span
                                className="ml-3"
                                style={{ fontWeight: '600' }}>
                                {currentStop.name}
                            </span>
                        </div>
                        <CurrentStaticStep
                            active={currentSubstop}
                            projectId=""
                            stopId=""
                            soloWorkoutId=""
                        />
                        <div className="d-flex w-100">
                            <Button
                                to={to}
                                primary
                                rounded
                                style={{ borderRadius: '2px' }}
                                className="mt-3 d-flex align-items-center text-center justify-content-center ml-auto">
                                <span className="mr-2">Continue</span>
                                <i className="fe fe-arrow-right" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectUserCard;
