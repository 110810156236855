import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, Link } from 'react-router-dom';
import { getEventDetails } from '../reducers/itineraryActions';
import Loader from './Loader';
import { MAX_WIDTH } from '../utils/CONSTANTS';

const ItineraryItem = ({ letter, title, slug, disabled }) => {
    const location = useLocation();
    return (
        <li className="d-block">
            <Link
                to={`${location.pathname}/${slug}`}
                className="ItineraryItem d-flex align-items-center py-3 text-body"
                disabled={disabled}>
                <span
                    className="d-flex align-items-center justify-content-center mr-3 small"
                    style={{
                        border: '2px solid #F4F4F4',
                        borderRadius: '50%',
                        width: '2rem',
                        height: '2rem',
                    }}>
                    {letter}
                </span>
                <span className="mr-auto d-block pr-3">{title}</span>
                <i
                    className={`fe fe-${
                        disabled ? 'lock' : 'chevron-right'
                    } ml-auto`}
                    style={{ color: '#BFBFC1' }}
                />
            </Link>
        </li>
    );
};

const IggyJrItinerary = ({ items }) => {
    return (
        <div
            className="bg-white px-5 pt-5 pb-2 d-flex flex-column"
            style={{
                borderTopLeftRadius: '1.5rem',
                borderTopRightRadius: '1.5rem',
            }}>
            <h2>Itinerary</h2>
            <ul className="list-unstyled">
                {!items ? (
                    <Loader />
                ) : (
                    items
                        .sort((a, b) => a.ordinality - b.ordinality)
                        .filter(i => i.visible)
                        .map(item => (
                            <ItineraryItem
                                key={item.id}
                                title={item.title}
                                letter={item.letter}
                                slug={item.slug}
                                desc={item.description}
                                disabled={!item.active}
                            />
                        ))
                )}
            </ul>
            <p
                className="text-uppercase text-center text-black-50"
                style={{ fontSize: '0.5rem' }}>
                Iggy by Ignite House 2020
            </p>
        </div>
    );
};

const IggyJrDashboard = ({ goHome }) => {
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        dispatch(getEventDetails(eventId)).then(d => setEvent(d));
    }, [eventId]);

    if (!event) {
        return <Loader fullscreen />;
    }

    console.log(event);

    return (
        <div className="w-100" style={{ minHeight: '100vh' }}>
            <div
                className="d-flex align-items-center IggyJrCover"
                style={{
                    height: '384px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: event.cover_image
                        ? `url('${event.cover_image}')`
                        : 'radial-gradient(circle at 26% 34%, #313131, #121212 36%)',
                }}>
                <div
                    className="mx-auto d-flex flex-column w-100 px-5"
                    style={{ maxWidth: MAX_WIDTH, zIndex: 2 }}>
                    <span
                        className="mb-2"
                        style={{
                            fontSize: '14px',
                            color: 'rgba(235, 235, 245, 0.6)',
                        }}>
                        Welcome to 24/7 GROWTHLABS
                    </span>
                    <h1
                        className="mb-3 large-text"
                        style={{
                            color: 'white',
                        }}
                        dangerouslySetInnerHTML={{ __html: event.name }}
                    />
                    <span
                        style={{
                            fontSize: '18px',
                            color: 'rgba(235, 235, 245, 0.6)',
                        }}>
                        Please accompany us using the step-by-step itinerary.
                    </span>
                </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: MAX_WIDTH }}>
                <IggyJrItinerary items={event.app_areas} />
            </div>
        </div>
    );
};

export default IggyJrDashboard;
