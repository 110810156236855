import {
    FETCH_EVENT_GROUPS_SUCCESS,
    ADD_EVENT_GROUP,
} from './growthlabsActions';

const initialState = {
    eventGroups: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENT_GROUPS_SUCCESS:
            return {
                ...state,
                eventGroups: action.payload,
            };
        case ADD_EVENT_GROUP:
            return {
                ...state,
                eventGroups: state.eventGroups.concat(action.payload),
            };
        default:
            return state;
    }
};

export default reducer;
