import React from 'react';
import { Link } from 'react-router-dom';
import getKeyOfFirstTrue from '../utils/getKeyOfFirstTrue';
import Loader from './Loader';

const Button = ({
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    light,
    dark,
    white,
    transparent,
    block,
    large,
    small,
    className = '',
    children,
    type = 'button',
    outline,
    disabled,
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseOver = () => {},
    onMouseLeave = () => {},
    onMouseOut = () => {},
    to = '',
    href = '',
    rounded = false,
    isLoading = false,
    style = {},
    smallLoader,
    target = '_blank',
}) => {
    const btnTypes = {
        primary,
        secondary,
        success,
        danger,
        warning,
        info,
        light,
        dark,
        white,
        transparent,
    };

    const btnType = getKeyOfFirstTrue(btnTypes);
    const c = [
        'btn',
        `btn-${outline ? 'outline-' : ''}${btnType || 'default'}`,
        !rounded ? 'rounded-0' : '',
        className || 'default',
        block ? 'btn-block' : '',
        large || small ? `btn-${large ? 'lg' : 'sm'}` : '',
    ].join(' ');

    return to ? (
        <Link to={to} className={c} style={style}>
            {isLoading ? (
                <Loader className={smallLoader ? 'spinner-border-sm' : ''} />
            ) : (
                children
            )}
        </Link>
    ) : href ? (
        <a
            className={c}
            type={type}
            href={href}
            disabled={disabled}
            style={style}
            target={target}>
            {isLoading ? (
                <Loader className={smallLoader ? 'spinner-border-sm' : ''} />
            ) : (
                children
            )}
        </a>
    ) : (
        <button
            onMouseEnter={onMouseEnter}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onMouseOut={onMouseOut}
            className={c}
            type={type}
            onClick={onClick}
            disabled={disabled}
            style={style}>
            {isLoading ? (
                <Loader className={smallLoader ? 'spinner-border-sm' : ''} />
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
