import React, { useCallback, useState, Fragment } from 'react';
import useWindowEvent from '../hooks/useWindowEvent';
import Loader from './Loader';
export const Iframe = ({ src }) => {
    const [height, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const callback = useCallback(() => {
        setHeight(`${window.innerHeight - 200}px`);
        setIsLoading(false);
    }, []);
    useWindowEvent('resize', callback);

    return (
        <Fragment>
            {isLoading && <Loader />}
            <iframe
                src={src}
                className="border-0"
                width="100%"
                height={height}
                onLoad={callback}
            />
        </Fragment>
    );
};

export default Iframe;
