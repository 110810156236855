import React, { useEffect, Fragment } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { GiftboxTitle } from '../components/ProjectTitle';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { useParams, Route, Switch } from 'react-router-dom';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { Workout } from './RAAZ/SoloWorkoutScreen';
import Attendance from '../components/Attendance';
import BreakoutGroups from '../components/BreakoutGroups';
import { CardBadge, GLBadge } from '../components/StackItem';
import { GoogleMeetsLogo } from '../components/RaazIcons';
import StopLetter from '../components/StopLetter';
import AttendanceButton from '../components/AttendanceButton';
import { DataVisualization } from '../screens/ChartScreen';

const GET_APP_AREAS = gql`
    query GetAppAreas($eventId: ID!, $userId: ID) {
        appAreas(eventId: $eventId, userId: $userId) {
            id
            done
            description
            slug
            title
            type
            active
            visible
            letter
            discernment {
                id
            }
            dataVisualization {
                id
                reports(userId: $userId)
            }
        }
        event(id: $eventId) {
            name
            isAttendee(userId: $userId)
            isCurrent
            webcastUrl
        }
    }
`;

const APP_AREA_SUBSCRIPTION = gql`
    subscription AppAreaSubscription($eventId: ID!) {
        appAreaToggled(eventId: $eventId) {
            id
            active
            visible
        }
    }
`;

const AppArea = ({
    id,
    next,
    userId,
    eventId,
    type,
    discernment,
    dataVisualization,
}) => {
    switch (type) {
        case 'discernment':
            return (
                <Workout
                    next={next}
                    discernmentId={discernment.id}
                    eventId={eventId}
                />
            );
        case 'attendance':
            return <Attendance next={next} userId={userId} eventId={eventId} />;
        case 'breakout_groups':
            return (
                <BreakoutGroups next={next} userId={userId} eventId={eventId} />
            );
        case 'charts':
            return <DataVisualization reports={dataVisualization.reports} />;
        default:
            return <h1>{type}</h1>;
    }
};

const createUrl = (eventId, id) => `/growthlab-app/${eventId}/app-area/${id}`;

const GrowthlabAppScreen = ({ userId }) => {
    const { eventId } = useParams();
    const r = useQuery(GET_APP_AREAS, {
        variables: {
            eventId,
            userId,
        },
    });

    const sub = useSubscription(APP_AREA_SUBSCRIPTION, {
        variables: {
            eventId,
        },
    });

    useEffect(() => {
        console.log(sub);
    }, [sub]);

    const { data, loading } = r;
    if (loading) return <Loader fullscreen />;

    const { appAreas, event } = data;
    const attendance = appAreas.find(({ type }) => type === 'attendance');
    const breakoutGrp = appAreas.find(({ type }) => type === 'breakout_groups');

    return (
        <Switch>
            {appAreas.map((appArea, i) => (
                <Route
                    key={i}
                    path={`/growthlab-app/:eventId/app-area/${appArea.id}`}>
                    <GiftboxTitle
                        key="inner-title"
                        title={event.name}
                        subtitle="Growthlabs"
                        backToItinerary={`/growthlab-app/${eventId}`}
                    />
                    <div
                        className="py-5 px-3 mx-auto mt-5"
                        style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                        <div className="py-5">
                            <AppArea
                                {...appArea}
                                eventId={eventId}
                                userId={userId}
                                next={
                                    appAreas.length - 1 === i
                                        ? '/'
                                        : appArea.type === 'attendance'
                                        ? createUrl(eventId, breakoutGrp.id)
                                        : `/growthlab-app/${eventId}`
                                }
                            />
                        </div>
                    </div>
                </Route>
            ))}
            <Route path="/growthlab-app/:eventId" exact>
                <Fragment>
                    <GiftboxTitle
                        key="personal-growth"
                        title="Personal Growth"
                        subtitle="Growthlabs"
                    />
                    <div
                        style={{
                            backgroundColor: '#F7F7F7',
                            paddingTop: '10rem',
                        }}
                        className="pb-5 d-flex">
                        <div
                            className="d-flex flex-column align-items-start mx-auto w-100 px-5"
                            style={{ maxWidth: MAX_WIDTH }}>
                            <GLBadge className="d-inline-block mb-1" />
                            <h1 className="mb-3">{event.name}</h1>
                            <div className="d-flex align-items-center">
                                <Button
                                    href={
                                        !event.isCurrent
                                            ? null
                                            : event.webcastUrl
                                    }
                                    primary
                                    rounded
                                    disabled={!event.isCurrent}
                                    className="d-flex align-items-center mr-3"
                                    style={{
                                        borderRadius: '2px',
                                        backgroundColor: '#E3EAF7',
                                        borderColor: '#E3EAF7',
                                        color: '#276EF1',
                                    }}>
                                    <span className="mr-2">Join Webcast</span>
                                    <GoogleMeetsLogo fill="#276EF1" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="mx-auto d-flex flex-column align-items-stretch p-5"
                        style={{ maxWidth: MAX_WIDTH }}>
                        <h2>Itinerary</h2>
                        <ul className="unstyled-list p-0">
                            {appAreas
                                .filter(
                                    ({ type, visible }) =>
                                        type !== 'attendance' && visible,
                                )
                                .map((appArea, i) => (
                                    <li
                                        className="d-block ItineraryListItem"
                                        key={i}>
                                        <Button
                                            transparent
                                            block
                                            to={
                                                !appArea.active
                                                    ? ''
                                                    : `/growthlab-app/${eventId}/app-area/${
                                                          appArea.type ===
                                                          'breakout_groups'
                                                              ? !event.isAttendee
                                                                  ? attendance.id
                                                                  : appArea.id
                                                              : appArea.id
                                                      }`
                                            }
                                            style={
                                                i !== 0
                                                    ? { marginTop: '1rem' }
                                                    : {}
                                            }
                                            className="ItineraryItem w-100 d-flex align-items-center py-3 text-body">
                                            <StopLetter
                                                isLocked={!appArea.active}
                                                letter={appArea.letter}
                                                isDone={appArea.done}
                                            />
                                            <span className="mr-auto d-block pr-3 font-weight-bold">
                                                {appArea.title}
                                            </span>
                                            {appArea.active ? (
                                                <i
                                                    className="fe fe-chevron-right"
                                                    style={{
                                                        fontSize: '1.5rem',
                                                    }}
                                                />
                                            ) : (
                                                <i
                                                    className="fe fe-lock"
                                                    style={{
                                                        fontSize: '1.5rem',
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Fragment>
            </Route>
        </Switch>
    );
};

export default GrowthlabAppScreen;
