import React, { useRef, useEffect, useState } from 'react';
const AnimatedContainer = ({ scrollY, children, setCurrentView, ...props }) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const { top, bottom, height } = ref.current.getBoundingClientRect();
        const offset = height / 1.9;
        const isVisible =
            top + offset >= 0 && bottom - offset <= window.innerHeight;
        setIsVisible(isVisible);
        isVisible && setCurrentView(props.section);
    }, [scrollY]);
    return (
        <div
            className={`animated ${isVisible ? 'fadeIn' : 'fadeOut'}`}
            ref={ref}
            {...props}>
            {children}
        </div>
    );
};

export default AnimatedContainer;
