import React, { Fragment, useEffect, useState } from 'react';
import {
    Switch,
    Route,
    useHistory,
    useRouteMatch,
    Link,
    useLocation,
} from 'react-router-dom';
import RaazHeader from '../components/RaazHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getStops, FETCH_STOPS_SUCCESS } from '../reducers/stopsActions';
import { UPDATE_USER_SUCCESSFUL, LOGOUT } from '../reducers/usersActions';
import SoloWorkoutScreen from './RAAZ/SoloWorkoutScreen';
import ScrollToTop from '../components/ScrollToTop';
import HundredHScreen from './100HScreen';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import ProfileScreen from './ProfileScreen';
import NewDashboard from './NewDashboard';
import GiftboxCollectionScreen from './GiftboxCollectionScreen';
import GiftboxScreen from './GiftboxScreen';
import BuddyWorkoutScreen from './BuddyWorkoutScreen';
import GrowthlabCardScreen from './GrowthlabCardScreen';
import PostcardScreen from './PostcardScreen';
import GrowthlabAppScreen from './GrowthlabAppScreen';
import GrowthlabsScreen from './RAAZ/247GrowthlabsScreen';
import GrowthlabDetailScreen from './RAAZ/247GrowthlabDetailScreen';
import GET_CURRENT_USER from './queries/getCurrentUser';
import { useQuery } from '@apollo/client';
import Loader from '../components/Loader';

const RaazScreen = () => {
    const { data, loading } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-and-network',
    });
    const { isExact } = useRouteMatch('/');
    const dispatch = useDispatch();

    const isGrowthlabApp = useRouteMatch('/growthlab-app');
    const isGrowthlabCard = useRouteMatch('/growthlab-card');
    const isMastery = useRouteMatch('/mastery');
    const isSubstop = useRouteMatch('/100h/projects/:projectId/hurdles');
    const isNewProject = useRouteMatch('/100h/projects/new');
    const isGiftBox = useRouteMatch('/giftbox');
    const isBuddyWorkout = useRouteMatch('/buddy-workout');
    const isPostcard = useRouteMatch('/post-card');

    const showMainNav =
        !isPostcard &&
        !isGrowthlabApp &&
        !isGrowthlabCard &&
        !isMastery &&
        !isSubstop &&
        !isNewProject &&
        !isGiftBox &&
        !isBuddyWorkout;

    useEffect(() => {
        if (!loading && data && data.currentUser === null) {
            dispatch({ type: LOGOUT });
        }
    }, [data, loading]);

    if (loading || !(data && data.currentUser)) return <Loader fullscreen />;

    const { currentUser } = data;

    return (
        <div
            className="isRaazDashboard d-flex flex-column w-100"
            style={{ minHeight: '100vh' }}>
            <ScrollToTop />
            {showMainNav && (
                <RaazHeader
                    user={currentUser}
                    isIggyJr={isExact}
                    isIggyDashboard={isExact}
                    showNavButtons={!isExact}
                />
            )}
            <div className="mx-auto w-100">
                <Switch>
                    <Route path="/growthlabs">
                        <GrowthlabsScreen />
                    </Route>
                    <Route path="/growthlab-app/:eventId">
                        <GrowthlabAppScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/growthlab-card/:id">
                        <GrowthlabCardScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/post-card/:id">
                        <PostcardScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/mastery/:id">
                        <SoloWorkoutScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/100h">
                        <HundredHScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/buddy-workout/:id">
                        <BuddyWorkoutScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/giftbox/:userCardId">
                        <GiftboxScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/giftbox-collection">
                        <GiftboxCollectionScreen
                            userId={currentUser ? currentUser.id : ''}
                        />
                    </Route>
                    <Route path="/profile">
                        <ProfileScreen />
                    </Route>
                    <Route path="/" exact>
                        <NewDashboard user={data.currentUser} />
                    </Route>
                </Switch>
            </div>
            {!isSubstop && !isNewProject ? (
                <footer
                    className="py-5 px-4 DashboardFooter mt-auto"
                    style={{ backgroundColor: '#f7f7f7' }}>
                    <div className="d-flex align-items-center w-100">
                        <small className="mr-auto">
                            © IGNITE 2020. RESTART. ALL RIGHTS RESERVED
                        </small>
                        <Link className="mr-4" to="/terms-and-conditions">
                            Terms and Conditions
                        </Link>
                        <Link className="mr-4" to="/privacy-policy">
                            Privacy Policy
                        </Link>
                    </div>
                </footer>
            ) : null}
        </div>
    );
};

export default RaazScreen;
