import {
    FETCH_PROJECTS_SUCCESS,
    ADD_PROJECT,
} from './hundredhoursActions';

const initialState = {
    projects: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload,
            };
        case ADD_PROJECT:
            return {
                ...state,
                projects: state.projects.concat(action.payload),
            };
        default:
            return state;
    }
};

export default reducer;
