import { ApolloLink } from 'apollo-link';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from './components/Provider';
import { ActionCableLink } from 'graphql-ruby-client';
import { createConsumer } from '@rails/actioncable';

function getWebSocketURL() {
    const s = store.getState();
    const token = s ? s.app.auth_token : '';
    return `/cable?token=${token}`;
}

const cable = createConsumer(getWebSocketURL);

const httpLink = createHttpLink({
    uri: '/graphql',
    credentials: 'include',
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
        ({ kind, operation }) =>
            kind === 'OperationDefinition' && operation === 'subscription',
    );
};

const link = ApolloLink.split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    httpLink,
);

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const s = store.getState();
    const token = s ? s.app.auth_token : '';
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
});
