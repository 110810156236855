import React, { useState, useEffect, Fragment, useRef } from 'react';
import Modal from './Modal';
import Button from './Button';
import Reply from './Reply';
import CommentBox from './CommentBox';
import { StarIcon } from './RaazIcons';
import { useDispatch, useSelector } from 'react-redux';
import {
    markAsFavorite,
    addComment,
    unmarkAsFavorite,
    deleteComment,
} from '../reducers/itineraryActions';
import Loader from './Loader';
import moment from 'moment';
import { hideModal } from '../reducers/modalActions';
import { useHistory } from 'react-router-dom';
import { UPDATE_GROUP_ANSWER } from '../reducers/peerExchangeActions';
import { checkURLIfImg } from '../utils/answerUtils';
import MaterialLink from './MaterialLink';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';

const PeerExchangeModal = ({ details: passedDetails }) => {
    const defaultDetails = {
        answer: [],
        answerId: '',
        question: '',
        user: {},
        isFavorite: false,
        comments: [],
        editAnswerLink: '',
        project: {},
    };

    const [details, setDetails] = useState({
        ...defaultDetails,
        ...passedDetails,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState('');
    const [comments, setComments] = useState(details.comments);
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector(s => s.users);
    const history = useHistory();
    const ref = useRef(null);

    const markAsIterated = () => {
        setIsSubmitting(true);
        dispatch(markAsFavorite(details.answerId)).then(res => {
            setDetails({ ...details, isFavorite: true });
            dispatch({
                type: UPDATE_GROUP_ANSWER,
                payload: res,
            });
        });
    };

    const unmarkAsIterated = () => {
        setIsSubmitting(true);
        dispatch(unmarkAsFavorite(details.answerId)).then(res => {
            setDetails({ ...details, isFavorite: false });
            dispatch({
                type: UPDATE_GROUP_ANSWER,
                payload: res,
            });
        });
    };

    const reply = comment => dispatch(addComment(details.answerId, comment));
    const delComment = commentId => {
        setIsDeleting(commentId);
        const filteredComments = comments.filter(c => c.id !== commentId);
        dispatch(deleteComment(commentId)).then(
            res => {
                setIsDeleting('');
                setComments(filteredComments);
                dispatch({
                    type: UPDATE_GROUP_ANSWER,
                    payload: {
                        answer: {
                            id: passedDetails.answerId,
                            project_id: passedDetails.project.id,
                            discernment_id: passedDetails.soloWorkoutId,
                            question_key: passedDetails.questionKey,
                            comments: filteredComments,
                        },
                    },
                });
            },
            err => setIsDeleting(''),
        );
    };

    const updateComment = c => {
        setComments(comments.concat(c));
        dispatch({
            type: UPDATE_GROUP_ANSWER,
            payload: {
                answer: {
                    id: passedDetails.answerId,
                    project_id: passedDetails.project.id,
                    discernment_id: passedDetails.soloWorkoutId,
                    question_key: passedDetails.questionKey,
                    comments: comments.concat(c),
                },
            },
        });
    };

    const goToEditAnswer = () => {
        dispatch(hideModal());
        history.push(details.editAnswerLink);
    };

    const isDefaultAvatar =
        details.user &&
        details.user.avatar &&
        details.user.avatar === '/images/user-icon.png';

    const [firstName, lastName] =
        details.user && details.user.name ? details.user.name.split(' ') : [''];

    return (
        <Modal centered floatingCloseBtn>
            <div
                ref={ref}
                className="PeerExchangeAnswer-detail"
                style={{
                    maxHeight: '80vh',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}>
                <div className="MainAnswer p-5">
                    <div className="d-flex align-items-center mb-3">
                        <a className="avatar avatar-sm" href="#">
                            {isDefaultAvatar ? (
                                <div
                                    className="d-flex align-items-center justify-content-center text-center"
                                    style={{
                                        backgroundColor: getDefaultAvatarColors(
                                            details.user.name,
                                        ),
                                        color: 'white',
                                        fontWeight: 'bold',
                                        width: '2.5rem',
                                        height: '2.5rem',
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                    }}>
                                    {details.user.name ? firstName[0] : ''}
                                    {details.user.name ? lastName[0] : ''}
                                </div>
                            ) : (
                                <img
                                    src={details.user.avatar || '/icon.png'}
                                    alt="..."
                                    className="avatar-img rounded-circle"
                                />
                            )}
                        </a>
                        <span className="ml-2">{details.user.name}</span>
                    </div>
                    <div className="py-3">
                        <p
                            style={{
                                fontSize: '0.875rem',
                                fontWeight: 'bold',
                            }}>
                            {details.question}
                        </p>
                        {details.answer && (
                            <p
                                style={{ fontSize: '1.125rem' }}
                                dangerouslySetInnerHTML={{
                                    __html: details.answer,
                                }}
                            />
                        )}

                        {details.referenceMaterials &&
                        details.referenceMaterials.length ? (
                            <div
                                className="d-flex w-100"
                                style={{ overflowX: 'scroll' }}>
                                {details.referenceMaterials.map(
                                    (material, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="p-1"
                                                style={{
                                                    width: '150px',
                                                    flexShrink: 0,
                                                }}>
                                                {checkURLIfImg(
                                                    material.url,
                                                ) && (
                                                    <img
                                                        src={material.url}
                                                        className="w-100 mb-2"
                                                        style={{
                                                            maxWidth: '100%',
                                                        }}
                                                    />
                                                )}
                                                <a
                                                    style={{
                                                        fontSize: '11px',
                                                        color:
                                                            'rgba(60, 60, 67, 0.7)',
                                                    }}
                                                    href={material.url}
                                                    className="d-block"
                                                    target="_blank">
                                                    Attachment {material.id}
                                                </a>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="comment-actions py-3 px-4">
                    {details.user.id === currentUser.id ? (
                        <Button small transparent onClick={goToEditAnswer}>
                            Edit
                        </Button>
                    ) : null}
                    {details.project.owner_id === currentUser.id ? (
                        <Button
                            onClick={
                                details.isFavorite
                                    ? unmarkAsIterated
                                    : markAsIterated
                            }
                            small
                            transparent
                            className="d-flex align-items-center">
                            {details.isFavorite && <StarIcon />}
                            <span className="ml-1">Iterated Workout</span>
                        </Button>
                    ) : (
                        details.isFavorite && (
                            <Button
                                onClick={() => {}}
                                small
                                transparent
                                className="d-flex align-items-center">
                                <StarIcon />
                                <span className="ml-1">Iterated Workout</span>
                            </Button>
                        )
                    )}
                    <Button
                        small
                        transparent
                        onClick={() => {
                            ref.current &&
                                ref.current.scroll({
                                    top: ref.current.scrollHeight,
                                    behavior: 'smooth',
                                });
                        }}
                        className="d-flex align-items-center">
                        <span className="mr-1">
                            Comments ({comments.length})
                        </span>
                    </Button>
                </div>

                {isLoading ? (
                    <div className="py-3 px-4 w-100 justify-content-center">
                        <Loader />
                    </div>
                ) : (
                    <ul className="PeerExchange-replies py-3 px-4">
                        {comments
                            .sort((a, b) =>
                                moment(b.created_at).diff(a.created_at),
                            )
                            .map(({ user, body, created_at, id }, i) => (
                                <Reply
                                    isDeleting={isDeleting === id}
                                    key={i}
                                    deleteComment={() => delComment(id)}
                                    user={user}
                                    time={created_at}
                                    currentUser={currentUser}>
                                    {body}
                                </Reply>
                            ))}
                    </ul>
                )}
                <CommentBox reply={reply} addComment={updateComment} />
            </div>
        </Modal>
    );
};

export default PeerExchangeModal;
