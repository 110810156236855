import React from 'react';

const StopLetter = ({ isCurrent, letter, isDone }) => (
    <span className={`StopLetter mr-4 ${isCurrent ? 'is_current' : ''}`}>
        <span
            className="d-flex align-items-center justify-content-center"
            style={{
                border:
                    isDone || isCurrent
                        ? '2px solid #121212'
                        : '1px solid #f7f7f7',
                borderRadius: '50%',
                width: '2rem',
                height: '2rem',
                fontSize: '0.6875rem',
                ...(isDone || isCurrent
                    ? {
                          backgroundColor: isDone ? 'white' : '#121212',
                          color: isDone ? '#121212' : 'white',
                      }
                    : {}),
            }}>
            {isDone ? (
                <i className="fe fe-check" style={{ fontSize: '1rem' }} />
            ) : (
                letter
            )}
        </span>
    </span>
);

export default StopLetter;
