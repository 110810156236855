import React, { Fragment, useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import toBase64 from '../utils/toBase64';
import Button from './Button';
import { useDispatch } from 'react-redux';
import { showCheckboxModal, UPDATE_MODAL } from '../reducers/modalActions';

const getFileName = f => {
    const arr = f.url.split('/');
    const fileName = arr[arr.length - 1];
    return fileName;
};

const OptionalText = () => (
    <span className="StackItem--badge is-workout d-inline-block text-uppercase font-weight-bold mb-2">
        Optional
    </span>
);

export const OpenTextV2 = ({
    field,
    id = '',
    label = '',
    disabled,
    rows = 1,
    max = 360,
    placeholder = 'Write here',
    allow_file_upload,
    form,
    showSampleAnswer,
    required,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasFile =
        value.reference_materials && value.reference_materials.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (
            hasFile &&
            value.reference_materials[0] &&
            value.reference_materials[0].url
        ) {
            setFileName(getFileName(value.reference_materials[0]));
        }
    }, [hasFile, value.reference_materials]);

    const charCount = allow_file_upload
        ? value.values && Array.isArray(value.values)
            ? value.values[0]
                ? value.values[0].length
                : 0
            : value.values.length
        : Array.isArray(value)
        ? value[0].length
        : value.length;

    return (
        <div
            className={`QuestionInput v2 qi-textarea w-100 mb-2 ${
                isFocused ? 'isFocused' : ''
            }`}>
            {/* {!required && <OptionalText />} */}
            <label
                className="SoloWorkout--label"
                htmlFor={id || name}
                dangerouslySetInnerHTML={{ __html: label }}
            />
            {showSampleAnswer && (
                <Fragment>
                    <br />
                    <Button
                        onClick={showSampleAnswer}
                        transparent
                        className="p-0 mb-2 text-uppercase"
                        style={{
                            color: '#276EF1',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}>
                        Sample Answer
                    </Button>
                </Fragment>
            )}
            <div
                className="input-container p-4"
                style={{
                    marginLeft: '-1.5rem',
                    marginRight: '-1.5rem',
                }}>
                <div style={{ position: 'relative' }}>
                    <TextareaAutosize
                        id={id || name}
                        name={name}
                        value={allow_file_upload ? value.values : value}
                        onChange={e => {
                            if (allow_file_upload) {
                                form.setFieldValue(name, {
                                    values: e.target.value,
                                    reference_materials:
                                        value.reference_materials || [],
                                });
                            } else {
                                onChange(e);
                            }
                        }}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder={placeholder}
                        disabled={disabled}
                        rows={rows}
                        maxLength={max}
                        style={{ maxHeight: 280, paddingRight: '2rem' }}
                    />
                    {allow_file_upload && (
                        <Fragment>
                            <label
                                htmlFor={`${field.name}-fileUpload`}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '0.5rem',
                                    color: '#276ef1',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                }}
                                className="d-flex align-items-center fileUpload py-2">
                                <span className="mr-2">Attach File</span>
                                <i className="fe fe-paperclip" />
                            </label>
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                disabled={disabled}
                                id={`${field.name}-fileUpload`}
                                onChange={e => {
                                    const file = e.currentTarget.files[0];
                                    setFileName(file.name);
                                    toBase64(file).then(base64 => {
                                        setFileName(file.name);
                                        const f = {
                                            name: file.name,
                                            type: file.type,
                                            file: base64,
                                        };
                                        form.setFieldValue(name, {
                                            values: value.values || '',
                                            reference_materials: [f],
                                        });
                                    });
                                }}
                            />
                        </Fragment>
                    )}
                </div>
                <div className="d-flex w-100 justify-content-end">
                    <span
                        className="small"
                        style={{ color: 'rgba(60, 60, 67, 0.3)' }}>
                        {charCount}/{max}
                    </span>
                </div>
            </div>
            {fileName && (
                <div
                    className="d-flex align-items-center"
                    style={{ color: '#276EF1' }}>
                    <i className="fe fe-file" />
                    <span className="ml-2">{fileName}</span>
                </div>
            )}
        </div>
    );
};

export const FromTo = ({
    field,
    form,
    id = '',
    label = '',
    disabled,
    rows = 1,
    max = 360,
}) => {
    const [isFromFocused, setIsFromFocused] = useState(false);
    const [isToFocused, setIsToFocused] = useState(false);
    const { onBlur, name, value } = field;
    const hasValue = field.value && field.value.length > 0;

    const handleFromBlur = e => {
        setIsFromFocused(false);
        onBlur(e);
    };
    const handleFromFocus = () => {
        setIsFromFocused(true);
    };
    const handleToBlur = e => {
        setIsToFocused(false);
        onBlur(e);
    };
    const handleToFocus = () => {
        setIsToFocused(true);
    };

    const handleFromChange = e => {
        form.setFieldValue(field.name, [e.target.value, value[1]]);
    };

    const handleToChange = e => {
        form.setFieldValue(field.name, [value[0], e.target.value]);
    };

    return (
        <Fragment>
            <div
                className={`QuestionInput v2 qi-textarea w-100 mb-2 ${
                    isFromFocused ? 'isFocused' : ''
                }`}>
                <label
                    className="SoloWorkout--label"
                    htmlFor={`${id || name}-from`}>
                    From
                </label>
                <div
                    className="input-container p-4"
                    style={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }}>
                    <TextareaAutosize
                        id={`${id || name}-from`}
                        name={name}
                        value={value[0]}
                        onChange={handleFromChange}
                        onBlur={handleFromBlur}
                        onFocus={handleFromFocus}
                        placeholder="Write here"
                        disabled={disabled}
                        rows={rows}
                        maxLength={max}
                        style={{ maxHeight: 280 }}
                    />
                    <div className="d-flex w-100 justify-content-end">
                        <span
                            className="small"
                            style={{ color: 'rgba(60, 60, 67, 0.3)' }}>
                            {Array.isArray(value)
                                ? value[0].length
                                : value.length}
                            /{max}
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`QuestionInput v2 qi-textarea w-100 mb-2 ${
                    isToFocused ? 'isFocused' : ''
                }`}>
                <label
                    className="SoloWorkout--label"
                    htmlFor={`${id || name}-to`}>
                    To
                </label>
                <div
                    className="input-container p-4"
                    style={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }}>
                    <TextareaAutosize
                        id={`${id || name}-to`}
                        name={name}
                        value={value[1]}
                        onChange={handleToChange}
                        onBlur={handleToBlur}
                        onFocus={handleToFocus}
                        placeholder="Write here"
                        disabled={disabled}
                        rows={rows}
                        maxLength={max}
                        style={{ maxHeight: 280 }}
                    />
                    <div className="d-flex w-100 justify-content-end">
                        <span
                            className="small"
                            style={{ color: 'rgba(60, 60, 67, 0.3)' }}>
                            {Array.isArray(value)
                                ? value[1]
                                    ? value[1].length
                                    : 0
                                : value.length}
                            /{max}
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export const RadioHorizontalV2 = ({
    label,
    field,
    disabled,
    form,
    options,
    slider_left_label,
    slider_right_label,
    showSampleAnswer,
    required,
}) => {
    return (
        <div className="mb-3">
            {/* {!required && <OptionalText />} */}
            <p className="SoloWorkout--label">{label}</p>
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <div className="SoloWorkoutScale d-flex align-items-center">
                {options && options.length > 0
                    ? options.map((o, i) => {
                          const checked = field.value === o.value;
                          return (
                              <label
                                  key={i}
                                  className={`d-flex align-items-center justify-content-center p-3 ${
                                      checked ? 'checked' : ''
                                  } ${disabled ? 'disabled' : ''}`}>
                                  <input
                                      type="radio"
                                      disabled={disabled}
                                      onChange={() =>
                                          form.setFieldValue(
                                              field.name,
                                              o.value,
                                          )
                                      }
                                      checked={checked}
                                      value={o.value}
                                      style={{ display: 'none' }}
                                  />
                                  {o.body}
                              </label>
                          );
                      })
                    : null}
            </div>
            <div
                className="d-flex align-items-center mt-3"
                style={{
                    color: 'rgba(60, 60, 67, 0.7)',
                    fontSize: '0.6875rem',
                }}>
                <span className="mr-auto">{slider_left_label}</span>
                <span>{slider_right_label}</span>
            </div>
        </div>
    );
};

export const RadioHorizontalV3 = ({
    label,
    field,
    disabled,
    form,
    options,
    slider_left_label,
    slider_right_label,
    bottom,
    top,
    showSampleAnswer,
    required,
}) => {
    return (
        <div className="mb-4 d-flex align-items-center">
            {/* {!required && <OptionalText />} */}
            <p
                className={`${bottom ? 'mb-4' : 'mb-0'} ${
                    top ? 'mt-4' : 'mt-0'
                } pr-4 mr-auto flex-shrink-0 w-50`}
                style={{ fontSize: '14px', fontWeight: 600 }}>
                {label}
            </p>
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <div className="d-block w-50">
                {top && (
                    <div
                        className="d-flex align-items-center mb-3"
                        style={{
                            color: 'rgba(60, 60, 67, 0.7)',
                            fontSize: '0.6875rem',
                        }}>
                        <span className="mr-auto">{slider_left_label}</span>
                        <span>{slider_right_label}</span>
                    </div>
                )}
                <div className="SoloWorkoutScale d-flex align-items-center">
                    {options.map((o, i) => {
                        const checked = field.value === o.value;
                        return (
                            <label
                                key={i}
                                style={{ fontSize: '11px' }}
                                className={`d-flex align-items-center justify-content-center p-3 ${
                                    checked ? 'checked' : ''
                                } ${disabled ? 'disabled' : ''}`}>
                                <input
                                    type="radio"
                                    disabled={disabled}
                                    onChange={() =>
                                        form.setFieldValue(field.name, o.value)
                                    }
                                    checked={checked}
                                    value={o.value}
                                    style={{ display: 'none' }}
                                />
                                {o.body}
                            </label>
                        );
                    })}
                </div>
                {bottom && (
                    <div
                        className="d-flex align-items-center mt-3"
                        style={{
                            color: 'rgba(60, 60, 67, 0.7)',
                            fontSize: '0.6875rem',
                        }}>
                        <span className="mr-auto">{slider_left_label}</span>
                        <span>{slider_right_label}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export const Options = ({
    options,
    selectedOptions,
    disabled,
    max,
    name,
    setSelectedOptions,
}) => {
    const reachedMax = selectedOptions.length >= max;
    return options.map((option, i) => {
        const isSelected = selectedOptions.includes(option.value);
        const [topLabel, bottomLabel] = option.body.split(' --- ');
        const haveSubtitle = option.body.split(' --- ').length > 1;
        return (
            <label
                className="QuestionInput--option mt-3 d-flex align-items-center"
                key={i}
                tabIndex={i}
                htmlFor={`${name}-${i}`}>
                <input
                    key={i}
                    type="checkbox"
                    id={`${name}-${i}`}
                    name={name}
                    value={option.value}
                    disabled={disabled || (reachedMax && !isSelected)}
                    onChange={e => {
                        const o = isSelected
                            ? selectedOptions.filter(v => v !== option.value)
                            : selectedOptions.concat(option.value);
                        setSelectedOptions(o);
                    }}
                    style={{ display: 'none' }}
                />
                {isSelected ? (
                    <i
                        className="fe fe-check-square"
                        style={{
                            color: '#276ef1',
                            fontSize: '1.125rem',
                        }}
                    />
                ) : (
                    <i
                        className="fe fe-square"
                        style={{
                            color: '#bfbfc1',
                            fontSize: '1.125rem',
                        }}
                    />
                )}
                {haveSubtitle ? (
                    <span className="ml-3 d-flex flex-column">
                        <span style={{ color: '#121212', fontSize: '14px' }}>
                            {topLabel}
                        </span>
                        <span
                            style={{
                                color: 'rgba(60, 60, 67, 0.7)',
                                fontSize: '11px',
                            }}>
                            {bottomLabel}
                        </span>
                    </span>
                ) : (
                    <span
                        className="ml-3"
                        style={{ color: '#121212', fontSize: '14px' }}>
                        {option.body}
                    </span>
                )}
            </label>
        );
    });
};

const Tag = ({ children, onClick }) => (
    <span
        className="mr-2 mb-2 d-flex"
        style={{
            borderRadius: '28px',
            backgroundColor: '#f7f7f7',
        }}>
        <span className="pl-3 py-3">{children}</span>
        <Button onClick={onClick} transparent>
            <i className="fe fe-x" />
        </Button>
    </span>
);

export const SelectV2 = ({
    field,
    label = '',
    max = Infinity,
    options,
    disabled,
    form,
    showSampleAnswer,
    required,
}) => {
    const reachedMax = field.value.length >= max;
    const dispatch = useDispatch();

    const values = field.value
        .map(v => options.find(o => o.value === v))
        .map((o, i) => (
            <Tag
                key={i}
                onClick={() => {
                    const vals = field.value.filter(v => v !== o.value);
                    form.setFieldValue(field.name, vals);
                }}>
                {o.body.split(' ---')[0]}
            </Tag>
        ));

    const onClick = () => {
        dispatch(
            showCheckboxModal({
                options,
                field,
                form,
                label,
                max,
            }),
        );
    };

    return (
        <div className="QuestionInput v2 mb-5">
            {/* {!required && <OptionalText />} */}
            <label
                className="SoloWorkout--label"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <div className="d-flex align-items-center flex-wrap">
                {values}
                {!reachedMax && (
                    <Button
                        onClick={onClick}
                        rounded
                        className="mb-2"
                        style={{
                            color: '#276ef1',
                            borderRadius: '28px',
                            backgroundColor: 'rgba(39, 110, 241, 0.1)',
                        }}>
                        <span className="mr-2">Add Tag</span>
                        <i className="fe fe-plus" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export const RadioV2 = ({
    label,
    options,
    field,
    slider,
    disabled,
    form,
    showSampleAnswer,
    required,
}) => {
    return (
        <div className="mb-3">
            {/* {!required && <OptionalText />} */}
            <p
                className="SoloWorkout--label"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <div
                className={`OptionsContainer ${
                    slider ? 'is-slider' : ''
                } mb-4`}>
                {options &&
                    options.map((o, i) => {
                        const checked = field.value === o.value;
                        return (
                            <label key={i} className="d-flex mb-3">
                                <input
                                    type="radio"
                                    disabled={disabled}
                                    onChange={() =>
                                        form.setFieldValue(field.name, o.value)
                                    }
                                    checked={checked}
                                    value={o.value}
                                    style={{ display: 'none' }}
                                />
                                <i
                                    className={`RadioInput ${
                                        checked ? 'isSelected' : ''
                                    }`}
                                />

                                <span className="ml-3">
                                    {o.body || o.label}
                                </span>
                            </label>
                        );
                    })}
            </div>
        </div>
    );
};

export const FileUpload = ({
    field,
    label = '',
    max = Infinity,
    disabled,
    id,
    form,
}) => {
    const [fileName, setFileName] = useState('No file chosen');
    const { name, value, ...others } = field;
    const { reference_materials } = value;

    return (
        <div className="QuestionInput v2 mb-5">
            <label
                className="SoloWorkout--label"
                dangerouslySetInnerHTML={{ __html: label }}
            />
            <input
                style={{ display: 'none' }}
                type="file"
                name={name}
                disabled={disabled}
                id={name}
                onChange={e => {
                    const file = e.currentTarget.files[0];
                    setFileName(file.name);
                    toBase64(file).then(base64 => {
                        form.setFieldValue(name, {
                            name: file.name,
                            type: file.type,
                            file: base64,
                            ...(reference_materials
                                ? { reference_materials: reference_materials }
                                : {}),
                        });
                    });
                }}
            />
            {reference_materials
                ? reference_materials.map((r, k) => (
                      <a
                          href={r.url}
                          key={k}
                          target="_blank"
                          className="d-flex align-items-center mt-3 mb-4">
                          <i className="fe fe-paperclip" />{' '}
                          <span className="ml-2">Attachment {k + 1}</span>
                      </a>
                  ))
                : null}
            <div className="d-flex align-items-center">
                <label htmlFor={name} className="btn btn-secondary">
                    Choose File
                </label>
                <span className="pl-3">{fileName}</span>
            </div>
        </div>
    );
};

export const InputV2 = ({
    type = 'text',
    field,
    id = '',
    label = '',
    disabled,
    showSampleAnswer,
}) => {
    const { onBlur, onChange, name, value } = field;
    const handleBlur = e => {
        onBlur(e);
    };

    return (
        <div className={`QuestionInput w-100 mb-5`}>
            {label && (
                <label
                    className="SoloWorkout--label"
                    htmlFor={id || name}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            )}
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <input
                type={type}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                placeholder="Write here"
                disabled={disabled}
            />
        </div>
    );
};

export const NumericalInputV2 = ({
    inputmode = 'numeric',
    pattern = '[0-9]*',
    field,
    className = '',
    id = '',
    label = '',
    showSampleAnswer,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const { onBlur, onChange, name, value } = field;
    const hasValue = field.value && field.value.length > 0;
    const handleBlur = e => {
        setIsFocused(false);
        onBlur(e);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    return (
        <div className="QuestionInput w-100 mb-5">
            <label htmlFor={id || name} className="SoloWorkout--label">
                {label}
            </label>
            {showSampleAnswer && (
                <Button
                    onClick={showSampleAnswer}
                    transparent
                    className="p-0 mb-2 text-uppercase"
                    style={{
                        color: '#276EF1',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}>
                    Sample Answer
                </Button>
            )}
            <input
                type="text"
                inputMode={inputmode}
                pattern={pattern}
                id={id || name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};
