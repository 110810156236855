import React from 'react';
import { Formik, Form, Field } from 'formik';
import { OpenText } from './QuestionInput';
import Button from './Button';
import AnimatedContainer from './AnimatedContainer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addFinalResponse } from '../reducers/itineraryActions';
import MaterialLink from './MaterialLink';
import { StarIcon } from './RaazIcons';

const ResponseContainer = ({ answer, referenceMaterials, user }) => (
    <div
        className="p-5"
        style={{ backgroundColor: '#F7F7F7', borderRadius: '12px' }}>
        {answer && (
            <p
                dangerouslySetInnerHTML={{
                    __html: answer,
                }}
            />
        )}
        {referenceMaterials.length ? (
            <div>
                {referenceMaterials.map((m, i) => (
                    <MaterialLink key={i} {...m} />
                ))}
            </div>
        ) : null}

        {user && (
            <div className="SelectedResponse-user d-flex align-items-center">
                <div className="avatar avatar-sm mr-3">
                    <img
                        src={user.avatar || '/icon.png'}
                        alt="..."
                        className="avatar-img rounded-circle"
                    />
                </div>
                <p className="mb-0">{user ? user.name || user.email : ''}</p>
            </div>
        )}
    </div>
);

const ProjectOwnerForm = ({
    isOwner,
    answer,
    slug,
    projectId,
    question_key,
    nextSection,
    stop,
    isLast,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <Formik
            enableReinitialize
            initialValues={{ answer }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const data = {
                    discernment_slug: slug,
                    project_id: projectId,
                    stop_id: stop && stop.id,
                    answers: [
                        {
                            question_key: question_key,
                            values: [values.answer],
                            reference_materials: [],
                        },
                    ],
                };

                dispatch(addFinalResponse(data)).then(
                    res => {
                        setSubmitting(false);
                        !isLast
                            ? window.scrollTo({
                                  top: nextSection,
                                  behavior: 'smooth',
                              })
                            : history.push(`/100h/projects/${projectId}`);
                    },
                    () => setSubmitting(false),
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <Field
                        component={OpenText}
                        name="answer"
                        disabled={isSubmitting || !isOwner}
                    />
                    <div className="mt-5 py-5 w-100 d-flex justify-content-end">
                        <Button
                            primary
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}>
                            Next
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const NonProjectOwner = ({
    answer,
    nextSection,
    projectId,
    isLast,
    referenceMaterials,
    user,
}) => {
    const history = useHistory();
    return (
        <div>
            {answer ? (
                <ResponseContainer
                    answer={answer}
                    referenceMaterials={referenceMaterials}
                    user={user}
                />
            ) : (
                <ResponseContainer
                    referenceMaterials={[]}
                    answer="<span style='display: block; margin-bottom: -1rem; color: #717171; text-align: center'>No answer from Project Owner yet</span>"
                />
            )}
            <div className="mt-5 d-flex w-100">
                <Button
                    className="ml-auto"
                    primary
                    type="button"
                    onClick={() => {
                        !isLast
                            ? window.scrollTo({
                                  top: nextSection,
                                  behavior: 'smooth',
                              })
                            : history.push(`/100h/projects/${projectId}`);
                    }}>
                    Next
                </Button>
            </div>
        </div>
    );
};

const IteratedResponse = ({
    id,
    scrollY,
    isLast,
    section,
    setCurrentView,
    nextSection,
    answer,
    user,
    question,
    projectId,
    slug,
    question_key,
    finalResponses,
    isOwner,
    stop,
    referenceMaterials,
    userOwner,
}) => {
    const initialAnswer =
        finalResponses && finalResponses[question_key]
            ? finalResponses[question_key][0].values
            : '';

    return (
        <AnimatedContainer
            scrollY={scrollY}
            id={id}
            section={section}
            setCurrentView={setCurrentView}>
            <div className="py-5">
                <h2 className="header-pretitle isRaaz mt-5 pt-5">
                    ITERATED WORKOUT
                </h2>
                <h1 className="question">{question}</h1>
                <div className="mb-5">
                    <p className="font-weight-bold d-flex align-items-center">
                        <span className="mr-1">Selected Response</span>
                        <StarIcon width={32} height={32} />
                    </p>
                    {answer ||
                    (referenceMaterials && referenceMaterials.length) ? (
                        <ResponseContainer
                            answer={answer}
                            referenceMaterials={referenceMaterials}
                            user={user}
                        />
                    ) : null}
                    {!answer &&
                    referenceMaterials &&
                    !referenceMaterials.length ? (
                        <div className="p-5 bg-light">
                            <strong>
                                Please pick an answer in peer exchange.
                            </strong>
                        </div>
                    ) : null}
                </div>

                <div className="mt-3 mb-5">
                    <p className="font-weight-bold">Project Owner Response</p>
                    {isOwner && (
                        <ProjectOwnerForm
                            isOwner={isOwner}
                            answer={initialAnswer}
                            slug={slug}
                            projectId={projectId}
                            question_key={question_key}
                            stop={stop}
                            isLast={isLast}
                            nextSection={nextSection}
                        />
                    )}
                    {!isOwner && (
                        <NonProjectOwner
                            answer={answer}
                            referenceMaterials={referenceMaterials}
                            user={user}
                            nextSection={nextSection}
                            projectId={projectId}
                            isLast={isLast}
                        />
                    )}
                </div>
            </div>
        </AnimatedContainer>
    );
};

export default IteratedResponse;
