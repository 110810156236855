import React, { Fragment, useState, useEffect } from 'react';
import { GiftboxTitle } from '../components/ProjectTitle';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { DefaultMediaObject } from '../components/GiftboxMediaObject';
import Loader from '../components/Loader';
import { GoogleMeetsLogo } from '../components/RaazIcons';
import Button from '../components/Button';
import GET_CARD_BY_FRIENDLY_ID from './queries/getCardByFriendlyId';
import START_USER_CARD from './mutations/startUserCard';

const GET_GROWTHLAB_APP_CARD = gql`
    query GetGrowthlabAppCard($id: ID!, $userId: ID) {
        userCard(id: $id) {
            id
            card {
                title
                id
                imageUrl
                ordinality
                schedule
                description
                event {
                    id
                    name
                    startTime
                    endTime
                    coverImageUrl
                    speakers
                    date
                    learnMoreUrl
                    speakers
                    webcastUrl
                    isCurrent
                    isAttendee(userId: $userId)
                }
            }
        }
    }
`;

const GrowthlabCardScreen = ({ userId }) => {
    const [card, setCard] = useState({
        event: {},
    });
    const { id } = useParams();
    const r = useQuery(GET_CARD_BY_FRIENDLY_ID, {
        variables: {
            friendlyId: id,
            userId,
        },
    });

    const [startUserCard, { loading }] = useMutation(START_USER_CARD);

    useEffect(() => {
        if (r.data) {
            setCard(r.data.card);
        }
    }, [r.data]);

    useEffect(() => {
        if (r.data && r.data.card) {
            startUserCard({
                variables: {
                    input: {
                        userCardId: r.data.card.userCard.id,
                        start: true,
                        userId,
                    },
                },
            });
        }
    }, [r.data]);

    if (r.loading) return <Loader fullscreen />;
    /* redirect to dashboard if there is no event assigned to card */
    if (card && !card.event) return <Redirect to="/" />;
    return (
        <Fragment>
            <GiftboxTitle title="Personal Growth" />
            <div
                className="py-5 px-3 mx-auto mt-5"
                style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <div
                    className="py-5 d-flex align-items-center w-100"
                    style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <DefaultMediaObject
                        to={`/growthlab-app/${card.event.id}`}
                        type="Growthlab"
                        heading={card.title}
                        body={card.description}
                        date={card.event.date}
                        startTime={card.event.startTime}
                        endTime={card.event.endTime}
                        isCurrent={card.event.isCurrent}
                        coverImage={card.imageUrl}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default GrowthlabCardScreen;
