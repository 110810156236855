import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../reducers/modalActions';
import Button from './Button';

export const ModalHeader = ({ title }) => (
    <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);

export const ModalBody = ({ children }) => (
    <div className="modal-body">{children}</div>
);

export const ModalFooter = ({ children }) => (
    <div className="modal-footer">{children}</div>
);

export const FloatingCloseBtn = ({ onClick, style = {}, backToItinerary }) => (
    <div className="FloatingCloseBtn--container d-flex align-items-center">
        {backToItinerary && (
            <span className="mr-2 text-white small">Back to itinerary</span>
        )}
        <button
            type="button"
            style={style}
            className="close FloatingCloseBtn"
            data-dismiss="modal"
            onClick={onClick}
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);

const Modal = ({
    title,
    centered,
    children,
    afterCloseCallBack,
    floatingCloseBtn,
    regularCloseBtn,
    size,
    Subdialog = null,
    className,
    backToItinerary,
}) => {
    const node = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsMounted(false);
            dispatch(hideModal());
            afterCloseCallBack && afterCloseCallBack();
        }, 300);
    };

    const handleClickOutside = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        closeModal();
    };

    useEffect(() => {
        setIsMounted(true);
        setTimeout(() => {
            setIsVisible(true);
        }, 10);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Fragment>
            <div
                className={`modal fade ${isVisible ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={isMounted ? { display: 'block' } : {}}>
                <div
                    className={`modal-dialog ${
                        centered ? 'modal-dialog-centered' : ''
                    } ${size || ''} ${className || ''}`}
                    role="document">
                    {Subdialog && <Subdialog />}
                    <div className="modal-content" ref={node}>
                        {floatingCloseBtn && (
                            <FloatingCloseBtn
                                onClick={closeModal}
                                backToItinerary={backToItinerary}
                            />
                        )}
                        {regularCloseBtn && (
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: '0.5rem',
                                    right: '1rem',
                                    fontSize: '1.5rem',
                                    color: '#87878B',
                                }}
                                transparent
                                onClick={closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        )}
                        {title && <ModalHeader title={title} />}
                        {children}
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${isVisible ? 'show' : ''}`} />
        </Fragment>
    );
};

export default Modal;
