import React, { Fragment, useState, useEffect } from 'react';
import { useScroll } from '../../hooks/useScroll';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import Loader from '../../components/Loader';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { Link, useHistory } from 'react-router-dom';
import {
    TargetIcon,
    BrainstormIcon,
    RefineIcon,
    TestIcon,
    LaunchIcon,
} from '../../components/IteratedResponseIcons';
import {
    addFinalResponse,
    getFinalResponses,
} from '../../reducers/itineraryActions';
import Button from '../../components/Button';
import IteratedResponse from '../../components/IteratedResponse';

const IteratedResponseStop4 = ({
    stop,
    projectId,
    stops,
    user,
    isOwner,
    soloWorkoutId,
    answers,
}) => {
    const dispatch = useDispatch();
    const [finalResponses, setFinalResponses] = useState([]);

    const { slug } = stop.hundred_h.find(h => h.type === 'iterated_response');

    useEffect(() => {
        dispatch(
            getFinalResponses(soloWorkoutId, { project_id: projectId }),
        ).then(res => {
            setFinalResponses(res.answers);
        });
    }, [soloWorkoutId]);

    const ans = answers[Object.keys(answers)[0]];

    return (
        <div
            className="d-flex flex-column mx-auto w-100"
            style={{ position: 'relative', maxWidth: MAX_WIDTH }}>
            <div className="d-flex flex-column w-100">
                <div className="py-5">
                    <IteratedResponse
                        projectId={projectId}
                        answer={ans && ans[0] ? ans[0].values : ''}
                        user={user}
                        question="Solution"
                        isLast
                        slug={slug}
                        finalResponses={finalResponses}
                        referenceMaterials={
                            ans && ans[0]
                                ? ans[0].reference_materials || []
                                : []
                        }
                        stop={stop}
                        isOwner={isOwner}
                        setCurrentView={() => {}}
                    />
                </div>
            </div>
        </div>
    );
};

export default IteratedResponseStop4;
