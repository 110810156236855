import instance from '../instance';

export const FETCH_STOPS_SUCCESS = 'FETCH_STOPS_SUCCESS';
export const FETCH_HH_SUCCESS = 'FETCH_HH_SUCCESS';

export const getStops = () => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/stops`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getHundredHours = () => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/stops/hundred_hours`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const submitPostcard = (stopId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/stops/${stopId}/submit_postcard`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
            data,
        })
        .then(res => res.data);
};
