import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import appReducer from './appReducer';
import usersReducer from './usersReducer';
import modalReducer from './modalReducer';
import itineraryReducer from './itineraryReducer';
import stopsReducer from './stopsReducer';
import growthlabsReducer from './growthlabsReducer';
import hundredhoursReducer from './hundredhoursReducer';
import peerExchangeReducer from './peerExchangeReducer';

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['users'],
};

const appPersistConfig = {
    key: 'app',
    storage: storage,
    whitelist: ['auth_token'],
};

const rootReducer = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    users: usersReducer,
    itinerary: itineraryReducer,
    modal: modalReducer,
    stops: stopsReducer,
    growthlabs: growthlabsReducer,
    hundredhours: hundredhoursReducer,
    peerExchange: peerExchangeReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
