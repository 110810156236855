import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { hideModal } from '../reducers/modalActions';
import Button from './Button';
import { audit, getAudit } from '../reducers/itineraryActions';
import setResponseErrors from '../utils/setResponseErrors';
import Loader from './Loader';
import { RadioHorizontalV3, RadioV2 } from './QuestionInputV2';

const OPTIONS = [
    { body: 1, value: 1 },
    { body: 2, value: 2 },
    { body: 3, value: 3 },
    { body: 4, value: 4 },
];

const AuditInput = ({ field, form, length = 4, Icon, label, description }) => (
    <div className="mb-3">
        <strong style={{ fontSize: '14px' }}>{label}</strong>
        <p style={{ color: '#A0A0A0', fontSize: '11px' }} className="mb-3">
            {description}
        </p>
        <div className="d-flex align-items-center">
            {new Array(length)
                .fill(_ => 1)
                .map((_, i) => (
                    <label
                        key={i}
                        htmlFor={`${field.name}-${i}`}
                        style={{
                            cursor: 'pointer',
                        }}
                        className="mr-3">
                        <input
                            type="checkbox"
                            id={`${field.name}-${i}`}
                            name={field.name}
                            onChange={() => {
                                form.setFieldValue(field.name, i + 1);
                            }}
                            style={{ display: 'none' }}
                        />
                        <Icon
                            fill={field.value >= i + 1 ? '#276EF1' : '#dadada'}
                        />
                    </label>
                ))}
        </div>
    </div>
);

const defaultValue = {
    workforce: 0,
    customers: 0,
    costs: 0,
    partners: 0,
    sales: 0,
    operations: 0,
};

const AuditModal = ({ auditDetails }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(defaultValue);

    useEffect(() => {
        dispatch(
            getAudit({
                stop_id: auditDetails.stopId,
                project_id: auditDetails.projectId,
            }),
        ).then(
            res => {
                const {
                    created_at,
                    stop_id,
                    project_id,
                    updated_at,
                    user_id,
                    ...v
                } = res;
                setInitialValues(v);
                setIsLoading(false);
            },
            err => {
                setIsLoading(false);
            },
        );
    }, [auditDetails.projectId, auditDetails.stopId]);

    if (isLoading)
        return (
            <div
                className="p-5 d-flex align-items-center justify-content-center"
                style={{ minHeight: '75vh' }}>
                <Loader />
            </div>
        );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(
                    audit({
                        ...values,
                        project_id: auditDetails.projectId,
                        stop_id: auditDetails.stopId,
                    }),
                ).then(
                    () => {
                        setSubmitting(false);
                        history.push(auditDetails.next);
                        dispatch(hideModal());
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form>
                    <div
                        className="p-5"
                        style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                        <h2 className="lora large-text">Impact Audit</h2>
                        <p style={{ fontSize: '14px' }} className="mb-4">
                            Rate the impact of this 100H Project on
                        </p>
                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            top
                            slider_left_label="Not Much"
                            slider_right_label="Very Much"
                            label="1. Customer Loyalty & Growth"
                            name="customers"
                            description="Bushwick swag fam, sustainable readymade subway tile copper mug taxidermy tattooed small batch four loko 3 wolf moon jean shorts vice mumblecore."
                        />
                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            label="2. Workforce Performance & Morale"
                            name="workforce"
                        />

                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            label="3. Operations & Cost Efficiencies"
                            name="costs"
                        />

                        <Field
                            component={RadioHorizontalV3}
                            slider
                            disabled={isSubmitting}
                            options={OPTIONS}
                            bottom
                            slider_left_label="Not Much"
                            slider_right_label="Very Much"
                            label="4. Revenue Growth"
                            name="sales"
                        />
                    </div>
                    <div
                        className="px-5 py-3 d-flex align-items-center"
                        style={{ borderTop: '1px solid #F7F7F7' }}>
                        <span className="mr-auto" style={{ fontSize: '14px' }}>
                            Next: Iterated Workout
                        </span>
                        <Button
                            type="submit"
                            primary
                            isLoading={isSubmitting}
                            disabled={isSubmitting}>
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AuditModal;
