import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import {
    populateDiscernmentAnswer,
    getDiscernmentDetails,
} from '../../reducers/itineraryActions';
import Sections from '../../components/Sections';
import { GiftboxTitle } from '../../components/ProjectTitle';
import { DefaultMediaObject } from '../../components/GiftboxMediaObject';
import { useQuery, gql, useMutation } from '@apollo/client';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import START_USER_CARD from '../mutations/startUserCard';

const GET_WORKOUT_CARD = gql`
    query GetWorkoutCard($friendlyId: ID!) {
        card(friendlyId: $friendlyId) {
            title
            id
            imageUrl
            ordinality
            schedule
            description
            discernment {
                id
            }
            userCard {
                id
            }
        }
    }
`;

export const Workout = ({ discernmentId, next = '/', eventId = null }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState(null);
    const [sections, setSections] = useState([]);
    useEffect(() => {
        if (discernmentId) {
            dispatch(getDiscernmentDetails(discernmentId, eventId)).then(
                ({ sections, answer, ...d }) => {
                    setDetails(d);
                    setSections(sections);
                    dispatch(populateDiscernmentAnswer(answer));
                },
            );
        }
    }, [discernmentId]);

    if (!sections.length || !details) return <Loader fullscreen />;

    return (
        <div className="w-100">
            <Sections
                sections={sections}
                slug={details.slug}
                next={next}
                offset={0}
                eventId={eventId}
            />
        </div>
    );
};

const SoloWorkoutScreen = ({ userId }) => {
    const { id } = useParams();
    const [card, setCard] = useState({});
    const showBack = useRouteMatch('/mastery/:id/workout');
    const r = useQuery(GET_WORKOUT_CARD, {
        variables: {
            friendlyId: id,
        },
    });

    const [startUserCard, { loading }] = useMutation(START_USER_CARD);
    useEffect(() => {
        if (r.data) {
            setCard(r.data.card);
        }
    }, [r.data]);

    useEffect(() => {
        if (r.data && r.data.card) {
            startUserCard({
                variables: {
                    input: {
                        userCardId: r.data.card.userCard.id,
                        start: true,
                        userId,
                    },
                },
            });
        }
    }, [r.data]);

    if (r.loading) {
        return <Loader fullscreen />;
    }

    return (
        <Fragment>
            <GiftboxTitle
                title={showBack ? card.title : 'Personal Growth'}
                subtitle={showBack ? 'Workout' : ''}
                showBack={showBack}
            />
            <div
                className="py-5 px-3 mx-auto mt-5"
                style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <Switch>
                    <Route path="/mastery/:id/workout/:soloWorkoutId">
                        <Workout
                            discernmentId={
                                card.discernment ? card.discernment.id : ''
                            }
                        />
                    </Route>
                    <Route path="/mastery/:id" exact>
                        <div
                            className="py-5 d-flex align-items-center w-100"
                            style={{ minHeight: 'calc(100vh - 200px)' }}>
                            <DefaultMediaObject
                                to={`/mastery/${id}/workout/${
                                    card.discernment ? card.discernment.id : ''
                                }`}
                                type="Workout"
                                heading={card.title}
                                body={card.description}
                                coverImage={card.imageUrl}
                            />
                        </div>
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default SoloWorkoutScreen;
