import React from 'react';
import { BUSINESS_IMPACT } from '../utils/CONSTANTS';
import Button from './Button';
import EmptyGiftboxCards from './EmptyGiftboxCards';
import ProjectUserCard from './ProjectUserCard';
import { StackIcon } from './RaazIcons';
import StackItem from './StackItem';

const HorizontalUserCards = ({
    cards,
    title,
    userId,
    alt,
    setAll,
    desc,
    nextCardIds,
}) => {
    const moreThan10 = cards.length > 10;
    const nextCards = cards
        .filter(c => nextCardIds.includes(c.id))
        .sort((a, b) => b.ordinality - a.ordinality);
    const startedCards = cards
        .filter(c => !nextCardIds.includes(c.id) && c.isStarted)
        .sort((a, b) => b.ordinality - a.ordinality);
    const sortedCards = cards
        .filter(c => !nextCardIds.includes(c.id) && !c.isStarted)
        .sort((a, b) => b.ordinality - a.ordinality);

    if (cards.length <= 0) {
        return (
            <div className="p-5">
                <h2 className="pb-0 w-100 d-flex align-items-center large-text">
                    <span className="lora">{title}</span>
                    <span
                        className="mx-4"
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#c4c4c4',
                        }}
                    />
                    <div>
                        <StackIcon fill="#276EF1" />
                        <span
                            className="ml-1"
                            style={{
                                fontSize: '14px',
                                color: '#717171',
                                fontWeight: '600',
                            }}>
                            {cards.filter(c => c.isClaimed).length} of{' '}
                            {cards.length} Cards
                        </span>
                    </div>
                </h2>
                <p
                    style={{
                        color: '#838383',
                        fontSize: '14px',
                        fontWeight: '600',
                        maxWidth: '760px',
                    }}>
                    {desc}
                </p>
                <EmptyGiftboxCards />
            </div>
        );
    }

    return (
        <div
            className="pl-5 py-5"
            style={{ backgroundColor: alt ? '#E3EAF7' : 'white' }}>
            <h2 className="pb-0 w-100 d-flex align-items-center large-text">
                <span className="lora">{title}</span>
                <span
                    className="mx-4"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#c4c4c4',
                    }}
                />
                <div>
                    <StackIcon fill="#276EF1" />
                    <span
                        className="ml-1"
                        style={{
                            fontSize: '14px',
                            color: '#717171',
                            fontWeight: '600',
                        }}>
                        {cards.filter(c => c.isClaimed).length} of{' '}
                        {cards.length} Cards
                    </span>
                </div>
                {setAll && (
                    <div className="ml-auto position-relative">
                        <Button
                            transparent
                            className="d-flex align-items-center"
                            style={{ color: '#828282' }}
                            onClick={() => setAll(title)}>
                            <span className="mr-2">See All</span>
                            <i className="fe fe-chevron-down" />
                        </Button>
                    </div>
                )}
            </h2>
            <p
                style={{
                    color: '#838383',
                    fontSize: '14px',
                    fontWeight: '600',
                    maxWidth: '760px',
                }}>
                {desc}
            </p>
            <div
                className="d-flex flex-row pb-5"
                style={{
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    paddingLeft: '1.5rem',
                    marginLeft: '-1.5rem',
                }}>
                {nextCards
                    .concat(startedCards)
                    .concat(sortedCards)
                    .slice(0, 10)
                    .map((c, i) =>
                        c.isProject ? (
                            <h1>Is Project</h1>
                        ) : (
                            <StackItem
                                key={i}
                                type={c.card.cardType}
                                title={c.card.title}
                                time={c.card.schedule}
                                description={c.card.description}
                                claimed={c.isClaimed}
                                event={c.card.event}
                                cardId={c.id}
                                friendlyId={c.card.friendlyId}
                                isFavorited={c.isFavorited}
                                userId={userId}
                                imageUrl={c.card.imageUrl}
                                discernment={c.card.discernment}
                                className="alt"
                                isNext={nextCardIds.includes(c.id)}
                                isStarted={c.isStarted}
                            />
                        ),
                    )}
                {moreThan10 && (
                    <div className="StackItem--container alt mb-3">
                        <div
                            className="StackItem d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: '#276EF1' }}>
                            <Button
                                transparent
                                onClick={() => setAll(title)}
                                className="text-white mb-0 d-flex align-items-center"
                                style={{ fontSize: '28px', fontWeight: '600' }}>
                                <span className="mr-2">See All</span>
                                <i className="fe fe-arrow-right" />
                            </Button>
                        </div>
                    </div>
                )}
                <div className="ml-3 px-3" style={{ flexShrink: 0 }} />
            </div>
        </div>
    );
};

export const HorizontalNextCards = ({
    cards,
    title,
    userId,
    alt,
    desc,
    nextProject,
    activeTab,
}) => {
    return (
        <div
            className="pl-5 py-5"
            style={{ backgroundColor: alt ? '#E3EAF7' : 'white' }}>
            <h2 className="pb-0 w-100 d-flex align-items-center large-text">
                <span className="lora">{title}</span>
                <span
                    className="mx-4"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#c4c4c4',
                    }}
                />
                <div>
                    <StackIcon fill="#276EF1" />
                    <span
                        className="ml-1"
                        style={{
                            fontSize: '14px',
                            color: '#717171',
                            fontWeight: '600',
                        }}>
                        {cards.filter(c => c.isClaimed).length} of{' '}
                        {cards.length + (nextProject ? 1 : 0)} Cards
                    </span>
                </div>
            </h2>
            <p
                style={{
                    color: '#838383',
                    fontSize: '14px',
                    fontWeight: '600',
                    maxWidth: '760px',
                }}>
                {desc}
            </p>
            <div
                className="d-flex flex-row pb-5"
                style={{
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    paddingLeft: '1.5rem',
                    marginLeft: '-1.5rem',
                }}>
                {nextProject && activeTab === BUSINESS_IMPACT ? (
                    <ProjectUserCard {...nextProject} />
                ) : null}
                {cards.map((c, i) => (
                    <StackItem
                        key={i}
                        type={c.card.cardType}
                        title={c.card.title}
                        time={c.card.schedule}
                        description={c.card.description}
                        claimed={c.isClaimed}
                        event={c.card.event}
                        cardId={c.id}
                        friendlyId={c.card.friendlyId}
                        isFavorited={c.isFavorited}
                        userId={userId}
                        imageUrl={c.card.imageUrl}
                        discernment={c.card.discernment}
                        className="alt"
                        isNext={true}
                    />
                ))}
                {nextProject && activeTab !== BUSINESS_IMPACT ? (
                    <ProjectUserCard {...nextProject} />
                ) : null}
                <div className="ml-3 px-3" style={{ flexShrink: 0 }} />
            </div>
        </div>
    );
};

export default HorizontalUserCards;
