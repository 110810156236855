import instance from '../instance';

export const FETCH_EVENT_GROUPS_SUCCESS = 'FETCH_EVENT_GROUPS_SUCCESS';
export const ADD_EVENT_GROUP = 'ADD_EVENT_GROUP';

export const getEventGroups = () => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/event_groups`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getEventGroup = id => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/event_groups/${id}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};
