import React, { Fragment, useEffect, useState } from 'react';
import { GiftboxTitle } from '../components/ProjectTitle';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { DefaultMediaObject } from '../components/GiftboxMediaObject';
import { useParams, Route, useRouteMatch, Switch } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { Formik, Form, Field } from 'formik';
import {
    InputV2,
    OpenTextV2,
    RadioHorizontalV3,
    RadioHorizontalV2,
} from '../components/QuestionInputV2';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';
import START_USER_CARD from './mutations/startUserCard';

const WorkplaceIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M12.1268 9.74732C11.9468 9.95399 11.6334 10.2807 11.1268 10.2807C10.4201 10.2807 10.1868 9.76732 9.97345 9.22732L8.90678 6.60732L7.84011 9.22732C7.66678 9.66732 7.46678 10.2807 6.68678 10.2807C5.90678 10.2807 5.71345 9.66732 5.52678 9.22732L3.74011 4.81398H5.24678L6.68678 8.44732L7.76011 5.80065C7.92678 5.38065 8.12678 4.74732 8.90678 4.74732C9.66678 4.74732 9.88011 5.38065 10.0534 5.80065L11.2201 8.66732C11.8134 7.76065 12.0668 6.66732 11.9201 5.59398C11.7749 4.5191 11.2442 3.53355 10.4268 2.82065C9.61143 2.10556 8.56462 1.71004 7.48011 1.70732C6.35345 1.70065 5.26011 2.05398 4.33345 2.70732C3.43306 3.37054 2.7619 4.29804 2.41345 5.36065C2.06678 6.42732 2.06678 7.58065 2.41345 8.64732C2.76011 9.71398 3.44011 10.6407 4.35345 11.294C5.26678 11.954 6.36011 12.3007 7.48011 12.294C7.96678 12.294 8.44678 12.2273 8.91345 12.094V13.5007C8.44011 13.6073 7.96011 13.6673 7.48011 13.6673C6.07345 13.6673 4.69345 13.2273 3.54678 12.4007C2.40011 11.574 1.54678 10.4073 1.11345 9.06732C0.667926 7.72592 0.665589 6.27681 1.10678 4.93398C1.54678 3.59398 2.40011 2.42732 3.54678 1.60065C4.68678 0.773984 6.06678 0.333984 7.48011 0.333984C10.7068 0.333984 13.3334 2.96065 13.3334 6.19398C13.3334 7.47398 12.9068 8.72732 12.1268 9.74732Z"
            fill="white"
        />
    </svg>
);

const GET_BUDDY_WORKOUT_CARD = gql`
    query GetBuddyCard($friendlyId: ID!) {
        card(friendlyId: $friendlyId) {
            title
            id
            imageUrl
            ordinality
            schedule
            description
            userCard {
                id
                buddies {
                    id
                    avatar
                    displayName
                    company
                    email
                    selfTitle
                    title
                    firstName
                    lastName
                    mobileNumber
                }
                buddyAnswers
            }
        }
    }
`;

const ADD_BUDDY_WORKOUT_ANSWER = gql`
    mutation AddAnswerToBuddyWorkout(
        $answerBuddyWorkoutInput: AnswerBuddyWorkoutInput!
    ) {
        answerBuddyWorkout(input: $answerBuddyWorkoutInput) {
            errors
            success
            userCard {
                buddyAnswers
                id
                isClaimed
            }
        }
    }
`;

const OPTIONS = [
    { body: 1, value: 1 },
    { body: 2, value: 2 },
    { body: 3, value: 3 },
    { body: 4, value: 4 },
];

const Buddy = ({
    id,
    userCardId,
    name,
    title,
    avatar,
    email,
    mobile,
    answers,
    initials,
}) => {
    const [showTakeaway, setShowTakeaway] = useState(false);
    const hasAnswer = Boolean(answers);
    const [submitAnswerBuddyWorkout] = useMutation(ADD_BUDDY_WORKOUT_ANSWER);
    return (
        <Fragment>
            <div className="Buddy">
                <div className="d-flex align-items-stretch">
                    {!avatar ||
                    (avatar && avatar === '/images/user-icon.png') ? (
                        <div
                            className="Buddy--avatar d-flex align-items-center justify-content-center text-center mr-4"
                            style={{
                                color: 'white',
                                fontSize: '3rem',
                                fontWeight: 'bold',
                                backgroundColor: getDefaultAvatarColors(name),
                            }}>
                            {initials}
                        </div>
                    ) : (
                        <div
                            className="Buddy--avatar mr-4"
                            style={{ backgroundImage: `url(${avatar})` }}
                        />
                    )}
                    <div className="Buddy--details">
                        <p className="lora large-text mb-1">{name}</p>
                        <p className="Buddy--title">{title}</p>
                        <div className="Buddy--contact">
                            <p className="d-flex align-items-center mb-0">
                                <i className="fe fe-send" />
                                <span className="ml-2">{email}</span>
                            </p>
                            {mobile && (
                                <p className="d-flex align-items-center mb-0 ml-4">
                                    <i className="fe fe-smartphone" />
                                    <span className="ml-2">{mobile}</span>
                                </p>
                            )}
                        </div>
                        <div className="d-flex align-items-center mt-auto">
                            <Button
                                primary
                                rounded
                                style={{
                                    borderRadius: '2px',
                                    fontSize: '14px',
                                }}
                                className="d-flex align-items-center mr-3">
                                <WorkplaceIcon />
                                <span className="ml-2">
                                    Connect to Workplace
                                </span>
                            </Button>
                            <Button
                                rounded
                                className="d-flex align-items-center"
                                style={{
                                    borderRadius: '2px',
                                    backgroundColor: '#F7F7F7',
                                    color: '#121212',
                                    fontSize: '14px',
                                }}
                                onClick={() => setShowTakeaway(!showTakeaway)}>
                                {hasAnswer ? (
                                    <Fragment>
                                        <span className="mr-2">
                                            Edit Key Takeaways
                                        </span>
                                        <i className="fe fe-edit" />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <span className="mr-2">
                                            Key Takeaways
                                        </span>
                                        <i className="fe fe-chevron-down" />
                                    </Fragment>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>

                {showTakeaway && (
                    <div className="Takeaway py-5 mt-5">
                        <div className="Takeaway--notice p-5">
                            <i className="fe fe-info" />
                            <p className="mb-0 ml-3">
                                To ensure privacy, please use our in-app
                                messaging system in Facebook Workplace to make
                                the initial contact. Subsequently, please feel
                                free to connect via other media if mutually
                                preferable to you.
                            </p>
                        </div>
                        <Formik
                            onSubmit={async (
                                answers,
                                { setSubmitting, setErrors },
                            ) => {
                                setSubmitting(true);
                                try {
                                    const a = await submitAnswerBuddyWorkout({
                                        variables: {
                                            answerBuddyWorkoutInput: {
                                                buddyId: id,
                                                userCardId,
                                                answers,
                                            },
                                        },
                                    });
                                    setShowTakeaway(false);
                                    setSubmitting(false);
                                } catch (error) {
                                    setErrors(error);
                                    setSubmitting(false);
                                }
                            }}
                            initialValues={{
                                keyTakeaways: '',
                                helpful: 0,
                                ...answers,
                            }}>
                            {({ isSubmitting }) => (
                                <Form className="p-5">
                                    <Field
                                        required
                                        name="keyTakeaways"
                                        component={OpenTextV2}
                                        label="Key Takeaways from our connection"
                                    />
                                    <Field
                                        required
                                        name="helpful"
                                        component={RadioHorizontalV2}
                                        options={OPTIONS}
                                        slider_left_label="Not Helpful"
                                        slider_right_label="Super Helpful"
                                        label="How helpful is this Buddy workout?"
                                    />
                                    <div className="d-flex w-100 mt-5">
                                        <Button
                                            isLoading={isSubmitting}
                                            disabled={isSubmitting}
                                            rounded
                                            style={{ borderRadius: '2px' }}
                                            className="ml-auto"
                                            type="submit"
                                            primary>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                )}
            </div>
            {hasAnswer && !showTakeaway ? (
                <div className="Buddy--answer p-5 mb-5">
                    <p className="lora large-text">
                        Key Takeaways from our connection
                    </p>
                    <p className="mb-5" style={{ fontSize: '20px' }}>
                        {answers.keyTakeaways}
                    </p>

                    <RadioHorizontalV2
                        options={OPTIONS}
                        required
                        label="How helpful this Buddy workout?"
                        slider_left_label="Not Helpful"
                        slider_right_label="Super Helpful"
                        form={{}}
                        field={{ value: answers.helpful }}
                    />
                </div>
            ) : null}
        </Fragment>
    );
};

const BuddyWorkoutScreen = ({ userId }) => {
    const [card, setCard] = useState({
        userCard: {
            buddies: [],
            buddyAnswers: {},
        },
    });
    const { id } = useParams();
    const showBack = useRouteMatch('/buddy-workout/:id/takeaways');
    const r = useQuery(GET_BUDDY_WORKOUT_CARD, {
        variables: {
            friendlyId: id,
        },
    });

    const [startUserCard, { loading }] = useMutation(START_USER_CARD);

    useEffect(() => {
        if (r.data) {
            setCard(r.data.card);
        }
    }, [r.data]);

    useEffect(() => {
        if (r.data && r.data.card) {
            startUserCard({
                variables: {
                    input: {
                        userCardId: r.data.card.userCard.id,
                        start: true,
                        userId,
                    },
                },
            });
        }
    }, [r.data]);

    const { buddies, buddyAnswers } = card.userCard;

    if (r.loading) {
        return <Loader fullscreen />;
    }

    return (
        <Fragment>
            <GiftboxTitle
                title={showBack ? card.title : 'Personal Growth'}
                subtitle={showBack ? 'Workout' : ''}
                showBack={showBack}
            />
            <div
                className="py-5 px-3 mx-auto mt-5"
                style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <Switch>
                    <Route path="/buddy-workout/:id/takeaways">
                        <h1 className="lora large-text mb-1">Buddy Workout</h1>
                        <p className="mb-4" style={{ fontSize: '20px' }}>
                            We learn better when we learn with others. Who knows
                            what these connections might lead to?
                        </p>
                        {buddies.map((b, i) => (
                            <Buddy
                                answers={buddyAnswers ? buddyAnswers[b.id] : ''}
                                key={i}
                                id={b.id}
                                userCardId={r.data.card.userCard.id}
                                avatar={b.avatar}
                                initials={`${b.firstName[0]}${b.lastName[0]}`}
                                name={`${b.firstName} ${b.lastName}`}
                                title={b.selfTitle || b.title}
                                email={b.email}
                                mobile={b.mobileNumber}
                            />
                        ))}
                    </Route>
                    <Route path="/buddy-workout/:id">
                        <div
                            className="py-5 d-flex align-items-center w-100"
                            style={{ minHeight: 'calc(100vh - 200px)' }}>
                            <DefaultMediaObject
                                to={`/buddy-workout/${id}/takeaways`}
                                type="Mastery"
                                heading={card.title}
                                body={card.description}
                                coverImage={card.imageUrl}
                            />
                        </div>
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default BuddyWorkoutScreen;
