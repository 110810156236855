import {
    POPULATE_GROUP_ANSWERS,
    UPDATE_GROUP_ANSWER,
} from './peerExchangeActions';

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case POPULATE_GROUP_ANSWERS:
            return {
                ...state,
                [`${payload.projectId}-${payload.soloWorkoutId}`]: payload.groupAnswers,
            };
        case UPDATE_GROUP_ANSWER:
            const k = `${payload.answer.project_id}-${payload.answer.discernment_id}`;
            const newState = {
                ...state,
                [k]: {
                    ...state[k],
                    [payload.answer.question_key]: state[k][
                        payload.answer.question_key
                    ].map(a =>
                        a.id === payload.answer.id
                            ? {
                                  ...a,
                                  ...payload.answer,
                                  isFavorite: payload.answer.favorited,
                              }
                            : a,
                    ),
                },
            };
            return newState;
        default:
            return state;
    }
};

export default reducer;
