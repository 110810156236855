import React, { Fragment } from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import Button from '../../components/Button';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import {
    OpenTextV2,
    RadioHorizontalV2,
    SelectV2,
    RadioV2,
    FileUpload,
    FromTo,
} from '../../components/QuestionInputV2';
import { Slider, OpenText, Select } from '../../components/QuestionInput';
import { Radio, RadioHorizontal } from '../../components/Inputs';
import { useDispatch } from 'react-redux';
import { showSampleAnswerModal } from '../../reducers/modalActions';

export const ContentV1 = ({
    question_type,
    question,
    question_key,
    options,
    max = 3,
    disabled,
    optional,
    values,
    slider_length,
    slider_left_label,
    slider_right_label,
    is_slider,
}) => {
    return (
        <div className="mb-4">
            {question_type === 'open_text' && (
                <Field
                    name={question_key}
                    label={question}
                    component={OpenText}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'multi_text' && (
                <FieldArray
                    name={question_key}
                    render={arrayHelpers => (
                        <Fragment>
                            <label
                                className="SoloWorkout--label"
                                dangerouslySetInnerHTML={{ __html: question }}
                            />
                            {values[question_key] &&
                            values[question_key].length > 0
                                ? values[question_key].map((v, i) => (
                                      <div
                                          className="d-flex w-100 mb-3"
                                          key={i}>
                                          <Field
                                              name={`${question_key}.${i}`}
                                              component={OpenText}
                                              disabled={disabled}
                                              required={!optional}
                                              noMargin
                                          />
                                          {values[question_key].length > 1 && (
                                              <Button
                                                  transparent
                                                  onClick={() =>
                                                      arrayHelpers.remove(i)
                                                  }>
                                                  <i className="fe fe-x" />
                                              </Button>
                                          )}
                                      </div>
                                  ))
                                : null}
                            {values[question_key] &&
                                values[question_key].length < max && (
                                    <Button
                                        className="mb-3"
                                        onClick={() => arrayHelpers.push('')}>
                                        <i className="fe fe-plus" />{' '}
                                        <span>Add another</span>
                                    </Button>
                                )}
                        </Fragment>
                    )}
                />
            )}
            {question_type === 'select' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Select}
                    options={options}
                    max={max}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Radio}
                    options={options}
                    disabled={disabled}
                    slider={is_slider}
                    required={!optional}
                />
            )}
            {question_type === 'slider' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Slider}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'horizontal_radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={RadioHorizontal}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                    options={options}
                />
            )}
            {question_type === 'file_upload' && (
                <Field
                    name={question_key}
                    label={question}
                    component={FileUpload}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            <ErrorMessage
                name={question_key}
                component={ErrorMessageContainer}
            />
        </div>
    );
};

const Content = ({
    question_type,
    question,
    question_key,
    options,
    max = 3,
    disabled,
    optional,
    values,
    slider_length,
    slider_left_label,
    slider_right_label,
    is_slider,
    allow_file_upload,
    sample_answer,
}) => {
    const dispatch = useDispatch();
    const showSampleAnswer = sample_answer
        ? () => {
              dispatch(showSampleAnswerModal(sample_answer));
          }
        : null;

    return (
        <div className="mb-4">
            {question_type === 'open_text' && (
                <Field
                    name={question_key}
                    label={question}
                    component={OpenTextV2}
                    disabled={disabled}
                    required={!optional}
                    showSampleAnswer={showSampleAnswer}
                    allow_file_upload={allow_file_upload}
                />
            )}
            {question_type === 'multi_text' && (
                <FieldArray
                    name={question_key}
                    render={arrayHelpers => (
                        <Fragment>
                            <label
                                className="SoloWorkout--label"
                                dangerouslySetInnerHTML={{ __html: question }}
                            />
                            {values[question_key] &&
                            values[question_key].length > 0
                                ? values[question_key].map((v, i) => (
                                      <div
                                          className="d-flex w-100 mb-3"
                                          key={i}>
                                          <Field
                                              name={`${question_key}.${i}`}
                                              component={OpenTextV2}
                                              disabled={disabled}
                                              required={!optional}
                                              noMargin
                                          />
                                          {values[question_key].length > 1 && (
                                              <Button
                                                  transparent
                                                  onClick={() =>
                                                      arrayHelpers.remove(i)
                                                  }>
                                                  <i className="fe fe-x" />
                                              </Button>
                                          )}
                                      </div>
                                  ))
                                : null}
                            {values[question_key] &&
                                values[question_key].length < max && (
                                    <Button
                                        className="mb-3"
                                        onClick={() => arrayHelpers.push('')}>
                                        <i className="fe fe-plus" />{' '}
                                        <span>Add another</span>
                                    </Button>
                                )}
                        </Fragment>
                    )}
                />
            )}
            {question_type === 'select' && (
                <Field
                    name={question_key}
                    label={question}
                    component={SelectV2}
                    options={options}
                    max={max}
                    disabled={disabled}
                    required={!optional}
                    showSampleAnswer={showSampleAnswer}
                />
            )}
            {question_type === 'radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={RadioV2}
                    options={options}
                    disabled={disabled}
                    slider={is_slider}
                    required={!optional}
                    showSampleAnswer={showSampleAnswer}
                />
            )}
            {question_type === 'slider' && (
                <Field
                    name={question_key}
                    label={question}
                    component={Slider}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                    showSampleAnswer={showSampleAnswer}
                />
            )}
            {question_type === 'horizontal_radio' && (
                <Field
                    name={question_key}
                    label={question}
                    component={RadioHorizontalV2}
                    slider_length={slider_length}
                    slider_left_label={slider_left_label}
                    slider_right_label={slider_right_label}
                    disabled={disabled}
                    required={!optional}
                    options={options}
                    showSampleAnswer={showSampleAnswer}
                />
            )}
            {question_type === 'file_upload' && (
                <Field
                    name={question_key}
                    label={question}
                    component={FileUpload}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            {question_type === 'from_to' && (
                <Field
                    name={question_key}
                    label={question}
                    component={FromTo}
                    disabled={disabled}
                    required={!optional}
                />
            )}
            <ErrorMessage
                name={question_key}
                component={ErrorMessageContainer}
            />
        </div>
    );
};

export default Content;
