import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    Fragment,
} from 'react';
import { Formik, Form } from 'formik';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInitialAnswer,
    createSchema,
    createDataForSave,
} from '../../utils/answerUtils';
import {
    submitAnswers,
    updateDiscernmentAnswer,
} from '../../reducers/itineraryActions';
import { SoloWorkoutFooter } from '../GrowthLabQuestionScreen';
import setResponseErrors from '../../utils/setResponseErrors';
import AnimatedContainer from '../../components/AnimatedContainer';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { useScroll } from '../../hooks/useScroll';
import useWindowEvent from '../../hooks/useWindowEvent';
import Content, { ContentV1 } from './Content';
import Button from '../../components/Button';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import StepTracker from '../../components/StepTracker';
import { showAuditModal } from '../../reducers/modalActions';

export const InnerSoloWorkout = ({
    c,
    values,
    isSubmitting,
    setCurrentView,
    scrollY,
    id,
    section,
    nextSection,
    isLast,
}) => {
    const ref = useRef();
    const [height, setHeight] = useState('100vh');
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    const scroll = () =>
        window.scrollTo({
            top: nextSection,
            behavior: 'smooth',
        });

    return (
        <AnimatedContainer
            id={id}
            scrollY={scrollY}
            section={section}
            setCurrentView={setCurrentView}>
            <div
                ref={ref}
                className="d-flex flex-column justify-content-center"
                style={{
                    minHeight: `calc(${height} - 84px)`,
                }}>
                <h2 className="header-pretitle isRaaz">SOLO WORKOUT</h2>
                {c.type === 'pro_tip' ? (
                    <div className="bg-gray p-4 mb-6 mt-6">
                        <strong className="mb-2 d-block">
                            <i className="fe fe-info" /> PRO TIP
                        </strong>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: c.blurb,
                            }}
                        />
                    </div>
                ) : c.type === 'question' ? (
                    <Content {...c} values={values} disabled={isSubmitting} />
                ) : (
                    <div
                        className="mb-3 soloworkout-blurb"
                        dangerouslySetInnerHTML={{
                            __html: c.blurb,
                        }}
                    />
                )}
                <div className="mt-0 w-100 d-flex">
                    {isLast ? (
                        <Button
                            primary
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}>
                            Submit
                        </Button>
                    ) : (
                        <Button
                            primary
                            onClick={scroll}
                            disabled={isSubmitting}>
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </AnimatedContainer>
    );
};

const InnerSoloWorkoutV1 = ({ content, values, isSubmitting }) => {
    return (
        <Fragment>
            <h2 className="header-pretitle isRaaz">SOLO WORKOUT</h2>
            {content.map((c, i) => (
                <Fragment key={i}>
                    {c.blurb ? (
                        <div
                            className="mb-3 soloworkout-blurb"
                            dangerouslySetInnerHTML={{
                                __html: c.blurb,
                            }}
                        />
                    ) : (
                        <ContentV1
                            {...c}
                            values={values}
                            disabled={isSubmitting}
                        />
                    )}
                </Fragment>
            ))}
            <Button
                primary
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}>
                Submit
            </Button>
        </Fragment>
    );
};

const SoloWorkoutContent = ({ title, next, content, slug }) => {
    const { scrollY } = useScroll();
    const [currentView, setCurrentView] = useState(0);
    const { offsets, scrollIntoView, setRef } = useScrollIntoView({
        offset: 72,
    });
    const { projectId } = useParams();

    const hasQuestion = content.filter(c => c.question_type).length;
    const history = useHistory();
    const dispatch = useDispatch();
    const answers = useSelector(s => s.itinerary.discernmentAnswers);
    const contentAnswers = answers[slug];

    const filteredContent = content.filter(c => c.question_key);
    const sortedContent = content.sort((a, b) => a.ordinality - b.ordinality);

    useEffect(() => {
        const fContent = sortedContent[0];
        setCurrentView(fContent.id);
        document.getElementById(fContent.id) &&
            document.getElementById(fContent.id).scrollIntoView();
    }, [sortedContent]);

    const initialValues = filteredContent.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.question_key]: getInitialAnswer(cur, contentAnswers),
        };
    }, {});

    const goBack = () => history.goBack();

    const schema = createSchema(filteredContent);

    if (hasQuestion) {
        return (
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    setSubmitting(true);
                    const data = createDataForSave(values, slug);

                    dispatch(
                        submitAnswers('', {
                            ...data,
                            ...(projectId && { project_id: projectId }),
                        }),
                    ).then(
                        res => {
                            setSubmitting(false);
                            dispatch(updateDiscernmentAnswer(data));

                            history.push(next);
                        },
                        err => {
                            setSubmitting(false);
                            setResponseErrors(err, setFieldError);
                        },
                    );
                }}>
                {({ isSubmitting, values }) => (
                    <Form>
                        <div
                            className="d-flex flex-column mx-auto py-3"
                            style={{
                                position: 'relative',
                                maxWidth: MAX_WIDTH,
                            }}>
                            <StepTracker
                                length={offsets.length}
                                currentView={currentView}
                                scrollIntoView={scrollIntoView}
                            />

                            <div className="w-100" ref={setRef}>
                                {sortedContent.map((c, i) => (
                                    <InnerSoloWorkout
                                        c={c}
                                        key={i}
                                        id={c.id}
                                        scrollY={scrollY}
                                        section={i}
                                        nextSection={offsets[i + 1]}
                                        isLast={i === content.length - 1}
                                        values={values}
                                        isSubmitting={isSubmitting}
                                        setCurrentView={setCurrentView}
                                    />
                                ))}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    return (
        <div>
            <div className="px-4 py-2">
                <h2>{title}</h2>
                {content
                    .sort((a, b) => a.ordinality - b.ordinality)
                    .map(c => (
                        <div
                            key={c.id}
                            dangerouslySetInnerHTML={{ __html: c.blurb }}
                        />
                    ))}
            </div>
            <SoloWorkoutFooter goBack={goBack} to={next} />
        </div>
    );
};

export default SoloWorkoutContent;
