import React, { useState, Fragment } from 'react';
import Button from './Button';
import {
    RestartIcon,
    HundredHIcon,
    GrowthlabsIcon,
    PeakPocketIcon,
    RocketIcon,
    PeakPadIcon,
    ChixIcon,
    VideoIcon,
    RaazLogo,
} from './RaazIcons';
import { FloatingCloseBtn } from './Modal';
import Youtube, { VideoContainerFullParent } from './Youtube';

const activeStyle = {
    transform: 'scale(1.1) translate3d(0, -2px, 0)',
    opacity: '1',
};

const WelcomeContent = ({
    backgroundImage,
    Icon,
    title,
    to,
    body,
    iconFill,
    setActiveSlide,
    activeSlide,
    closeSlider,
    frequency = [],
    deadline,
    children,
    noMargin,
}) => (
    <div
        className={`RaazWelcome p-5 d-flex flex-column ${
            noMargin ? 'm-0' : 'mt-5'
        } mx-auto`}
        style={{
            backgroundImage: `url('${backgroundImage}')`,
            maxWidth: '1024px',
        }}>
        {closeSlider && (
            <FloatingCloseBtn onClick={closeSlider} style={{ top: '-3rem' }} />
        )}
        {body && (
            <div className="RaazWelcome-content">
                {title && (
                    <div className="d-flex align-items-center mb-2">
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                borderRadius: '6px',
                                backgroundColor: iconFill,
                                width: '3rem',
                                height: '3rem',
                            }}>
                            <Icon width="24" height="24" fill="#fff" />
                        </div>
                        <h1 className="mb-0 mt-0 ml-2 text-uppercase">
                            {title}
                        </h1>
                    </div>
                )}
                <div
                    className="frequency d-inline-block mb-3 align-items-center"
                    style={
                        frequency.length > 0
                            ? {}
                            : {
                                  visibility: 'hidden',
                                  height: 0,
                              }
                    }>
                    {frequency.map((f, i) => (
                        <span className="f d-inline-block" key={i}>
                            {f}
                        </span>
                    ))}
                    <span className="ml-2 d-inline-block pr-2">{deadline}</span>
                </div>
                <p
                    className="mb-5"
                    style={{ maxWidth: '440px', fontSize: '0.875rem' }}
                    dangerouslySetInnerHTML={{ __html: body }}
                />
                {to && (
                    <Button light to={to}>
                        Get Started
                    </Button>
                )}
            </div>
        )}
        {children}

        <div className="RaazWelcome-select mt-auto justify-content-end d-flex">
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#7356BF',
                    opacity: '0.4',
                    ...(activeSlide === 'video' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'video')}>
                <VideoIcon />
            </Button>

            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#276EF1',
                    opacity: '0.4',
                    ...(activeSlide === 'os100' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'os100')}>
                <RestartIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#ff862d',
                    opacity: '0.4',
                    ...(activeSlide === 'hundredH' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'hundredH')}>
                <HundredHIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#f65050',
                    opacity: '0.4',
                    ...(activeSlide === 'gl' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'gl')}>
                <GrowthlabsIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#87878b',
                    opacity: '0.4',
                    ...(activeSlide === 'challenge' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'challenge')}>
                <RocketIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#02b482',
                    opacity: '0.4',
                    ...(activeSlide === 'peakpocket' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'peakpocket')}>
                <PeakPocketIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#f2307f',
                    opacity: '0.4',
                    ...(activeSlide === 'peakpad' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'peakpad')}>
                <PeakPadIcon />
            </Button>
            <Button
                className="mr-3 mt-3"
                style={{
                    backgroundColor: '#ffc043',
                    opacity: '0.4',
                    ...(activeSlide === 'chix' && activeStyle),
                }}
                onClick={setActiveSlide.bind(null, 'chix')}>
                <ChixIcon />
            </Button>
        </div>
    </div>
);

const OS100 = p => (
    <WelcomeContent
        title="O/S 100"
        to="/os100"
        body="Your step-by-step GPS for Peak Performance in the New Normal - you advance in a deeply personal way while measuring progress vs. a 100-point scale."
        Icon={RestartIcon}
        backgroundImage="/raaz-slider/os-100-image.jpg"
        iconFill="#276EF1"
        frequency={['Stop 1–8']}
        deadline="in 100 days"
        {...p}
    />
);

const HundredH = p => (
    <WelcomeContent
        title="100H LAUNCHPAD"
        to="/100h/projects"
        body="Your team's step-by-step accelerator, to go from idea to market as lean, agile and fast as you can: 100H Launchpad is where we ultimately win or lose in the new normal."
        Icon={HundredHIcon}
        iconFill="#ff862d"
        backgroundImage="/raaz-slider/100-h-l-image.jpg"
        frequency={['Hurdles 1–12']}
        deadline="in 100 hours"
        {...p}
    />
);

const GL = p => (
    <WelcomeContent
        title="24/7 GROWTHLABS"
        to="/growthlabs"
        body="Your peer-to-peer media network, where you experience peak performance and apply peak practices in live case studies and real world scenarios."
        Icon={GrowthlabsIcon}
        iconFill="#f65050"
        backgroundImage="/raaz-slider/247-gl-image-1.jpg"
        frequency={['M–F']}
        deadline="in 60 days"
        {...p}
    />
);

const HChallenge = p => (
    <WelcomeContent
        title="100H CHALLENGE"
        to="/growthlabs"
        body="We gathered some of the leading minds in business  to dig up ventures that can be launched in 100 hours or less (read: “super fast”). It's about exploring opportunities brought on by our New Normal – for those of us who can move fast and agile."
        Icon={RocketIcon}
        iconFill="#87878b"
        backgroundImage="/raaz-slider/100-h-l-image-1.jpg"
        frequency={['T', 'Th']}
        deadline="10:30 AM–12:00 PM"
        {...p}
    />
);

const PeakPocket = p => (
    <WelcomeContent
        title="Peak Pocket Sessions"
        to="#"
        body={`
          Mindfulness Based Stress Reduction Practices<br /><br />
One, start your morning fully refreshed. Two, refocus in the middle of the day to channel away from stress, anxiety and things beyond your control. Three, relax to be fully present for your loved ones at the end of a hardworking day.<br /><br />
All this from the comfort of our home.
        `}
        Icon={PeakPocketIcon}
        iconFill="#02b482"
        backgroundImage="/raaz-slider/pps-image.jpg"
        deadline="3 times daily"
        frequency={['10 mins']}
        {...p}
    />
);

const PeakPad = p => (
    <WelcomeContent
        title="PEAKPAD"
        to="/growthlabs"
        body={`
          Each week, we dive into another GrowthLab challenge with another leader on his or her road to Peak Performance - or, as we call it in Ignite, Peak Wholeness. Our deeply personal capacity to continually connect with our gifts and our pains, and to channel them into relentless innovation and impact - concrete ways we can renew the world and make it a better place for the people around us.
          `}
        Icon={PeakPadIcon}
        iconFill="#f2307f"
        backgroundImage="/raaz-slider/peakpad-image.jpg"
        frequency={['F']}
        deadline="2:30–4:30 PM"
        {...p}
    />
);

const Chix52 = p => (
    <WelcomeContent
        title="CHI/X 52"
        to="/chix-52"
        body="Each week (52 weeks a year!), we give you a new showcase of human-centered, data-driven innovation - personalized for you in cahoots with our partners in banking, insurance, communications, healthcare, education, etc."
        Icon={ChixIcon}
        iconFill="#ffc043"
        backgroundImage="/raaz-slider/chix-52-image-1.jpg"
        {...p}
    />
);

const VideoSlide = p => {
    const [showVideo, setShowVideo] = useState(false);
    const [showPagination, setShowPagination] = useState(false);
    return (
        <WelcomeContent backgroundImage="/raaz-slider/video-poster.jpg" {...p}>
            <div
                className="d-flex justify-content-center align-items-center flex-column mt-auto"
                style={{ zIndex: showPagination ? 2 : 3 }}>
                {!showVideo ? (
                    <Fragment>
                        <RaazLogo fill="#fff" />
                        <p className="text-center">
                            A web-based operating system
                            <br />
                            to help individuals, teams and organizations
                            <br />
                            survive and thrive in chaotic change.
                        </p>
                        <Button transparent onClick={() => setShowVideo(true)}>
                            <img
                                src="/raaz-slider/ic_play_circle_filled@3x.png"
                                style={{ maxWidth: '3rem' }}
                            />
                        </Button>
                    </Fragment>
                ) : (
                    <VideoContainerFullParent
                        id="DNsEvlTMzW4"
                        onEnd={() => setShowVideo(false)}
                        onPause={() => setShowPagination(true)}
                        onPlay={() => setShowPagination(false)}
                        showPagination={showPagination}
                    />
                )}
            </div>
        </WelcomeContent>
    );
};

const RaazWelcome = ({ closeSlider, noPadding }) => {
    const [activeSlide, setActiveSlide] = useState('video');

    let El = null;
    switch (activeSlide) {
        case 'os100':
            El = (
                <OS100
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'hundredH':
            El = (
                <HundredH
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'gl':
            El = (
                <GL
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'challenge':
            El = (
                <HChallenge
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'peakpocket':
            El = (
                <PeakPocket
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'peakpad':
            El = (
                <PeakPad
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;
        case 'chix':
            El = (
                <Chix52
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;

        case 'video':
            El = (
                <VideoSlide
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                    closeSlider={closeSlider}
                    noMargin={noPadding}
                />
            );
            break;

        default:
            return null;
    }

    return <div className={noPadding ? '' : 'p-5'}>{El}</div>;
};

export default RaazWelcome;
