import React, { Fragment, useState, useEffect } from 'react';
import {
    Route,
    useParams,
    Switch,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import { Giftbox } from './GiftboxCollectionScreen';
import { GiftboxTitle } from '../components/ProjectTitle';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import GiftboxMediaObject, {
    BuddyMediaObject,
} from '../components/GiftboxMediaObject';
import GrowthLabEvent from '../components/GrowthLabEvent';
import { useQuery, gql } from '@apollo/client';
import GET_USER_CARD from './queries/getUserCard';
import Loader from '../components/Loader';
import { useDispatch } from 'react-redux';
import { getDataViz } from '../reducers/usersActions';
import { DataVisualization } from './ChartScreen';

const MustReads = ({ articles }) => (
    <div className="py-5">
        {articles.map((a, i) => (
            <GiftboxMediaObject
                key={i}
                href={a.url}
                heading={a.name}
                body={a.description}
                coverImg={a.imageUrl}
                type="Must Reads"
            />
        ))}
    </div>
);

const BuddyWorkout = ({ id, fromGiftbox }) => {
    const { userCardId } = useParams();
    const path = `/giftbox/${userCardId}/buddy-workout/${id}${
        fromGiftbox ? '?fromGiftbox=true' : ''
    }`;
    return (
        <div className="py-5">
            <GiftboxMediaObject
                to={path}
                type="Buddy Workout"
                heading="People You Should Meet"
                coverImg="/giftbox-bg/buddy-card-bg.jpg"
                body="We learn better when we learn with others - especially when smart algorithms work round the clock to find these matches. Beyond learnings, who knows what kind of collaborations these conversations will bring!"
            />
        </div>
    );
};

const DataViz = ({ id, fromGiftbox }) => {
    const { userCardId } = useParams();
    const path = `/giftbox/${userCardId}/data-viz/${id}${
        fromGiftbox ? '?fromGiftbox=true' : ''
    }`;
    return (
        <div className="py-5">
            <GiftboxMediaObject
                to={path}
                type="Data is Beautiful"
                heading="Data is Beautiful"
                coverImg="/giftbox-bg/data-card-bg.jpg"
                body="Powerful insights await you with these data visualizations, all updated in real time based on the RESTART community’s collective responses to our smart cards. "
            />
        </div>
    );
};

const GET_GIFTBOX_DETAILS = gql`
    query getGiftboxDetails($userIds: [String!]!) {
        users(userIds: $userIds) {
            id
            avatar
            company
            email
            firstName
            lastName
            title
            selfTitle
            mobileNumber
        }

        events {
            id
            isCurrent
            name
            startTime
            endTime
            coverImageUrl
            webcastUrl
            date
        }
    }
`;

const DataVizReports = ({ title }) => {
    const { datavizId } = useParams();
    const [reports, setReports] = useState();
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(getDataViz(datavizId)).then(
            data => {
                setLoading(false);
                setReports(data);
            },
            err => {
                setLoading(false);
                setReports(null);
            },
        );
    }, [datavizId]);

    return (
        <div className="py-5">
            <h1 className="lora large-text">Data is Beautiful</h1>
            <p style={{ fontSize: '20px' }}>
                Powerful insights await you with these data visualizations, all
                updated in real time based on the RESTART community’s collective
                responses to our smart cards.
            </p>

            {isLoading && <Loader />}
            {!isLoading && !reports ? <div>No Reports yet</div> : null}
            {!isLoading && reports ? (
                <DataVisualization reports={reports} />
            ) : null}
        </div>
    );
};

const GiftboxScreen = ({ userId }) => {
    const { userCardId } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const fromGiftbox = query.get('fromGiftbox') !== null;

    const { data, loading } = useQuery(GET_USER_CARD, {
        variables: {
            id: userCardId,
            userId,
        },
    });

    const r = useQuery(GET_GIFTBOX_DETAILS, {
        skip: !data,
        variables: {
            userIds: data ? data.userCard.giftboxMeta.user_ids : [],
            // eventIds: data ? data.userCard.giftboxMeta.event_ids : [],
            // mustReadIds: data ? data.userCard.giftboxMeta.must_read_ids : []
        },
    });

    const isBuddyWorkoutSingle = useRouteMatch(
        '/giftbox/:userCardId/buddy-workout/:buddyWorkoutId',
    );
    const isDataVizSingle = useRouteMatch(
        '/giftbox/:userCardId/data-viz/:dataVizId',
    );

    if (loading || !data || !r.data) return <Loader fullscreen />;

    const { userCard } = data;

    const eventsToAttend = userCard.giftboxMeta.event_ids.map(eventId =>
        r.data.events.find(e => e.id === eventId),
    );

    return (
        <Fragment>
            <GiftboxTitle
                title={userCard.card.title}
                showBack={isBuddyWorkoutSingle || isDataVizSingle}
                fromGiftbox={fromGiftbox}
            />
            <div
                className="py-5 px-3 mx-auto mt-5"
                style={{ maxWidth: MAX_WIDTH * 1.8 }}>
                <Switch>
                    <Route path="/giftbox/:userCardId/buddy-workout/:buddyWorkoutId">
                        <div className="py-5">
                            {r.data.users.map((user, i) => (
                                <BuddyMediaObject
                                    profileImage={user.avatar}
                                    name={`${user.firstName} ${user.lastName}`}
                                    initials={`${user.firstName[0]}${user.lastName[0]}`}
                                    position={user.selfTitle || user.title}
                                    description={user.company}
                                    email={user.email}
                                    mobile={user.mobileNumber}
                                    key={i}
                                />
                            ))}
                        </div>
                    </Route>
                    <Route path="/giftbox/:userCardId/buddy-workout">
                        <BuddyWorkout
                            id={userCard.card.id}
                            fromGiftbox={fromGiftbox}
                        />
                    </Route>
                    <Route path="/giftbox/:userCardId/growthlabs">
                        <div className="py-5">
                            {eventsToAttend.map((event, i) => (
                                <GrowthLabEvent
                                    key={i}
                                    currentEvent={{
                                        coverImageUrl: event.coverImageUrl,
                                        date: event.date,
                                        name: event.name,
                                        startTime: event.startTime,
                                        endTime: event.endTime,
                                        description: event.description,
                                        webcastUrl: event.webcastUrl,
                                        id: event.id,
                                        isCurrent: event.isCurrent,
                                        location: '',
                                    }}
                                />
                            ))}
                        </div>
                    </Route>
                    <Route path="/giftbox/:userCardId/data-viz/:datavizId">
                        <DataVizReports title={userCard.card.title} />
                    </Route>
                    <Route path="/giftbox/:userCardId/data-viz">
                        <DataViz
                            id={userCard.giftboxMeta.data_visualization_id}
                            fromGiftbox={fromGiftbox}
                        />
                    </Route>
                    <Route path="/giftbox/:userCardId/must-reads">
                        <MustReads articles={userCard.mustReads || []} />
                    </Route>
                    <Route path="/giftbox/:userCardId" exact>
                        <Giftbox fromGiftbox={fromGiftbox} />
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default GiftboxScreen;
