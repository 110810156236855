import React from 'react';
import AttendanceIllus from './AttendanceIllus';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import AttendanceButton from './AttendanceButton';

const Attendance = ({ next, eventId, userId }) => {
    const history = useHistory();
    return (
        <div className="d-flex align-items-center justify-content-center flex-column">
            <AttendanceIllus />
            <h1 className="mt-5 lora text-large">I am here!</h1>
            <p className="text-center mb-5 pb-3">
                My presence and participation matters.
            </p>
            <AttendanceButton
                className="d-flex align-items-center"
                primary
                eventId={eventId}
                userId={userId}
                successCb={() => history.push(next)}
                rounded
                style={{ borderRadius: '2px' }}>
                <span className="mr-2">Count me in</span>
                <i className="fe fe-arrow-right" />
            </AttendanceButton>
        </div>
    );
};

export default Attendance;
