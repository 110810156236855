import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import Button from '../../components/Button';
import setResponseErrors from '../../utils/setResponseErrors';
import { useHistory } from 'react-router-dom';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import {
    OpenTextV2,
    RadioHorizontalV3,
} from '../../components/QuestionInputV2';
import { gql, useMutation } from '@apollo/client';
/*
const BoolField = ({ field, disabled, form }) => (
    <div className="d-flex align-items-center">
        <input
            className="mr-3"
            id="independent"
            type="checkbox"
            disabled={disabled}
            onChange={() => {
                form.setFieldValue(field.name, !field.value);
            }}
        />
        <label className="mb-0 d-flex flex-column" htmlFor="independent">
            <strong>This project is independent of any company</strong>
            <small className="text-muted">
                If this project is independent of any company, please mark as
                independent
            </small>
        </label>
    </div>
);
*/

const ADD_PROJECT = gql`
    mutation AddProject($addProjectInput: AddProjectInput!, $userId: ID!) {
        addProject(input: $addProjectInput) {
            errors
            success
            project {
                id
                archived
                description
                members
                ordinality
                title
                currentStop(userId: $userId) {
                    id
                    duration
                    name
                    ordinality
                    stopType
                }
                currentSubstop(userId: $userId)
                owner {
                    id
                    displayName
                    avatar
                    email
                }
                users {
                    id
                    displayName
                    avatar
                    email
                }
                stops {
                    discernment {
                        id
                        name
                        slug
                        ordinality
                    }
                    id
                    duration
                    name
                    ordinality
                    stopType
                }
                costs
                partners
                sales
                workforce
                customers
            }
        }
    }
`;

const ProjectSchema = yup.object().shape({
    title: yup.string().required('This field is required'),
    description: yup.string().required('This field is required'),
});

const OPTIONS = [
    { body: 1, value: 1 },
    { body: 2, value: 2 },
    { body: 3, value: 3 },
    { body: 4, value: 4 },
];

const initialValues = {
    title: '',
    description: '',
    workforce: 0,
    partners: 0,
    customers: 0,
    sales: 0,
    costs: 0,
    operations: 0,
};

const NewProjectScreen = ({ userId }) => {
    const history = useHistory();
    const [addProject] = useMutation(ADD_PROJECT);
    return (
        <Formik
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);

                try {
                    const res = await addProject({
                        variables: {
                            addProjectInput: {
                                attributes: { ...values, userId },
                            },
                            userId,
                        },
                    });
                    setSubmitting(false);
                    history.push(
                        `/100h/projects/${res.data.addProject.project.id}`,
                    );
                } catch (error) {
                    setSubmitting(false);
                    setResponseErrors(err, setFieldError);
                }
            }}
            initialValues={initialValues}
            validationSchema={ProjectSchema}>
            {({ isSubmitting }) => (
                <Form
                    style={{
                        minHeight: 'calc(100vh - 230px)',
                        maxWidth: MAX_WIDTH,
                    }}
                    className="d-flex flex-column mx-auto p-5">
                    <div className="mb-3">
                        <Field
                            component={OpenTextV2}
                            disabled={isSubmitting}
                            className="mb-1"
                            name="title"
                            label="Create new project"
                            placeholder="Project Name"
                            max={400}
                        />
                        <ErrorMessage
                            component={ErrorMessageContainer}
                            name="title"
                        />
                        <Field
                            component={OpenTextV2}
                            disabled={isSubmitting}
                            name="description"
                            className="mb-1"
                            placeholder="Description"
                            max={400}
                        />
                        <ErrorMessage
                            component={ErrorMessageContainer}
                            name="description"
                        />
                    </div>
                    <h2 style={{ fontSize: '20px' }}>Impact Audit</h2>
                    <p style={{ fontSize: '14px' }} className="mb-4">
                        Rate the impact of this 100H Project on
                    </p>
                    <Field
                        component={RadioHorizontalV3}
                        slider
                        disabled={isSubmitting}
                        options={OPTIONS}
                        top
                        slider_left_label="Not Much"
                        slider_right_label="Very Much"
                        label="1. Customer Loyalty & Growth"
                        name="customers"
                        description="Bushwick swag fam, sustainable readymade subway tile copper mug taxidermy tattooed small batch four loko 3 wolf moon jean shorts vice mumblecore."
                    />
                    <Field
                        component={RadioHorizontalV3}
                        slider
                        disabled={isSubmitting}
                        options={OPTIONS}
                        label="2. Workforce Performance & Morale"
                        name="workforce"
                    />

                    <Field
                        component={RadioHorizontalV3}
                        slider
                        disabled={isSubmitting}
                        options={OPTIONS}
                        label="3. Operations & Cost Efficiencies"
                        name="costs"
                    />

                    <Field
                        component={RadioHorizontalV3}
                        slider
                        disabled={isSubmitting}
                        options={OPTIONS}
                        bottom
                        slider_left_label="Not Much"
                        slider_right_label="Very Much"
                        label="4. Revenue Growth"
                        name="sales"
                    />

                    <div className="d-flex mt-5 justify-content-end">
                        <Button
                            className="mr-3"
                            rounded
                            secondary
                            disabled={isSubmitting}
                            to="/">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            rounded
                            primary
                            disabled={isSubmitting}
                            isLoading={isSubmitting}>
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default NewProjectScreen;
