import React, { useState } from 'react';
import PeerExchangeGroupAnswers from './PeerExchangeGroupAnswers';
import { useScrollIntoView } from '../hooks/useScrollIntoView';
import StepTracker from '../components/StepTracker';
import { useScroll } from '../hooks/useScroll';
import { MAX_WIDTH } from '../utils/CONSTANTS';

const PeerExchangeResponsesScreen = ({ content, groupAnswers, members }) => {
    const { scrollY } = useScroll();
    const [currentView, setCurrentView] = useState(0);
    const { scrollIntoView, offsets, setRef } = useScrollIntoView({
        offset: 72,
    });

    return (
        <div
            className="mx-auto w-100"
            style={{ maxWidth: MAX_WIDTH * 1.33, position: 'relative' }}>
            <StepTracker
                length={offsets.length}
                scrollIntoView={scrollIntoView}
                currentView={currentView}
            />
            <div ref={setRef} className="w-100 pt-5 mt-5">
                {content.map((c, i) => {
                    /* Remove html tags from c.question */
                    const div = document.createElement('div');
                    div.innerHTML = c.question;
                    const isFromTo = c.question_type === 'from_to';
                    return (
                        <PeerExchangeGroupAnswers
                            id={c.question_key}
                            key={i}
                            scrollY={scrollY}
                            question={div.innerText}
                            setCurrentView={setCurrentView}
                            section={i}
                            isFromTo={isFromTo}
                            groupAnswers={groupAnswers[c.question_key]}
                            options={c.options}
                            nextSection={offsets[i + 1]}
                            isLast={i === content.length - 1}
                            members={members}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default PeerExchangeResponsesScreen;
