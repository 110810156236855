import React, { Fragment } from 'react';
import { CurrentStaticStep } from './100HCurrentStep';
import Button from './Button';
import { StackIcon } from './RaazIcons';

export const getTypeSlug = type => {
    switch (type) {
        case 'iterated_response':
            return 'iterated-response';

        case 'peer_exchange':
            return 'peer-exchange';

        default:
            return type;
    }
};

const ProjectStack = ({
    title,
    description,
    id,
    cover_image,
    company_name,
    members,
    currentStop = {},
    currentSubstop,
    stops,
    idx,
}) => {
    const stopData = (stops || []).find(s => s.id === currentStop.id);
    const to = `/100h/projects/${id}/hurdles/${currentStop.id}/${getTypeSlug(
        currentSubstop,
    )}/${stopData.discernment.id}`;
    return (
        <Fragment>
            <div
                className="d-flex align-items-center mb-3"
                style={{ fontSize: '20px' }}>
                <span>Launchpad {idx}</span>
                <span
                    className="mx-4"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#c4c4c4',
                    }}
                />
                <div>
                    <StackIcon fill="#276EF1" />
                    <span
                        className="ml-1"
                        style={{
                            fontSize: '14px',
                            color: '#717171',
                            fontWeight: '600',
                        }}>
                        {currentStop.ordinality} of 9 Hurdles
                    </span>
                </div>
            </div>
            <div className="ProjectStack--container p-3 mb-5">
                <div className="ProjectStack--cover d-flex flex-column justify-content-center py-5">
                    <div className="ProjectTitle px-3 mb-5">
                        <h3
                            className="mb-3 large-text"
                            style={{ fontWeight: 'bold' }}>
                            {title}
                        </h3>
                        <p className="mb-0" style={{ fontSize: '14px' }}>
                            {description}
                        </p>
                    </div>
                    <div
                        className="px-5"
                        style={{ borderLeft: '8px solid transparent' }}>
                        <h4
                            className="text-uppercase"
                            style={{ fontSize: '14px', fontWeight: '600' }}>
                            Members ({members.length})
                        </h4>
                        {members.length <= 6 && (
                            <div className="ProjectStack--members">
                                {members.map((_, i) => (
                                    <img
                                        src="./icon.png"
                                        className="avatar avatar-sm"
                                        key={i}
                                    />
                                ))}
                            </div>
                        )}
                        {members.length > 6 && (
                            <div className="ProjectStack--members">
                                <div className="avatar-container">
                                    <img
                                        src="./icon.png"
                                        className="avatar avatar-sm"
                                    />
                                </div>
                                {members.slice(0, 5).map((m, i) => (
                                    <img
                                        src={m.avatar || './icon.png'}
                                        className="avatar avatar-sm"
                                        key={i}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="ProjectStack--substop p-5 d-flex align-items-start flex-column justify-content-center">
                    <div
                        className="d-flex align-items-center"
                        style={{ color: '#121212' }}>
                        <span
                            style={{
                                color: 'white',
                                fontSize: '20px',
                                width: '2rem',
                                height: '2rem',
                                overflow: 'hidden',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#121212',
                                flexShrink: 0,
                            }}>
                            {currentStop.ordinality}
                        </span>
                        <span
                            className="ml-5"
                            style={{ fontSize: '20px', fontWeight: '600' }}>
                            {currentStop.name}
                        </span>
                    </div>
                    <CurrentStaticStep
                        active={currentSubstop}
                        projectId=""
                        stopId=""
                        soloWorkoutId=""
                    />
                    <Button
                        block
                        to={to}
                        primary
                        rounded
                        style={{ borderRadius: '2px' }}
                        className="mt-3 d-flex align-items-center text-center justify-content-center">
                        <span className="mr-2">Go</span>
                        <i className="fe fe-arrow-right" />
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default ProjectStack;
