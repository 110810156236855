export const MODAL_TYPES = {
    RESET_PW_SUCCESS: 'RESET_PW_SUCCESS',
    LOCK_MODAL: 'LOCK_MODAL',
    LICENSE_MODAL: 'LICENSE_MODAL',
    POSTCARD_MODAL: 'POSTCARD_MODAL',
    LOCKED_RESTART_SOLO_WORKOUT_MODAL: 'LOCKED_RESTART_SOLO_WORKOUT_MODAL',
    LOCKED_RESTART_GROWTHLABS_MODAL: 'LOCKED_RESTART_GROWTHLABS_MODAL',
    LOCKED_RESTART_POSTCARD_MODAL: 'LOCKED_RESTART_POSTCARD_MODAL',
    LOCKED_RESTART_DATAVIZ_MODAL: 'LOCKED_RESTART_DATAVIZ_MODAL',
    HUNDRED_SOLO_WORKOUT: 'HUNDRED_SOLO_WORKOUT',
    HUNDRED_PEER_EXCHANGE: 'HUNDRED_PEER_EXCHANGE',
    HUNDRED_ITERATED_RESPONSE: 'HUNDRED_ITERATED_RESPONSE',
    PEER_EXCHANGE_MODAL: 'PEER_EXCHANGE_MODAL',
    RAAZ_WELCOME: 'RAAZ_WELCOME',
    AUDIT_MODAL: 'AUDIT_MODAL',
    CHECKBOX_MODAL: 'CHECKBOX_MODAL',
    SUBSCRIBE_NOW_MODAL: 'SUBSCRIBE_NOW_MODAL',
    DELETE_MODAL: 'DELETE_MODAL',
    SAMPLE_ANSWER_MODAL: 'SAMPLE_ANSWER_MODAL',
    PERSONAL_GROWTH_MODAL: 'PERSONAL_GROWTH_MODAL',
    BUSINESS_IMPACT_MODAL: 'BUSINESS_IMPACT_MODAL',
};

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';

export const showModal = type => ({ type: SHOW_MODAL, payload: { type } });
export const hideModal = () => ({ type: HIDE_MODAL });
export const showLockModal = lockDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.LOCK_MODAL,
        lockDetails,
    },
});

export const showPostcardModal = postcard => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.POSTCARD_MODAL,
        postcard,
    },
});

export const showLicenseModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.LICENSE_MODAL,
    },
});

export const showLockedRestartModal = substop => {
    let type = '';

    switch (substop) {
        case 'solo_workout':
            type = MODAL_TYPES.LOCKED_RESTART_SOLO_WORKOUT_MODAL;
            break;
        case 'postcard':
            type = MODAL_TYPES.LOCKED_RESTART_POSTCARD_MODAL;
            break;
        case 'dataviz':
            type = MODAL_TYPES.LOCKED_RESTART_DATAVIZ_MODAL;
            break;
        case 'growthlab':
            type = MODAL_TYPES.LOCKED_RESTART_GROWTHLABS_MODAL;
            break;
        case 'peer_exchange':
            type = MODAL_TYPES.HUNDRED_PEER_EXCHANGE;
            break;
        case 'iterated_response':
            type = MODAL_TYPES.HUNDRED_ITERATED_RESPONSE;
            break;
        default:
            break;
    }

    return {
        type: SHOW_MODAL,
        payload: {
            type: type,
        },
    };
};

export const show100hSoloWorkoutModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.HUNDRED_SOLO_WORKOUT,
    },
});

export const show100hPeerExchangeModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.HUNDRED_PEER_EXCHANGE,
    },
});

export const show100hIteratedResponseModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.HUNDRED_ITERATED_RESPONSE,
    },
});

export const showPeerExchangeModal = peerExchangeDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.PEER_EXCHANGE_MODAL,
        peerExchangeDetails,
    },
});

export const showRaazSlider = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.RAAZ_WELCOME,
    },
});

export const showAuditModal = auditDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.AUDIT_MODAL,
        auditDetails,
    },
});

export const showCheckboxModal = checkboxDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.CHECKBOX_MODAL,
        checkboxDetails,
    },
});

export const showSubscribeNowModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.SUBSCRIBE_NOW_MODAL,
    },
});

export const showDeleteModal = deleteDetails => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.DELETE_MODAL,
        deleteDetails,
    },
});

export const showSampleAnswerModal = sampleAnswer => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.SAMPLE_ANSWER_MODAL,
        sampleAnswer,
    },
});

export const showPersonalGrowthModal = dashboardStats => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.PERSONAL_GROWTH_MODAL,
        dashboardStats,
    },
});

export const showBusinessImpactModal = dashboardStats => ({
    type: SHOW_MODAL,
    payload: {
        type: MODAL_TYPES.BUSINESS_IMPACT_MODAL,
        dashboardStats,
    },
});
