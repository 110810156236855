import React from 'react';
import ReactAddToCalendar from 'react-add-to-calendar';
import * as moment from 'moment';
import Button from './Button';
import { getStartTime, getEndTime } from '../utils/getStartEndTime';

const UpcomingEvent = ({
    cover_image,
    name,
    description,
    date,
    start_time,
    end_time,
    location,
    features,
    event_id,
    isCurrent,
    learn_more_url,
}) => {
    const check = moment(date);
    const startTime = getStartTime(date, start_time);
    const endTime = getEndTime(date, end_time);
    let event = {
        title: name,
        description: description,
        location: location || '',
        startTime: startTime.format(),
        endTime: endTime.format(),
    };
    return (
        <div
            className="d-flex align-items-center mb-4 w-100"
            style={{
                boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.1)`,
                borderRadius: '12px',
            }}>
            <div
                className="EventImage mr-3"
                style={{
                    borderTopLeftRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    backgroundImage: `url("${cover_image}")`,
                    overflow: 'hidden',
                }}
            />
            <div className="mr-auto py-4 pr-3">
                <h3
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: name }}
                />
                <p
                    className="mb-2"
                    style={{
                        fontSize: '0.6875rem',
                        color: 'rgba(60, 60, 67, 0.6)',
                    }}>
                    {check.format('DD MMMM YYYY')}{' '}
                    {start_time && end_time
                        ? `• ${startTime.format('H:mm A')} – ${endTime.format(
                              'H:mm A',
                          )}`
                        : ''}
                </p>
                {features && (
                    <p
                        className="mb-0"
                        style={{
                            fontSize: '0.6875rem',
                            color: 'rgba(60, 60, 67, 0.6)',
                        }}>
                        <i className="fe fe-user mr-2" />
                        <span>{features}</span>
                    </p>
                )}
            </div>

            <div
                className="d-flex flex-column align-items-center"
                style={{ flexShrink: 0 }}>
                {isCurrent ? (
                    <Button
                        light
                        rounded
                        small
                        to={`/growthlab-app/${event_id}`}
                        style={{ fontSize: '11px' }}>
                        <span>Next</span>
                    </Button>
                ) : (
                    <ReactAddToCalendar
                        event={event}
                        buttonLabel="Add to calendar"
                        useFontAwesomeIcons={false}
                        className="btn btn-primary"
                        buttonTemplate={{ 'fe fe-calendar': 'right' }}
                    />
                )}
            </div>
        </div>
    );
};
export default UpcomingEvent;
