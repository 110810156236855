import React, { useState, Fragment } from 'react';
import Button from './Button';
import { PERSONAL_GROWTH, BUSINESS_IMPACT } from '../utils/CONSTANTS';
import ProjectStack from './ProjectStack';
import StackItem from './StackItem';

const ExpandIcon = ({ width = 10, height = 15, fill = '#717171' }) => (
    <svg width={width} height={height} viewBox="0 0 10 15" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.60002 11.2207L7.77002 14.0467L9.18002 12.7897L4.60002 8.69782L0.0100234 12.7897L1.43002 14.0467M4.58 2.82601L1.41 5.5426e-07L0 1.25699L4.58 5.34891L9.17 1.25699L7.75 0L4.58 2.82601Z"
            fill={fill}
        />
    </svg>
);

const Stack = ({ idx, cards, type = PERSONAL_GROWTH, project, userId }) => {
    const [expand, setExpand] = useState(false);
    return (
        <div className="mb-4">
            {type === PERSONAL_GROWTH && (
                <Fragment>
                    <div className="StackItems">
                        {cards.slice(0, 9).map((c, i) => (
                            <StackItem
                                key={i}
                                type={c.card.cardType}
                                title={c.card.title}
                                time={c.card.schedule}
                                description={c.card.description}
                                claimed={c.isClaimed}
                                event={c.card.event}
                                cardId={c.id}
                                friendlyId={c.card.friendlyId}
                                isFavorited={c.isFavorited}
                                userId={userId}
                                imageUrl={c.card.imageUrl}
                                discernment={c.card.discernment}
                            />
                        ))}
                        {expand && (
                            <Fragment>
                                {cards.slice(9, cards.length).map((c, i) => (
                                    <StackItem
                                        key={i + 3}
                                        type={c.card.cardType}
                                        title={c.card.title}
                                        time={c.card.schedule}
                                        description={c.card.description}
                                        claimed={c.isClaimed}
                                        event={c.card.event}
                                        cardId={c.id}
                                        friendlyId={c.card.friendlyId}
                                        imageUrl={c.card.imageUrl}
                                        discernment={c.card.discernment}
                                        isFavorited={c.isFavorited}
                                        userId={userId}
                                    />
                                ))}
                            </Fragment>
                        )}
                    </div>
                    {cards.length > 9 && (
                        <Fragment>
                            <hr />
                            <div className="w-100 text-center">
                                <Button
                                    className="mx-auto"
                                    primary
                                    rounded
                                    onClick={() => setExpand(!expand)}>
                                    {!expand ? 'Show More' : 'Show Less'}
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
            {type === BUSINESS_IMPACT && (
                <ProjectStack {...project} idx={idx} />
            )}
        </div>
    );
};

export default Stack;
