import React from 'react';
import { useDispatch } from 'react-redux';
import Button from './Button';
import { showPeerExchangeModal } from '../reducers/modalActions';
import { StarIcon } from './RaazIcons';
import { checkURLIfImg } from '../utils/answerUtils';
import MaterialLink from './MaterialLink';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';

const PeerExchangeAnswer = ({
    user,
    answer,
    answerId,
    question,
    isFavorite,
    comments,
    editAnswerLink,
    project,
    referenceMaterials,
    questionKey,
    soloWorkoutId,
}) => {
    const dispatch = useDispatch();
    const peerExchangeDetails = {
        answer,
        answerId,
        question,
        user,
        isFavorite,
        comments,
        editAnswerLink,
        project,
        referenceMaterials,
        questionKey,
        soloWorkoutId,
    };

    const isDefaultAvatar =
        user && user.avatar && user.avatar === '/images/user-icon.png';

    const [firstName, lastName] =
        user && user.name ? user.name.split(' ') : [''];

    return (
        <li className="p-3 col-md-4 mb-3">
            <div
                onClick={() => {
                    dispatch(showPeerExchangeModal(peerExchangeDetails));
                }}
                role="button"
                style={{ cursor: 'pointer' }}
                className="PeerExchange-answer d-flex flex-column align-items-center">
                {isDefaultAvatar ? (
                    <div
                        className="mx-auto d-flex align-items-center justify-content-center text-center"
                        style={{
                            backgroundColor: getDefaultAvatarColors(user.name),
                            color: 'white',
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: '-1rem',
                            left: 0,
                            right: 0,
                            width: '2rem',
                            height: '2rem',
                            borderRadius: '50%',
                            overflow: 'hidden',
                        }}>
                        {user.name ? firstName[0] : ''}
                        {user.name ? lastName[0] : ''}
                    </div>
                ) : (
                    <img
                        src={user ? user.avatar || '/icon.png' : '/icon.png'}
                        alt="..."
                        className="avatar-img rounded-circle mx-auto"
                        style={{
                            position: 'absolute',
                            top: '-1rem',
                            left: 0,
                            right: 0,
                            width: '2rem',
                            height: '2rem',
                        }}
                    />
                )}
                {isFavorite && (
                    <StarIcon
                        height={32}
                        width={32}
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                        }}
                    />
                )}
                <p className="pt-4">{user.name || user.email}</p>
                {answer && (
                    <p
                        className="my-auto mx-auto px-4 PeerExchange-answer-container"
                        style={{
                            maxWidth: '100%',
                            height: '156px',
                        }}
                        dangerouslySetInnerHTML={{ __html: answer }}
                    />
                )}
                {referenceMaterials && referenceMaterials.length ? (
                    <div className="my-auto px-4">
                        {referenceMaterials.find(r => checkURLIfImg(r.url)) ? (
                            <img
                                style={{ maxWidth: '100%' }}
                                src={
                                    referenceMaterials.find(r =>
                                        checkURLIfImg(r.url),
                                    ).url
                                }
                            />
                        ) : (
                            referenceMaterials
                                .slice(0, 3) /* Just return the first 3 */
                                .filter(Boolean)
                                .map((material, i) => (
                                    <MaterialLink key={i} {...material} />
                                ))
                        )}
                    </div>
                ) : null}
                <div className="d-flex p-3 w-100 small">
                    <span
                        className="p-0 mr-2"
                        style={{ color: 'rgba(60, 60, 67, 0.7)' }}>
                        <i className="fe fe-message-square" /> {comments.length}
                    </span>
                    <span
                        className="p-0 mr-auto"
                        style={{ color: 'rgba(60, 60, 67, 0.7)' }}>
                        <i className="fe fe-paperclip" />{' '}
                        {referenceMaterials.length}
                    </span>
                    <span
                        className="p-0"
                        style={{ color: 'rgba(60, 60, 67, 0.7)' }}>
                        See More <i className="fe fe-chevron-right" />
                    </span>
                </div>
            </div>
        </li>
    );
};

export default PeerExchangeAnswer;
