import { gql } from '@apollo/client';

const cardParts = gql`
    fragment cardParts on Card {
        cardType
        cardSubType
        description
        id
        ordinality
        title
        schedule
        imageUrl
        friendlyId
        discernment {
            id
        }
        userCard(userId: $userId) {
            id
        }
        event {
            id
            startTime
            endTime
            date
            isCurrent
            isAttendee(userId: $userId)
        }
    }
`;

export default cardParts;
