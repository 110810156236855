import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DashboardHeader } from './DashboardScreen';
import { Link, useParams, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import { getEventDetails } from '../reducers/itineraryActions';
import Button from '../components/Button';

const ItineraryItem = ({ letter, title, slug, disabled }) => {
    const location = useLocation();
    return (
        <li className="d-block">
            <Link
                to={`${location.pathname}/${slug}`}
                className="ItineraryItem d-flex align-items-center py-3 text-body"
                disabled={disabled}>
                <span
                    className="d-flex align-items-center justify-content-center mr-3 small"
                    style={{
                        border: '2px solid #F4F4F4',
                        borderRadius: '50%',
                        width: '2rem',
                        height: '2rem',
                    }}>
                    {letter}
                </span>
                <span className="mr-auto d-block pr-3">{title}</span>
                <i
                    className={`fe fe-${
                        disabled ? 'lock' : 'chevron-right'
                    } ml-auto`}
                    style={{ color: '#BFBFC1' }}
                />
            </Link>
        </li>
    );
};

const GrowthLabItinerary = ({ items }) => {
    return (
        <div
            className="bg-white px-5 pt-5 pb-2 d-flex flex-column"
            style={{
                borderTopLeftRadius: '1.5rem',
                borderTopRightRadius: '1.5rem',
            }}>
            <h2>Itinerary</h2>
            <ul className="list-unstyled">
                {!items ? (
                    <Loader />
                ) : (
                    items
                        .sort((a, b) => a.ordinality - b.ordinality)
                        .filter(i => i.visible)
                        .map(item => (
                            <ItineraryItem
                                key={item.id}
                                title={item.title}
                                letter={item.letter}
                                slug={item.slug}
                                desc={item.description}
                                disabled={!item.active}
                            />
                        ))
                )}
            </ul>
            <p
                className="text-uppercase text-center text-black-50"
                style={{ fontSize: '0.5rem' }}>
                Iggy by Ignite House 2020
            </p>
        </div>
    );
};

const GrowthLabScreen = () => {
    const itineraries = useSelector(s => s.itinerary.itineraries);
    const dispatch = useDispatch();
    const { itineraryId } = useParams();
    const currentItinerary = itineraries.find(i => i.id === itineraryId);
    const [event, setEvent] = useState(null);

    useEffect(() => {
        if (
            currentItinerary &&
            currentItinerary.event &&
            currentItinerary.event.id
        ) {
            dispatch(getEventDetails(currentItinerary.event.id)).then(d =>
                setEvent(d),
            );
        }
    }, [currentItinerary]);

    if (!currentItinerary || !event) {
        return <Loader fullscreen />;
    }

    return (
        <div className="w-100" style={{ minHeight: '100vh' }}>
            <DashboardHeader growthlab>
                <h6 className="header-pretitle">
                    {currentItinerary.event.name}
                </h6>
                <h1 className="header-title">
                    Here’s your Itinerary for today.
                </h1>
            </DashboardHeader>
            <GrowthLabItinerary items={event.app_areas} />
        </div>
    );
};

export const GrowthLabScreenWithEventId = ({ goHome }) => {
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        dispatch(getEventDetails(eventId)).then(d => setEvent(d));
    }, [eventId]);

    if (!event) {
        return <Loader fullscreen />;
    }

    return (
        <div className="w-100" style={{ minHeight: '100vh' }}>
            <DashboardHeader
                growthlab
                BackArrow={() => (
                    <Button
                        transparent
                        onClick={goHome}
                        className="text-body p-0">
                        <i className="fe fe-arrow-left mr-auto" />
                    </Button>
                )}>
                <h6 className="header-pretitle">{event.name}</h6>
                <h1 className="header-title">
                    Here’s your Itinerary for today.
                </h1>
            </DashboardHeader>
            <GrowthLabItinerary items={event.app_areas} />
        </div>
    );
};

export default GrowthLabScreen;
