import instance from '../instance';
import { client } from '../client';

export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const ADD_PROJECT = 'ADD_PROJECT';

export const getDashboadData = () => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/dashboard`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getProjects = () => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/projects`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const getProject = id => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/projects/${id}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const createProject = data => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/projects`,
            method: 'POST',
            data,
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const updateProject = (projectId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/projects/${projectId}`,
            method: 'PATCH',
            data,
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};

export const inviteMember = (projectId, data) => (_, getState) => {
    const { auth_token } = getState().app;
    return instance
        .request({
            url: `/api/v3/projects/${projectId}/invite_members`,
            method: 'POST',
            data,
            headers: {
                Authorization: `Bearer ${auth_token}`,
            },
        })
        .then(res => res.data);
};
