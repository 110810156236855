import React from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MAX_WIDTH } from '../utils/CONSTANTS';

const Step = ({ title, isActive, to }) =>
    to ? (
        <Link className={`step ${isActive ? 'isActive' : ''}`} to={to}>
            <span className="substop-title">{title}</span>
            <span className="dot" />
        </Link>
    ) : (
        <div className={`step ${isActive ? 'isActive' : ''}`}>
            <span
                className="dot"
                style={isActive ? {} : { marginBottom: '1.25rem' }}
            />
            {isActive && <span className="substop-title">{title}</span>}
        </div>
    );

const RenderStep = ({ s }) => {
    const { projectId, stopId, soloWorkoutId } = useParams();
    const basePath = `/100h/projects/${projectId}/hurdles/${stopId}`;

    const isSoloWorkout = useRouteMatch(
        '/100h/projects/:projectId/hurdles/:stopId/solo_workout',
    );
    const isPeerExchange = useRouteMatch(
        '/100h/projects/:projectId/hurdles/:stopId/peer-exchange',
    );
    const isIteratedResponse = useRouteMatch(
        '/100h/projects/:projectId/hurdles/:stopId/iterated-response',
    );
    switch (s) {
        case 'iterated_response':
            return (
                <Step
                    title="Iterated Workout"
                    isActive={isIteratedResponse}
                    to={`${basePath}/iterated-response/${soloWorkoutId}`}
                />
            );
        case 'peer_exchange':
            return (
                <Step
                    title="Peer Exchange"
                    isActive={isPeerExchange}
                    to={`${basePath}/peer-exchange/${soloWorkoutId}`}
                />
            );
        case 'solo_workout':
            return (
                <Step
                    title="Solo Workout"
                    isActive={isSoloWorkout}
                    to={`${basePath}/solo_workout/${soloWorkoutId}`}
                />
            );
        default:
            return null;
    }
};

const RenderStaticStep = ({ s, active, projectId, stopId, soloWorkoutId }) => {
    // const basePath = `/100h/projects/${projectId}/hurdles/${stopId}`;

    switch (s) {
        case 'iterated_response':
            return (
                <Step
                    title="Iterated Workout"
                    isActive={active === 'iterated_response'}
                />
            );
        case 'peer_exchange':
            return (
                <Step
                    title="Peer Exchange"
                    isActive={active === 'peer_exchange'}
                />
            );
        case 'solo_workout':
            return (
                <Step
                    title="Solo Workout"
                    isActive={active === 'solo_workout'}
                />
            );
        default:
            return null;
    }
};

const CurrentStep = () => {
    const enabledSubstops = [
        'solo_workout',
        'peer_exchange',
        'iterated_response',
    ];
    return (
        <div
            className="current-steps d-flex align-items-center mx-auto"
            style={{ maxWidth: MAX_WIDTH, zIndex: 30 }}>
            {enabledSubstops.reverse().map((s, i) => (
                <RenderStep s={s} key={i} />
            ))}
        </div>
    );
};

export const CurrentStaticStep = ({
    active,
    projectId,
    stopId,
    soloWorkoutId,
}) => {
    const enabledSubstops = [
        'solo_workout',
        'peer_exchange',
        'iterated_response',
    ];
    return (
        <div
            className="current-steps current-static-steps d-flex align-items-center mx-auto"
            style={{ maxWidth: MAX_WIDTH, zIndex: 30 }}>
            {enabledSubstops.reverse().map((s, i) => (
                <RenderStaticStep
                    s={s}
                    key={i}
                    active={active}
                    projectId={projectId}
                    stopId={stopId}
                    soloWorkoutId={soloWorkoutId}
                />
            ))}
        </div>
    );
};

export default CurrentStep;
