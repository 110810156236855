import React, { useState, Fragment } from 'react';
import Button from '../../components/Button';
import GrowthPoints from 'components/GrowthPoints';
import UserDays from 'components/UserDays';
import { useSelector } from 'react-redux';
import { MAX_WIDTH } from '../../utils/CONSTANTS';
import { LeaderboardIcon, SliderIcon } from '../../components/RaazIcons';
import RaazWelcome from '../../components/RaazWelcome';
import Loader from '../../components/Loader';
import StopItem, { HundredHoursStopItem } from '../../components/StopItem';

export const StopGroups = ({ stop_groups, hundred_hours, current_stop }) => {
    if (stop_groups.length < 1) {
        return (
            <div className="d-flex align-items-center justify-content-center w-100 p-5">
                <Loader />
            </div>
        );
    }

    return (
        <ul
            className="list-unstyled mx-auto mb-5"
            style={{ maxWidth: MAX_WIDTH }}>
            {stop_groups
                .sort((a, b) => a.ordinality - b.ordinality)
                .map(({ name, stops }, j) => (
                    <Fragment key={j}>
                        <h6 className="ItineraryGroupLabel px-4">{name}</h6>
                        <ul className="StopGroup list-unstyled mx-auto" key={j}>
                            {stops
                                .sort((a, b) => a.ordinality - b.ordinality)
                                .map(
                                    (
                                        {
                                            name,
                                            points,
                                            ordinality,
                                            duration,
                                            ...others
                                        },
                                        i,
                                    ) =>
                                        hundred_hours ? (
                                            <HundredHoursStopItem
                                                key={i}
                                                letter={Number(ordinality)}
                                                title={name}
                                                points={points}
                                                duration={duration}
                                                is_current={
                                                    current_stop.id ===
                                                    others.id
                                                }
                                                {...others}
                                            />
                                        ) : (
                                            <StopItem
                                                key={i}
                                                letter={Number(ordinality)}
                                                title={name}
                                                points={points}
                                                {...others}
                                            />
                                        ),
                                )}
                        </ul>
                    </Fragment>
                ))}
        </ul>
    );
};

const RaazDashboard = ({ stop_groups }) => {
    const [showSlider, setShowSlider] = useState(true);
    const { user } = useSelector(s => s.users);
    return (
        <div className="w-100">
            <div className="RaazDashboard--upper">
                {showSlider && (
                    <RaazWelcome closeSlider={() => setShowSlider(false)} />
                )}

                <div className="p-5 mx-auto" style={{ maxWidth: MAX_WIDTH }}>
                    {user && Object.keys(user).length > 0 ? (
                        <GrowthPoints
                            alt
                            points={user.points}
                            className="pt-5"
                        />
                    ) : null}
                    {user && Object.keys(user).length > 0 ? (
                        <UserDays alt days={user.days} className="pt-5" />
                    ) : null}

                    <div
                        className="d-flex align-items-flex-center justify-content-end ml-auto"
                        style={{ marginTop: '-0.75rem' }}>
                        <Button
                            dark
                            className="border-0 d-flex mr-2 d-flex align-items-center"
                            rounded
                            small
                            onClick={() => setShowSlider(true)}
                            style={{
                                backgroundColor: '#363636',
                                color: 'rgba(235, 235, 245, 0.7)',
                                borderRadius: '50%',
                                width: '2rem',
                                height: '2rem',
                            }}>
                            <SliderIcon fill="rgba(235, 235, 245, 0.7)" />
                        </Button>
                        <Button
                            dark
                            className="border-0 d-flex  align-items-center"
                            rounded
                            small
                            style={{
                                backgroundColor: '#363636',
                                color: 'rgba(235, 235, 245, 0.7)',
                                borderRadius: '1rem',
                            }}>
                            <LeaderboardIcon fill="rgba(235, 235, 245, 0.7)" />{' '}
                            <span className="ml-2 text-uppercase">
                                Leaderboard
                            </span>
                        </Button>
                    </div>
                </div>
            </div>

            <h2
                className="mx-auto mt-5 mb-0 text-bold text-uppercase px-3 lora"
                style={{ maxWidth: MAX_WIDTH }}>
                O/S 100
            </h2>
            <StopGroups stop_groups={stop_groups} />
        </div>
    );
};

export default RaazDashboard;
