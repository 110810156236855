import React, { Fragment, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PublicScreen from 'screens/PublicScreen';
import { useSelector, useDispatch } from 'react-redux';
import PrivateScreen from 'screens/PrivateScreen';
import Modal from 'components/Modal';
import { MODAL_TYPES, hideModal } from 'reducers/modalActions';
import Button from 'components/Button';
import { MAX_WIDTH } from 'utils/CONSTANTS';
import Iframe from 'components/Iframe';
import {
    CertificateIllus,
    LockedGrowthlabIcon,
    SoloWorkoutIcon,
    PeerExchangeIcon,
} from 'components/RaazIcons';
import {
    OperationsIcon,
    SalesIcon,
    WorkforceIcon,
    CustomersIcon,
    CostIcon,
    PartnerIcon,
} from 'components/AuditIcons';
import PeerExchangeModal from '../components/PeerExchangeModal';
import RaazWelcome from '../components/RaazWelcome';
import AuditModal from '../components/AuditModal';
import { Options } from '../components/QuestionInputV2';
import { ModalHeader, ModalBody } from '../components/Modal';
import Subdialog from '../components/Subdialog';
import {
    IteratedResponseIcon,
    SubscribeNowIcon,
} from '../components/RaazIcons';
import { DeleteProjectModal } from '../components/DeleteModal';
import DesktopNotice from '../screens/DesktopNotice';
import checkIfMobile from '../utils/checkIfMobile';

const ModalTitle = ({ children }) => (
    <h3 className="mb-3 mt-3 text-uppercase font-weight-bold">{children}</h3>
);

const App = () => {
    const isAuthenticated = useSelector(s => s.app.auth_token);
    const modal = useSelector(s => s.modal);
    const history = useHistory();
    const dispatch = useDispatch();

    // const isMobile = checkIfMobile();

    // if (isMobile) {
    //     return <DesktopNotice />;
    // }

    return (
        <Fragment>
            {!isAuthenticated ? (
                <div
                    className="container-fluid px-0"
                    style={{ overflow: 'hidden' }}>
                    <div className="mx-auto">
                        <PublicScreen />
                    </div>
                </div>
            ) : (
                <PrivateScreen />
            )}
            {modal.show && modal.type === MODAL_TYPES.RESET_PW_SUCCESS ? (
                <Modal
                    centered
                    afterCloseCallBack={() => {
                        history.push('/login');
                    }}>
                    <div className="raas-modal-content">
                        <i
                            className="fe fe-check-circle text-success"
                            style={{ fontSize: '3rem' }}
                        />
                        <h2 className="mb-3">Password Reset</h2>
                        <p className="mt-0">
                            We successfully reset your password. Please check
                            your email for the instructions.
                        </p>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.LOCK_MODAL ? (
                <Modal centered>
                    <div className="raas-modal-content">
                        <i
                            className="fe fe-user-x text-warning"
                            style={{ fontSize: '3rem' }}
                        />
                        <h2 className="mb-3">{modal.lockDetails.title}</h2>
                        <div
                            className="mt-0"
                            dangerouslySetInnerHTML={{
                                __html: modal.lockDetails.body,
                            }}
                        />
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Button
                                light
                                className="w-100 mr-3"
                                onClick={() => {
                                    dispatch(hideModal());
                                }}>
                                Close
                            </Button>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="btn btn-primary rounded-0 w-100">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.LICENSE_MODAL ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <CertificateIllus />
                        <ModalTitle>License Required</ModalTitle>
                        <p>
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.RESTART_POSTCARD_MODAL ? (
                <Modal centered>
                    <div className="raas-modal-content">
                        <h2 className="mb-3">{modal.postcard.name}</h2>
                        <div className="mt-0">
                            <Iframe src={modal.postcard.url} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Button
                                light
                                className="w-100 mr-3"
                                onClick={() => {
                                    dispatch(hideModal());
                                }}>
                                Close
                            </Button>
                            <Button
                                primary
                                href={modal.postcard.url}
                                className="rounded-0 w-100">
                                <span>View Postcard</span>
                                <i className="fe fe-external-link" />
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.HUNDRED_SOLO_WORKOUT ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <SoloWorkoutIcon width={72} height={72} />
                        <ModalTitle>Solo Workout</ModalTitle>
                        <p className="text-center">
                            Solitude allows you to be receptive to answers that
                            are unique and meaningful to you. Free your mind
                            from distractions as you answer these prompts on
                            your own.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.HUNDRED_PEER_EXCHANGE ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <PeerExchangeIcon />
                        <ModalTitle>Peer Exchange</ModalTitle>
                        <p className="text-center">
                            Here are the ideas everyone came up with during the
                            solo workout. This is an exchange of learnings and
                            insights, so share your feedback to enrich your
                            peers' answers, as they do the same for you.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show &&
            modal.type === MODAL_TYPES.HUNDRED_ITERATED_RESPONSE ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <IteratedResponseIcon />
                        <ModalTitle>Iterated Workout</ModalTitle>
                        <p className="text-center">
                            Using the feedback gathered from the peer exchange,
                            the project owner picks one idea to iterate and
                            develop into a more informed answer to the prompt
                            question.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}

            {modal.show &&
            modal.type === MODAL_TYPES.LOCKED_RESTART_SOLO_WORKOUT_MODAL ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <SoloWorkoutIcon width={72} height={72} />
                        <ModalTitle>Solo Workout</ModalTitle>
                        <p className="text-center">
                            All growth starts with solo workouts. We pose the
                            challenge, you find the answers; each uniquely
                            yours; every workout a step closer to peak
                            performance.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}

            {modal.show &&
            modal.type === MODAL_TYPES.LOCKED_RESTART_POSTCARD_MODAL ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <CertificateIllus />
                        <ModalTitle>Postcard</ModalTitle>
                        <p className="text-center">
                            Not to be missed! PostCards magically extend the
                            GrowthLabs experience through time and space. Our
                            most popular segment are the key takeaways - short
                            and sweet recaps of our most memorable characters
                            and most actionable learnings.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}

            {modal.show &&
            modal.type === MODAL_TYPES.LOCKED_RESTART_DATAVIZ_MODAL ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <CertificateIllus />
                        <ModalTitle>DataViz</ModalTitle>
                        <p className="text-center">
                            Data is Beautiful - Data visualization on what all
                            of us said...
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show &&
            modal.type === MODAL_TYPES.LOCKED_RESTART_GROWTHLABS_MODAL ? (
                <Modal
                    Subdialog={Subdialog}
                    regularCloseBtn
                    className="flex-wrap">
                    <div className="raas-modal-content">
                        <LockedGrowthlabIcon />
                        <ModalTitle>GrowthLabs</ModalTitle>
                        <p className="text-center">
                            Our peer-to-peer media network, where you experience
                            peak performance and apply peak practices in live
                            case studies and real world scenarios.
                        </p>
                        <p className="text-center text-muted small">
                            This segment is accessible via Software-as-a-Service
                            (SaaS) license from Ignite House.
                        </p>
                        <div className="d-flex align-items-center justify-content-center modal-actions">
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/contact-us"
                                className="text-uppercase btn btn-secondary mr-4 rounded-0 w-100">
                                Inquire
                            </Link>
                            <Link
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                                to="/buy-license"
                                className="text-uppercase btn btn-primary rounded-0 w-100">
                                Subscribe Now
                            </Link>
                        </div>
                    </div>
                </Modal>
            ) : null}

            {modal.show && modal.type === MODAL_TYPES.PEER_EXCHANGE_MODAL ? (
                <PeerExchangeModal details={modal.peerExchangeDetails} />
            ) : null}

            {modal.show && modal.type === MODAL_TYPES.RAAZ_WELCOME ? (
                <Modal centered floatingCloseBtn size="modal-lg">
                    <RaazWelcome noPadding />
                </Modal>
            ) : null}

            {modal.show && modal.type === MODAL_TYPES.SUBSCRIBE_NOW_MODAL ? (
                <Modal centered floatingCloseBtn size="modal-lg">
                    <div
                        className="p-5 text-center mx-auto"
                        style={{ maxWidth: '330px' }}>
                        <h1
                            className="text-uppercase mb-2"
                            style={{
                                fontSize: '18px',
                                color: '#102C60',
                                fontWeight: 700,
                            }}>
                            Join July 2020 Cohort
                        </h1>
                        <p
                            style={{
                                fontSize: '11px',
                                color: 'rgba(60, 60, 67, 0.8)',
                            }}>
                            Deadline: 17 July 2020
                        </p>
                        <SubscribeNowIcon />
                        <p className="mt-4" style={{ fontSize: '14px' }}>
                            To optimize everyone’s experience we start cohorts
                            every 2-4 weeks.
                            <br />
                            Each cohort runs in 100 Day cycles.
                        </p>
                        <p
                            style={{
                                fontSize: '11px',
                                color: 'rgba(60, 60, 67, 0.8)',
                            }}>
                            Current Cohort: Started 17 July 2020
                            <br />
                            Next Cohort: Starts Aug 2020
                        </p>
                        <Button block primary>
                            Subscribe Now
                        </Button>
                    </div>
                </Modal>
            ) : null}

            {modal.show && modal.type === MODAL_TYPES.AUDIT_MODAL ? (
                <Modal centered floatingCloseBtn size="modal-md">
                    <AuditModal auditDetails={modal.auditDetails} />
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.CHECKBOX_MODAL ? (
                <Modal centered floatingCloseBtn size="modal-md">
                    <CheckboxOptionsModal
                        options={modal.checkboxDetails.options}
                        field={modal.checkboxDetails.field}
                        form={modal.checkboxDetails.form}
                        max={modal.checkboxDetails.max}
                        label={modal.checkboxDetails.label}
                    />
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.DELETE_MODAL ? (
                <Modal
                    centered
                    floatingCloseBtn
                    size="modal-md"
                    backToItinerary>
                    <DeleteProjectModal
                        projectId={modal.deleteDetails.projectId}
                        member={modal.deleteDetails.member}
                    />
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.SAMPLE_ANSWER_MODAL ? (
                <Modal centered size="modal-md">
                    <div className="px-5 pt-5 pb-3">
                        <h2>Sample Answer</h2>
                        <p>{modal.sampleAnswer}</p>
                        <div className="d-flex align-items-center modal-actions">
                            <Button
                                primary
                                className="ml-auto"
                                onClick={() => dispatch(hideModal())}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.PERSONAL_GROWTH_MODAL ? (
                <Modal centered regularCloseBtn>
                    <div className="raas-modal-content">
                        <h2 className="lora">Accelerate Peak Performance</h2>
                        <div className="d-flex align-items-center">
                            <div className="donut-container px-2 mr-3">
                                <svg width="160" height="160" className="donut">
                                    <g>
                                        <title>Graph</title>
                                        <circle
                                            r="69.85699"
                                            cy="81"
                                            cx="81"
                                            strokeWidth="12"
                                            stroke="#F7F7F7"
                                            fill="none"
                                        />
                                        <circle
                                            className="circle_animation"
                                            r="69.85699"
                                            cy="81"
                                            cx="81"
                                            strokeWidth="12"
                                            stroke="#276EF1"
                                            strokeDashoffset={0.5 * 440}
                                            fill="none"
                                        />
                                    </g>
                                </svg>
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        margin: 'auto',
                                    }}>
                                    <p
                                        className="mb-1 d-flex align-items-flex-start justify-content-center"
                                        style={{ width: '160px' }}>
                                        <span
                                            style={{
                                                fontSize: '40px',
                                                lineHeight: 1,
                                            }}>
                                            45
                                        </span>
                                        /100
                                    </p>
                                    <p className="m-0">Growth Cards</p>
                                </div>
                            </div>
                            <div className="text-left px-2 d-flex flex-column">
                                <p>
                                    The more cards you complete, the closer and
                                    faster you get to peak performance and
                                    lifelong growth.
                                </p>
                                <ul className="list-unstyled">
                                    {Object.keys(
                                        modal.dashboardStats.cards_breakdown,
                                    ).map((k, i) => {
                                        const {
                                            done,
                                            total,
                                        } = modal.dashboardStats.cards_breakdown[
                                            k
                                        ];
                                        return (
                                            <li
                                                key={i}
                                                className="d-flex mb-2 pb-2"
                                                style={{
                                                    fontSize: '11px',
                                                    borderBottom:
                                                        '1px solid #BFBFC1',
                                                }}>
                                                <span className="mr-auto">
                                                    {k}
                                                </span>
                                                <span>
                                                    {done}/{total} Cards
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <Button
                                    className="ml-auto"
                                    primary
                                    rounded
                                    style={{ borderRadius: '2px' }}
                                    onClick={() => {
                                        dispatch(hideModal());
                                        window.showPersonalGrowth();
                                    }}>
                                    Go To Stacks
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : null}
            {modal.show && modal.type === MODAL_TYPES.BUSINESS_IMPACT_MODAL ? (
                <BreakthroughModal
                    dashboardStats={modal.dashboardStats}
                    hideModal={() => {
                        dispatch(hideModal());
                        window.showBusinessImpact();
                    }}
                />
            ) : null}
        </Fragment>
    );
};

const OPPORTUNITIES_BIRTHED = 'OPPORTUNITIES BIRTHED';
const IMPACT_AUDIT = 'IMPACT AUDIT';
const IMPACT_DETAILS = [
    {
        label: '1. Customer Loyalty & Growth',
        name: 'customers',
        icon: CustomersIcon,
    },
    {
        label: '2. Workforce Performance & Morale',
        name: 'workforce',
        icon: WorkforceIcon,
    },
    {
        label: '3. Operations & Cost Efficiencies',
        name: 'costs',
        icon: CostIcon,
    },
    { label: '4. Revenue Growth', name: 'sales', icon: SalesIcon },
];

const AuditLineItem = ({ label, name, length, value, Icon }) => (
    <li className="mb-3 d-flex">
        <strong className="mr-auto" style={{ fontSize: '14px' }}>
            {label}
        </strong>
        <div className="d-flex align-items-center">
            {new Array(length)
                .fill(_ => 1)
                .map((_, i) => (
                    <label key={i} htmlFor={`${name}-${i}`} className="mr-3">
                        <input
                            type="checkbox"
                            id={`${name}-${i}`}
                            name={name}
                            disabled
                            style={{ display: 'none' }}
                        />
                        <Icon fill={value >= i + 1 ? '#276EF1' : '#dadada'} />
                    </label>
                ))}
        </div>
    </li>
);

const BreakthroughIcon = ({ type }) => {
    switch (type) {
        case 'Restart Community':
            return (
                <svg width="24" height="14" viewBox="0 0 24 14" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C10.067 0 8.5 1.567 8.5 3.5C8.5 5.433 10.067 7 12 7C13.933 7 15.5 5.433 15.5 3.5C15.5 1.567 13.933 0 12 0ZM12 2C12.8284 2 13.5 2.67157 13.5 3.5C13.5 4.32843 12.8284 5 12 5C11.1716 5 10.5 4.32843 10.5 3.5C10.5 2.67157 11.1716 2 12 2ZM5.5 3C4.11929 3 3 4.11929 3 5.5C3 6.44 3.53 7.25 4.29 7.68C4.65 7.88 5.06 8 5.5 8C5.94 8 6.35 7.88 6.71 7.68C7.08 7.47 7.39 7.17 7.62 6.81C6.89 5.86 6.5 4.7 6.5 3.5C6.5 3.41 6.5 3.31 6.5 3.22C6.2 3.08 5.86 3 5.5 3ZM18.5 3C18.14 3 17.8 3.08 17.5 3.22C17.5 3.31 17.5 3.41 17.5 3.5C17.5 4.7 17.11 5.86 16.38 6.81C16.5 7 16.63 7.15 16.78 7.3C16.94 7.45 17.1 7.58 17.29 7.68C17.65 7.88 18.06 8 18.5 8C18.94 8 19.35 7.88 19.71 7.68C20.47 7.25 21 6.44 21 5.5C21 4.11929 19.8807 3 18.5 3ZM12 9C9.66 9 5 10.17 5 12.5V14H19V12.5C19 10.17 14.34 9 12 9ZM4.71 9.55C2.78 9.78 0 10.76 0 12.5V14H3V12.07C3 11.06 3.69 10.22 4.71 9.55ZM19.29 9.55C20.31 10.22 21 11.06 21 12.07V14H24V12.5C24 10.76 21.22 9.78 19.29 9.55ZM12 11C13.53 11 15.24 11.5 16.23 12H7.77C8.76 11.5 10.47 11 12 11Z"
                        fill="#414141"
                    />
                </svg>
            );
        case 'Average User':
            return (
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14 8.78L18.24 1.45L19.97 2.45L14.74 11.5L8.23 7.75L3.46 16H20V18H0V0H2V14.54L7.5 5L14 8.78Z"
                        fill="#414141"
                    />
                </svg>
            );
        default:
            return <CustomersIcon fill="#414141" width={14} height={14} />;
    }
};

const BreakthroughModal = ({ hideModal, dashboardStats }) => {
    const { projects_breakdown, user_audit_breakdown } = dashboardStats;
    const [activeTab, setActiveTab] = useState(OPPORTUNITIES_BIRTHED);
    return (
        <Modal centered regularCloseBtn>
            <div className="text-center py-5 px-5">
                <h2 className="lora">Launch Next Level Breakthrough</h2>
                <div
                    className="DashboardAnchors mt-5 pb-0"
                    style={{ paddingBottom: '0 !important' }}>
                    <Button
                        onClick={() => setActiveTab(OPPORTUNITIES_BIRTHED)}
                        transparent
                        className={`DashboardAnchor py-3 px-5 justify-content-center w-100 ${
                            activeTab === OPPORTUNITIES_BIRTHED
                                ? 'isActive'
                                : ''
                        }`}>
                        <span className="text-uppercase">
                            Opportunities Birthed
                        </span>
                    </Button>
                    <Button
                        onClick={() => setActiveTab(IMPACT_AUDIT)}
                        transparent
                        className={`DashboardAnchor py-3 px-5 justify-content-center w-100 ${
                            activeTab === IMPACT_AUDIT ? 'isActive' : ''
                        }`}>
                        <span className="text-uppercase">Impact Audit</span>
                    </Button>
                </div>
                <div className="mb-3">
                    {activeTab === OPPORTUNITIES_BIRTHED && (
                        <ul className="list-unstyled">
                            {Object.keys(projects_breakdown).map((k, i) => (
                                <li key={i} className="d-flex mb-4">
                                    <div
                                        style={{ width: '60px' }}
                                        className="d-flex align-items-center justify-content-center">
                                        <BreakthroughIcon type={k} />
                                    </div>
                                    <span className="mr-auto">{k}</span>
                                    <span>{projects_breakdown[k]}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                    {activeTab === IMPACT_AUDIT && (
                        <ul className="list-unstyled">
                            {IMPACT_DETAILS.map((d, i) => (
                                <AuditLineItem
                                    label={d.label}
                                    key={i}
                                    name={d.name}
                                    length={4}
                                    Icon={d.icon}
                                    value={user_audit_breakdown[d.name]}
                                />
                            ))}
                        </ul>
                    )}
                </div>
                <div className="d-flex w-100">
                    <Button
                        className="ml-auto"
                        primary
                        rounded
                        style={{ borderRadius: '2px' }}
                        onClick={hideModal}>
                        Go To Stacks
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const CheckboxOptionsModal = ({ field, form, options, max, label }) => {
    const [selectedOptions, setSelectedOptions] = useState(field.value);
    const dispatch = useDispatch();
    return (
        <div style={{ maxHeight: '75vh', overflow: 'hidden' }}>
            <div
                className="px-5 py-3"
                dangerouslySetInnerHTML={{ __html: label }}
                style={{ borderBottom: '1px solid #F7F7F7' }}
            />
            <div
                className="px-5 py-3"
                style={{ height: 'calc(75vh - 140px)', overflow: 'scroll' }}>
                <Options
                    options={options}
                    field={field}
                    max={max}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                />
            </div>
            <div
                className="px-5 py-3 d-flex align-items-center justify-content-end"
                style={{ borderTop: '1px solid #F7F7F7' }}>
                <Button
                    type="submit"
                    className="mr-3"
                    onClick={() => {
                        dispatch(hideModal());
                    }}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    primary
                    onClick={() => {
                        dispatch(hideModal());
                        form.setFieldValue(field.name, selectedOptions);
                    }}>
                    Add Tags
                </Button>
            </div>
        </div>
    );
};

export default App;
