import React from 'react';

const AttendanceIllus = () => (
    <svg width="272" height="211" viewBox="0 0 272 211" fill="none">
        <g clipPath="url(#clip0)">
            <path
                d="M208.221 192.067C216.22 192.067 222.704 178.806 222.704 162.448C222.704 146.089 216.22 132.828 208.221 132.828C200.222 132.828 193.738 146.089 193.738 162.448C193.738 178.806 200.222 192.067 208.221 192.067Z"
                fill="#3F3D56"
            />
            <path
                d="M206.884 210.93C212.684 176.047 206.942 141.29 206.883 140.943L205.755 141.135C205.813 141.48 211.523 176.054 205.755 210.742L206.884 210.93Z"
                fill="#276EF1"
            />
            <path
                d="M195.47 156.078L194.93 157.09L208.027 164.111L208.567 163.1L195.47 156.078Z"
                fill="#276EF1"
            />
            <path
                d="M221.849 159.596L208.754 166.621L209.294 167.633L222.389 160.607L221.849 159.596Z"
                fill="#276EF1"
            />
            <path
                d="M243.019 173.503C258.664 173.503 271.347 147.566 271.347 115.571C271.347 83.5759 258.664 57.6387 243.019 57.6387C227.375 57.6387 214.692 83.5759 214.692 115.571C214.692 147.566 227.375 173.503 243.019 173.503Z"
                fill="#E6E6E6"
            />
            <path
                d="M239.864 210.305C251.194 142.168 239.979 74.2793 239.864 73.6016L238.736 73.7931C238.85 74.4692 250.033 142.176 238.735 210.117L239.864 210.305Z"
                fill="#276EF1"
            />
            <path
                d="M217.822 103.596L217.282 104.607L242.898 118.341L243.438 117.329L217.822 103.596Z"
                fill="#276EF1"
            />
            <path
                d="M269.932 110.475L244.32 124.215L244.86 125.226L270.472 111.486L269.932 110.475Z"
                fill="#276EF1"
            />
            <path
                d="M177.23 210.487H26.5558C26.0016 210.487 25.4704 210.266 25.0785 209.873C24.6866 209.48 24.4662 208.948 24.4656 208.392V112.964C24.4662 112.409 24.6866 111.876 25.0785 111.483C25.4703 111.091 26.0016 110.87 26.5558 110.869H177.23C177.784 110.87 178.315 111.091 178.707 111.483C179.099 111.876 179.319 112.409 179.32 112.964V208.392C179.319 208.948 179.099 209.48 178.707 209.873C178.315 210.266 177.784 210.487 177.23 210.487Z"
                fill="#E6E6E6"
            />
            <path
                d="M40.4122 122.434C40.1747 122.434 39.947 122.529 39.779 122.697C39.6111 122.865 39.5166 123.093 39.5164 123.331V198.026C39.5166 198.264 39.6111 198.493 39.779 198.661C39.947 198.829 40.1747 198.924 40.4122 198.924H163.373C163.611 198.924 163.838 198.829 164.006 198.661C164.174 198.493 164.269 198.264 164.269 198.026V123.331C164.269 123.093 164.174 122.865 164.006 122.697C163.838 122.529 163.611 122.434 163.373 122.434H40.4122Z"
                fill="white"
            />
            <path
                d="M164.567 136.939H37.7946V141.673H164.567V136.939Z"
                fill="#E6E6E6"
            />
            <path
                d="M164.567 160.08H37.7946V164.813H164.567V160.08Z"
                fill="#E6E6E6"
            />
            <path
                d="M109.662 147.457H56.667V152.19H109.662V147.457Z"
                fill="#E6E6E6"
            />
            <path
                d="M164.567 184.535H37.7946V189.268H164.567V184.535Z"
                fill="#E6E6E6"
            />
            <path
                d="M59.0281 122.135H54.3058V187.427H59.0281V122.135Z"
                fill="#E6E6E6"
            />
            <path
                d="M147.179 122.135H142.456V162.709H147.179V122.135Z"
                fill="#E6E6E6"
            />
            <path
                d="M111.499 122.135H106.776V199.224H111.499V122.135Z"
                fill="#E6E6E6"
            />
            <path
                d="M84.4764 160.867H79.754V199.222H84.4764V160.867Z"
                fill="#E6E6E6"
            />
            <path
                d="M109.138 169.092C112.47 169.092 115.172 166.384 115.172 163.044C115.172 159.704 112.47 156.996 109.138 156.996C105.805 156.996 103.104 159.704 103.104 163.044C103.104 166.384 105.805 169.092 109.138 169.092Z"
                fill="#276EF1"
            />
            <path
                d="M271.882 211.001H0V210.348H272L271.882 211.001Z"
                fill="#3F3D56"
            />
            <path
                d="M109.861 127.117C130.418 127.117 147.083 110.413 147.083 89.8084C147.083 69.2036 130.418 52.5 109.861 52.5C89.3035 52.5 72.6384 69.2036 72.6384 89.8084C72.6384 110.413 89.3035 127.117 109.861 127.117Z"
                fill="#276EF1"
            />
            <path
                d="M110.225 150.456L98.4653 130.322L86.7057 110.187L109.982 110.047L133.259 109.906L121.742 130.181L110.225 150.456Z"
                fill="#276EF1"
            />
            <path
                d="M110.16 102.959C117.535 102.959 123.515 96.9659 123.515 89.5732C123.515 82.1805 117.535 76.1875 110.16 76.1875C102.784 76.1875 96.8048 82.1805 96.8048 89.5732C96.8048 96.9659 102.784 102.959 110.16 102.959Z"
                fill="white"
            />
            <path
                d="M109 0.191704C109 0.191704 101.035 -2.05709 98.6792 8.84956C96.3234 19.7562 98.1183 25.2658 98.1183 25.2658L115.899 23.1856C115.899 23.1856 120.947 3.50867 109 0.191704Z"
                fill="#2F2E41"
            />
            <path
                d="M97.0835 47.4316C97.0835 47.4316 85.4004 48.5979 87.4813 53.3255C89.5622 58.0531 89.5622 58.4703 89.5622 58.4703L99.828 76.8245L103.157 73.3483C103.157 73.3483 99.4118 65.1445 99.4118 62.5026C99.4118 59.8607 95.5275 55.2722 95.5275 55.2722L102.325 54.716L97.0835 47.4316Z"
                fill="#FFB9B9"
            />
            <path
                d="M129.099 53.0464L132.984 61.5283C132.984 61.5283 135.897 64.4483 139.088 76.1283C142.278 87.8082 142.972 87.3911 142.972 87.3911L138.117 88.3644C138.117 88.3644 132.706 75.7111 130.903 74.7378C129.099 73.7645 126.88 64.8654 126.88 64.8654L120.498 56.8007L122.44 52.4902L129.099 53.0464Z"
                fill="#FFB9B9"
            />
            <path
                d="M124.383 43.3129L130.348 54.4367C130.348 54.4367 122.857 50.9606 121.747 58.6082L114.949 53.3244C114.949 53.3244 105.516 56.3834 99.828 55.271C99.828 55.271 101.493 49.9872 95.5275 47.6234L108.984 45.9548L108.429 43.0349L109.816 40.2539L124.383 43.3129Z"
                fill="#2F2E41"
            />
            <path
                d="M100.66 74.3202C100.66 74.3202 96.9148 73.764 97.8859 77.1011L99.828 83.7754C99.828 83.7754 97.331 90.5887 101.215 89.0592C105.1 87.5297 107.597 78.0745 107.597 78.0745C107.597 78.0745 109.261 70.844 107.597 70.844C105.932 70.844 100.66 69.7316 101.215 70.844C101.77 71.9564 100.66 74.3202 100.66 74.3202Z"
                fill="#2F2E41"
            />
            <path
                d="M142.556 85.4448C142.556 85.4448 137.701 84.1934 136.591 86.14C135.481 88.0867 135.758 94.4829 135.758 94.6219C135.758 94.761 137.423 96.1514 137.978 96.1514C138.533 96.1514 142.001 102.409 146.718 100.184C151.435 97.959 142.556 85.4448 142.556 85.4448Z"
                fill="#2F2E41"
            />
            <path
                d="M112.937 12.6188C113.416 9.66251 111.413 6.87706 108.463 6.39733C105.514 5.9176 102.735 7.92524 102.256 10.8815C101.777 13.8378 103.78 16.6233 106.73 17.103C109.679 17.5827 112.458 15.5751 112.937 12.6188Z"
                fill="#FFB9B9"
            />
            <path
                d="M112.036 12.8633C112.036 12.8633 113.146 18.7033 114.117 19.5375C115.088 20.3718 106.764 21.6233 106.764 21.6233C106.764 21.6233 108.013 17.5909 106.487 15.5052C104.961 13.4195 112.036 12.8633 112.036 12.8633Z"
                fill="#FFB9B9"
            />
            <path
                d="M126.602 45.9566C125.658 45.9566 123.252 45.5645 120.555 45.0598C118.222 44.6232 115.668 44.1045 113.654 43.6846C111.496 43.2341 109.955 42.8976 109.955 42.8976C109.955 42.8976 109.4 46.3738 107.597 45.9566C106.043 45.5979 101.4 29.3419 100.144 24.8493C100.053 24.5212 100.07 24.1721 100.195 23.8551C100.319 23.5381 100.544 23.2705 100.834 23.0931L107.211 19.19L108.845 19.8157C108.845 19.8157 111.606 17.6772 113.208 17.6341C114.811 17.591 117.585 17.1738 117.585 17.1738C117.585 17.1738 120.776 17.4519 121.331 19.8157C121.886 22.1795 122.163 36.9185 124.244 39.5604C126.325 42.2023 129.238 45.9566 126.602 45.9566Z"
                fill="#D0CDE1"
            />
            <path
                d="M107.181 53.7417C107.181 53.7417 107.181 59.1645 110.787 58.3302C114.394 57.4959 111.065 52.4902 111.065 52.4902L107.181 53.7417Z"
                fill="#FFB9B9"
            />
            <path
                d="M117.169 54.2983C117.169 54.2983 115.92 60.6945 113.285 59.7211C110.649 58.7478 113.285 53.0469 113.285 53.0469L117.169 54.2983Z"
                fill="#FFB9B9"
            />
            <path
                opacity="0.15"
                d="M122.995 39.1413C122.995 39.1413 121.637 42.3894 120.555 45.0577C118.222 44.6211 115.668 44.1025 113.654 43.6826C113.798 43.2985 114.001 42.9393 114.256 42.6175C115.227 41.5051 114.672 41.227 114.672 41.227C113.839 39.5584 114.533 37.3337 114.533 37.3337C112.868 32.8842 115.365 27.3223 115.365 27.3223L122.995 39.1413Z"
                fill="black"
            />
            <path
                d="M115.782 27.323C115.782 27.323 113.285 32.8849 114.949 37.3344C114.949 37.3344 114.256 39.5592 115.088 41.2277C115.088 41.2277 115.643 41.5058 114.672 42.6182C113.701 43.7306 112.868 47.6239 113.562 48.4582C114.256 49.2925 112.591 50.822 113.007 51.6563C113.423 52.4906 112.313 53.6029 112.313 53.6029C112.313 53.6029 116.475 54.4372 117.169 55.6887C117.169 55.6887 118.001 54.7153 117.585 53.6029C117.169 52.4906 117.446 52.0734 117.863 51.3782C118.279 50.683 119.527 49.1534 119.666 48.4582C119.805 47.763 123.412 39.142 123.412 39.142L121.72 23.4316L115.782 27.323Z"
                fill="#D0CDE1"
            />
            <path
                d="M101.354 23.4297L101.095 23.4769C100.733 23.5426 100.407 23.734 100.172 24.0175C99.9381 24.3009 99.8111 24.6581 99.8138 25.0262C99.8236 26.4223 99.9021 28.8999 100.244 32.3287C100.799 37.8906 102.741 46.2334 103.157 47.0677C103.818 48.544 104.559 49.983 105.377 51.3782C106.626 53.4639 106.903 52.7687 106.626 53.742C106.541 54.0101 106.536 54.2969 106.609 54.5681C106.683 54.8393 106.833 55.0835 107.042 55.2715C107.042 55.2715 109.539 52.4906 112.036 54.0201C112.215 53.6269 112.309 53.2007 112.313 52.7687C112.313 52.0734 112.73 51.3782 112.036 50.9611C111.342 50.5439 112.452 48.7363 111.342 47.763C110.233 46.7897 110.094 46.9287 110.233 45.9554C110.297 45.2936 110.202 44.6259 109.955 44.0087L101.354 23.4297Z"
                fill="#D0CDE1"
            />
            <path
                d="M109.619 4.7832C109.619 4.7832 107.376 10.7425 101.879 13.2162L100.982 7.93152L109.619 4.7832Z"
                fill="#2F2E41"
            />
            <path
                d="M112.868 11.1251C113.29 11.1251 113.631 10.4714 113.631 9.66508C113.631 8.85874 113.29 8.20508 112.868 8.20508C112.447 8.20508 112.105 8.85874 112.105 9.66508C112.105 10.4714 112.447 11.1251 112.868 11.1251Z"
                fill="#FFB9B9"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="272" height="211" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default AttendanceIllus;
