import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import zxcvbn from 'zxcvbn';
import { Form, Field, Formik, ErrorMessage } from 'formik';
import ErrorMessageContainer from '../components/ErrorMessageContainer';
import Button from '../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import setResponseErrors from '../utils/setResponseErrors';
import {
    updateProfile,
    UPDATE_USER_SUCCESSFUL,
    currentUser,
} from '../reducers/usersActions';
import { gql, useQuery } from '@apollo/client';
import { useHistory, Switch, Route, NavLink } from 'react-router-dom';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { UserIcon, PasswordIcon } from '../components/RaazIcons';
import toBase64 from '../utils/toBase64';
import getDefaultAvatarColors from '../utils/getDefaultAvatarColors';
import Loader from '../components/Loader';

const ProfileSchema = yup.object().shape({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    email: yup
        .string()
        .email()
        .required('This field is required'),
    password: yup.string(),
    avatar: yup.mixed(),
    mobileNumber: yup.string(),
    title: yup.string(),
    selfTitle: yup.string(),
    division: yup.string(),
    department: yup.string(),
    company: yup.string(),
});

const GET_CURRENT_USER = gql`
    query currentUser {
        currentUser {
            id
            firstName
            lastName
            email
            mobileNumber
            title
            selfTitle
            department
            division
            company
            avatar
        }
    }
`;

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    title: '',
    selfTitle: '',
    department: '',
    division: '',
    company: '',
    avatar: null,
};

const ImageUpload = ({ id, field, form, disabled, user }) => {
    const [preview, setPreview] = useState(user.avatar);
    return (
        <div className="w-100 d-flex justify-content-center">
            <label
                htmlFor={id || field.name}
                style={{ position: 'relative' }}
                className="avatar avatar-xxl mb-4 mx-auto"
                style={{ position: 'relative', cursor: 'pointer' }}>
                {preview && preview !== '/images/user-icon.png' ? (
                    <img
                        src={preview}
                        className="avatar-img rounded-circle"
                        style={{
                            backgroundColor: getDefaultAvatarColors(
                                `${user.firstName} ${user.lastName}`,
                            ),
                        }}
                    />
                ) : (
                    <div
                        className="avatar-img rounded-circle d-flex align-items-center text-center"
                        style={{
                            backgroundColor: getDefaultAvatarColors(
                                `${user.firstName} ${user.lastName}`,
                            ),
                        }}
                    />
                )}
                <i
                    className="fe fe-camera"
                    style={{
                        fontSize: '32px',
                        width: '32px',
                        height: '32px',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        margin: 'auto',
                        color: '#BFBFC1',
                        lineHeight: 1,
                    }}
                />
            </label>
            <input
                style={{ display: 'none' }}
                type="file"
                name={field.name}
                disabled={disabled}
                id={id || field.name}
                onChange={e => {
                    const file = e.currentTarget.files[0];
                    form.setFieldValue(field.name, file);
                    toBase64(file).then(base64 => {
                        setPreview(base64);
                    });
                }}
            />
        </div>
    );
};

const createPasswordSchema = test =>
    yup.object().shape({
        password: yup
            .string()
            .required('Please enter your password')
            .matches(
                /^(?=.*\d|.*[!#$%&?@"])[a-zA-Z0-9!#$%&?@."]{8,}$/,
                'Must contain at least 8 characters, one number, or one special case Character',
            )
            .test(test),
        confirm_password: yup
            .string()
            .required('Please enter your confirm password.')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

const AccountTextInput = ({
    id,
    field,
    label,
    className,
    disabled,
    type = 'text',
    helpText = '',
}) => (
    <div className={`d-flex flex-column ${className}`}>
        <label className="AccountInputLabel mb-1" htmlFor={id || field.name}>
            {label}
        </label>
        <input
            className="p-2"
            disabled={disabled}
            style={{ border: '1px solid #DADADA' }}
            type={type}
            {...field}
            id={id || field.name}
        />
        {helpText && (
            <span style={{ fontSize: '11px', color: '#717171' }}>
                {helpText}
            </span>
        )}
    </div>
);

const AccountNumericalInput = ({
    id,
    field,
    label,
    className,
    disabled,
    inputmode = 'numeric',
    pattern = '[0-9]*',
}) => (
    <div className={`d-flex flex-column ${className}`}>
        <label className="AccountInputLabel mb-1" htmlFor={id || field.name}>
            {label}
        </label>
        <input
            className="p-2"
            disabled={disabled}
            style={{ border: '1px solid #DADADA' }}
            type="text"
            inputMode={inputmode}
            pattern={pattern}
            id={id || field.name}
            {...field}
        />
    </div>
);

const passwordStrength = score => {
    if (score < 3) return { color: '#FF862D', value: 'Weak' };
    if (score === 3) return { color: '#02B482', value: 'Good' };
    if (score > 3) return { color: '#276EF1', value: 'Strong' };
};

const Warning = ({ pass, children }) => (
    <p style={{ color: pass ? '#02B482' : '#A0A0A0' }} className="d-flex">
        <i className="fe fe-check-circle" />
        <span className="ml-2" style={pass ? { color: '#121212' } : {}}>
            {children}
        </span>
    </p>
);

const removeEmpty = obj =>
    Object.fromEntries(
        Object.entries(obj)
            .filter(([k, v]) => v != null)
            .map(([k, v]) =>
                typeof v === 'object' ? [k, removeEmpty(v)] : [k, v],
            ),
    );

const UpdateProfileForm = ({ user, dispatch, setSuccessMessage }) => (
    <Formik
        enableReinitialize
        initialValues={{ ...initialValues, ...removeEmpty(user) }}
        validationSchema={ProfileSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);

            let formData = new FormData();
            Object.keys(values).forEach(k => {
                formData.append(
                    k.replace(/([A-Z])/g, '_$1').toLowerCase(),
                    values[k] === null ? '' : values[k],
                );
            });

            dispatch(updateProfile(formData)).then(
                d => {
                    setSubmitting(false);
                    setSuccessMessage('Profile updated.');
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000);
                    window.scrollTo(0, 0);
                    dispatch({
                        type: UPDATE_USER_SUCCESSFUL,
                        payload: {
                            email: d.email,
                            firstName: d.first_name,
                            lastName: d.last_name,
                            mobileNumber: d.mobile_number,
                            title: d.title,
                            selfTitle: d.self_title,
                            deparmtent: d.deparmtent,
                            division: d.division,
                            company: d.company,
                            avatar: d.avatar,
                        },
                    });
                },
                err => {
                    setSubmitting(false);
                    setResponseErrors(err, setFieldError);
                },
            );
        }}>
        {({ isSubmitting }) => (
            <Form
                className="d-flex flex-column pb-3"
                style={{ minHeight: 'calc(100% - 200px)' }}>
                <Field
                    name="avatar"
                    user={user}
                    disabled={isSubmitting}
                    component={ImageUpload}
                />
                <Field
                    name="firstName"
                    label="First Name"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="firstName"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="lastName"
                    label="Last Name"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="lastName"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="email"
                    label="Email Address"
                    type="email"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage name="email" component={ErrorMessageContainer} />
                <Field
                    name="mobileNumber"
                    label="Mobile Number"
                    className="mb-4"
                    component={AccountNumericalInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="mobileNumber"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="title"
                    label="Job Title"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage name="title" component={ErrorMessageContainer} />

                <Field
                    name="selfTitle"
                    label="Job Title 2"
                    className="mb-4"
                    helpText="This is the Job Title I'd use if I could freely describe my current role."
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="selfTitle"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="division"
                    label="Division"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="division"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="department"
                    label="Department"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="department"
                    component={ErrorMessageContainer}
                />

                <Field
                    name="company"
                    label="Company"
                    className="mb-4"
                    component={AccountTextInput}
                    disabled={isSubmitting}
                />
                <ErrorMessage
                    name="company"
                    component={ErrorMessageContainer}
                />

                <div className="d-flex w-100">
                    <Button
                        large
                        primary
                        type="submit"
                        className="ml-auto mt-auto"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}>
                        <span>Save</span>
                    </Button>
                </div>
            </Form>
        )}
    </Formik>
);

const UpdatePasswordForm = ({ dispatch, user, setSuccessMessage }) => {
    const firstName = user && user.firstName && user.firstName.toLowerCase();
    const lastName = user && user.lastName && user.lastName.toLowerCase();
    const email = user && user.email && user.email.toLowerCase();
    const PasswordSchema = createPasswordSchema({
        name: 'no-names',
        message: 'Password must not include your name or email',
        test: value =>
            !(
                value &&
                firstName &&
                value.toLowerCase().includes(firstName) &&
                lastName &&
                value.toLowerCase().includes(lastName) &&
                email &&
                value.toLowerCase().includes(email)
            ),
    });

    return (
        <Formik
            initialValues={{
                password: '',
                confirm_password: '',
            }}
            validationSchema={PasswordSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(updateProfile(values)).then(
                    d => {
                        setSubmitting(false);
                        setSuccessMessage('Password updated.');
                        setTimeout(() => {
                            setSuccessMessage('');
                        }, 3000);
                        window.scrollTo(0, 0);
                    },
                    err => {
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({ isSubmitting, values }) => {
                const results = zxcvbn(values.password, [
                    firstName,
                    lastName,
                    email,
                ]);
                const { feedback, score } = results;
                const strength = passwordStrength(score);
                return (
                    <Form
                        className="d-flex flex-column pb-3"
                        style={{ minHeight: 'calc(100% - 200px)' }}>
                        <Field
                            name="password"
                            label="Enter New Password"
                            component={AccountTextInput}
                            type="password"
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="password"
                            component={ErrorMessageContainer}
                        />

                        {feedback && feedback.warning ? (
                            <ErrorMessageContainer>
                                {feedback.warning}
                            </ErrorMessageContainer>
                        ) : null}
                        <Field
                            name="confirm_password"
                            label="Confirm New Password"
                            className="mt-4"
                            component={AccountTextInput}
                            type="password"
                            disabled={isSubmitting}
                        />
                        <ErrorMessage
                            name="confirm_password"
                            component={ErrorMessageContainer}
                        />

                        <div
                            style={{ fontSize: '14px' }}
                            className="mt-4 d-flex flex-column">
                            <p>
                                <span>Password Strength: </span>
                                {values.password ? (
                                    <span style={{ color: strength.color }}>
                                        {strength.value}
                                    </span>
                                ) : (
                                    <span>—</span>
                                )}
                            </p>
                            <Warning
                                pass={
                                    values.password &&
                                    !values.password
                                        .toLowerCase()
                                        .includes(firstName) &&
                                    !values.password
                                        .toLowerCase()
                                        .includes(lastName) &&
                                    !values.password
                                        .toLowerCase()
                                        .includes(email)
                                }>
                                Must not contain your name or email
                            </Warning>
                            <Warning pass={values.password.length >= 8}>
                                At least 8 characters
                            </Warning>
                            <Warning
                                pass={
                                    values.password.search(
                                        /^(?=.*\d|.*[!#$%&?@"])[a-zA-Z0-9!#$%&?@."]{1,}$/,
                                    ) > -1
                                }>
                                Contains a symbol or a number
                            </Warning>
                        </div>
                        <div className="d-flex w-100 mt-4">
                            <Button
                                large
                                primary
                                type="submit"
                                className="ml-auto mt-auto"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}>
                                <span>Save</span>
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

const ProfileScreen = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const dispatch = useDispatch();
    const r = useQuery(GET_CURRENT_USER);

    if (r.loading) return <Loader fullscreen />;

    return (
        <div
            className="py-5 px-4 mx-auto"
            style={{ maxWidth: MAX_WIDTH * 1.3 }}>
            {successMessage && (
                <div className="AccountSuccessMsg">{successMessage}</div>
            )}
            <h1 className="lora mb-5">Account Settings</h1>
            <div className="row">
                <div className="col-4">
                    <div className="d-flex flex-column pr-3">
                        <NavLink
                            to="/profile"
                            className="AccountLink mb-4"
                            style={{ color: '#121212', fontSize: '14px' }}
                            activeClassName="isActive"
                            exact>
                            <UserIcon width={16} />
                            <span className="ml-3">Account Information</span>
                        </NavLink>
                        <NavLink
                            to="/profile/password"
                            className="AccountLink"
                            style={{ color: '#121212', fontSize: '14px' }}
                            activeClassName="isActive">
                            <PasswordIcon width={16} />
                            <span className="ml-3">Reset Password</span>
                        </NavLink>
                    </div>
                </div>
                <div className="col-8">
                    <Switch>
                        <Route path="/profile/password">
                            <UpdatePasswordForm
                                dispatch={dispatch}
                                user={r.data.currentUser}
                                setSuccessMessage={setSuccessMessage}
                            />
                        </Route>
                        <Route path="/profile" exact>
                            <UpdateProfileForm
                                user={r.data.currentUser}
                                dispatch={dispatch}
                                setSuccessMessage={setSuccessMessage}
                            />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default ProfileScreen;
