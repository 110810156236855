import React, { Fragment, useEffect, useState } from 'react';
import ProjectTitle from '../components/ProjectTitle';
import { Switch, Route, useParams } from 'react-router-dom';
import CurrentStep from '../components/100HCurrentStep';
import Preamble from '../components/Preamble';
import PeerExchangeResponsesScreen from './PeerExchangeResponsesScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../reducers/hundredhoursActions';
import Loader from '../components/Loader';
import getQuestionsFromSections from '../utils/getQuestionsFromSections';
import Axios from 'axios';
import { getPeerReview } from '../reducers/itineraryActions';
import { showAuditModal } from '../reducers/modalActions';
import { POPULATE_GROUP_ANSWERS } from '../reducers/peerExchangeActions';

const PeerExchangeScreen = ({ stops, projectDetails }) => {
    const { projectId, stopId, soloWorkoutId } = useParams();
    const dispatch = useDispatch();
    const stop = stops.find(s => s.id === stopId);
    const ga = useSelector(s => s.peerExchange);
    const groupAnswers = ga[`${projectId}-${soloWorkoutId}`] || [];
    useEffect(() => {
        dispatch(getPeerReview(soloWorkoutId, { project_id: projectId }))
            .then(({ answers }) =>
                dispatch({
                    type: POPULATE_GROUP_ANSWERS,
                    payload: {
                        projectId,
                        soloWorkoutId,
                        groupAnswers: answers,
                    },
                }),
            )
            .catch(console.warn);
    }, [projectId, soloWorkoutId]);

    const path = `/100h/projects/${projectId}/hurdles/${stopId}`;

    const handleClick = () => {
        dispatch(
            showAuditModal({
                next: `${path}/iterated-response/${soloWorkoutId}`,
                projectId,
                stopId,
            }),
        );
    };

    if (!projectDetails || !stop || !stop.hundred_h)
        return <Loader fullscreen />;

    const filteredContent = getQuestionsFromSections(
        stop.hundred_h.find(h => h.type === 'peer_exchange').sections,
    );

    return (
        <Fragment>
            <ProjectTitle
                title={projectDetails.title}
                subtitle={`Hurlde ${stop.ordinality}: ${stop.name}`}
            />
            <div className="d-flex flex-column align-items-center justify-content-center">
                <Switch>
                    <Route path="/100h/projects/:projectId/hurdles/:stopId/peer-exchange/:soloWorkoutId/plenary-exchange">
                        <CurrentStep />
                        <Preamble
                            buttonTitle="Next"
                            title="Plenary Exchange"
                            content="Once you read all the responses. You can now exchange views on it and seek concerns. Organize an online meeting to discuss the response. After which, choose which of the responses from the group will be referenced when you fill in your Iterated Workout in the next section. Try using these tools."
                            onClick={handleClick}>
                            <div className="d-flex align-items-center">
                                <img
                                    src="/images/zoom@2x.png"
                                    className="mr-3"
                                    style={{ maxHeight: '100px' }}
                                />
                                <img
                                    src="/images/meet@2x.png"
                                    style={{ maxHeight: '100px' }}
                                />
                            </div>
                        </Preamble>
                    </Route>
                    <Route path="/100h/projects/:projectId/hurdles/:stopId/peer-exchange/:soloWorkoutId/answers">
                        <PeerExchangeResponsesScreen
                            content={filteredContent}
                            groupAnswers={groupAnswers}
                            members={projectDetails.members}
                        />
                    </Route>
                    <Route
                        path="/100h/projects/:projectId/hurdles/:stopId/peer-exchange/:soloWorkoutId"
                        exact>
                        <CurrentStep />
                        <Preamble
                            title="Peer Exchange"
                            content="Members share each other’s notes in order to strengthen their own. When you’ve read your members response please mark them as read."
                            to={`${path}/peer-exchange/${soloWorkoutId}/answers`}
                        />
                    </Route>
                </Switch>
            </div>
        </Fragment>
    );
};

export default PeerExchangeScreen;
