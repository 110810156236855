import React, {
    useRef,
    useState,
    useCallback,
    Fragment,
    useEffect,
} from 'react';
import {
    getInitialAnswer,
    createSchema,
    createDataForSave,
} from '../utils/answerUtils';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useWindowEvent from '../hooks/useWindowEvent';
import AnimatedContainer from './AnimatedContainer';
import { useScrollIntoView } from '../hooks/useScrollIntoView';
import StepTracker from './StepTracker';
import { Formik, Form } from 'formik';
import Content from '../screens/RAAZ/Content';
import Button from './Button';
import { MAX_WIDTH } from '../utils/CONSTANTS';
import { useScroll } from '../hooks/useScroll';
import {
    saveAnswers,
    submitAnswers,
    updateDiscernmentAnswer,
} from '../reducers/itineraryActions';
import setResponseErrors from '../utils/setResponseErrors';
import { useParams } from 'react-router-dom';
import getQuestionsFromSections from '../utils/getQuestionsFromSections';

const SectionContent = ({ c, title, values, isSubmitting }) => (
    <Fragment>
        {title && <h2 className="header-pretitle isRaaz">{title}</h2>}
        {c.type === 'pro_tip' && c.blurb ? (
            <div className="bg-gray p-4 mb-6 mt-6">
                <strong className="mb-2 d-block">
                    <i className="fe fe-info" /> PRO TIP
                </strong>
                <p
                    dangerouslySetInnerHTML={{
                        __html: c.blurb,
                    }}
                />
            </div>
        ) : null}
        {c.type === 'question' ? (
            <Content {...c} values={values} disabled={isSubmitting} />
        ) : null}
        {c.type === 'blurb' ? (
            <div
                className="mb-3 soloworkout-blurb"
                dangerouslySetInnerHTML={{
                    __html: c.blurb,
                }}
            />
        ) : null}
        {c.type === 'iterated_answer' && c.answer ? (
            <div className="p-5 mb-4 bg-light">
                <strong
                    className="d-block mb-2"
                    dangerouslySetInnerHTML={{ __html: c.question }}
                />
                <p
                    className="mt-2 mb-0"
                    dangerouslySetInnerHTML={{ __html: c.answer }}
                />
            </div>
        ) : null}
    </Fragment>
);

const Section = ({
    index,
    setCurrentView,
    content,
    isSubmitting,
    isFirst,
    isLast,
    values,
    nextSection,
    prevSection,
    errors,
    protip,
    submitFailed,
    startSubmission,
    setStatus,
    handleSubmit,
    autoSave,
}) => {
    const ref = useRef();
    const [showTooltip, setShowTooltip] = useState(false);
    const [height, setHeight] = useState('100vh');
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    const scroll = () => {
        autoSave();
        window.scrollTo({
            top: nextSection,
            behavior: 'smooth',
        });
    };

    const scrollBack = () =>
        window.scrollTo({
            top: prevSection,
            behavior: 'smooth',
        });

    return (
        <AnimatedContainer
            scrollY={scrollY}
            section={index}
            setCurrentView={setCurrentView}>
            <div
                ref={ref}
                className="d-flex flex-column justify-content-center py-5"
                style={{
                    minHeight: `calc(${height} - 84px)`,
                }}>
                {content
                    .sort((a, b) => a.ordinality - b.ordinality)
                    .map((c, i) => (
                        <SectionContent
                            key={i}
                            c={c}
                            isSubmitting={isSubmitting}
                            values={values}
                        />
                    ))}
                <div className="mt-0 w-100 d-flex align-items-center">
                    {!isFirst && (
                        <Button
                            secondary
                            onClick={scrollBack}
                            rounded
                            type="button"
                            className="mr-2"
                            style={{ borderRadius: '2px' }}
                            disabled={isSubmitting}>
                            Prev
                        </Button>
                    )}
                    {isLast ? (
                        <Fragment>
                            <Button
                                primary
                                type="submit"
                                onClick={e => {
                                    setStatus('StartSubmission');
                                    handleSubmit(e);
                                }}
                                isLoading={isSubmitting}
                                rounded
                                style={{ borderRadius: '2px' }}
                                disabled={isSubmitting}>
                                Submit
                            </Button>
                            {submitFailed ||
                            (startSubmission &&
                                Object.keys(errors || {}).length > 0) ? (
                                <p className="text-danger mb-0 ml-2">
                                    Failed to submit. Check earlier questions
                                    for errors.
                                </p>
                            ) : null}
                        </Fragment>
                    ) : (
                        <Button
                            primary
                            onClick={scroll}
                            rounded
                            type="button"
                            style={{ borderRadius: '2px' }}
                            disabled={isSubmitting}>
                            Next
                        </Button>
                    )}
                    {protip && (
                        <Button
                            secondary
                            className="ml-auto d-flex align-items-center"
                            rounded
                            type="button"
                            onMouseEnter={() => {
                                setShowTooltip(true);
                            }}
                            onMouseLeave={() => {
                                setShowTooltip(false);
                            }}
                            onClick={() => {
                                setShowTooltip(!showTooltip);
                            }}
                            style={{
                                borderRadius: '2px',
                                position: 'relative',
                            }}>
                            <span>Pro Tip</span>
                            <i className="ml-2 fe fe-info" />
                            {showTooltip && (
                                <span className="d-flex flex-column p-3 Btn--tooltip">
                                    <strong className="text-uppercase mb-2">
                                        Pro Tip
                                    </strong>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: protip,
                                        }}
                                    />
                                </span>
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </AnimatedContainer>
    );
};

const Sections = ({ sections, slug, next, offset = 0, eventId }) => {
    const { scrollY } = useScroll();
    const answers = useSelector(s => s.itinerary.discernmentAnswers);
    const { offsets, scrollIntoView, setRef } = useScrollIntoView({ offset });
    const [currentView, setCurrentView] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const { projectId } = useParams();

    const filteredContent = getQuestionsFromSections(sections);

    const contentAnswers = answers[slug];
    const initialValues = filteredContent.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.question_key]: getInitialAnswer(cur, contentAnswers),
        };
    }, {});
    const schema = createSchema(filteredContent);

    useEffect(() => {
        offsets.length > 0 && scrollIntoView(currentView);
    }, []);

    const autoSave = (v, s) => {
        const data = createDataForSave(v, s);
        dispatch(
            saveAnswers(eventId, {
                ...data,
                ...(projectId && { project_id: projectId }),
            }),
        );
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting, setFieldError, setStatus }) => {
                setSubmitting(true);
                const data = createDataForSave(values, slug);

                dispatch(
                    submitAnswers(eventId, {
                        ...data,
                        ...(projectId && { project_id: projectId }),
                    }),
                ).then(
                    res => {
                        setStatus('');
                        setSubmitting(false);
                        dispatch(updateDiscernmentAnswer(data));
                        history.push(next);
                    },
                    err => {
                        setStatus('SubmissionFailed');
                        setSubmitting(false);
                        setResponseErrors(err, setFieldError);
                    },
                );
            }}>
            {({
                isSubmitting,
                values,
                errors,
                status,
                setStatus,
                handleSubmit,
            }) => (
                <Form>
                    <StepTracker
                        length={offsets.length}
                        currentView={currentView}
                        scrollIntoView={scrollIntoView}
                    />
                    <div
                        ref={setRef}
                        className="mx-auto"
                        style={{ maxWidth: MAX_WIDTH }}>
                        {sections
                            .sort((a, b) => a.ordinality - b.ordinality)
                            .map((s, i) => (
                                <Section
                                    key={i}
                                    index={i}
                                    content={s.content}
                                    scrollY={scrollY}
                                    setCurrentView={setCurrentView}
                                    nextSection={offsets[i + 1]}
                                    prevSection={offsets[i - 1]}
                                    isSubmitting={isSubmitting}
                                    values={values}
                                    isLast={sections.length - 1 === i}
                                    isFirst={i === 0}
                                    errors={errors}
                                    protip={s.protip}
                                    submitFailed={status === 'SubmissionFailed'}
                                    startSubmission={
                                        status === 'StartSubmission'
                                    }
                                    handleSubmit={handleSubmit}
                                    autoSave={() => autoSave(values, slug)}
                                    setStatus={setStatus}
                                />
                            ))}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Sections;
