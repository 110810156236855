import React from 'react';

export const TargetIcon = ({ fill = '#121212', width = 34, height = 34 }) => (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none">
        <path
            d="M17 0.333008C14.8113 0.333008 12.644 0.764104 10.6219 1.60168C8.59983 2.43926 6.76251 3.66692 5.21487 5.21456C2.08926 8.34017 0.333313 12.5794 0.333313 16.9997C0.333313 21.42 2.08926 25.6592 5.21487 28.7848C6.76251 30.3324 8.59983 31.5601 10.6219 32.3977C12.644 33.2352 14.8113 33.6663 17 33.6663C21.4203 33.6663 25.6595 31.9104 28.7851 28.7848C31.9107 25.6592 33.6666 21.42 33.6666 16.9997C33.6666 15.0663 33.3166 13.1497 32.65 11.3497L29.9833 14.0163C30.2167 14.9997 30.3333 15.9997 30.3333 16.9997C30.3333 20.5359 28.9286 23.9273 26.4281 26.4278C23.9276 28.9283 20.5362 30.333 17 30.333C13.4638 30.333 10.0724 28.9283 7.57189 26.4278C5.0714 23.9273 3.66665 20.5359 3.66665 16.9997C3.66665 13.4635 5.0714 10.0721 7.57189 7.57158C10.0724 5.0711 13.4638 3.66634 17 3.66634C18 3.66634 19 3.78301 19.9833 4.01634L22.6666 1.33301C20.85 0.683008 18.9333 0.333008 17 0.333008ZM28.6666 0.333008L22 6.99967V9.49967L17.75 13.7497C17.5 13.6663 17.25 13.6663 17 13.6663C16.1159 13.6663 15.2681 14.0175 14.643 14.6427C14.0178 15.2678 13.6666 16.1156 13.6666 16.9997C13.6666 17.8837 14.0178 18.7316 14.643 19.3567C15.2681 19.9818 16.1159 20.333 17 20.333C17.884 20.333 18.7319 19.9818 19.357 19.3567C19.9821 18.7316 20.3333 17.8837 20.3333 16.9997C20.3333 16.7497 20.3333 16.4997 20.25 16.2497L24.5 11.9997H27L33.6666 5.33301H28.6666V0.333008ZM17 6.99967C14.3478 6.99967 11.8043 8.05324 9.92891 9.92861C8.05355 11.804 6.99998 14.3475 6.99998 16.9997C6.99998 19.6518 8.05355 22.1954 9.92891 24.0707C11.8043 25.9461 14.3478 26.9997 17 26.9997C19.6521 26.9997 22.1957 25.9461 24.0711 24.0707C25.9464 22.1954 27 19.6518 27 16.9997H23.6666C23.6666 18.7678 22.9643 20.4635 21.714 21.7137C20.4638 22.964 18.7681 23.6663 17 23.6663C15.2319 23.6663 13.5362 22.964 12.2859 21.7137C11.0357 20.4635 10.3333 18.7678 10.3333 16.9997C10.3333 15.2316 11.0357 13.5359 12.2859 12.2856C13.5362 11.0354 15.2319 10.333 17 10.333V6.99967Z"
            fill={fill}
        />
    </svg>
);

export const BrainstormIcon = ({
    fill = '#121212',
    width = 38,
    height = 38,
}) => (
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
        <path
            d="M32.3334 17.3332H37.3334V20.6665H32.3334V17.3332ZM0.666687 17.3332H5.66669V20.6665H0.666687V17.3332ZM20.6667 0.666504V5.6665H17.3334V0.666504H20.6667ZM7.20002 4.83317L10.75 8.39984L8.38335 10.7498L4.83335 7.2165L7.20002 4.83317ZM27.25 8.38317L30.7833 4.83317L33.1667 7.2165L29.6167 10.7498L27.25 8.38317ZM19 8.99984C21.6522 8.99984 24.1957 10.0534 26.0711 11.9288C27.9465 13.8041 29 16.3477 29 18.9998C29 22.6998 26.9834 25.9332 24 27.6665V30.6665C24 31.1085 23.8244 31.5325 23.5119 31.845C23.1993 32.1576 22.7754 32.3332 22.3334 32.3332H15.6667C15.2247 32.3332 14.8007 32.1576 14.4882 31.845C14.1756 31.5325 14 31.1085 14 30.6665V27.6665C11.0167 25.9332 9.00002 22.6998 9.00002 18.9998C9.00002 16.3477 10.0536 13.8041 11.929 11.9288C13.8043 10.0534 16.3479 8.99984 19 8.99984ZM22.3334 33.9998V35.6665C22.3334 36.1085 22.1578 36.5325 21.8452 36.845C21.5326 37.1576 21.1087 37.3332 20.6667 37.3332H17.3334C16.8913 37.3332 16.4674 37.1576 16.1548 36.845C15.8423 36.5325 15.6667 36.1085 15.6667 35.6665V33.9998H22.3334ZM17.3334 28.9998H20.6667V25.4498C23.55 24.7165 25.6667 22.0998 25.6667 18.9998C25.6667 17.2317 24.9643 15.536 23.7141 14.2858C22.4638 13.0355 20.7681 12.3332 19 12.3332C17.2319 12.3332 15.5362 13.0355 14.286 14.2858C13.0357 15.536 12.3334 17.2317 12.3334 18.9998C12.3334 22.0998 14.45 24.7165 17.3334 25.4498V28.9998Z"
            fill={fill}
        />
    </svg>
);

export const RefineIcon = ({ fill = '#121212', width = 31, height = 36 }) => (
    <svg width={width} height={height} viewBox="0 0 31 36" fill="none">
        <path
            d="M7.33335 17.0002H20.6667V20.3335H7.33335V17.0002ZM10.6667 30.3335H4.00002V3.66683H15.6667V12.0002H24V17.1668L27.3334 13.8335V10.3335L17.3334 0.333496H4.00002C3.11597 0.333496 2.26812 0.684685 1.643 1.30981C1.01788 1.93493 0.666687 2.78277 0.666687 3.66683V30.3335C0.666687 31.2176 1.01788 32.0654 1.643 32.6905C2.26812 33.3156 3.11597 33.6668 4.00002 33.6668H10.6667V30.3335ZM7.33335 27.0002H14.1667L15.6667 25.5002V23.6668H7.33335V27.0002ZM27.6667 18.6668C27.8334 18.6668 28.1667 18.8335 28.3334 19.0002L30.5 21.1668C30.8334 21.5002 30.8334 22.1668 30.5 22.5002L28.8334 24.1668L25.3334 20.6668L27 19.0002C27.1667 18.8335 27.3334 18.6668 27.6667 18.6668ZM27.6667 25.1668L17.5 35.3335H14V31.8335L24.1667 21.6668L27.6667 25.1668Z"
            fill={fill}
        />
    </svg>
);

export const TestIcon = ({ fill = '#121212', width = 34, height = 34 }) => (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none">
        <path
            d="M3.66665 0.333496C2.78259 0.333496 1.93474 0.684685 1.30962 1.30981C0.684502 1.93493 0.333313 2.78277 0.333313 3.66683V17.0002H3.66665V10.3335H6.99998V17.0002H10.3333V3.66683C10.3333 2.78277 9.98212 1.93493 9.357 1.30981C8.73188 0.684685 7.88404 0.333496 6.99998 0.333496H3.66665ZM3.66665 3.66683H6.99998V7.00016H3.66665V3.66683ZM33.6666 22.8335V20.3335C33.6666 19.4494 33.3155 18.6016 32.6903 17.9765C32.0652 17.3514 31.2174 17.0002 30.3333 17.0002H23.6666V33.6668H30.3333C31.2174 33.6668 32.0652 33.3156 32.6903 32.6905C33.3155 32.0654 33.6666 31.2176 33.6666 30.3335V27.8335C33.6498 27.1758 33.381 26.5496 32.9157 26.0844C32.4505 25.6192 31.8244 25.3504 31.1666 25.3335C31.8244 25.3166 32.4505 25.0478 32.9157 24.5826C33.381 24.1173 33.6498 23.4912 33.6666 22.8335ZM30.3333 30.3335H27V27.0002H30.3333V30.3335ZM30.3333 23.6668H27V20.3335H30.3333V23.6668ZM6.64998 33.0168L4.01665 30.9835L27.35 0.983496L29.9833 3.01683L6.64998 33.0168Z"
            fill={fill}
        />
    </svg>
);
export const LaunchIcon = ({ fill = '#121212', width = 34, height = 34 }) => (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none">
        <path
            d="M18.8833 33.9835L16.1667 27.6002C18.7833 26.6335 21.2333 25.3335 23.5 23.8168L18.8833 33.9835ZM6.4 17.8335L0.0166626 15.1168L10.1833 10.5002C8.66666 12.7668 7.36666 15.2168 6.4 17.8335ZM29.0333 3.66683C29.5 3.66683 29.9167 3.66683 30.2667 3.75016C30.55 6.06683 30.2333 10.8335 24.7667 16.3002C21.9333 19.1502 18.55 21.3335 14.75 22.7835L11.1667 19.2835C12.7 15.4335 14.8833 12.0502 17.7 9.2335C22.3 4.6335 26.4 3.66683 29.0333 3.66683ZM29.0333 0.333496C25.7333 0.333496 20.7333 1.4835 15.3333 6.8835C11.6833 10.5335 9.5 14.5502 8.08333 18.0668C7.61666 19.3168 7.93333 20.6835 8.85 21.6168L12.4 25.1502C13.0333 25.7835 13.8833 26.1668 14.7667 26.1668C15.15 26.1668 15.55 26.0668 15.9333 25.9168C19.5 24.5502 23.4667 22.3168 27.1167 18.6668C36.55 9.2335 33.0167 0.983496 33.0167 0.983496C33.0167 0.983496 31.5 0.333496 29.0333 0.333496ZM21.2333 12.7668C19.9333 11.4668 19.9333 9.35016 21.2333 8.05016C22.5333 6.75016 24.65 6.75016 25.95 8.05016C27.2333 9.35016 27.25 11.4668 25.95 12.7668C24.65 14.0668 22.5333 14.0668 21.2333 12.7668ZM11.8 24.5502L9.45 22.2002L11.8 24.5502ZM7.4 33.6668L13.4667 27.6002C12.9 27.4502 12.35 27.2002 11.85 26.8502L5.05 33.6668H7.4ZM0.333329 33.6668H2.68333L10.6333 25.7335L8.26666 23.3835L0.333329 31.3168V33.6668ZM0.333329 28.9502L7.15 22.1502C6.8 21.6502 6.55 21.1168 6.4 20.5335L0.333329 26.6002V28.9502Z"
            fill={fill}
        />
    </svg>
);
