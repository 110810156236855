import React, { useState, useEffect, Fragment } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import {
    populateDiscernmentAnswer,
    getSoloWorkoutDetails,
} from '../../reducers/itineraryActions';
import SoloWorkoutContent from '../RAAZ/SoloWorkoutContent';
import ProjectTitle from '../../components/ProjectTitle';
import Sections from '../../components/Sections';
import Preamble from '../../components/Preamble';
import CurrentStep from '../../components/100HCurrentStep';

const Hurdle = ({ stop }) => {
    const { soloWorkoutId, stopId, projectId } = useParams();
    const dispatch = useDispatch();
    const [details, setDetails] = useState(null);
    const [sections, setSections] = useState([]);

    const next = `/100h/projects/${projectId}/hurdles/${stopId}/peer-exchange/${soloWorkoutId}`;

    useEffect(() => {
        dispatch(
            getSoloWorkoutDetails(soloWorkoutId, { project_id: projectId }),
        ).then(({ sections, answers, ...d }) => {
            setDetails(d);
            setSections(sections);

            if (answers.project_id === projectId) {
                dispatch(populateDiscernmentAnswer(answers));
            } else {
                dispatch(
                    populateDiscernmentAnswer({
                        discernment_slug: answers.discernment_slug,
                        answers: [],
                    }),
                );
            }
        });
    }, [soloWorkoutId]);

    if (!sections.length || !details || !stop) return <Loader fullscreen />;

    return (
        <Fragment>
            <ProjectTitle
                subtitle={`Hurdle ${stop.ordinality}: ${stop.name}`}
            />
            <div className="w-100 pt-5">
                <Sections
                    sections={sections}
                    next={next}
                    slug={details.slug}
                    offset={-72}
                    eventId
                />
            </div>
        </Fragment>
    );
};

const HurdleScreen = ({ stops }) => {
    const { soloWorkoutId, stopId, projectId } = useParams();
    const stop = stops.find(s => s.id === stopId);
    const to = `/100h/projects/${projectId}/hurdles/${stopId}/solo_workout/${soloWorkoutId}/workout`;
    return (
        <Switch>
            <Route path="/100h/projects/:projectId/hurdles/:stopId/solo_workout/:soloWorkoutId/workout">
                <Hurdle stop={stop} />
            </Route>
            <Route
                path="/100h/projects/:projectId/hurdles/:stopId/solo_workout/:soloWorkoutId"
                exact>
                {!stop ? (
                    <div className="p-5 w-100 justify-content-center d-flex">
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <ProjectTitle
                            transparent
                            subtitle={`Hurdle ${stop.ordinality}: ${stop.name}`}
                        />
                        <CurrentStep />
                        <Preamble
                            title="Solo Workout"
                            content={stop.solo_workout_preamble}
                            to={to}
                        />
                    </Fragment>
                )}
            </Route>
        </Switch>
    );
};

export default HurdleScreen;
