import React, {
    Fragment,
    useState,
    useRef,
    useCallback,
    useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from './Button';
import { LOGOUT } from '../reducers/usersActions';
import {
    RaazLogo,
    RestartIcon,
    HundredHIcon,
    GrowthlabsIcon,
    ChixIcon,
    VideoIcon,
    UserIcon,
} from './RaazIcons';
import useWindowEvent from '../hooks/useWindowEvent';
import * as moment from 'moment';
import { client } from '../client';

export const RaazHeader = ({
    children,
    user,
    toggleIggyNext,
    isIggyJr,
    isIggyDashboard,
}) => {
    const topBar = useRef();
    const [fillerHeight, setFillerHeight] = useState('76px');
    const { validLicense, freeTrial, licenseValidityDate } = user;
    const [showMenu, toggleMenu] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    const callback = useCallback(() => {
        topBar.current && setFillerHeight(`${topBar.current.offsetHeight}px`);
    }, [topBar.current, location.pathname]);

    useEffect(() => {
        topBar.current && setFillerHeight(`${topBar.current.offsetHeight}px`);
    }, [topBar.current, location.pathname]);

    useWindowEvent('resize', callback);

    const daysLeft = licenseValidityDate
        ? moment(licenseValidityDate).fromNow('D')
        : 0;

    return (
        <Fragment>
            <div className="RaazHeader fixed-top" ref={topBar}>
                <div
                    className="px-4 d-flex align-items-center"
                    style={{ minHeight: '80px' }}>
                    <Link
                        to="/"
                        className="mr-4 text-muted"
                        onClick={() =>
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                        }>
                        <RaazLogo
                            fill={
                                !isIggyDashboard && isIggyJr
                                    ? '#121212'
                                    : '#fff'
                            }
                        />
                    </Link>
                    <div
                        className={`${
                            toggleIggyNext ? 'ml-3' : 'ml-auto'
                        } dropdown d-flex`}>
                        <Button
                            transparent
                            className="text-white"
                            onClick={() => toggleMenu(!showMenu)}>
                            <i className="fe fe-menu" />
                        </Button>
                        <div
                            className={`dropdown-menu dropdown-menu-right ${
                                showMenu ? 'show' : ''
                            }`}
                            style={{
                                position: 'absolute',
                                willChange: 'transform',
                                top: '1.7rem',
                                right: 0,
                            }}>
                            <Link
                                to="/"
                                style={{ color: '#121212' }}
                                onClick={() => {
                                    toggleMenu(false);
                                    window.showPersonalGrowth &&
                                        showPersonalGrowth();
                                }}
                                className="d-flex dropdown-item justify-content-end align-items-center py-3">
                                <span
                                    className="mr-2"
                                    style={{ color: '#121212' }}>
                                    Personal Growth
                                </span>
                            </Link>
                            <Link
                                to="/"
                                style={{ color: '#121212' }}
                                onClick={() => {
                                    toggleMenu(false);
                                    window.showBusinessImpact &&
                                        showBusinessImpact();
                                }}
                                className="d-flex dropdown-item justify-content-end align-items-center py-3">
                                <span
                                    className="mr-2"
                                    style={{ color: '#121212' }}>
                                    Business Impact
                                </span>
                            </Link>
                            <Link
                                to="/growthlabs"
                                style={{ color: '#121212' }}
                                onClick={() => toggleMenu(false)}
                                className="d-flex dropdown-item justify-content-end align-items-center py-3">
                                <span
                                    className="mr-2"
                                    style={{ color: '#121212' }}>
                                    24/7 GrowthLabs
                                </span>
                                <GrowthlabsIcon fill="#121212" width={16} />
                            </Link>
                            <Link
                                to="/giftbox-collection"
                                style={{ color: '#121212' }}
                                onClick={() => toggleMenu(false)}
                                className="d-flex dropdown-item justify-content-end align-items-center">
                                <span
                                    className="mr-2"
                                    style={{ color: '#121212' }}>
                                    Giftbox Collection
                                </span>
                            </Link>
                            <Link
                                to="/profile"
                                onClick={() => toggleMenu(false)}
                                style={{ color: '#121212' }}
                                className="d-flex dropdown-item justify-content-end align-items-center py-3">
                                <span
                                    className="mr-2"
                                    style={{ color: '#121212' }}>
                                    Profile
                                </span>
                                <UserIcon fill="#121212" width={14} />
                            </Link>
                            <a
                                href="https://restartapp.tawk.help"
                                target="_blank"
                                style={{ color: '#121212' }}
                                className="d-flex dropdown-item justify-content-end align-items-center py-3">
                                FAQs
                            </a>
                            <div className="dropdown-divider" />
                            <Button
                                className="px-4 text-right"
                                transparent
                                block
                                onClick={() => {
                                    client.clearStore();
                                    dispatch({ type: LOGOUT });
                                }}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    {children}
                </div>
                {validLicense && freeTrial ? (
                    <div className="LicenseNotice p-2">
                        <p className="m-0 mr-2">
                            You only have {daysLeft} left in your Trial Period
                        </p>
                        <Button
                            transparent
                            href="https://restart100.io/buy/"
                            target="_blank"
                            className="py-1 px-2 border-0"
                            rounded
                            style={{
                                borderRadius: '2px',
                                color: 'white',
                                backgroundColor: 'rgba(18, 18, 18, 0.1)',
                                fontSize: '0.6875rem',
                            }}>
                            Subscribe Now
                        </Button>
                    </div>
                ) : null}
                {!validLicense ? (
                    <div className="LicenseNotice p-2">
                        <p className="m-0 mr-2">
                            You are accessing the free trial version of the app.
                            To access more stops in full
                        </p>
                        <Button
                            transparent
                            href="https://restart100.io/buy/"
                            target="_blank"
                            className="py-1 px-2 border-0"
                            rounded
                            style={{
                                borderRadius: '2px',
                                color: 'white',
                                backgroundColor: 'rgba(18, 18, 18, 0.1)',
                                fontSize: '0.6875rem',
                            }}>
                            Subscribe Now
                        </Button>
                    </div>
                ) : null}
            </div>
            <div
                style={{
                    height: fillerHeight,
                }}>
                &nbsp;
            </div>
        </Fragment>
    );
};

export default RaazHeader;
