import {
    POPULATE_ITINERARY_GROUPS,
    POPULATE_DISCERNMENT_ANSWER,
    UPDATE_DISCERNMENT_ANSWER,
    TOGGLE_APP_AREA_LOCK,
} from './itineraryActions';

const initialState = {
    groups: [],
    itineraries: [],
    discernmentAnswers: {},
};

const discernmentReducer = (state = {}, action) => {
    switch (action.type) {
        case POPULATE_DISCERNMENT_ANSWER:
            return {
                ...state,
                ...action.payload,
            };
        case UPDATE_DISCERNMENT_ANSWER:
            const currentAnswers = state[action.payload.discernment_slug] || [];
            const newAnswers = action.payload.answers;
            const uniqKeys = [
                ...new Set(
                    currentAnswers.concat(newAnswers).map(c => c.question_key),
                ),
            ];
            return {
                ...state,
                [action.payload.discernment_slug]: uniqKeys
                    .map(key => {
                        const ans =
                            newAnswers.find(a => a.question_key === key) ||
                            currentAnswers.find(a => a.question_key === key);
                        return ans;
                    })
                    .filter(Boolean),
            };
        default:
            return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POPULATE_ITINERARY_GROUPS:
            return {
                ...state,
                groups: action.payload,
                itineraries: action.payload.flatMap(g => g.itineraries),
            };
        case POPULATE_DISCERNMENT_ANSWER:
        case UPDATE_DISCERNMENT_ANSWER:
            return {
                ...state,
                discernmentAnswers: discernmentReducer(
                    state.discernmentAnswers,
                    action,
                ),
            };
        case TOGGLE_APP_AREA_LOCK:
            const itineraries = state.itineraries.map(it => {
                if (it.event.id === action.payload.event_id) {
                    const updatedAppAreas = it.event.app_areas.map(area => {
                        if (area.id === action.payload.app_area) {
                            return {
                                ...area,
                                visible: action.payload.visible,
                                active: action.payload.active,
                            };
                        } else {
                            return area;
                        }
                    });

                    return {
                        ...it,
                        event: {
                            ...it.event,
                            app_areas: updatedAppAreas,
                        },
                    };
                } else {
                    return it;
                }
            });

            return {
                ...state,
                itineraries: itineraries,
            };
        default:
            return state;
    }
};

export default reducer;
