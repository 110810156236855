import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ErrorMessageContainer from '../../components/ErrorMessageContainer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postGrowthQuotient } from '../../reducers/itineraryActions';
import setResponseErrors from '../../utils/setResponseErrors';
import * as yup from 'yup';
import useWindowEvent from '../../hooks/useWindowEvent';
import AnimatedContainer from '../../components/AnimatedContainer';
import { Input, NumericalInput } from '../../components/Inputs';
import Button from '../../components/Button';

const PreambleSchema = yup.object().shape({
    organization_name: yup.string().required('Organization name is required'),
    number_of_employees: yup
        .number('Must be a number.')
        .required('Number of employees is required'),
});

const GQPreamble = ({ eventId, initialValues, updateInitialValues, next }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const ref = useRef();
    const [height, setHeight] = useState('100vh');
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={PreambleSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                dispatch(postGrowthQuotient(eventId, values)).then(
                    res => {
                        setSubmitting(false);
                        updateInitialValues(values);
                        history.push(next);
                    },
                    err => {
                        setResponseErrors(err, setFieldError);
                        setSubmitting(false);
                    },
                );
            }}>
            {({ isSubmitting }) => (
                <Form
                    ref={ref}
                    className="d-flex flex-column justify-content-center"
                    style={{
                        minHeight: `calc(${height} - 84px)`,
                    }}>
                    <h2 className="header-pretitle isRaaz mb-3 text-uppercase">
                        Stop 1
                    </h2>
                    <h1 className="lora mb-3">4GS Diagnostics</h1>
                    <p className="mb-4">
                        This is a 3-minute intake to calculate your
                        organization's Growth Quotient (GQ) across the 4 Growth
                        Streams. Please note that the intake references
                        practices and behaviors of your organization, not
                        yourself.
                    </p>
                    <Field
                        component={Input}
                        name="organization_name"
                        label="Organization"
                        className="mb-4"
                        disabled={isSubmitting}
                    />
                    <ErrorMessage
                        name="organization_name"
                        component={ErrorMessageContainer}
                    />

                    <Field
                        component={NumericalInput}
                        name="number_of_employees"
                        label="Number of employees"
                        className="mb-4"
                        disabled={isSubmitting}
                    />
                    <ErrorMessage
                        name="number_of_employees"
                        component={ErrorMessageContainer}
                    />
                    <Button
                        type="submit"
                        primary
                        rounded
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        style={{ alignSelf: 'flex-start' }}>
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default GQPreamble;
