import * as moment from 'moment';
import { getStartTime, getEndTime } from './getStartEndTime';

const createSchedule = (date, startTime, endTime) =>
    `${moment(date).format('D MMMM YYYY')}${getStartTime(
        date,
        startTime,
    ).format(' | LT')}–${getEndTime(date, endTime).format('LT')}`;

export default createSchedule;
