import { gql } from '@apollo/client';
import cardParts from './cardParts';

const GET_CARD_BY_FRIENDLY_ID = gql`
    query GetGrowthlabAppCard($friendlyId: ID!, $userId: ID) {
        card(friendlyId: $friendlyId) {
            ...cardParts
        }
    }
    ${cardParts}
`;

export default GET_CARD_BY_FRIENDLY_ID;
