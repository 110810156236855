import React from 'react';
import Button from './Button';
import { useMutation, gql } from '@apollo/client';

const ATTEND_EVENT = gql`
    mutation AttendEvent($attendEventInput: AttendEventInput!) {
        attendEvent(input: $attendEventInput) {
            success
            errors
        }
    }
`;

const AttendanceButton = ({
    eventId,
    userId,
    successCb = () => {},
    errorCb = () => {},
    children,
    ...props
}) => {
    const [triggerEventAttendance, { loading }] = useMutation(ATTEND_EVENT);
    const onClickSubmit = async () => {
        const a = await triggerEventAttendance({
            variables: {
                attendEventInput: {
                    eventId,
                    userId,
                },
            },
        });
        if (a.data.attendEvent.success) {
            successCb();
        }
        try {
        } catch (error) {
            errorCb();
        }
    };

    return (
        <Button
            isLoading={loading}
            disabled={loading}
            onClick={onClickSubmit}
            {...props}>
            {children}
        </Button>
    );
};

export default AttendanceButton;
