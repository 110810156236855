import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
    QuestionHeader,
    QuestionBody,
    QuestionFooter,
} from './GrowthLabQuestionScreen';
import { useDispatch } from 'react-redux';
import { getMyGroup } from '../reducers/itineraryActions';
import Loader from '../components/Loader';
import useWindowEvent from '../hooks/useWindowEvent';
import Button from '../components/Button';
import { useParams } from 'react-router-dom';

const Member = ({ member }) => (
    <li className="d-flex flex-column align-items-center mx-auto">
        <div className="avatar avatar-sm mb-2">
            <img
                src={member ? member.avatar || '/icon.png' : '/icon.png'}
                alt={`${member.name}'s avatar'`}
                className="avatar-img rounded-circle"
            />
        </div>
        <span style={{ fontWeight: 600, fontSize: '14px' }}>{member.name}</span>
    </li>
);

const BreakoutGroupsScreen = ({ currentArea, goToItineraryGroup }) => {
    const [group, setGroup] = useState(null);
    const [height, setHeight] = useState('100vh');
    const dispatch = useDispatch();
    const callback = useCallback(() => {
        setHeight(`${window.innerHeight}px`);
    }, []);
    useWindowEvent('resize', callback);
    const { eventId } = useParams();

    useEffect(() => {
        dispatch(getMyGroup(currentArea.eventId)).then(data => setGroup(data));
    }, []);

    if (!group) return <Loader fullscreen />;

    return (
        <div className="QuestionInput v2">
            <div
                className="d-flex flex-column justify-content-center"
                style={{
                    minHeight: `calc(${height} - 84px)`,
                }}>
                <h2 className="header-pretitle isRaaz mb-3 text-uppercase">
                    {currentArea.title}
                </h2>
                <h1
                    className="lora mb-4"
                    dangerouslySetInnerHTML={{
                        __html: currentArea.description,
                    }}
                />
                <div
                    className="d-flex flex-column
                align-items-center
                justify-content-center
                bg-ignite-blue text-light mb-4 p-5">
                    <span style={{ lineHeight: 1 }} className="large-text">
                        Group {group.group}
                    </span>
                </div>

                <ul className="list-unstyled d-flex align-items-center justify-content-center">
                    {group.members.map((member, i) => (
                        <Member key={i} member={member} />
                    ))}
                </ul>
                <div className="mt-3">
                    <Button to={`/growthlab-app/${eventId}`} primary>
                        Done
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BreakoutGroupsScreen;
